import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import Typography from '~/components/elements/Typography/Typography'
import { TextEntryState } from '~/types'

interface TextEntryDialogProps extends BasePuiDialogProps {
  documentElement?: TextEntryState
  onSave: (data: TextEntryState) => void
}

export const TextEntryDialog = ({
  open,
  onClose,
  onSave,
  documentElement,
}: TextEntryDialogProps) => {
  const { t } = useTranslation(['Common', 'Dialogs'])

  const { fields, validate, reset } = useFields([
    {
      name: 'title',
      label: `${t('Dialogs:TEXT_ENTRY_DIALOG.TEXT_FIELD_LABEL')}*`,
      validators: ['required'],
      initialValue: documentElement?.label || '',
    },
  ])

  const { title } = fields

  const handleAddToForm = () => {
    if (validate()) {
      onSave({
        label: title.value,
      })
      reset()
      onClose?.()
    }
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <Grid width={150}>
          <ButtonWithLoader fullWidth onClick={handleAddToForm}>
            {t('Dialogs:COMMON.ADD_TO_FORM')}
          </ButtonWithLoader>
        </Grid>
      }
      header={
        <Grid container>
          <Grid item xs={12}>
            <Typography.Paragraph>
              {t(
                'Dialogs:TEXT_ENTRY_DIALOG.USER_CAN_ENTER_TEXT_IN_FIELD_IN_RESPONSE_TO_THIS_QUESTION',
              )}
            </Typography.Paragraph>
          </Grid>
        </Grid>
      }
      maxWidth="xl"
      open={open}
      title={t('Dialogs:TEXT_ENTRY_DIALOG.ADD_TEXT_ENTRY')}
      onClose={onClose}
    >
      <Grid container>
        <Grid item px={2} xs={12}>
          <PuiTextField field={title} label={title.label} />
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

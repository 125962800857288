import * as R from 'ramda'
import {
  ConstantMap,
  FieldObject,
  NamedEntity,
  Utils,
} from '@pbt/pbt-ui-components'

import { ExceptionColumnType } from '~/constants/migration'
import i18n from '~/locales/i18n'
import { MigrationConstant } from '~/types'
import {
  MigrationException,
  MigrationStructure,
  ProposedColumn,
} from '~/types/entities/migration'

const isNotNil = R.complement(R.isNil)
export const extractExceptionRow = R.pipe(
  R.props(['pimsValue1', 'pimsValue2', 'rhapsodyValue1', 'rhapsodyValue2']),
  R.filter(isNotNil),
)
export const extractExceptionValues = R.pipe(
  R.props(['rhapsodyValue1', 'rhapsodyValue2']),
  R.filter(isNotNil),
)

export const buildCellId = (columnId: string, rowIndex: number) =>
  `${columnId}-${rowIndex}`

const getOptionSubTypesById = (id: number, options: MigrationConstant[]) => {
  const targetOption = R.find(R.propEq('id', id), options)

  return targetOption?.subTypes
}

export const getColumnOptions = (
  fields: FieldObject,
  column: ProposedColumn,
) => {
  if (!column.groupColumn) {
    return column.options
  }

  const field = fields[column.groupColumn]

  if (column.isGroupColumnSubTypes) {
    const subTypes = getOptionSubTypesById(
      field?.value,
      column?.options as MigrationConstant[],
    )

    if (subTypes) {
      return subTypes
    }
  }

  return column?.options?.[field?.value]
}

// @TODO: temporary add this do do case-insensitive search
const findIdByName = (
  criteria: string | null,
  haystack: MigrationConstant[] = [],
) => {
  const needle = R.find(
    ({ name }) => name.toLowerCase() === criteria?.toLowerCase(),
    haystack,
  )
  return needle?.id
}

export const getInitialCellOptions = (
  column: ProposedColumn,
  columns: ProposedColumn[],
  value: string | null,
  valuesList: Record<string, string | null>,
) => {
  if (!column.groupColumn) {
    return column.options || []
  }

  const parentColumnIndex = R.findIndex(
    R.propEq('id', column.groupColumn),
    columns,
  )
  const parentOptions = columns[parentColumnIndex]
    .options as MigrationConstant[]
  const parentValueId = Number(
    parentOptions && findIdByName(valuesList[parentColumnIndex], parentOptions),
  )

  if (column.isGroupColumnSubTypes) {
    const subTypes = getOptionSubTypesById(
      parentValueId,
      column?.options as MigrationConstant[],
    )

    if (subTypes) {
      return subTypes
    }
  }

  return (parentValueId && column?.options?.[parentValueId]) || []
}

export const buildField = ({
  proposedColumns,
  column,
  columnIndex,
  valuesList,
}: {
  column: ProposedColumn
  columnIndex: number
  proposedColumns: ProposedColumn[]
  valuesList: Record<string, string>
}) => {
  const value = valuesList[columnIndex] || null
  const options = getInitialCellOptions(
    column,
    proposedColumns,
    value,
    valuesList,
  )
  const initialValue =
    findIdByName(value, options as MigrationConstant[]) || null

  return {
    name: column.id,
    initialValue,
  }
}

export const getFields = (
  proposedColumns: ProposedColumn[] = [],
  exception: MigrationException,
) => {
  if (!exception) {
    return []
  }

  return proposedColumns.map((column, columnIndex) => {
    const valuesList = extractExceptionValues(exception)
    return buildField({ proposedColumns, column, columnIndex, valuesList })
  })
}

export const getColumnValue = R.curry((fields, column) => {
  const idValue = fields[column.id]?.value
  const caredOptions = getColumnOptions(fields, column)

  return (
    Utils.findById(idValue, caredOptions as MigrationConstant[])?.name ?? null
  )
})

export const getRhapsodyValues = (
  fields: FieldObject,
  proposedColumns: ProposedColumn[],
) => {
  const getColumnValueForFields = getColumnValue(fields)
  return proposedColumns.map(getColumnValueForFields)
}

const getSubtypesByName = (constant: MigrationConstant[], name: string) =>
  R.find(R.propEq('name', name), constant)?.subTypes

export const chooseOptionsForEntityType = (
  type: string,
  Constants: ConstantMap,
  isObjectExpected?: boolean,
) => {
  const [constantName, subtypeName] = type?.split('.') || []
  const constant = (Constants[constantName as keyof typeof Constants] ||
    []) as MigrationConstant[]
  const constantOptions =
    Array.isArray(constant) || isObjectExpected ? constant : R.values(constant)

  return subtypeName
    ? getSubtypesByName(constant, subtypeName)
    : constantOptions
}

export const filterSupportedEntityOptions = (
  options: MigrationConstant[],
  typesIds: string[],
) => options.filter(({ id }) => typesIds.includes(id))

export const buildScopeToggleProps = (DictionaryScope: NamedEntity[] = []) => {
  const SessionScope = Utils.findByName('Session', DictionaryScope) || {}
  const PIMSScope = Utils.findByName('PIMS', DictionaryScope) || {}
  const GenericScope = Utils.findByName('Generic', DictionaryScope) || {}

  return {
    noneLabel: SessionScope.name?.[0],
    noneValue: SessionScope.id,
    onLabel: PIMSScope.name?.[0],
    onValue: PIMSScope.id,
    offLabel: GenericScope.name?.[0],
    offValue: GenericScope.id,
  }
}

const currentPIMSString = i18n.t(
  'Abbreviations:ACRONYMS.PRODUCTION_INFORMATION_MANAGEMENT_SYSTEM.CURRENT_PIMS',
)

export const getActualColumns = ({
  field,
  entity,
  humanReadableName,
}: MigrationStructure) => {
  if (field === 'BREED') {
    return [
      {
        id: 'BREED-Species-Pims',
        name: `${currentPIMSString}: ${i18n.t(
          'Businesses:MIGRATION.PATIENT_SPECIES',
        )}`,
        type: ExceptionColumnType.STRING,
      },
      {
        id: 'BREED-Breed-Pims',
        name: `${currentPIMSString}: ${i18n.t(
          'Businesses:MIGRATION.PATIENT_BREED',
        )}`,
        type: ExceptionColumnType.STRING,
      },
    ]
  }

  // EVENT_SUBTYPE
  // TODO: would be nice to change type to EVENT_SUBTYPE in BE also and use this name
  if (field === 'SUBTYPE') {
    return [
      {
        id: 'SUBTYPE-Types-Pims',
        name: `${currentPIMSString}: ${i18n.t('Businesses:TYPES')}`,
        type: ExceptionColumnType.STRING,
      },
      {
        id: 'SUBTYPE-Subtypes-Pims',
        name: `${currentPIMSString}: ${i18n.t(
          'Businesses:MIGRATION.SUBTYPES',
        )}`,
        type: ExceptionColumnType.STRING,
      },
    ]
  }

  return [
    {
      id: `${entity}-${humanReadableName}`,
      name: `${currentPIMSString}: ${humanReadableName}`,
      type: ExceptionColumnType.STRING,
    },
  ]
}

export const getProposedColumns = (
  {
    field,
    entity,
    humanReadableName,
    constantName,
    supportedTypeIds,
  }: MigrationStructure,
  Constants: ConstantMap,
): ProposedColumn[] => {
  const columnType = `${field}_${entity}`

  if (field === 'BREED') {
    return [
      {
        id: 'BREED-Species',
        name: `${i18n.t('Businesses:MIGRATION.PROPOSED_VALUE')}: ${i18n.t(
          'Businesses:MIGRATION.SPECIES',
        )}`,
        type: ExceptionColumnType.SELECT,
        options: chooseOptionsForEntityType('Species', Constants),
      },
      {
        id: 'BREED-Breed',
        name: `${i18n.t('Businesses:MIGRATION.PROPOSED_VALUE')}: ${i18n.t(
          'Businesses:MIGRATION.BREEDS',
        )}`,
        type: ExceptionColumnType.AUTOCOMPLETE,
        options: chooseOptionsForEntityType('Breed', Constants, true),
        groupColumn: 'BREED-Species',
      },
    ]
  }

  // EVENT_SUBTYPE
  // TODO: would be nice to change type to EVENT_SUBTYPE in BE also and use this name
  if (field === 'SUBTYPE') {
    const options = chooseOptionsForEntityType(
      'EventType',
      Constants,
    ) as MigrationConstant[]

    return [
      {
        id: 'SUBTYPE-Types',
        name: `${i18n.t('Businesses:MIGRATION.PROPOSED_VALUE')}: ${i18n.t(
          'Businesses:TYPES',
        )}`,
        type: ExceptionColumnType.SELECT,
        options: filterSupportedEntityOptions(options, supportedTypeIds),
      },
      {
        options,
        id: 'SUBTYPE-Subtypes',
        name: `${i18n.t('Businesses:MIGRATION.PROPOSED_VALUE')}: ${i18n.t(
          'Businesses:MIGRATION.SUBTYPES',
        )}`,
        type: ExceptionColumnType.AUTOCOMPLETE,
        groupColumn: 'SUBTYPE-Types',
        isGroupColumnSubTypes: true,
      },
    ]
  }

  return [
    {
      id: `${entity}-${columnType}`,
      name: `${i18n.t(
        'Businesses:MIGRATION.PROPOSED_VALUE',
      )}: ${humanReadableName}`,
      type: ExceptionColumnType.SELECT,
      options: chooseOptionsForEntityType(constantName, Constants),
    },
  ]
}

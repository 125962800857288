import React, { ChangeEvent, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import {
  AlertIconType,
  PermissionArea,
  PuiAlert,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import {
  fetchZoomSettings,
  getIsZoomSettingsLoading,
  getZoomIsTestingConnection,
  getZoomLastTestCredentialsValid,
  getZoomSettings,
  updateZoomSettings,
} from '~/store/duck/conferencing'
import {
  clearEmailAppointmentDummyConfig,
  clearEmailAppointmentDummyTemplate,
  fetchEmailZoomLinkDummyConfig,
  fetchEmailZoomLinkDummyTemplate,
  getEmailAppointmentDummyTemplate,
  getEmailAppointmentTemplateIsReceiving,
  getEmailZoomLinkDummyConfig,
} from '~/store/duck/emailAppointment'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import {
  BasePracticeDetailsSectionProps,
  PracticeTelehealthIntegrationFields,
} from '~/types'
import { hasHtmlFieldChanged } from '~/utils/htmlUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../practices'
import MessageWithPreviewConfiguration from './wellness-plans/MessageWithPreviewConfiguration'
import ZoomIntegration from './ZoomIntegration'

const ZOOM_INTEGRATION_NAME = 'Zoom'

const TelehealthIntegration = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const lastTestCredentialsValid = useSelector(getZoomLastTestCredentialsValid)
  const emailZoomLinkDummyConfig = useSelector(getEmailZoomLinkDummyConfig)
  const initialZoomSettings = useSelector(getZoomSettings)
  const businessId = business.id

  const [showConfirmDisconnectAlert, setShowConfirmDisconnectAlert] =
    useState(false)
  const [connectionTestAlertOpen, setConnectionTestAlertOpen] = useState(false)

  const [openEmailTemplatePreviewDialog] = useDialog(
    DialogNames.EMAIL_TEMPLATE_PREVIEW_DIALOG,
  )

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'integrationEnabled',
        label: t('Businesses:INTEGRATION.ZOOM_INTEGRATION'),
        type: 'toggle',
        initialValue: initialZoomSettings.integrationEnabled || false,
      },
      {
        name: 'linkMessage',
        initialValue: business.telehealthSettings?.linkMessage || '',
      },
    ],
    false,
  )

  const { integrationEnabled, linkMessage } = fields

  usePracticeFieldsSection<PracticeTelehealthIntegrationFields>({
    business,
    fields,
    parsedFields: {
      telehealthSettings: {
        linkMessage: linkMessage.value,
      },
    },
    sectionName: PracticeDetailsPanels.TELEHEALTH_INTEGRATION,
    validate,
    reset,
    resetDependencies: [business, initialZoomSettings],
    softUpdate: R.any(Boolean, [
      hasHtmlFieldChanged(linkMessage),
      integrationEnabled.value !== integrationEnabled.initialValue,
    ]),
  })

  useEffect(() => {
    if (businessId) {
      dispatch(fetchZoomSettings(businessId))
    }
  }, [businessId])

  const connectionTestAlertConfig = {
    iconType: lastTestCredentialsValid
      ? AlertIconType.SUCCESS
      : AlertIconType.WARN,
    text: lastTestCredentialsValid
      ? t('Businesses:INTEGRATION.YOU_ARE_NOW_SET_UP_WITH_ADD_ZOOM_MEETINGS')
      : t(
          'Businesses:INTEGRATION.WE_ARE_UNABLE_TO_CONNECT_TO_YOUR_ZOOM_ACCOUNT',
        ),
  }

  const openTelehealthLinkMessagePreview = () => {
    const config = {
      ...emailZoomLinkDummyConfig,
      message: linkMessage.value,
    }

    openEmailTemplatePreviewDialog({
      title: t('Businesses:INTEGRATION.PREVIEW_EMAIL_ZOOM_LINK'),
      subtitle: t('Businesses:PREVIEW_INCLUDES_SAMPLE_CLIENT_AND_PATIENT'),
      fetchTemplate: () => dispatch(fetchEmailZoomLinkDummyTemplate(config)),
      getTemplateSelector: getEmailAppointmentDummyTemplate,
      getIsLoadingSelector: getEmailAppointmentTemplateIsReceiving,
    })
  }

  useEffect(() => {
    dispatch(clearEmailAppointmentDummyTemplate())
    dispatch(clearEmailAppointmentDummyConfig())
  }, [business?.id])

  useEffect(() => {
    if (!emailZoomLinkDummyConfig) {
      dispatch(fetchEmailZoomLinkDummyConfig())
    }
  }, [emailZoomLinkDummyConfig])

  const onDisconnectAlertCancelDiscard = () => {
    setShowConfirmDisconnectAlert(false)
  }

  const onDisconnectAlertApprove = () => {
    integrationEnabled.setValue(false)
    setConnectionTestAlertOpen(false)
    setShowConfirmDisconnectAlert(false)
    dispatch(
      updateZoomSettings({
        businessId: business.id,
        integrationEnabled: false,
      }),
    )
  }

  const onToggle = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      if (initialZoomSettings?.integrationEnabled) {
        setShowConfirmDisconnectAlert(true)
      } else {
        reset()
      }
    } else {
      integrationEnabled.set(event)
    }
  }

  const onConnectionTestingFinished = () => {
    setConnectionTestAlertOpen(true)
  }

  const setShowResultAfterSettingsUpdate = useCloseAfterCreation(
    onConnectionTestingFinished,
    getIsZoomSettingsLoading,
  )
  const setShowResultAfterConnectionTesting = useCloseAfterCreation(
    onConnectionTestingFinished,
    getZoomIsTestingConnection,
  )
  return (
    <Grid container>
      <MessageWithPreviewConfiguration
        resetStateOnValueChange
        disabled={!permissions.update}
        field={linkMessage}
        previewDisabled={!emailZoomLinkDummyConfig}
        tooltipText={t(
          'Businesses:INTEGRATION.SEND_YOUR_CLIENTS_MEETING_LINKS_AND_INFORMATION',
        )}
        tooltipTitle={t('Businesses:INTEGRATION.SHARE_TELEHEALTH_LINK')}
        onPreview={openTelehealthLinkMessagePreview}
      />
      <Grid container item direction="column">
        <Text strong mt={2} variant="subheading3">
          {t('Businesses:INTEGRATION.ZOOM_INTEGRATION')}
        </Text>
        <PuiSwitch
          disabled={!permissions.update}
          field={{
            ...integrationEnabled,
            set: onToggle,
          }}
          label={integrationEnabled.label}
        />

        {integrationEnabled.value && (
          <ZoomIntegration
            business={business}
            setShowResultAfterConnectionTesting={
              setShowResultAfterConnectionTesting
            }
            setShowResultAfterSettingsUpdate={setShowResultAfterSettingsUpdate}
          />
        )}
      </Grid>
      <PuiAlert
        cancelButtonText={t('Businesses:INTEGRATION.DISCONNECT_NAME', {
          name: ZOOM_INTEGRATION_NAME,
        })}
        iconType={AlertIconType.WARN}
        message={t('Businesses:INTEGRATION.TURN_OFF_INTEGRATION_ZOOM_MESSAGE')}
        okButtonText={t('Businesses:INTEGRATION.KEEP_CONNECTION')}
        open={showConfirmDisconnectAlert}
        onCancel={onDisconnectAlertApprove}
        onClose={onDisconnectAlertCancelDiscard}
        onOk={onDisconnectAlertCancelDiscard}
      />
      <PuiAlert
        iconType={connectionTestAlertConfig.iconType}
        message={connectionTestAlertConfig.text}
        open={connectionTestAlertOpen}
        onClose={() => setConnectionTestAlertOpen(false)}
        onOk={() => setConnectionTestAlertOpen(false)}
      />
    </Grid>
  )
}

export default memo(TelehealthIntegration, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)

import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTextField,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import {
  clearMatchChewyUserByEmail,
  getEmailMatches,
  getIsEmailsLoading,
  getMatchChewyUserByEmail,
  getMatchChewyUserIsLoading,
  getMatchChewyUserResult,
  resetChewyPets,
} from '~/store/duck/chewyPets'

import EmailLookupWithFallback from './EmailLookupWithFallback'

const useStyles = makeStyles(
  (theme) => ({
    radio: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    paper: {
      minWidth: 700,
    },
    dialogTitle: {
      borderBottom: 'none',
    },
    otherInput: {
      marginTop: 0,
    },
  }),
  { name: 'LinkChewyAccountDialog' },
)

const USE_OTHER_EMAIL_ADDRESS = 'use-other-email-address'

interface LinkChewyAccountDialogProps extends BasePuiDialogProps {
  onSubmit: (emailToSave: string) => void
}

const LinkChewyAccountDialog = ({
  open,
  onClose,
  onSubmit,
}: LinkChewyAccountDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsEmailsLoading)
  const userMatches = useSelector(getEmailMatches)
  const matchChewyUserResult = useSelector(getMatchChewyUserResult)
  const matchChewyUserIsLoading = useSelector(getMatchChewyUserIsLoading)
  const { t } = useTranslation(['Common', 'Dialogs'])

  const [currentValue, setCurrentValue] = useState('')
  const [checkedEmail, setCheckedEmail] = useState<string>('')

  const matchingEmails = userMatches.filter(
    (userMatch) => userMatch.hasKyriosId,
  )

  const noEmailMatch = matchingEmails.length === 0

  const userEmails = useMemo(
    () => userMatches.map((match) => match.email),
    [userMatches],
  )

  useEffect(
    () => () => {
      dispatch(resetChewyPets())
    },
    [],
  )

  const { fields, validate } = useFields([
    {
      name: 'otherEmail',
      label: t('Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.OTHER_EMAIL_ADDRESS'),
      initialValue: '',
      messages: {
        consistencyValidator: t(
          'Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.EMAIL_TAMPER',
        ),
      },
      validators: [
        {
          validatorName: 'consistencyValidator',
          validator: (state) => state.value === checkedEmail,
        },
      ],
    },
  ])
  const { otherEmail } = fields

  const handleSubmit = () => {
    if (currentValue !== USE_OTHER_EMAIL_ADDRESS) {
      // radio button used
      onSubmit(currentValue)
    } else if (validate()) {
      // other email option same as when checked
      onSubmit(otherEmail.value)
    } else {
      // email modified since checked clear match
      dispatch(clearMatchChewyUserByEmail())
    }
  }

  const checkEmail = () => {
    setCheckedEmail(otherEmail.value)
    dispatch(getMatchChewyUserByEmail({ email: otherEmail.value }))
  }

  const isOtherEmailError =
    currentValue === USE_OTHER_EMAIL_ADDRESS && matchChewyUserResult === false
  return (
    <>
      <PuiDialog
        classes={{
          dialogTitle: classes.dialogTitle,
          paper: classes.paper,
        }}
        open={open}
        onClose={onClose}
      >
        {isLoading ? (
          <Box display="flex" justifyContent="center" overflow="hidden" py={2}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {' '}
            {noEmailMatch ? (
              <EmailLookupWithFallback
                userFailedEmails={userEmails}
                onSubmit={onSubmit}
              />
            ) : (
              <>
                <Box p={2}>
                  <Text variant="h2">
                    {t('Dialogs:COMMON.CONNECT_CHEWY_ACCOUNT')}
                  </Text>
                </Box>
                <Box px={2}>
                  <Text strong variant="subheading3">
                    {t(
                      'Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.WE_FOUND_THE_FOLOWING_ACCOUNT',
                    )}
                  </Text>
                  <RadioGroup
                    value={currentValue}
                    onChange={(_, value) => {
                      setCurrentValue(value)
                    }}
                  >
                    {matchingEmails.map(({ email }) => (
                      <FormControlLabel
                        control={<Radio className={classes.radio} />}
                        key={email}
                        label={email}
                        value={email}
                      />
                    ))}
                    <Text strong variant="subheading3">
                      {t('Common:OR').toUpperCase()}
                    </Text>
                    <FormControlLabel
                      control={<Radio className={classes.radio} />}
                      key={USE_OTHER_EMAIL_ADDRESS}
                      label={t(
                        'Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.USE_OTHER_EMAIL_ADDRESS',
                      )}
                      value={USE_OTHER_EMAIL_ADDRESS}
                    />
                  </RadioGroup>
                  {currentValue === USE_OTHER_EMAIL_ADDRESS ? (
                    <Box px={4}>
                      <PuiTextField
                        className={classes.otherInput}
                        error={isOtherEmailError}
                        field={otherEmail}
                        helperText={
                          isOtherEmailError &&
                          t(
                            'Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.EMAIL_NOT_ASSOCIATED',
                          )
                        }
                        label={t(
                          'Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.OTHER_EMAIL_ADDRESS',
                        )}
                      />
                    </Box>
                  ) : null}
                </Box>
                <Box pl={2} py={2}>
                  {currentValue === USE_OTHER_EMAIL_ADDRESS &&
                  !matchChewyUserResult ? (
                    <ButtonWithLoader
                      color="primary"
                      loading={matchChewyUserIsLoading}
                      onClick={checkEmail}
                    >
                      {t(
                        'Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.CHECK_CHEWY_ACCOUNT_EMAIL',
                      )}
                    </ButtonWithLoader>
                  ) : (
                    <ButtonWithLoader color="primary" onClick={handleSubmit}>
                      {t('Dialogs:COMMON.CONNECT_CHEWY_ACCOUNT')}
                    </ButtonWithLoader>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </PuiDialog>
    </>
  )
}

export default LinkChewyAccountDialog

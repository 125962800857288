import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Box,
  Collapse,
  FormControl,
  Input,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Field, PuiCheckbox, PuiSelect, Utils } from '@pbt/pbt-ui-components'

import AutoshipIcon from '~/components/elements/Icons/generated/AutoshipIcon'
import {
  AUTOSHIP_FREQUENCY_OPTIONS_MAP,
  AutoshipFrequencyOption,
} from '~/constants/autoship'
import { getAutoshipUnit } from '~/store/reducers/constants'

import { AutoshipRefillTooltip } from './AutoshipRefillTooltip'

interface AutoshipFrequencySelectProps {
  autoshipFrequencyField: Field
  autoshipUnitIdField: Field
  enableAutoshipField: Field
}

const StyledContainer = styled('div')`
  display: flex;
  width: 100%;
`

const StyledAutoshipCollapse = styled(Collapse)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}))

const StyledPuiCheckbox = styled(PuiCheckbox)`
  display: flex;

  .MuiSvgIcon-root {
    margin-right: 5px;
  }
`

const StyledFormControl = styled(FormControl)`
  width: 25rem;
`

const AutoshipFrequencySelect = ({
  autoshipFrequencyField,
  autoshipUnitIdField,
  enableAutoshipField,
}: AutoshipFrequencySelectProps) => {
  const { t } = useTranslation(['Common', 'Soap'])

  const autoshipUnitsList = useSelector(getAutoshipUnit)
  const initializedAutoshipFrequency: AutoshipFrequencyOption = {
    uom: Utils.getConstantName(autoshipUnitIdField?.value, autoshipUnitsList),
    value: autoshipFrequencyField?.value,
  }

  const [enableAutoship, setEnableAutoship] = useState(
    enableAutoshipField?.value ?? false,
  )
  const [autoshipFrequencyOption, setAutoshipFrequencyOption] =
    useState<AutoshipFrequencyOption>(initializedAutoshipFrequency)

  const handleSelectorChange = (event: SelectChangeEvent<unknown>) => {
    const selectedOption = AUTOSHIP_FREQUENCY_OPTIONS_MAP.find(
      (item) => item.id === (event.target.value as string),
    )?.option
    setAutoshipFrequencyOption(selectedOption || initializedAutoshipFrequency)
  }

  const handleCheckboxChange = () => {
    setEnableAutoship((prevValue: boolean) => !prevValue)
  }

  useEffect(() => {
    const autoshipUnitValue: string = Utils.findConstantIdByName(
      autoshipFrequencyOption.uom,
      autoshipUnitsList,
    )
    autoshipFrequencyField?.setValue(autoshipFrequencyOption.value)
    autoshipUnitIdField?.setValue(autoshipUnitValue)
    enableAutoshipField?.setValue(enableAutoship)
  }, [enableAutoship, autoshipFrequencyOption])

  return (
    <StyledContainer>
      <StyledPuiCheckbox
        checked={enableAutoship}
        label={
          <Box alignItems="center" display="flex">
            <AutoshipIcon />
            <div>{t('Soap:AUTOSHIP.ADD_AUTOSHIP')}</div>
            <StyledAutoshipCollapse
              in={!enableAutoship}
              orientation="horizontal"
            >
              <AutoshipRefillTooltip />
            </StyledAutoshipCollapse>
          </Box>
        }
        onChange={handleCheckboxChange}
      />
      <Collapse in={enableAutoship} orientation="horizontal">
        <StyledFormControl>
          <InputLabel htmlFor="task-repeat-select">
            {t('Common:FREQUENCY_ONE')}
          </InputLabel>
          <PuiSelect
            input={<Input id="autoship-frequency-select" />}
            items={AUTOSHIP_FREQUENCY_OPTIONS_MAP}
            renderEmpty={false}
            value={
              autoshipFrequencyOption
                ? `${autoshipFrequencyOption.uom}-${autoshipFrequencyOption.value}`
                : ''
            }
            onChange={handleSelectorChange}
          />
        </StyledFormControl>
      </Collapse>
    </StyledContainer>
  )
}

export default AutoshipFrequencySelect

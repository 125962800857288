// REMOVE in CVC-13486
export const versionsMap = {
  'version-3': {
    id: 'version-3',
    name: 'Version 3',
    active: true,
    activePatients: 100,
    plans: ['plan-1', 'plan-2'],
  },
  'version-2': {
    id: 'version-2',
    name: 'Version 2',
    active: false,
    activePatients: 80,
    plans: ['plan-3', 'plan-4', 'plan-7'],
  },
  'version-1': {
    id: 'version-1',
    name: 'Version 1',
    active: false,
    activePatients: 70,
    plans: ['plan-5', 'plan-6'],
  },
}

export const versionsList = ['version-3', 'version-2', 'version-1']

export const plansMap = {
  'plan-1': {
    id: 'plan-1',
    versionId: 'version-3',
    name: 'Standard plan',
    autoRenewInto: 'plan-1',
  },
  'plan-2': {
    id: 'plan-2',
    versionId: 'version-3',
    name: 'Lite plan',
    autoRenewInto: 'plan-2',
  },
  'plan-3': {
    id: 'plan-3',
    versionId: 'version-2',
    name: 'Standard plan',
    autoRenewInto: 'plan-3',
  },
  'plan-4': {
    id: 'plan-4',
    versionId: 'version-2',
    name: 'Lite plan',
    autoRenewInto: 'plan-4',
  },
  'plan-5': {
    id: 'plan-5',
    versionId: 'version-1',
    name: 'Standard plan',
    autoRenewInto: 'plan-3',
  },
  'plan-6': {
    id: 'plan-6',
    versionId: 'version-1',
    name: 'Lite plan',
    autoRenewInto: 'plan-4',
  },
  'plan-7': {
    id: 'plan-7',
    versionId: 'version-2',
    name: 'Lite plan 2',
    autoRenewInto: 'plan-4',
  },
}

export const plansList = [
  'plan-1',
  'plan-2',
  'plan-3',
  'plan-4',
  'plan-5',
  'plan-6',
  'plan-7',
]

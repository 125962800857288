import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import { DateUtils, Text, User } from '@pbt/pbt-ui-components'
import {
  Communications as CommunicationsIcon,
  Email as EmailIcon,
  EmailOpened as EmailOpenedIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import ConversationStatusLabel from '~/components/dashboard/communications/common/ConversationStatusLabel'
import { ConversationStatus } from '~/constants/communications'
import DialogNames from '~/constants/DialogNames'
import {
  LandingType,
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import i18n from '~/locales/i18n'
import {
  setAssigneeForConversation,
  setIsReadForConversation,
} from '~/store/actions/conversations'
import {
  getWidgetData,
  getWidgetDataIsLoading,
  getWidgetDataTotalCount,
} from '~/store/duck/landing'
import { getCommunicationsWidgetAssignedMode } from '~/store/reducers/constants'
import {
  getConversationsIsUpdating,
  getMultipleConversations,
} from '~/store/reducers/conversations'
import { Conversation, WidgetColumn } from '~/types'
import { getConversationStatus } from '~/utils/communicationsUtils'
import useDialog from '~/utils/useDialog'

import DateGroupingCell from '../DateGroupingCell'
import LandingWidget, { LandingWidgetProps } from '../LandingWidget'
import NoRecords from '../NoRecords'
import TableWidget from '../TableWidget'
// @ts-ignore
import useEnrichGroupedItemsByDate from '../useEnrichGroupedItemsByDate'
import UserSelectActionPopper from '../UserSelectActionPopper'
import { getVisibleWidgetColumns } from '../widgetUtils'
import AssigneeCell from './AssigneeCell'
import CommunicationTypeCell from './CommunicationTypeCell'

const columns: WidgetColumn<Conversation>[] = [
  {
    id: 'dateGrouping',
    prop: (item) => <DateGroupingCell title={item?.dateTitle} />,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 1.5,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 1.5,
      [WidgetWidthType.HALF_WIDTH]: 1.5,
      [WidgetWidthType.THIRD_WIDTH]: 1,
    },
    label: i18n.t('Common:DATE_TIME'),
  },
  {
    id: 'lastMessageDate',
    prop: (item) => (
      <Text strong variant="body2">
        {item?.lastMessageDate
          ? DateUtils.formatTime(item?.lastMessageDate)
          : ''}
      </Text>
    ),
    width: 1,
    widthTypes: [
      WidgetWidthType.FULL_WIDTH,
      WidgetWidthType.TWO_THIRDS_WIDTH,
      WidgetWidthType.HALF_WIDTH,
    ],
    label: i18n.t('Common:LAST_MESSAGE'),
  },
  {
    id: 'to',
    prop: 'to',
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 1.5,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 1.5,
      [WidgetWidthType.HALF_WIDTH]: 1.5,
      [WidgetWidthType.THIRD_WIDTH]: 1,
    },
    label: i18n.t('Common:TO'),
  },
  {
    id: 'title',
    prop: 'title',
    width: 2,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 2.5,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 2.5,
      [WidgetWidthType.HALF_WIDTH]: 2,
      [WidgetWidthType.THIRD_WIDTH]: 1,
    },
    label: i18n.t('Common:TITLE'),
  },
  {
    id: 'assignee',
    component: AssigneeCell,
    width: 2,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:ASSIGNED'),
  },
  {
    id: 'conversationStatus',
    prop: (item) => (
      <ConversationStatusLabel
        hideNotifications
        conversationId={item?.id}
        status={getConversationStatus(item)}
      />
    ),
    width: 1.5,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:STATUS'),
  },
  {
    id: 'communicationType',
    component: CommunicationTypeCell,
    width: 1.5,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:COMMUNICATION_TYPE'),
  },
]

interface CommunicationWidgetProps extends LandingWidgetProps<Conversation> {
  onExpand: () => void
  rowsCount?: number
  widthType?: WidgetWidthType
}

const CommunicationWidget = ({
  widthType = WidgetWidthType.FULL_WIDTH,
  rowsCount = 10,
  onExpand,
  ...rest
}: CommunicationWidgetProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Landing'])

  const CommunicationsWidgetAssignedMode = useSelector(
    getCommunicationsWidgetAssignedMode,
  )
  const conversationIds = useSelector(
    getWidgetData(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.COMMUNICATIONS,
    ),
  )
  const totalCount = useSelector(
    getWidgetDataTotalCount(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.COMMUNICATIONS,
    ),
  )
  const isLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.COMMUNICATIONS,
    ),
  )
  const conversations = useSelector(getMultipleConversations(conversationIds))
  const conversationsLoading = useSelector(getConversationsIsUpdating)

  const conversationsList = useEnrichGroupedItemsByDate({
    dateProp: 'lastMessageDate',
    titleProp: 'dateTitle',
    items: conversations,
  })

  const [openNewConversationDialog] = useDialog(
    DialogNames.NEW_CONVERSATION_WITH_STEPS,
  )

  const handleNavigateToCommunications = () => navigate('/communications')
  const goToConversation = (item?: Conversation) => {
    if (item) {
      navigate(`/communications/${item.id}`)
    }
  }

  const handleNavigateToItemDetails = goToConversation

  const handleGoToConversation = goToConversation

  const updateReadStatus = (conversationId: string, isRead: boolean) => {
    dispatch(setIsReadForConversation(conversationId, isRead))
  }

  const handleMarkAsRead = (item: Conversation) =>
    updateReadStatus(item.id, true)
  const handleMarkAsUnread = (item: Conversation) =>
    updateReadStatus(item.id, false)

  const handleAssignConversation = (assignee: User, item?: Conversation) => {
    if (item) {
      dispatch(setAssigneeForConversation(item.id, assignee.id))
    }
  }

  const handleCreateConversation = openNewConversationDialog

  const getActions = (item?: Conversation) =>
    [
      {
        id: 'root',
        isGroup: true,
        items: [
          {
            id: 'goToConversation',
            label: t('Common:GO_TO_CONVERSATION'),
            Icon: ArrowForwardIcon,
            onClick: () => handleGoToConversation(item),
          },
          item?.state === ConversationStatus.UNREAD && {
            id: 'markAsRead',
            label: t('Common:MARK_AS_READ'),
            Icon: EmailIcon,
            onClick: () => handleMarkAsRead(item),
          },
          item?.state === ConversationStatus.OPEN && {
            id: 'markAsRead',
            label: t('Common:MARK_AS_UNREAD'),
            Icon: EmailOpenedIcon,
            onClick: () => handleMarkAsUnread(item),
          },
          {
            id: 'assignConversation',
            label: t('Landing:COMMUNICATION_WIDGET.ASSIGN_CONVERSATION'),
            Icon: CommunicationsIcon,
            subItem: UserSelectActionPopper,
            onClick: (value) => handleAssignConversation(value, item),
          },
        ],
      },
    ] as PopperAction[]

  const visibleColumns = getVisibleWidgetColumns(columns, widthType)

  return (
    <LandingWidget
      showHeaderTitles
      assignedModes={CommunicationsWidgetAssignedMode}
      columns={visibleColumns}
      component={TableWidget}
      data={conversationsList}
      getActions={getActions}
      isLoading={isLoading || conversationsLoading}
      navigateToDetailsTooltip={t('Common:GO_TO_COMMUNICATIONS')}
      navigateToItemDetailsTooltip={t('Common:GO_TO_CONVERSATION')}
      noRecords={
        <NoRecords
          action={t('Common:NEW_CLIENT_CONVERSATION')}
          text={t('Landing:COMMUNICATION_WIDGET.NO_RECENT_COMMUNICATIONS')}
          onClick={handleCreateConversation}
        />
      }
      rowsCount={rowsCount}
      totalCount={totalCount}
      onExpand={onExpand}
      onNavigateToDetails={handleNavigateToCommunications}
      onNavigateToItemDetails={handleNavigateToItemDetails}
      {...rest}
    />
  )
}

export default CommunicationWidget

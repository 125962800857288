import * as React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const SvgChewyCIcon = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <g clipPath="url(#chewyCIcon_svg__a)">
      <path
        d="M17.301 12.56c-1.736.394-2.18 2.365-4.025 2.725-2.095.411-3.419-1.148-3.82-3.021-.334-1.537-.048-3.81 2.277-4.14 2.04-.296 2.52 1.739 4.79 1.31 1.349-.255 1.998-1.264 1.683-2.557-.498-1.96-3.753-3.398-7.323-2.697-4.596.9-7.705 4.813-6.69 9.725 1.044 5.073 6.065 6.72 10.036 5.891 5.112-1.067 6.005-4.523 5.725-5.665a2.17 2.17 0 0 0-2.653-1.572"
        fill="currentColor"
      />
    </g>,
    <defs>
      <clipPath id="chewyCIcon_svg__a">
        <path d="M4 4h16v16H4z" fill="currentColor" />
      </clipPath>
    </defs>,
  )
export default SvgChewyCIcon

export const FETCH_TASKS_LIST_FAILURE = 'tasks/FETCH_TASKS_LIST_FAILURE'
export const FETCH_TASKS_LIST_SUCCESS = 'tasks/FETCH_TASKS_LIST_SUCCESS'
export const FETCH_TASKS_LIST = 'tasks/FETCH_TASKS_LIST'

export const FETCH_TASK = 'tasks/FETCH_TASK'
export const FETCH_TASK_FAILURE = 'tasks/FETCH_TASK_FAILURE'
export const FETCH_TASK_SUCCESS = 'tasks/FETCH_TASK_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_TASKS_LIST_FAILURE =
  'tasks/FETCH_MORE_ITEMS_FOR_TASKS_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_TASKS_LIST_SUCCESS =
  'tasks/FETCH_MORE_ITEMS_FOR_TASKS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_TASKS_LIST =
  'tasks/FETCH_MORE_ITEMS_FOR_TASKS_LIST'

export const CREATE_TASKS = 'tasks/CREATE_TASKS'
export const CREATE_TASKS_FAILURE = 'tasks/CREATE_TASKS_FAILURE'
export const CREATE_TASKS_SUCCESS = 'tasks/CREATE_TASKS_SUCCESS'

export const CREATE_TASK = 'tasks/CREATE_TASK'
export const CREATE_TASK_FAILURE = 'tasks/CREATE_TASK_FAILURE'
export const CREATE_TASK_SUCCESS = 'tasks/CREATE_TASK_SUCCESS'

export const EDIT_TASKS = 'tasks/EDIT_TASKS'
export const EDIT_TASKS_FAILURE = 'tasks/EDIT_TASKS_FAILURE'
export const EDIT_TASKS_SUCCESS = 'tasks/EDIT_TASKS_SUCCESS'

export const EDIT_TASK = 'tasks/EDIT_TASK'
export const EDIT_TASK_FAILURE = 'tasks/EDIT_TASK_FAILURE'
export const EDIT_TASK_SUCCESS = 'tasks/EDIT_TASK_SUCCESS'

export const PARTIAL_EDIT_TASK = 'tasks/PARTIAL_EDIT_TASK'
export const PARTIAL_EDIT_TASK_SUCCESS = 'tasks/PARTIAL_EDIT_TASK_SUCCESS'
export const PARTIAL_EDIT_TASK_FAILURE = 'tasks/PARTIAL_EDIT_TASK_FAILURE'

export const DELETE_TASKS = 'tasks/DELETE_TASKS'
export const DELETE_TASKS_FAILURE = 'tasks/DELETE_TASKS_FAILURE'
export const DELETE_TASKS_SUCCESS = 'tasks/DELETE_TASKS_SUCCESS'

export const DELETE_TASK = 'tasks/DELETE_TASK'
export const DELETE_TASK_FAILURE = 'tasks/DELETE_TASK_FAILURE'
export const DELETE_TASK_SUCCESS = 'tasks/DELETE_TASK_SUCCESS'

export const EDIT_TASK_STATE = 'tasks/EDIT_TASK_STATE'
export const EDIT_TASK_STATE_FAILURE = 'tasks/EDIT_TASK_STATE_FAILURE'
export const EDIT_TASK_STATE_SUCCESS = 'tasks/EDIT_TASK_STATE_SUCCESS'

export const UPDATE_TASKS = 'tasks/UPDATE_TASKS'

export const SET_TASKS_LIST_FILTERS = 'tasks/SET_TASKS_LIST_FILTERS'

export const FETCH_SOAP_TASKS = 'tasks/FETCH_SOAP_TASKS'
export const FETCH_SOAP_TASKS_FAILURE = 'tasks/FETCH_SOAP_TASKS_FAILURE'
export const FETCH_SOAP_TASKS_SUCCESS = 'tasks/FETCH_SOAP_TASKS_SUCCESS'
export const FETCH_SOAP_TASKS_IGNORED = 'tasks/FETCH_SOAP_TASKS_IGNORED'

export const ADD_TASK_TO_SOAP_LIST = 'tasks/ADD_TASK_TO_SOAP_LIST'

export const UPDATE_SOAP_TASKS_SELECTED_DATE =
  'tasks/UPDATE_SOAP_TASKS_SELECTED_DATE'

export const REMOVE_TASK_FROM_LIST = 'tasks/REMOVE_TASK_FROM_LIST'

export const CLEAR_TASKS_VALIDATION_ERROR = 'tasks/CLEAR_TASKS_VALIDATION_ERROR'
export const CLEAR_TASKS_ERROR = 'tasks/CLEAR_TASKS_ERROR'

export const CLEAR_SOAP_TASKS = 'tasks/CLEAR_SOAP_TASKS'

export const WS_TASK_CREATE = 'tasks/WS_TASK_CREATE'
export const WS_TASK_CREATE_SUCCESS = 'tasks/WS_TASK_CREATE_SUCCESS'
export const WS_TASK_CREATE_FAILURE = 'tasks/WS_TASK_CREATE_FAILURE'

export const WS_TASK_DELETE = 'tasks/WS_TASK_DELETE'
export const WS_TASK_DELETE_SUCCESS = 'tasks/WS_TASK_DELETE_SUCCESS'
export const WS_TASK_DELETE_FAILURE = 'tasks/WS_TASK_DELETE_FAILURE'

export const WS_TASK_UPDATE = 'tasks/WS_TASK_UPDATE'
export const WS_TASK_UPDATE_SUCCESS = 'tasks/WS_TASK_UPDATE_SUCCESS'
export const WS_TASK_UPDATE_FAILURE = 'tasks/WS_TASK_UPDATE_FAILURE'

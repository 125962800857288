import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Divider, Grid, SelectChangeEvent, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { CurrencyTextField, PuiSelect, Text } from '@pbt/pbt-ui-components'

import { getRefundReason } from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme) => ({
    totalRefundedText: {
      fontWeight: 500,
      color: theme.colors.secondaryText,
      textAlign: 'end',
    },
    row: {
      width: 98,
      justifyContent: 'flex-end',
    },
    refundDetailText: {
      textAlign: 'end',
    },
  }),
  { name: 'ChargeItemRefundCandidate' },
)

export interface ChargeItemRefundCandidateProps {
  hidden?: boolean
  onChangeRefundReason?: (value: SelectChangeEvent<unknown>) => void
  refundAmount?: number
  refundQuantity?: number
  refundReason?: string
  restockQuantity?: number
}

export const ChargeItemRefundCandidate = ({
  hidden = true,
  refundAmount = 0,
  refundReason = '',
  refundQuantity = 0,
  restockQuantity = 0,
  onChangeRefundReason,
}: ChargeItemRefundCandidateProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Invoices'])

  const refundReasonItems = useSelector(getRefundReason)

  if (hidden) {
    return null
  }

  return (
    <Grid container mt={5} pl={0.5} pr={1}>
      <Text strong pb={1} px={1} variant="subheading2">
        {t('Invoices:LINE_ITEM_DETAIL.REFUND_HEADER')}
      </Text>
      <Stack
        direction="column"
        divider={
          <Divider
            component="hr"
            orientation="horizontal"
            sx={{ width: 'auto' }}
          />
        }
      >
        <Grid
          container
          item
          alignItems="center"
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Text
            className={classes.refundDetailText}
            lineHeight={2}
            p={1}
            variant="body"
          >
            {t('Common:REASON')}
          </Text>
          <Grid container item className={classes.row}>
            <PuiSelect
              items={refundReasonItems}
              placeholder={t('Common:REASON')}
              value={refundReason}
              onChange={onChangeRefundReason}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Text
            className={classes.refundDetailText}
            lineHeight={2}
            p={1}
            variant="body"
          >
            {t('Invoices:LINE_ITEM_DETAIL.REFUND_QTY')}
          </Text>
          <Grid container item className={classes.row}>
            <Text className={classes.refundDetailText}>{refundQuantity}</Text>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Text
            className={classes.refundDetailText}
            lineHeight={2}
            p={1}
            variant="body"
          >
            {t('Invoices:LINE_ITEM_DETAIL.RESTOCK_QTY')}
          </Text>
          <Grid container item className={classes.row}>
            <Text className={classes.refundDetailText}>{restockQuantity}</Text>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Text
            className={classes.totalRefundedText}
            lineHeight={2}
            p={1}
            variant="body"
          >
            {t('Invoices:TO_BE_REFUNDED')}
          </Text>
          <CurrencyTextField
            noLonelyAdornment
            InputProps={{ disableUnderline: true }}
            className={classNames(classes.totalRefundedText, classes.row)}
            fullWidth={false}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              className: classes.totalRefundedText,
            }}
            value={refundAmount}
          />
        </Grid>
      </Stack>
    </Grid>
  )
}

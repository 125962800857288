import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'

import { isUnpostDisabled } from '~/components/dashboard/invoices/invoiceUtils'
import {
  fetchSoapCharges,
  getCharges,
  getChargesLoading,
} from '~/store/duck/charges'
import {
  getAppointmentId,
  getIsCurrentContextSoap,
  getSoapId,
} from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import { isChargesInvoiceType } from '~/utils/finance'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import { InvoiceCard } from './InvoiceCard'
import NoInvoiceCard from './NoInvoiceCard'

const useStyles = makeStyles(
  (theme) => ({
    chargeSheetContainer: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    button: {
      height: 42,
      minWidth: 172,
      backgroundColor: theme.colors.important,
    },
    noInvoice: {
      color: theme.colors.important,
      flexDirection: 'row',
    },
    link: {
      color: theme.colors.link,
    },
  }),
  { name: 'ChargeSheetSection' },
)

const ChargeSheetSection = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const appointmentId = useSelector(getAppointmentId)
  const soapId = useSelector(getSoapId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const { id } = useSelector(getUser(appointment?.client)) || {}
  const clientCharges = useSelector(getCharges)
  const clientChargesLoading = useSelector(getChargesLoading)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)

  useEffectExceptOnMount(() => {
    if (
      R.isNil(clientCharges) &&
      !clientChargesLoading &&
      id &&
      soapId &&
      isCurrentContextSoap
    ) {
      dispatch(fetchSoapCharges({ id, soapId }))
    }
  }, [clientCharges, id, soapId, isCurrentContextSoap, clientChargesLoading])

  return (
    <Grid
      container
      item
      className={classes.chargeSheetContainer}
      direction="column"
      mt={2}
    >
      {isChargesInvoiceType(clientCharges) ? (
        <InvoiceCard
          disabled={isUnpostDisabled(clientCharges)}
          id={clientCharges.id}
          invoiceName={R.prop('invoiceNo', clientCharges)}
        />
      ) : (
        <NoInvoiceCard />
      )}
    </Grid>
  )
}

export default ChargeSheetSection

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
  Text,
} from '@pbt/pbt-ui-components'

import { ChewyPet } from '~/api/graphql/generated/types'
import ClientAndPatient from '~/components/dashboard/soap/ClientAndPatient'
import {
  getIsPetsLoading,
  getMatchedPets,
  getUnassignedAutoMatches,
  getUnmatchedPets,
} from '~/store/duck/chewyPets'

import PetInfoLabel from './PetInfoLabel'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    radio: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  }),
  { name: 'LinkIndividualPatientDialog' },
)

interface LinkIndividualPatientDialogProps extends BasePuiDialogProps {
  clientId: string
  patientId?: string
  submitHandler: Function
}

const LinkIndividualPatientDialog = ({
  clientId,
  patientId,
  open,
  onClose,
  submitHandler = () => {},
}: LinkIndividualPatientDialogProps) => {
  const { t } = useTranslation(['Common', 'Dialogs'])
  const [currentValue, setCurrentValue] = React.useState<number | Nil>(null)
  const classes = useStyles()
  const isLoading = useSelector(getIsPetsLoading)
  const matchedPets = useSelector(getMatchedPets) || {}
  const unassignedAutoMatches = useSelector(getUnassignedAutoMatches()) || []
  const unmatchedPets = useSelector(getUnmatchedPets) || {}

  const petProfiles = useMemo(
    (): ChewyPet[] =>
      unmatchedPets
        ? [...unassignedAutoMatches, ...Object.values(unmatchedPets)]
        : [...unassignedAutoMatches],
    [unmatchedPets, matchedPets],
  )

  const onConnectClick = () => {
    if (R.isNil(currentValue)) return
    submitHandler(petProfiles[currentValue])
  }

  const disableConnectButton = R.isNil(currentValue)

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        title: t('Dialogs:LINK_INDIVIDUAL_PATIENT_DIALOG.EXIT_TITLE'),
        okLabel: t('Common:CONTINUE_ACTION'),
        notOkLabel: t('Common:EXIT_ACTION'),
        onOk: R.T,
      }}
      classes={{ paper: classes.paper }}
      hasUnsavedChanges={R.T}
      open={open}
      title={t('Dialogs:COMMON.CONNECT_CHEWY_ACCOUNT')}
      onClose={onClose}
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" overflow="hidden" py={2}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid pb={2}>
          <Grid item ml={2} my={1}>
            <ClientAndPatient clientId={clientId} patientId={patientId} />
          </Grid>
          <Grid item ml={2} my={1}>
            <Text>
              {t(
                'Dialogs:LINK_INDIVIDUAL_PATIENT_DIALOG.WE_FOUND_THE_FOLLOWING',
              )}
            </Text>
            <Text>
              {t('Dialogs:LINK_INDIVIDUAL_PATIENT_DIALOG.IF_THERE_IS_NO_MATCH')}
            </Text>
          </Grid>

          <Grid item ml={2} my={1}>
            <RadioGroup
              value={currentValue}
              onChange={(_, value) => {
                setCurrentValue(parseInt(value, 10))
              }}
            >
              {petProfiles.map((pet, idx) => (
                <FormControlLabel
                  control={<Radio className={classes.radio} />}
                  key={`${pet.name}`}
                  label={
                    <>
                      <Text inline strong>
                        {pet.name}:
                      </Text>
                      <PetInfoLabel inline petProfile={pet} />
                    </>
                  }
                  value={idx}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item ml={2} my={1}>
            <ButtonWithLoader
              color="primary"
              disabled={disableConnectButton}
              onClick={onConnectClick}
            >
              {t('Dialogs:COMMON.CONNECT_CHEWY_ACCOUNT')}
            </ButtonWithLoader>
          </Grid>
        </Grid>
      )}
    </PuiDialog>
  )
}

export default LinkIndividualPatientDialog

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiDialog, PuiDialogProps, Text } from '@pbt/pbt-ui-components'

import BoopSlaMessagesPreview, {
  BoopSlaMessagesPreviewProps,
} from './BoopSlaMessagesPreview'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
  }),
  { name: 'BoopSlaMessagesPreviewDialog' },
)

export interface BoopSlaMessagesPreviewDialogProps
  extends Pick<Partial<PuiDialogProps>, 'open' | 'onClose'>,
    Pick<BoopSlaMessagesPreviewProps, 'business' | 'config'> {}

const BoopSlaMessagesPreviewDialog = ({
  business,
  config,
  open = true,
  onClose,
}: BoopSlaMessagesPreviewDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Dialogs')

  return (
    <PuiDialog
      aria-labelledby="boop-sla-messages-preview-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid container item direction="column" p={3}>
        <Text variant="h2">
          {t('Dialogs:BOOP_SLA_MESSAGES_TEMPLATE_DIALOG.BOOP_SLA_TITLE')}
        </Text>
        <Text variant="body2">
          {t('Dialogs:COMMON.PREVIEW_INCLUDES_SUBTITLE')}
        </Text>
      </Grid>
      <BoopSlaMessagesPreview
        business={business}
        config={config}
        pb={3}
        pt={0}
        px={3}
      />
    </PuiDialog>
  )
}

export default BoopSlaMessagesPreviewDialog

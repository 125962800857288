import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

import AutoshipIcon from '~/components/elements/Icons/generated/AutoshipIcon'
import { AutoshipFrequencyOption } from '~/constants/autoship'

const useStyles = makeStyles(
  () => ({
    container: {},
    icon: {},
    label: {},
  }),
  { name: 'AutoshipInfo' },
)

export interface AutoshipInfoProps {
  readonly classes?: ClassesType<typeof useStyles>
  readonly frequency: AutoshipFrequencyOption
}

export const AutoshipInfo = ({
  classes: classesProp,
  frequency,
}: AutoshipInfoProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Soap')

  const { uom, value } = frequency
  const frequencyLabel =
    uom === 'Week'
      ? t('Soap:AUTOSHIP.FREQUENCY_PLURALS.WEEK', { value })
      : t('Soap:AUTOSHIP.FREQUENCY_PLURALS.MONTH', { value })
  const label = t('Soap:AUTOSHIP.AUTOSHIP_FREQUENCY', {
    frequency: frequencyLabel,
  })

  return (
    <Box alignItems="center" className={classes.container} display="flex">
      <AutoshipIcon className={classes.icon} />
      <Text className={classes.label} ml={0.5} variant="body2">
        {label}
      </Text>
    </Box>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid, Radio } from '@mui/material'
import { DateUtils, Text } from '@pbt/pbt-ui-components'

import {
  getAppointmentsList,
  getLastAppointmentsIsLoading,
} from '~/store/duck/lastAppointments'

interface LabResultPatientAppointmentsProps {
  selectedAppointmentId?: string
  setSelectedAppointmentId: (appointmentId: string | undefined) => void
}

const LabResultPatientAppointments = ({
  selectedAppointmentId,
  setSelectedAppointmentId,
}: LabResultPatientAppointmentsProps) => {
  const { t } = useTranslation('Dialogs')
  const patientAppointments = useSelector(getAppointmentsList)
  const isLoadingPatientAppointments = useSelector(getLastAppointmentsIsLoading)
  const areAppointmentsLoading =
    isLoadingPatientAppointments || !patientAppointments.length

  return (
    <>
      {areAppointmentsLoading ? (
        <Grid container item justifyContent="center">
          <CircularProgress color="secondary" size={32} />
        </Grid>
      ) : (
        <>
          {patientAppointments.map(({ appointmentId, startDate, typeName }) => (
            <Grid container alignItems="center" key={appointmentId}>
              <Grid item>
                <Radio
                  checked={selectedAppointmentId === appointmentId}
                  onChange={() => {
                    setSelectedAppointmentId(appointmentId)
                  }}
                />
              </Grid>
              <Grid item>
                <Text variant="body">
                  {typeName} {DateUtils.formatDate(startDate)}
                </Text>
              </Grid>
            </Grid>
          ))}
          <Grid container alignItems="center">
            <Grid>
              <Radio
                checked={!selectedAppointmentId}
                onChange={() => {
                  setSelectedAppointmentId(undefined)
                }}
              />
            </Grid>
            <Grid item>
              <Text variant="body">{t('Dialogs:COMMON.NO_EVENT')}</Text>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default LabResultPatientAppointments

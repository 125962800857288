import { ApiError, BillingAddress, Nil } from '@pbt/pbt-ui-components'

import {
  CreditAdjustmentInput,
  RefundOnAccountInput,
} from '~/api/graphql/generated/types'
import PaymentType from '~/constants/paymentTypes'
import {
  ExtendPayment,
  GoTransaction,
  PaymentPayload,
  TransactionData,
  UnsavedExtendPayment,
} from '~/types'

import {
  APPLY_PAYMENT_TO_INVOICE,
  APPLY_PAYMENT_TO_INVOICE_FAILURE,
  APPLY_PAYMENT_TO_INVOICE_SUCCESS,
  CHARGE_PAYMENT,
  CHARGE_PAYMENT_FAILURE,
  CHARGE_PAYMENT_SUCCESS,
  CLEAR_CLIENT_BILLING_ADDRESSES,
  CLEAR_PROCESSED_BILLING_ADDRESS,
  CLEAR_RHAPSODY_GO_ERROR,
  CLEAR_TRANSACTION_INFO,
  CREATE_BILLING_ADDRESS,
  CREATE_BILLING_ADDRESS_FAILURE,
  CREATE_BILLING_ADDRESS_SUCCESS,
  CREATE_CREDIT_ADJUSTMENT,
  CREATE_CREDIT_ADJUSTMENT_FAILURE,
  CREATE_CREDIT_ADJUSTMENT_SUCCESS,
  CREATE_MANUAL_PAYMENT,
  CREATE_MANUAL_PAYMENT_FAILURE,
  CREATE_MANUAL_PAYMENT_SUCCESS,
  CREATE_PAYMENT,
  CREATE_PAYMENT_FAILURE,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_TRANSACTION,
  CREATE_PAYMENT_TRANSACTION_FAILURE,
  CREATE_PAYMENT_TRANSACTION_SUCCESS,
  CREATE_REFUND_ON_ACCOUNT,
  CREATE_REFUND_ON_ACCOUNT_FAILURE,
  CREATE_REFUND_ON_ACCOUNT_SUCCESS,
  CREATE_STRIPE_PAYMENT,
  CREATE_STRIPE_PAYMENT_FAILURE,
  CREATE_STRIPE_PAYMENT_SUCCESS,
  EDIT_GO_PAYMENT,
  EDIT_GO_PAYMENT_FAILURE,
  EDIT_GO_PAYMENT_SUCCESS,
  EDIT_GO_STRIPE_PAYMENT,
  EDIT_GO_STRIPE_PAYMENT_FAILURE,
  EDIT_GO_STRIPE_PAYMENT_SUCCESS,
  EDIT_PAYMENT,
  EDIT_PAYMENT_FAILURE,
  EDIT_PAYMENT_SUCCESS,
  FETCH_BILLING_ADDRESSES,
  FETCH_BILLING_ADDRESSES_FAILURE,
  FETCH_BILLING_ADDRESSES_SUCCESS,
  FETCH_GO_PAYMENT,
  FETCH_GO_PAYMENT_FAILURE,
  FETCH_GO_PAYMENT_SUCCESS,
  FETCH_PAYMENT,
  FETCH_PAYMENT_FAILURE,
  FETCH_PAYMENT_SUCCESS,
  FIND_ON_ACCOUNT_ORIGINAL_PAYMENT,
  FIND_ON_ACCOUNT_ORIGINAL_PAYMENT_FAILURE,
  FIND_ON_ACCOUNT_ORIGINAL_PAYMENT_SUCCESS,
  GET_TRANSACTION_STATUS,
  GET_TRANSACTION_STATUS_FAILURE,
  GET_TRANSACTION_STATUS_SUCCESS,
  LINK_UNAPPLIED_PAYMENT,
  LINK_UNAPPLIED_PAYMENT_FAILURE,
  LINK_UNAPPLIED_PAYMENT_SUCCESS,
  LINK_UNAPPLIED_PAYMENTS,
  LINK_UNAPPLIED_PAYMENTS_FAILURE,
  LINK_UNAPPLIED_PAYMENTS_SUCCESS,
  REFUND_RHAPSODY_GO_STRIPE_TRANSACTION,
  REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE,
  REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS,
  REFUND_RHAPSODY_GO_TRANSACTION,
  REFUND_RHAPSODY_GO_TRANSACTION_FAILURE,
  REFUND_RHAPSODY_GO_TRANSACTION_SUCCESS,
  RHAPSODY_GO_APPLY_PAYMENT,
  RHAPSODY_GO_APPLY_PAYMENT_FAILURE,
  RHAPSODY_GO_APPLY_PAYMENT_SUCCESS,
  RHAPSODY_GO_AUTHORIZATION,
  RHAPSODY_GO_AUTHORIZATION_FAILURE,
  RHAPSODY_GO_AUTHORIZATION_SUCCESS,
  SETTLE_GO_STRIPE_TRANSACTION,
  SETTLE_GO_STRIPE_TRANSACTION_FAILURE,
  SETTLE_GO_STRIPE_TRANSACTION_SUCCESS,
  SETTLE_GO_TRANSACTION,
  SETTLE_GO_TRANSACTION_FAILURE,
  SETTLE_GO_TRANSACTION_SUCCESS,
  UNLINK_PAYMENT_TO_INVOICE,
  UNLINK_PAYMENT_TO_INVOICE_FAILURE,
  UNLINK_PAYMENT_TO_INVOICE_SUCCESS,
  UPDATE_BILLING_ADDRESS,
  UPDATE_BILLING_ADDRESS_FAILURE,
  UPDATE_BILLING_ADDRESS_SUCCESS,
  UPDATE_GO_PAYMENTS,
  UPDATE_GO_STRIPE_PAYMENTS,
  UPDATE_PAYMENTS,
  UPDATE_PENDING_PAYMENT_STATE,
  VOID_RHAPSODY_GO_STRIPE_TRANSACTION,
  VOID_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE,
  VOID_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS,
  VOID_RHAPSODY_GO_TRANSACTION,
  VOID_RHAPSODY_GO_TRANSACTION_FAILURE,
  VOID_RHAPSODY_GO_TRANSACTION_SUCCESS,
} from './types/payments'

export const updatePayments = (payments: Record<string, ExtendPayment>) => ({
  type: UPDATE_PAYMENTS,
  payments,
})
export const updateGoPayments = (
  goPayments: Record<string, ExtendPayment>,
) => ({
  type: UPDATE_GO_PAYMENTS,
  goPayments,
})
export const updateGoStripePayments = (
  goStripePayments: Record<string, ExtendPayment>,
) => ({
  type: UPDATE_GO_STRIPE_PAYMENTS,
  goStripePayments,
})

export const fetchPayment = (paymentId: string) => ({
  type: FETCH_PAYMENT,
  paymentId,
})
export const fetchPaymentSuccess = () => ({ type: FETCH_PAYMENT_SUCCESS })
export const fetchPaymentFailure = (error: ApiError) => ({
  type: FETCH_PAYMENT_FAILURE,
  error,
})

export const fetchGoPayment = (goPaymentId: string) => ({
  type: FETCH_GO_PAYMENT,
  goPaymentId,
})
export const fetchGoPaymentSuccess = () => ({ type: FETCH_GO_PAYMENT_SUCCESS })
export const fetchGoPaymentFailure = (error: ApiError) => ({
  type: FETCH_GO_PAYMENT_FAILURE,
  error,
})

export const createPayment = (
  clientId: string | Nil,
  payment: UnsavedExtendPayment,
) => ({
  type: CREATE_PAYMENT,
  clientId,
  payment,
})
export const createPaymentSuccess = (paymentId: string) => ({
  type: CREATE_PAYMENT_SUCCESS,
  paymentId,
})
export const createPaymentFailure = (error: ApiError) => ({
  type: CREATE_PAYMENT_FAILURE,
  error,
})

export const createManualPayment = (
  clientId: string | Nil,
  payload: PaymentPayload,
) => ({
  type: CREATE_MANUAL_PAYMENT,
  clientId,
  payload,
})
export const createManualPaymentSuccess = (paymentId: string) => ({
  type: CREATE_MANUAL_PAYMENT_SUCCESS,
  paymentId,
})
export const createManualPaymentFailure = (error: ApiError) => ({
  type: CREATE_MANUAL_PAYMENT_FAILURE,
  error,
})

export const createPaymentTransaction = (
  clientId: string,
  payment: ExtendPayment,
  withUnapplied?: boolean,
) => ({
  type: CREATE_PAYMENT_TRANSACTION,
  clientId,
  payment,
  withUnapplied,
})

export const createPaymentTransactionSuccess = () => ({
  type: CREATE_PAYMENT_TRANSACTION_SUCCESS,
})
export const createPaymentTransactionFailure = (error: ApiError) => ({
  type: CREATE_PAYMENT_TRANSACTION_FAILURE,
  error,
})

export const getTransactionStatusSuccess = (transaction: TransactionData) => ({
  type: GET_TRANSACTION_STATUS_SUCCESS,
  transaction,
})

export const getTransactionStatusFailure = (error: ApiError) => ({
  type: GET_TRANSACTION_STATUS_FAILURE,
  error,
})

export const updatePendingPaymentState = (payment: ExtendPayment) => ({
  type: UPDATE_PENDING_PAYMENT_STATE,
  payment,
})

export const getTransactionStatus = (payment: ExtendPayment) => ({
  type: GET_TRANSACTION_STATUS,
  payment,
})

export const clearTransactionInfo = () => ({ type: CLEAR_TRANSACTION_INFO })

export const refundRhapsodyGoTransaction = (
  paymentToRefund: ExtendPayment,
  transactionType: PaymentType,
) => ({
  type: REFUND_RHAPSODY_GO_TRANSACTION,
  paymentToRefund,
  transactionType,
})

export const refundRhapsodyGoTransactionFailure = (error: ApiError) => ({
  type: REFUND_RHAPSODY_GO_TRANSACTION_FAILURE,
  error,
})

export const refundRhapsodyGoTransactionSuccess = (
  processedPayment: ExtendPayment,
) => ({
  type: REFUND_RHAPSODY_GO_TRANSACTION_SUCCESS,
  processedPayment,
})

export const voidRhapsodyGoTransaction = (
  paymentToVoid: ExtendPayment,
  transactionType: PaymentType,
) => ({
  type: VOID_RHAPSODY_GO_TRANSACTION,
  paymentToVoid,
  transactionType,
})

export const voidRhapsodyGoTransactionFailure = (error: ApiError) => ({
  type: VOID_RHAPSODY_GO_TRANSACTION_FAILURE,
  error,
})

export const voidRhapsodyGoTransactionSuccess = (
  processedPayment: ExtendPayment,
) => ({
  type: VOID_RHAPSODY_GO_TRANSACTION_SUCCESS,
  processedPayment,
})

export const refundRhapsodyGoStripeTransaction = (
  paymentToRefund: ExtendPayment,
  transactionType: PaymentType,
) => ({
  type: REFUND_RHAPSODY_GO_STRIPE_TRANSACTION,
  paymentToRefund,
  transactionType,
})

export const refundRhapsodyGoStripeTransactionFailure = (error: ApiError) => ({
  type: REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE,
  error,
})

export const refundRhapsodyGoStripeTransactionSuccess = (
  processedPayment: ExtendPayment,
) => ({
  type: REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS,
  processedPayment,
})

export const voidRhapsodyGoStripeTransaction = (
  paymentToVoid: ExtendPayment,
  transactionType: PaymentType,
) => ({
  type: VOID_RHAPSODY_GO_STRIPE_TRANSACTION,
  paymentToVoid,
  transactionType,
})

export const voidRhapsodyGoStripeTransactionFailure = (error: ApiError) => ({
  type: VOID_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE,
  error,
})

export const voidRhapsodyGoStripeTransactionSuccess = (
  processedPayment: ExtendPayment,
) => ({
  type: VOID_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS,
  processedPayment,
})

export const fetchBillingAddresses = (clientId: string) => ({
  type: FETCH_BILLING_ADDRESSES,
  clientId,
})
export const fetchBillingAddressesSuccess = (
  billingAddresses: BillingAddress,
) => ({
  type: FETCH_BILLING_ADDRESSES_SUCCESS,
  billingAddresses,
})
export const fetchBillingAddressesFailure = (error: ApiError) => ({
  type: FETCH_BILLING_ADDRESSES_FAILURE,
  error,
})

export const createBillingAddress = (
  address: BillingAddress,
  clientId: string,
) => ({
  type: CREATE_BILLING_ADDRESS,
  address,
  clientId,
})
export const createBillingAddressSuccess = (address: BillingAddress) => ({
  type: CREATE_BILLING_ADDRESS_SUCCESS,
  address,
})
export const createBillingAddressFailure = (error: ApiError) => ({
  type: CREATE_BILLING_ADDRESS_FAILURE,
  error,
})

export const updateBillingAddress = (
  address: BillingAddress,
  clientId: string,
) => ({
  type: UPDATE_BILLING_ADDRESS,
  address,
  clientId,
})
export const updateBillingAddressSuccess = (address: BillingAddress) => ({
  type: UPDATE_BILLING_ADDRESS_SUCCESS,
  address,
})
export const updateBillingAddressFailure = (error: ApiError) => ({
  type: UPDATE_BILLING_ADDRESS_FAILURE,
  error,
})

export const chargePayment = (
  paymentData: ExtendPayment,
  transactionType: PaymentType,
) => ({
  type: CHARGE_PAYMENT,
  paymentData,
  transactionType,
})
export const chargePaymentSuccess = (paymentId: string) => ({
  type: CHARGE_PAYMENT_SUCCESS,
  paymentId,
})
export const chargePaymentFailure = (error: ApiError) => ({
  type: CHARGE_PAYMENT_FAILURE,
  error,
})

export const rhapsodyGoAuthorization = (paymentData: ExtendPayment) => ({
  type: RHAPSODY_GO_AUTHORIZATION,
  paymentData,
})
export const rhapsodyGoAuthorizationSuccess = (paymentId: string) => ({
  type: RHAPSODY_GO_AUTHORIZATION_SUCCESS,
  paymentId,
})
export const rhapsodyGoAuthorizationFailure = (error: ApiError) => ({
  type: RHAPSODY_GO_AUTHORIZATION_FAILURE,
  error,
})

export const rhapsodyGoApplyPayment = (payload: {
  auth: boolean
  paymentData: PaymentPayload
}) => ({
  type: RHAPSODY_GO_APPLY_PAYMENT,
  payload,
})
export const rhapsodyGoApplyPaymentSuccess = (paymentId: string) => ({
  type: RHAPSODY_GO_APPLY_PAYMENT_SUCCESS,
  paymentId,
})
export const rhapsodyGoApplyPaymentFailure = (error: ApiError) => ({
  type: RHAPSODY_GO_APPLY_PAYMENT_FAILURE,
  error,
})

export const clearProcessedBillingAddress = () => ({
  type: CLEAR_PROCESSED_BILLING_ADDRESS,
})

export const clearClientBillingAddresses = () => ({
  type: CLEAR_CLIENT_BILLING_ADDRESSES,
})

export const clearRhapsodyGoError = () => ({ type: CLEAR_RHAPSODY_GO_ERROR })

export const applyPaymentToInvoice = (
  paymentId: string,
  invoiceIds: string[],
) => ({
  type: APPLY_PAYMENT_TO_INVOICE,
  paymentId,
  invoiceIds,
})
export const applyPaymentToInvoiceFailure = (error: ApiError) => ({
  type: APPLY_PAYMENT_TO_INVOICE_FAILURE,
  error,
})
export const applyPaymentToInvoiceSuccess = () => ({
  type: APPLY_PAYMENT_TO_INVOICE_SUCCESS,
})

export const linkUnappliedPayment = (
  invoiceIds: string[],
  paymentId: string,
) => ({
  type: LINK_UNAPPLIED_PAYMENT,
  invoiceIds,
  paymentId,
})
export const linkUnappliedPaymentFailure = (error: ApiError) => ({
  type: LINK_UNAPPLIED_PAYMENT_FAILURE,
  error,
})
export const linkUnappliedPaymentSuccess = () => ({
  type: LINK_UNAPPLIED_PAYMENT_SUCCESS,
})

export const linkUnappliedPayments = (
  invoiceIds: string[],
  paymentIds: string[],
) => ({
  type: LINK_UNAPPLIED_PAYMENTS,
  invoiceIds,
  paymentIds,
})
export const linkUnappliedPaymentsFailure = (error: ApiError) => ({
  type: LINK_UNAPPLIED_PAYMENTS_FAILURE,
  error,
})
export const linkUnappliedPaymentsSuccess = () => ({
  type: LINK_UNAPPLIED_PAYMENTS_SUCCESS,
})

export const unlinkPaymentToInvoice = (
  paymentId: string,
  invoiceId: string,
) => ({
  type: UNLINK_PAYMENT_TO_INVOICE,
  paymentId,
  invoiceId,
})
export const unlinkPaymentToInvoiceFailure = (error: ApiError) => ({
  type: UNLINK_PAYMENT_TO_INVOICE_FAILURE,
  error,
})
export const unlinkPaymentToInvoiceSuccess = () => ({
  type: UNLINK_PAYMENT_TO_INVOICE_SUCCESS,
})

export const editPayment = (
  paymentId: string,
  data: Partial<ExtendPayment>,
) => ({
  type: EDIT_PAYMENT,
  paymentId,
  data,
})
export const editPaymentSuccess = () => ({ type: EDIT_PAYMENT_SUCCESS })
export const editPaymentFailure = (error: ApiError) => ({
  type: EDIT_PAYMENT_FAILURE,
  error,
})

export const editGoPayment = (
  paymentId: string,
  data: Partial<ExtendPayment>,
) => ({
  type: EDIT_GO_PAYMENT,
  paymentId,
  data,
})
export const editGoPaymentSuccess = () => ({ type: EDIT_GO_PAYMENT_SUCCESS })
export const editGoPaymentFailure = (error: ApiError) => ({
  type: EDIT_GO_PAYMENT_FAILURE,
  error,
})

export const settleGoTransaction = (transactionToSettle: GoTransaction) => ({
  type: SETTLE_GO_TRANSACTION,
  transactionToSettle,
})
export const settleGoTransactionSuccess = (
  processedPayment: ExtendPayment,
) => ({
  type: SETTLE_GO_TRANSACTION_SUCCESS,
  processedPayment,
})
export const settleGoTransactionFailure = (error: ApiError) => ({
  type: SETTLE_GO_TRANSACTION_FAILURE,
  error,
})

export const createStripePayment = (
  paymentData: ExtendPayment,
  transactionType: PaymentType,
) => ({
  type: CREATE_STRIPE_PAYMENT,
  paymentData,
  transactionType,
})
export const createStripePaymentSuccess = (clientSecret: string) => ({
  type: CREATE_STRIPE_PAYMENT_SUCCESS,
  clientSecret,
})
export const createStripePaymentFailure = (error: ApiError) => ({
  type: CREATE_STRIPE_PAYMENT_FAILURE,
  error,
})

export const editGoStripePayment = (
  paymentId: string,
  data: Partial<ExtendPayment>,
) => ({
  type: EDIT_GO_STRIPE_PAYMENT,
  paymentId,
  data,
})
export const editGoStripePaymentSuccess = () => ({
  type: EDIT_GO_STRIPE_PAYMENT_SUCCESS,
})
export const editGoStripePaymentFailure = (error: ApiError) => ({
  type: EDIT_GO_STRIPE_PAYMENT_FAILURE,
  error,
})

export const settleGoStripeTransaction = (
  transactionToSettle: GoTransaction,
) => ({
  type: SETTLE_GO_STRIPE_TRANSACTION,
  transactionToSettle,
})
export const settleGoStripeTransactionSuccess = (
  processedPayment: ExtendPayment,
) => ({
  type: SETTLE_GO_STRIPE_TRANSACTION_SUCCESS,
  processedPayment,
})
export const settleGoStripeTransactionFailure = (error: ApiError) => ({
  type: SETTLE_GO_STRIPE_TRANSACTION_FAILURE,
  error,
})

export const createCreditAdjustment = ({
  adjustmentToRecord,
  clientId,
}: {
  adjustmentToRecord: CreditAdjustmentInput
  clientId: string
}) => ({
  type: CREATE_CREDIT_ADJUSTMENT,
  adjustmentToRecord,
  clientId,
})
export const createCreditAdjustmentFailure = (
  error: ApiError,
  hasConcurrentError: boolean,
) => ({
  type: CREATE_CREDIT_ADJUSTMENT_FAILURE,
  error,
  hasConcurrentError,
})
export const createCreditAdjustmentSuccess = (payment: ExtendPayment) => ({
  type: CREATE_CREDIT_ADJUSTMENT_SUCCESS,
  payment,
})

export const createRefundOnAccount = ({
  refundOnAccount,
}: {
  refundOnAccount: RefundOnAccountInput
}) => ({
  type: CREATE_REFUND_ON_ACCOUNT,
  refundOnAccount,
})
export const createRefundOnAccountFailure = (error: ApiError) => ({
  type: CREATE_REFUND_ON_ACCOUNT_FAILURE,
  error,
})
export const createRefundOnAccountSuccess = (payment: ExtendPayment) => ({
  type: CREATE_REFUND_ON_ACCOUNT_SUCCESS,
  payment,
})

export const findOnAccountOriginPayment = (onAccountPaymentId: string) => ({
  type: FIND_ON_ACCOUNT_ORIGINAL_PAYMENT,
  onAccountPaymentId,
})
export const findOnAccountOriginPaymentFailure = (error: ApiError) => ({
  type: FIND_ON_ACCOUNT_ORIGINAL_PAYMENT_FAILURE,
  error,
})
export const findOnAccountOriginPaymentSuccess = (
  payment: ExtendPayment | Nil,
) => ({
  type: FIND_ON_ACCOUNT_ORIGINAL_PAYMENT_SUCCESS,
  payment,
})

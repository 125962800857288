import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material'
import { Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { DateUtils, Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { InvoiceViewStates } from '~/constants/refund'
import { getRefundCalculation, resetRefundState } from '~/store/duck/refunds'
import { getPaymentPaidPersonName } from '~/utils/finance'
import { getPaymentMethodString } from '~/utils/paymentUtils'
import useDialog from '~/utils/useDialog'

import {
  formatMoneyRange,
  formatPositiveMoney,
} from '../../invoices/invoiceUtils'
import { RefundContext } from './RefundContext'

const useStyles = makeStyles(
  (theme) => ({
    totalContainer: {
      backgroundColor: theme.colors.tableLeftColumnBackground,
      borderLeft: theme.constants.tabBorder,
    },
    row: {
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.totalBorder,
      },
    },
    leftColumn: {
      borderRight: theme.constants.totalBorder,
    },
    expander: {
      cursor: 'pointer',
    },
    collapse: {
      paddingLeft: theme.spacing(3),
    },
  }),
  { name: 'InvoiceTotalsSectionForRefund' },
)

const MINIMUM_AMOUNT_OF_PAYMENTS_TO_COLLAPSE = 3

export const InvoiceTotalsSectionForRefund = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Invoices'])

  const [totalExpanded, setTotalExpanded] = useState(false)
  const [isPaymentsCollapsed, setIsPaymentsCollapsed] = useState(false)

  const { invoice, refundInvoice, viewState } = useContext(RefundContext)
  const invoiceView = viewState === InvoiceViewStates.REFUND_INVOICE
  const refundCalculation = useSelector(getRefundCalculation(invoice?.id))

  const subtotalValue = refundCalculation?.subtotal || refundInvoice?.subtotal
  const totalDiscountValue =
    refundCalculation?.totalDiscount || refundInvoice?.totalDiscount
  const totalTaxValue = refundCalculation?.totalTax || refundInvoice?.totalTax
  const refundAmountValue = refundCalculation?.amount || refundInvoice?.amount

  const [openPayment] = useDialog(DialogNames.PAYMENT_DETAILS)

  const completedPayments = refundInvoice?.payments || []
  const showTotalPaidLabel =
    completedPayments.length >= MINIMUM_AMOUNT_OF_PAYMENTS_TO_COLLAPSE

  useEffect(
    () => () => {
      dispatch(resetRefundState())
    },
    [],
  )

  return (
    <Grid
      container
      item
      className={classes.totalContainer}
      direction="column"
      justifyContent={totalExpanded ? 'flex-end' : 'center'}
      wrap="nowrap"
    >
      <Grid container item className={classes.row}>
        <Grid item className={classes.leftColumn} py={1.5} xs={8}>
          <Collapse className={classes.collapse} in={totalExpanded}>
            <Text variant="subheading3">{t('Common:SUBTOTAL')}</Text>
            <Text variant="subheading3">{t('Common:DISCOUNT')}</Text>
            <Text variant="subheading3">{t('Common:TAX')}</Text>
          </Collapse>
          <Grid
            container
            item
            alignItems="center"
            className={classes.expander}
            onClick={() => setTotalExpanded(!totalExpanded)}
          >
            {totalExpanded ? <KeyboardArrowUp /> : <KeyboardArrowRight />}
            <Text inline strong variant="body2">
              {t('Invoices:TO_BE_REFUNDED')}
            </Text>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          pr={3}
          wrap="nowrap"
          xs={4}
        >
          <Collapse in={totalExpanded}>
            <Text align="right" variant="subheading3">
              {formatMoneyRange(subtotalValue)}
            </Text>
            <Text align="right" variant="subheading3">
              {formatMoneyRange(totalDiscountValue)}
            </Text>
            <Text align="right" variant="subheading3">
              {formatMoneyRange(totalTaxValue)}
            </Text>
          </Collapse>
          <Text strong align="right" variant="subheading2">
            {formatMoneyRange(refundAmountValue)}
          </Text>
        </Grid>
      </Grid>
      {invoiceView && refundInvoice && !R.isEmpty(completedPayments) && (
        <>
          {showTotalPaidLabel && (
            <Grid
              container
              item
              alignItems="center"
              className={classNames(classes.row, classes.expander)}
              onClick={() => setIsPaymentsCollapsed(!isPaymentsCollapsed)}
            >
              <Grid
                container
                item
                alignItems="center"
                className={classes.leftColumn}
                py={1.5}
                wrap="nowrap"
                xs={8}
              >
                {isPaymentsCollapsed ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowUp />
                )}
                <Text strong variant="subheading3">
                  {t('Invoices:TOTAL_PAID_COMPLETED_PAYMENTS', {
                    completedPayments: completedPayments.length,
                  })}
                </Text>
              </Grid>
              <Grid item pr={3} xs={4}>
                <Text align="right" variant="subheading2">
                  {formatMoneyRange(refundInvoice?.paidAmountNoFee, true)}
                </Text>
              </Grid>
            </Grid>
          )}
          <Collapse in={!isPaymentsCollapsed}>
            {completedPayments.map((payment) => {
              const paidPersonName = getPaymentPaidPersonName(payment)

              const signedSplitAmount =
                R.prop('signedSplitAmount', payment) || 0
              const isReverseCharge = signedSplitAmount > 0

              const actionText = isReverseCharge
                ? paidPersonName
                  ? `${t('Common:PAYMENTS.REFUNDED_TO')} ${paidPersonName}`
                  : t('Common:PAYMENTS.REFUNDED')
                : paidPersonName
                  ? `${t('Common:PAYMENTS.PAID_BY')} ${paidPersonName}`
                  : null

              const money = payment.split ? payment.splitAmount : payment.amount

              return (
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classes.row}
                  key={payment.id}
                >
                  <Grid item className={classes.leftColumn} pl={3} xs={8}>
                    <Text strong variant="subheading3">
                      {actionText || t('Invoices:PAID_INFO_IS_MISSING')}
                    </Text>
                    <Text
                      inline
                      link
                      variant="body2"
                      onClick={() => {
                        openPayment({
                          paymentId: payment.id,
                          clientId: refundInvoice.client.id,
                          isPossibleToViewInvoice: true,
                          forceNotNavigateToBalanceAfterRefund: true,
                          invoiceId: refundInvoice.id,
                        })
                      }}
                    >
                      {`${DateUtils.formatDate(
                        payment.creationDate,
                      )} | ${getPaymentMethodString(payment)}`}
                    </Text>
                  </Grid>
                  <Grid item pr={3} xs={4}>
                    <Text strong align="right" variant="subheading2">
                      {isReverseCharge
                        ? formatPositiveMoney(money)
                        : formatMoneyRange(money, true)}
                    </Text>
                    {Boolean(payment.serviceFeeIncAmount) && (
                      <Text align="right" variant="subheading3">
                        {t('Invoices:INCLUDING_SERVICE_FEE', {
                          amount: formatMoneyRange(payment.serviceFeeIncAmount),
                        })}
                      </Text>
                    )}
                  </Grid>
                </Grid>
              )
            })}
          </Collapse>
        </>
      )}
    </Grid>
  )
}

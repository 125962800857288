import {
  UnitTypes,
  UnitTypesLabelMap,
} from '@pbt/pbt-ui-components/src/localization'

import { MeasurementConstant } from '~/constants/measurementUnits'
import { VitalFormInputTypes } from '~/constants/vitals'
import i18n from '~/locales/i18n'
import { VitalConfigItem } from '~/types'

const config: VitalConfigItem[] = [
  {
    id: 'weight',
    name: i18n.t('Common:WEIGHT'),
    humanReadable: i18n.t('Common:WEIGHT'),
    common: true,
    formInput: {
      type: VitalFormInputTypes.WEIGHT,
      units: UnitTypes.WEIGHT,
      placeholder: '0',
    },
    constant: MeasurementConstant.WeightUnits,
  },
  {
    id: 'temperature',
    name: i18n.t('Common:TEMPERATURE'),
    humanReadable: i18n.t('Common:TEMPERATURE'),
    common: true,
    constant: MeasurementConstant.TemperatureUnits,
    formInput: {
      type: VitalFormInputTypes.TEMPERATURE,
      units: UnitTypes.TEMPERATURE,
    },
  },
  {
    id: 'heartRate',
    name: i18n.t('Soap:VITALS.HEART_RATE.LABEL'),
    humanReadable: i18n.t('Soap:VITALS.HEART_RATE.LABEL'),
    common: true,
    tooltip: i18n.t('Soap:VITALS.HEART_RATE.TOOLTIP'),
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.BEATS_PER_MINUTE,
      unitsLabel: UnitTypesLabelMap[UnitTypes.BEATS_PER_MINUTE],
    },
  },
  {
    id: 'respirationRate',
    name: i18n.t('Soap:VITALS.RESPIRATION.LABEL'),
    humanReadable: i18n.t('Soap:VITALS.RESPIRATION.LABEL'),
    common: true,
    tooltip: i18n.t('Soap:VITALS.RESPIRATION.TOOLTIP'),
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.BREATHS_PER_MINUTE,
      unitsLabel: UnitTypesLabelMap[UnitTypes.BREATHS_PER_MINUTE],
    },
  },
  {
    id: 'mentationId',
    name: i18n.t('Soap:VITALS.MENTATION.LABEL'),
    humanReadable: i18n.t('Soap:VITALS.MENTATION.LABEL'),
    common: true,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'MentationTypes',
    },
  },
  {
    id: 'capillaryRefillTime',
    name: i18n.t('Abbreviations:VITALS.CAPILLARY_REFILL_TIME'),
    humanReadable: i18n.t('Soap:VITALS.CAPILLARY_REFILL_TIME.LABEL'),
    common: false,
    tooltip: i18n.t('Soap:VITALS.CAPILLARY_REFILL_TIME.TOOLTIP'),
    formInput: {
      type: VitalFormInputTypes.SELECT,
      options: [
        {
          id: '1',
          name: 'Normal',
          nameTranslation: i18n.t(
            'Soap:VITALS.CAPILLARY_REFILL_TIME.OPTIONS.NORMAL',
          ),
        },
        {
          id: '2',
          name: 'Abnormal',
          nameTranslation: i18n.t(
            'Soap:VITALS.CAPILLARY_REFILL_TIME.OPTIONS.ABNORMAL',
          ),
        },
      ],
    },
  },
  {
    id: 'mucousMembraneColorId',
    name: i18n.t('Abbreviations:VITALS.MUCOUS_MEMBRANE_COLOR'),
    humanReadable: i18n.t('Soap:VITALS.MUCOUS_MEMBRANE_COLOR.LABEL'),
    common: false,
    tooltip: i18n.t('Soap:VITALS.MENTATION.LABEL'),
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'MucousMembraneColors',
    },
  },
  {
    id: 'mucousMembraneMoistureId',
    name: i18n.t('Abbreviations:VITALS.MUCOUS_MEMBRANE_MOISTURE'),
    humanReadable: i18n.t('Soap:VITALS.MUCOUS_MEMBRANE_MOISTURE.LABEL'),
    common: false,
    tooltip: i18n.t('Soap:VITALS.MUCOUS_MEMBRANE_MOISTURE.LABEL'),
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'MucousMembraneMoistures',
    },
  },
  {
    id: 'skinTurgorId',
    name: i18n.t('Soap:VITALS.TURGOR.LABEL'),
    humanReadable: i18n.t('Soap:VITALS.TURGOR.LABEL'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'SkinTurgors',
    },
  },
  {
    id: 'bodyConditionScore',
    name: i18n.t('Abbreviations:VITALS.BODY_CONDITION_SCORE'),
    humanReadable: i18n.t('Soap:VITALS.BODY_CONDITION_SCORE.LABEL'),
    common: false,
    tooltip: i18n.t('Soap:VITALS.BODY_CONDITION_SCORE.TOOLTIP'),
    formInput: {
      type: VitalFormInputTypes.SELECT,
      options: [
        { id: '1', name: '1' },
        { id: '2', name: '2' },
        { id: '3', name: '3' },
        { id: '4', name: '4' },
        { id: '5', name: '5' },
        { id: '6', name: '6' },
        { id: '7', name: '7' },
        { id: '8', name: '8' },
        { id: '9', name: '9' },
      ],
    },
  },
  {
    id: 'dyspnea',
    name: i18n.t('Soap:VITALS.DYSPNEA.LABEL'),
    humanReadable: i18n.t('Soap:VITALS.DYSPNEA.LABEL'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      options: [
        { id: 'true', name: 'Yes', nameTranslation: i18n.t('Common:YES') },
        { id: 'false', name: 'No', nameTranslation: i18n.t('Common:NO') },
      ],
    },
  },
  {
    id: 'pulseCharacterId',
    name: i18n.t('Soap:VITALS.PULSE_CHARACTER.ABBREVIATION'),
    humanReadable: i18n.t('Soap:VITALS.PULSE_CHARACTER.LABEL'),
    tooltip: i18n.t('Soap:VITALS.PULSE_CHARACTER.LABEL'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'PulseCharacterTypes',
    },
  },
  {
    id: 'painScore',
    name: i18n.t('Soap:VITALS.PAIN_SCORE.LABEL'),
    humanReadable: i18n.t('Soap:VITALS.PAIN_SCORE.LABEL'),
    common: false,
    tooltip: i18n.t('Soap:VITALS.PAIN_SCORE.TOOLTIP'),
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'PainScore',
    },
  },
  {
    id: 'fearAnxietyScore',
    name: i18n.t('Abbreviations:VITALS.FEAR_ANXIETY_SCORE'),
    humanReadable: i18n.t('Soap:VITALS.FEAR_ANXIETY_SCORE.LABEL'),
    common: false,
    tooltip: i18n.t('Soap:VITALS.FEAR_ANXIETY_SCORE.TOOLTIP'),
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'FearAnxietyScore',
    },
  },
  {
    id: 'bloodPressure',
    name: i18n.t('Abbreviations:VITALS.BLOOD_PRESSURE'),
    humanReadable: i18n.t('Soap:VITALS.BLOOD_PRESSURE.LABEL'),
    common: true,
    tooltip: i18n.t('Soap:VITALS.BLOOD_PRESSURE.TOOLTIP'),
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.MILLIMETRE_OF_MERCURY,
      unitsLabel: UnitTypesLabelMap[UnitTypes.MILLIMETRE_OF_MERCURY],
    },
  },
  {
    id: 'spo2',
    name: i18n.t('Abbreviations:VITALS.SPO2'),
    humanReadable: i18n.t('Abbreviations:VITALS.SPO2'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.PERCENT,
      unitsLabel: UnitTypesLabelMap[UnitTypes.PERCENT],
    },
  },
  {
    id: 'etco2',
    name: i18n.t('Abbreviations:VITALS.ETCO2'),
    humanReadable: i18n.t('Abbreviations:VITALS.ETCO2'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.MILLIMETRE_OF_MERCURY,
      unitsLabel: UnitTypesLabelMap[UnitTypes.MILLIMETRE_OF_MERCURY],
    },
  },
  {
    id: 'o2Level',
    name: i18n.t('Soap:VITALS.O2_LEVEL.LABEL'),
    humanReadable: i18n.t('Soap:VITALS.O2_LEVEL.LABEL'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.LITRES_PER_MINUTE,
      unitsLabel: UnitTypesLabelMap[UnitTypes.LITRES_PER_MINUTE],
    },
  },
  {
    id: 'isoSevoLevel',
    name: i18n.t('Abbreviations:VITALS.ISO_SEVO_LEVEL'),
    humanReadable: i18n.t('Abbreviations:VITALS.ISO_SEVO_LEVEL'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.PERCENT,
      unitsLabel: UnitTypesLabelMap[UnitTypes.PERCENT],
    },
  },
  {
    id: 'recumbencyId',
    name: i18n.t('Soap:VITALS.RECUMBENCY.LABEL'),
    humanReadable: i18n.t('Soap:VITALS.RECUMBENCY.LABEL'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'VitalRecumbency',
    },
  },
  {
    id: 'muscleMassScore',
    name: i18n.t('Soap:VITALS.MUSCLE_MASS_SCORE.LABEL'),
    humanReadable: i18n.t('Soap:VITALS.MUSCLE_MASS_SCORE.LABEL'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.MUSCLE_MASS_SCORE,
    },
  },
]

export default config

import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { Editor } from '@pbt/pbt-ckeditor-ui'
import { Nil } from '@pbt/pbt-ui-components'

import {
  getCKEditorEnabledTrackChanges,
  toggleTrackChanges,
} from '~/store/duck/ckeditor'

export interface UseEnabledTrackChangesProps {
  editor: Editor | undefined
  id: string
  removedTrackChanges: boolean
}

export function useEnabledTrackChanges({
  id,
  editor,
  removedTrackChanges,
}: UseEnabledTrackChangesProps) {
  const dispatch = useDispatch()

  const enabledTrackChanges = useSelector(getCKEditorEnabledTrackChanges(id))

  const callerController = useRef(false)

  useEffect(() => {
    if (!editor || removedTrackChanges) {
      return () => {}
    }

    const handleChange = () => {
      const trackChangesValue = editor.commands.get('trackChanges')?.value as
        | boolean
        | Nil

      if (R.isNil(trackChangesValue)) {
        return
      }

      dispatch(
        toggleTrackChanges(
          id,
          callerController.current ? true : trackChangesValue,
        ),
      )

      if (callerController.current) {
        callerController.current = false
      }
    }

    editor.commands.get('trackChanges')?.on('change', handleChange)

    return () => {
      editor.commands.get('trackChanges')?.off('change', handleChange)
    }
  }, [editor, callerController.current, enabledTrackChanges])

  useEffect(() => {
    if (removedTrackChanges) {
      return
    }

    const trackChangesValue = editor?.commands.get('trackChanges')?.value as
      | boolean
      | Nil

    if (editor && enabledTrackChanges !== trackChangesValue) {
      callerController.current = true
      editor.commands.get('trackChanges')?.execute()
    }
  }, [editor])
}

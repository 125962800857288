import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Field } from '@pbt/pbt-ui-components'

import NumericInput from '../../common/inputs/NumericInput'

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '50px',
  alignItems: 'center',
  padding: theme.spacing(0.5),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: theme.constants.fabBorder,
  padding: theme.spacing(1),
  borderRadius: 0,
  transition: 'background-color 0.3s, transform 0.1s',
  '&:first-of-type': {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  '&:last-of-type': {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:active': {
    transform: 'scale(0.95)',
  },
}))

const InputWrapper = styled(Box)(({ theme }) => ({
  borderTop: theme.constants.fabBorder,
  borderBottom: theme.constants.fabBorder,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}))

const StyledNumericInput = styled(NumericInput)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  width: '30px',
  '& input': {
    textAlign: 'center',
  },
}))

interface NumberStepperProps {
  disabled?: boolean
  field?: Field
  onChange?: (newValue: string | number) => void
  value?: number
}

export const NumberStepper = ({
  value,
  onChange,
  field,
  disabled,
}: NumberStepperProps) => {
  let localValue = value ?? field?.value
  let setValue = onChange || field?.setValue

  if (localValue === undefined || setValue === undefined) {
    // eslint-disable-next-line no-console
    console.error(
      'NumberStepper: Either value and onChange OR a field prop are required',
    )
    localValue = 0
    setValue = () => {}
  }

  const handleDecrement = () => {
    if (setValue) {
      setValue(Math.max(0, localValue - 1))
    }
  }

  const handleIncrement = () => {
    if (setValue) {
      setValue(localValue + 1)
    }
  }

  return (
    <Root>
      <StyledIconButton disabled={disabled} onClick={handleDecrement}>
        <RemoveIcon />
      </StyledIconButton>
      <InputWrapper>
        <StyledNumericInput
          allowDecimal
          disabled={disabled}
          field={
            field
              ? ({ ...field, setValue, value: localValue } as Field)
              : undefined
          }
          min={0}
          value={localValue}
          onChange={setValue}
        />
      </InputWrapper>
      <StyledIconButton disabled={disabled} onClick={handleIncrement}>
        <AddIcon />
      </StyledIconButton>
    </Root>
  )
}

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'

import {
  getAllProblemIds,
  getFirstLevelProblemsIdsWithoutCategories,
  getProblemById,
} from '~/store/reducers/problems'
import { Problem } from '~/types'

import TreeBranch from '../TreeBranch'
import ProblemsCheckboxList from './ProblemsCheckboxList'

const useStyles = makeStyles(
  (theme) => ({
    treeContainer: {
      borderRight: theme.constants.filterBorder,
      borderWidth: '1px',
    },
  }),
  { name: 'ProblemsTree' },
)

interface ProblemsTreeInterface {
  onProblemCheckboxClick: (problem: Problem) => void
  problemId: string
}

const ProblemsTree = ({
  problemId,
  onProblemCheckboxClick,
}: ProblemsTreeInterface) => {
  const classes = useStyles()
  const [selected, setSelected] = useState<string | null>(null)
  const problem = useSelector(getProblemById(problemId))
  const allProblemIds = useSelector(getAllProblemIds)
  const allChildrenProblems = useSelector(
    getFirstLevelProblemsIdsWithoutCategories(selected || problemId, {
      excludeRemoved: true,
    }),
  )

  const handleSelect = (_: React.SyntheticEvent, nodeId: string | null) => {
    setSelected(nodeId)
  }

  if (problem?.removed) {
    return null
  }

  return (
    <Grid container>
      <Grid item className={classes.treeContainer} xs={4}>
        <SimpleTreeView
          expandedItems={allProblemIds}
          selectedItems={selected || problemId}
          onSelectedItemsChange={handleSelect}
        >
          <TreeBranch isRootNode problemId={problemId} />
        </SimpleTreeView>
      </Grid>
      <Grid container item direction="column" xs={8}>
        <ProblemsCheckboxList
          problemList={allChildrenProblems || []}
          onProblemCheckboxClick={onProblemCheckboxClick}
        />
      </Grid>
    </Grid>
  )
}

export default ProblemsTree

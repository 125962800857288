import React from 'react'
import { useSelector } from 'react-redux'
import { Field, PuiSelect, SelectEntity } from '@pbt/pbt-ui-components'

import { getAutoRenewingPlansInfo } from '~/store/reducers/wellnessPlans'

import { WellnessPlanSelectItem } from './WellnessPlanSelectItem'

interface WellnessPlanSelectProps {
  field: Field
  plansList: string[]
}

export const WellnessPlanSelect = ({
  field,
  plansList,
}: WellnessPlanSelectProps) => {
  const { plansMap, versionsMap } = useSelector(getAutoRenewingPlansInfo)

  return (
    <PuiSelect
      fullWidth
      Item={WellnessPlanSelectItem}
      field={field}
      items={
        plansList.map((id) => {
          const plan = plansMap[id]
          const version = versionsMap[plan.versionId]
          return {
            id: plan.id,
            name: `${version.name} | ${plan.name}`,
            active: version.active,
          }
        }) as unknown as SelectEntity[]
      }
      renderEmpty={false}
      renderSelectedValue={(id: string) => {
        const plan = plansMap[id]
        const version = versionsMap[plan.versionId]
        return (
          <WellnessPlanSelectItem
            active={version.active}
            name={`${version.name} | ${plan.name}`}
          />
        )
      }}
    />
  )
}

import React from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'

import { getChargesLoading } from '~/store/duck/charges'

const PrescriptionSnapShotLoadingOrIcon = (Icon: any) => {
  const isLoading = useSelector(getChargesLoading)
  return isLoading ? (
    <Grid mr={1}>
      <CircularProgress size={20} />
    </Grid>
  ) : (
    <Icon.Icon className={Icon.className} />
  )
}

export default PrescriptionSnapShotLoadingOrIcon

import * as React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const SvgAutoshipIcon = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      clipRule="evenodd"
      d="m4.019 20.82 2.006-2.58c2.82 2.178 8.759 5.278 14.531-1.125.469-.52.931-1.1 1.386-1.755.019-.027.039-.05.058-.078l-.012.006c-1.918 1.103-4.252 1.691-6.741 1.535-2.948-.185-4.225-.89-6.058-2.65l2.037-2.618S6.954 9.683 2 11.822z"
      fill="#F27123"
      fillRule="evenodd"
    />,
    <path
      clipRule="evenodd"
      d="m19.981 4.117-2.006 2.579c-2.82-2.178-8.76-5.278-14.531 1.125-.47.52-.932 1.101-1.386 1.756-.02.027-.039.05-.058.078l.012-.007c1.917-1.102 4.252-1.69 6.741-1.534 2.948.184 4.225.89 6.057 2.649l-2.036 2.618s4.272 1.872 9.226-.267z"
      fill="#031657"
      fillRule="evenodd"
    />,
  )
export default SvgAutoshipIcon

export const FETCH_WELLNESS_PLANS_LIST_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLANS_LIST_FAILURE'
export const FETCH_WELLNESS_PLANS_LIST_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLANS_LIST_SUCCESS'
export const FETCH_WELLNESS_PLANS_LIST =
  'wellnessPlans/FETCH_WELLNESS_PLANS_LIST'

export const FETCH_WELLNESS_PLAN_VERSION =
  'wellnessPlans/FETCH_WELLNESS_PLAN_VERSION'
export const FETCH_WELLNESS_PLAN_VERSION_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_VERSION_FAILURE'
export const FETCH_WELLNESS_PLAN_VERSION_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_VERSION_SUCCESS'

export const FETCH_ACTIVE_WELLNESS_PLAN_VERSION =
  'wellnessPlans/FETCH_ACTIVE_WELLNESS_PLAN_VERSION'
export const FETCH_ACTIVE_WELLNESS_PLAN_VERSION_FAILURE =
  'wellnessPlans/FETCH_ACTIVE_WELLNESS_PLAN_VERSION_FAILURE'
export const FETCH_ACTIVE_WELLNESS_PLAN_VERSION_SUCCESS =
  'wellnessPlans/FETCH_ACTIVE_WELLNESS_PLAN_VERSION_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_FAILURE =
  'wellnessPlans/FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_SUCCESS =
  'wellnessPlans/FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST =
  'wellnessPlans/FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST'

export const CREATE_WELLNESS_PLAN_VERSION =
  'wellnessPlans/CREATE_WELLNESS_PLAN_VERSION'
export const CREATE_WELLNESS_PLAN_VERSION_FAILURE =
  'wellnessPlans/CREATE_WELLNESS_PLAN_VERSION_FAILURE'
export const CREATE_WELLNESS_PLAN_VERSION_SUCCESS =
  'wellnessPlans/CREATE_WELLNESS_PLAN_VERSION_SUCCESS'
export const CREATE_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE =
  'wellnessPlans/CREATE_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE'

export const EDIT_WELLNESS_PLAN_VERSION =
  'wellnessPlans/EDIT_WELLNESS_PLAN_VERSION'
export const EDIT_WELLNESS_PLAN_VERSION_FAILURE =
  'wellnessPlans/EDIT_WELLNESS_PLAN_VERSION_FAILURE'
export const EDIT_WELLNESS_PLAN_VERSION_SUCCESS =
  'wellnessPlans/EDIT_WELLNESS_PLAN_VERSION_SUCCESS'
export const EDIT_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE =
  'wellnessPlans/EDIT_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE'

export const DELETE_WELLNESS_PLAN_VERSION =
  'wellnessPlans/DELETE_WELLNESS_PLAN_VERSION'
export const DELETE_WELLNESS_PLAN_VERSION_FAILURE =
  'wellnessPlans/DELETE_WELLNESS_PLAN_VERSION_FAILURE'
export const DELETE_WELLNESS_PLAN_VERSION_SUCCESS =
  'wellnessPlans/DELETE_WELLNESS_PLAN_VERSION_SUCCESS'

export const UPDATE_WELLNESS_PLANS_VERSIONS =
  'wellnessPlans/UPDATE_WELLNESS_PLANS_VERSIONS'

export const FETCH_WELLNESS_PLAN_VERSION_TEMPLATE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_VERSION_TEMPLATE'
export const FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_FAILURE'
export const FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_SUCCESS'

export const CLEAR_WELLNESS_PLAN_VERSION_VALIDATION_ERROR =
  'wellnessPlans/CLEAR_WELLNESS_PLAN_VERSION_VALIDATION_ERROR'

export const FETCH_WELLNESS_PLANS_SETTINGS =
  'wellnessPlans/FETCH_WELLNESS_PLANS_SETTINGS'
export const FETCH_WELLNESS_PLANS_SETTINGS_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLANS_SETTINGS_FAILURE'
export const FETCH_WELLNESS_PLANS_SETTINGS_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLANS_SETTINGS_SUCCESS'

export const EDIT_WELLNESS_PLANS_SETTINGS =
  'wellnessPlans/EDIT_WELLNESS_PLANS_SETTINGS'
export const EDIT_WELLNESS_PLANS_SETTINGS_FAILURE =
  'wellnessPlans/EDIT_WELLNESS_PLANS_SETTINGS_FAILURE'
export const EDIT_WELLNESS_PLANS_SETTINGS_SUCCESS =
  'wellnessPlans/EDIT_WELLNESS_PLANS_SETTINGS_SUCCESS'

export const FETCH_WELLNESS_PLAN = 'wellnessPlans/FETCH_WELLNESS_PLAN'
export const FETCH_WELLNESS_PLAN_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_FAILURE'
export const FETCH_WELLNESS_PLAN_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SUCCESS'

export const FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS'
export const FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_FAILURE'
export const FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_SUCCESS'

export const ACTIVE_WELLNESS_PLAN_VERSION_CHANGED =
  'wellnessPlans/ACTIVE_WELLNESS_PLAN_VERSION_CHANGED'

export const FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN'
export const FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_FAILURE'
export const FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_SUCCESS'

export const CLEAR_WELLNESS_PLAN_SIGN_UP_TOKEN =
  'wellnessPlans/CLEAR_WELLNESS_PLAN_SIGN_UP_TOKEN'

export const CANCEL_WELLNESS_PLAN_OLD = 'wellnessPlans/CANCEL_WELLNESS_PLAN_OLD'
export const CANCEL_WELLNESS_PLAN_OLD_FAILURE =
  'wellnessPlans/CANCEL_WELLNESS_PLAN_OLD_FAILURE'
export const CANCEL_WELLNESS_PLAN_OLD_SUCCESS =
  'wellnessPlans/CANCEL_WELLNESS_PLAN_OLD_SUCCESS'
export const CANCEL_WELLNESS_PLAN_OLD_VALIDATION_FAILURE =
  'wellnessPlans/CANCEL_WELLNESS_PLAN_OLD_VALIDATION_FAILURE'
export const CLEAR_CANCEL_WELLNESS_PLAN_OLD_VALIDATION_ERROR =
  'wellnessPlans/CLEAR_CANCEL_WELLNESS_PLAN_OLD_VALIDATION_ERROR'

export const CANCEL_WELLNESS_PLAN = 'wellnessPlans/CANCEL_WELLNESS_PLAN'
export const CANCEL_WELLNESS_PLAN_FAILURE =
  'wellnessPlans/CANCEL_WELLNESS_PLAN_FAILURE'
export const CANCEL_WELLNESS_PLAN_SUCCESS =
  'wellnessPlans/CANCEL_WELLNESS_PLAN_SUCCESS'

export const FETCH_WP_CANCELLATION_INFO =
  'wellnessPlans/FETCH_WP_CANCELLATION_INFO'
export const FETCH_WP_CANCELLATION_INFO_FAILURE =
  'wellnessPlans/FETCH_WP_CANCELLATION_INFO_FAILURE'
export const FETCH_WP_CANCELLATION_INFO_SUCCESS =
  'wellnessPlans/FETCH_WP_CANCELLATION_INFO_SUCCESS'

export const CREATE_WELLNESS_PLAN_PAYMENT_METHOD =
  'wellnessPlans/CREATE_WELLNESS_PLAN_PAYMENT_METHOD'
export const CREATE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE =
  'wellnessPlans/CREATE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE'
export const CREATE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS =
  'wellnessPlans/CREATE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS'

export const GET_WELLNESS_PLAN_PAYMENT_METHOD =
  'wellnessPlans/GET_WELLNESS_PLAN_PAYMENT_METHOD'
export const GET_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE =
  'wellnessPlans/GET_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE'
export const GET_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS =
  'wellnessPlans/GET_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS'

export const REPLACE_WELLNESS_PLAN_PAYMENT_METHOD =
  'wellnessPlans/REPLACE_WELLNESS_PLAN_PAYMENT_METHOD'
export const REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE =
  'wellnessPlans/REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE'
export const REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS =
  'wellnessPlans/REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS'

export const FETCH_WELLNESS_PLAN_SAVINGS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SAVINGS'
export const FETCH_WELLNESS_PLAN_SAVINGS_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SAVINGS_FAILURE'
export const FETCH_WELLNESS_PLAN_SAVINGS_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SAVINGS_SUCCESS'

export const FETCH_WELLNESS_PLAN_WELCOME_MESSAGE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_WELCOME_MESSAGE'
export const FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE'
export const FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS'

export const FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE =
  'wellnessPlans/FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE'
export const FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE =
  'wellnessPlans/FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE'
export const FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS =
  'wellnessPlans/FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS'

export const FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE'
export const FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_FAILURE'
export const FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_SUCCESS'

export const FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE'
export const FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_FAILURE'
export const FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_SUCCESS'

export const SEND_DEFAULT_WELCOME_EMAIL =
  'wellnessPlans/SEND_DEFAULT_WELCOME_EMAIL'
export const SEND_DEFAULT_WELCOME_EMAIL_SUCCESS =
  'wellnessPlans/SEND_DEFAULT_WELCOME_EMAIL_SUCCESS'
export const SEND_DEFAULT_WELCOME_EMAIL_FAILURE =
  'wellnessPlans/SEND_DEFAULT_WELCOME_EMAIL_FAILURE'

export const SEND_WELCOME_EMAIL = 'wellnessPlans/SEND_WELCOME_EMAIL'
export const SEND_WELCOME_EMAIL_SUCCESS =
  'wellnessPlans/SEND_WELCOME_EMAIL_SUCCESS'
export const SEND_WELCOME_EMAIL_FAILURE =
  'wellnessPlans/SEND_WELCOME_EMAIL_FAILURE'

export const FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP'
export const FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_FAILURE'
export const FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_SUCCESS'

export const FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE'
export const FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_FAILURE =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_FAILURE'
export const FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_SUCCESS =
  'wellnessPlans/FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_SUCCESS'

export const TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN =
  'wellnessPlans/TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN'
export const TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_FAILURE =
  'wellnessPlans/TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_FAILURE'
export const TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_SUCCESS =
  'wellnessPlans/TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_SUCCESS'

export const CLEAR_WELLNESS_PLAN_ERROR =
  'wellnessPlans/CLEAR_WELLNESS_PLAN_ERROR'
export const CLEAR_WELLNESS_PLAN_ERROR_STATUS =
  'wellnessPlans/CLEAR_WELLNESS_PLAN_ERROR_STATUS'
export const CLEAR_WELLNESS_PLAN_VERSION_TEMPLATE =
  'wellnessPlans/CLEAR_WELLNESS_PLAN_VERSION_TEMPLATE'
export const CLEAR_CANCEL_WELLNESS_PLAN_SUCCESS =
  'wellnessPlans/CLEAR_CANCEL_WELLNESS_PLAN_SUCCESS'
export const CLEAR_CANCEL_WELLNESS_PLAN_ERROR =
  'wellnessPlans/CLEAR_CANCEL_WELLNESS_PLAN_ERROR'

export const FETCH_AUTO_RENEWING_PLANS_INFO =
  'wellnessPlans/FETCH_AUTO_RENEWING_PLANS_INFO'
export const FETCH_AUTO_RENEWING_PLANS_INFO_FAILURE =
  'wellnessPlans/FETCH_AUTO_RENEWING_PLANS_INFO_FAILURE'
export const FETCH_AUTO_RENEWING_PLANS_INFO_SUCCESS =
  'wellnessPlans/FETCH_AUTO_RENEWING_PLANS_INFO_SUCCESS'
export const CLEAR_AUTO_RENEWING_PLANS_INFO =
  'wellnessPlans/CLEAR_AUTO_RENEWING_PLANS_INFO'

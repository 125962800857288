import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  Constant,
  Field,
  PuiSelect,
  PuiSelectProps,
  Utils,
} from '@pbt/pbt-ui-components'

import { getGender, getSpayedNeuteredStatus } from '~/store/reducers/constants'

import {
  generateSpayedNeuteredGender,
  getId,
  parseId,
  spayedNeuteredStatusToSpayedNeutered,
  spayedNeuteredToSpayedNeuteredStatus,
} from './genderUtils'

export type GenderSelectProps = PuiSelectProps & {
  gender: Field
  spayedNeutered: Field
}

const GenderSelect = ({
  gender,
  spayedNeutered,
  ...rest
}: GenderSelectProps) => {
  const Gender: Constant[] = useSelector(getGender)
  const SpayedNeuteredStatus: Constant[] = useSelector(getSpayedNeuteredStatus)

  const spayedNeuteredStatus = spayedNeuteredToSpayedNeuteredStatus(
    gender.value,
    Gender,
    spayedNeutered.value,
    SpayedNeuteredStatus,
  )

  const [value, setValue] = useState(getId(gender.value, spayedNeuteredStatus))
  const items: Constant[] = R.map(
    R.pick(['id', 'name']),
    generateSpayedNeuteredGender(Gender, SpayedNeuteredStatus),
  )

  useEffect(() => {
    const { genderId, spayedNeuteredStatusId } = parseId(value)
    gender.setValue(genderId)
    spayedNeutered.setValue(
      spayedNeuteredStatusToSpayedNeutered(
        spayedNeuteredStatusId,
        SpayedNeuteredStatus,
      ),
    )
  }, [value])

  return (
    <PuiSelect
      field={{
        ...gender,
        value,
        set: Utils.handleFormSelectInput(setValue),
      }}
      items={items}
      renderEmpty={false}
      {...rest}
    />
  )
}

export default GenderSelect

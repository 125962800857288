import { isNil } from 'ramda'
import { LanguageUtils, Nil, SentenceFormatter } from '@pbt/pbt-ui-components'
import { findById } from '@pbt/pbt-ui-components/src/utils'

import {
  CardPaymentMethod,
  ChewyAccountBalancePaymentMethod,
  PaymentMethod,
  PayPalPaymentMethod,
  ShippingAddress,
} from '~/api/graphql/generated/types'
import i18n from '~/locales/i18n'

export function getPrimary<T = any>(items: T[] = [], id?: string | Nil) {
  if (items.length === 0) {
    return null
  }

  const itemById = !isNil(id)
    ? items.find((item) => (item as any)?.id === id)
    : null
  const primaryItem = items.find((item) => (item as any)?.primary)

  return itemById ?? primaryItem ?? items[0]
}

export function formatShippingAddress(shippingAddress: ShippingAddress | Nil) {
  return LanguageUtils.getSentence(
    [shippingAddress?.address?.street1, shippingAddress?.address?.city],
    SentenceFormatter.REGULAR,
    ', ',
  )
}

export const formatCardPaymentMethod = (cardPaymentMethod: CardPaymentMethod) =>
  LanguageUtils.getSentence(
    [
      LanguageUtils.getSentence(
        [
          cardPaymentMethod.cardHolderName,
          cardPaymentMethod.paymentCardType?.name,
        ],
        SentenceFormatter.REGULAR,
        ' | ',
      ),
      cardPaymentMethod.lastFour,
    ],
    SentenceFormatter.REGULAR,
  )

export const formatPayPalPaymentMethod = (
  payPalPaymentMethod: PayPalPaymentMethod,
) =>
  LanguageUtils.getSentence(
    [i18n.t<string>('Payments:PAY_PAL'), payPalPaymentMethod.email],
    SentenceFormatter.REGULAR,
  )

export const formatPaymentMethod = (
  paymentMethod: CardPaymentMethod | PayPalPaymentMethod,
) => {
  switch (paymentMethod.__typename) {
    case 'CardPaymentMethod':
      return formatCardPaymentMethod(paymentMethod)
    case 'PayPalPaymentMethod':
      return formatPayPalPaymentMethod(paymentMethod)
    default:
      return ''
  }
}

export type CardAndPaypalPaymentMethods =
  | CardPaymentMethod
  | PayPalPaymentMethod

export const getCardAndPaypalPaymentMethods = (
  paymentMethods: PaymentMethod[],
) =>
  paymentMethods.filter(
    (method): method is CardAndPaypalPaymentMethods =>
      method.__typename === 'CardPaymentMethod' ||
      method.__typename === 'PayPalPaymentMethod',
  )

export const getPrimaryCardPaymentMethod = (paymentMethods: PaymentMethod[]) =>
  paymentMethods?.filter(
    (method): method is CardPaymentMethod =>
      method.__typename === 'CardPaymentMethod',
  ) ?? []

export const getChewyAccountBalancePaymentMethod = (
  paymentMethods: PaymentMethod[],
) =>
  paymentMethods.find(
    (method): method is ChewyAccountBalancePaymentMethod =>
      method.__typename === 'ChewyAccountBalancePaymentMethod',
  )

export const getPaymentMethodById = (
  id: string | Nil,
  paymentMethods: CardPaymentMethod[],
) => findById(id, paymentMethods) as CardPaymentMethod

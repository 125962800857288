import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, BoxProps, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ClassesType, Nil, Text } from '@pbt/pbt-ui-components'
import colors from '@pbt/pbt-ui-components/src/constants/colors'

import ChewyCIcon from '~/components/elements/Icons/generated/ChewyCIcon'
import { AutoshipFrequencyOption } from '~/constants/autoship'

import { AutoshipInfo, AutoshipInfoProps } from '../AutoshipInfo/AutoshipInfo'

const useStyles = makeStyles(
  () => ({
    container: {
      backgroundColor: colors.PRIMARY_LIGHT,
    },
  }),
  { name: 'ChewyOrderDetails' },
)

export interface ChewyOrderDetailsProps
  extends Omit<AutoshipInfoProps, 'frequency'>,
    Omit<BoxProps, 'classes'> {
  frequency?: AutoshipFrequencyOption
  readonly mainClasses?: ClassesType<typeof useStyles>
  orderNumber: number | Nil
}

export const ChewyOrderDetails = ({
  classes: autoshipInfoClasses,
  mainClasses,
  frequency,
  orderNumber,
  ...props
}: ChewyOrderDetailsProps) => {
  const classes = useStyles({ mainClasses })
  const { t } = useTranslation('Soap')

  return (
    <Box
      alignItems="flex-start"
      borderRadius={1}
      className={classes.container}
      display="flex"
      flexDirection="column"
      gap={2}
      p={1}
      {...props}
    >
      <Box alignItems="flex-start" display="flex">
        <ChewyCIcon
          sx={{
            fontSize: '3.2rem',
            color: (theme: Theme) => theme.colors.chewyPrimary,
          }}
        />
        {orderNumber ? (
          <Text strong ml={1} variant="chewy" whiteSpace="pre-line">
            {t('Soap:AUTOSHIP.AUTOSHIP_CHEWY_DETAILS_ORDER_NUMBER', {
              orderNumber,
            })}
          </Text>
        ) : (
          <Text strong ml={1} variant="chewy" whiteSpace="pre-line">
            {t('Soap:AUTOSHIP.AUTOSHIP_CHEWY_DETAILS')}
          </Text>
        )}
      </Box>
      {frequency ? (
        <Box ml={5}>
          <AutoshipInfo classes={autoshipInfoClasses} frequency={frequency} />
        </Box>
      ) : null}
    </Box>
  )
}

import * as R from 'ramda'
import { Constant, LanguageUtils, Nil, Utils } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { SpayedNeuteredGenderType } from '~/types'

export const getId = (genderId: string, spayedNeuteredStatusId: string) =>
  `${genderId}-${spayedNeuteredStatusId}`
export const parseId = (id: string) => {
  const [genderId, spayedNeuteredStatusId] = id.split('-')
  return { genderId, spayedNeuteredStatusId }
}

const getIds = (
  Gender: Constant[],
  gender: string,
  SpayedNeuteredStatus: Constant[],
  spayedNeuteredStatus: string,
) => {
  const genderId = Utils.findConstantIdByName(gender, Gender)
  const spayedNeuteredStatusId = Utils.findConstantIdByName(
    spayedNeuteredStatus,
    SpayedNeuteredStatus,
  )
  return {
    id: getId(genderId, spayedNeuteredStatusId),
    genderId,
    spayedNeuteredStatusId,
  }
}

export const generateSpayedNeuteredGender = (
  Gender: Constant[],
  SpayedNeuteredStatus: Constant[],
) => [
  {
    ...getIds(Gender, 'Male', SpayedNeuteredStatus, 'Intact'),
    name: i18n.t('Utils:GENDER_UTILS.INTACT_MALE'),
  },
  {
    ...getIds(Gender, 'Male', SpayedNeuteredStatus, 'Neutered'),
    name: i18n.t('Utils:GENDER_UTILS.NEUTERED_MALE'),
  },
  {
    ...getIds(Gender, 'Female', SpayedNeuteredStatus, 'Intact'),
    name: i18n.t('Utils:GENDER_UTILS.INTACT_FEMALE'),
  },
  {
    ...getIds(Gender, 'Female', SpayedNeuteredStatus, 'Spayed'),
    name: i18n.t('Utils:GENDER_UTILS.SPAYED_FEMALE'),
  },
]

export const spayedNeuteredToSpayedNeuteredStatus = (
  gender: string,
  Gender: Constant[],
  spayedNeutered: string,
  SpayedNeuteredStatus: Constant[],
) => {
  if (!spayedNeutered) {
    return Utils.findConstantIdByName('Intact', SpayedNeuteredStatus)
  }

  return Utils.getConstantName(gender, Gender) === 'Male'
    ? Utils.findConstantIdByName('Neutered', SpayedNeuteredStatus)
    : Utils.findConstantIdByName('Spayed', SpayedNeuteredStatus)
}

export const spayedNeuteredStatusToSpayedNeutered = (
  spayedNeuteredStatus: string,
  SpayedNeuteredStatus: Constant[],
) =>
  Utils.getConstantName(spayedNeuteredStatus, SpayedNeuteredStatus) !== 'Intact'

export const isFemale = (genderId: string, Gender: Constant[]) =>
  genderId
    ? Utils.getConstantName(genderId, Gender, '').toLowerCase() === 'female'
    : undefined

export const getIntactLocalizedString = (
  genderId: string | Nil,
  Gender: Constant[],
) =>
  genderId
    ? isFemale(genderId, Gender)
      ? i18n.t('Utils:GENDER_UTILS.INTACT_FEMALE_WITHOUT_GENDER')
      : i18n.t('Utils:GENDER_UTILS.INTACT_MALE_WITHOUT_GENDER')
    : i18n.t('Utils:GENDER_UTILS.INTACT_MALE_OR_FEMALE')
export function getSpayedNeuteredIntact(
  genderId: string | Nil,
  spayedNeutered: boolean | Nil,
  Gender: Constant[],
) {
  return genderId && R.is(Boolean)(spayedNeutered)
    ? spayedNeutered
      ? Utils.getSpayedNeutered(genderId, Gender)
      : getIntactLocalizedString(genderId, Gender)
    : ''
}

export function getSpayedNeuteredIntactWithGenderString(
  genderId: string | Nil,
  spayedNeutered: boolean | Nil,
  Gender: Constant[],
) {
  const rawString = i18n.t(
    'Utils:GENDER_UTILS.SPAYED_OR_NEUTERED_OR_INTACT_GENDER',
    {
      spayedOrNeuteredOrIntact: getSpayedNeuteredIntact(
        genderId,
        spayedNeutered,
        Gender,
      ),
      gender: LanguageUtils.getConstantTranslatedName(genderId, Gender, ''),
    },
  )

  return LanguageUtils.capitalize(rawString)
}

export function getShortGenderString(
  genderId: string | Nil,
  spayedNeutered: boolean | Nil,
  Gender: Constant[],
) {
  if (!genderId) {
    return ''
  }

  const genderLetter = LanguageUtils.getConstantTranslatedName(
    genderId,
    Gender,
    '',
  )?.[0]
  const spayedNeuteredLetter = spayedNeutered
    ? getSpayedNeuteredIntact(genderId, spayedNeutered, Gender)?.[0]
    : ''

  return `${genderLetter}${spayedNeuteredLetter}`
}

const isSpayedOrNeuteredStatus = (status: string | Nil) =>
  Boolean(status) && status !== 'Intact'

export function getGenderRestrictionString(
  genderRestrictions: SpayedNeuteredGenderType[] = [],
  Gender: Constant[],
  SpayedNeuteredStatus: Constant[],
) {
  return (
    genderRestrictions
      .map((restriction) => {
        const spayedNeuteredStatus = Utils.getConstantName(
          restriction.spayedNeuteredStatusId,
          SpayedNeuteredStatus,
          undefined,
        )

        const fullLocalizedGenderString =
          getSpayedNeuteredIntactWithGenderString(
            restriction.genderId,
            isSpayedOrNeuteredStatus(spayedNeuteredStatus),
            Gender,
          )
        return fullLocalizedGenderString.trim()
      })
      .join(', ') || i18n.t('Common:ALL')
  )
}

export function getGenderString(genderId: string | Nil, constant: Constant[]) {
  return LanguageUtils.getConstantTranslatedName(
    genderId,
    constant,
    i18n.t('Common:ALL'),
  )
}

export function getFullGenderString(
  genderId: string,
  Gender: Constant[],
  spayedNeuteredId: string,
  SpayedNeuteredStatus: Constant[],
) {
  const spayedNeuteredStatus = Utils.getConstantName(
    spayedNeuteredId,
    SpayedNeuteredStatus,
    '',
  )
  const fullLocalizedGenderString = getSpayedNeuteredIntactWithGenderString(
    genderId,
    isSpayedOrNeuteredStatus(spayedNeuteredStatus),
    Gender,
  )
  return genderId ? fullLocalizedGenderString.trim() : i18n.t('Common:ALL')
}

import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  LanguageUtils,
  Nil,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  ChargeSheetEmailDto,
  ConversationTransport,
  GroupIdentifier,
} from '~/api/graphql/generated/types'
import DownloadPdfContentButton from '~/components/common/buttons/DownloadPdfContentButton'
import EmailForm, { EmailFormHandle } from '~/components/common/email/EmailForm'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { GeneratingPdfContentKind } from '~/constants/communications'
import { fetchClient } from '~/store/actions/clients'
import { emailChargeSheet } from '~/store/actions/communications'
import { getClientFinanceLoading } from '~/store/duck/clientFinanceData'
import { useCreatedConversationsInfo } from '~/store/hooks/conversations'
import { useDownloadContentAsPdf } from '~/store/hooks/finance'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getUser } from '~/store/reducers/users'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'
import { useSaveEmailAndSendMessage } from '~/utils/useSaveEmailAndSendMessage'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    button: {
      width: 167,
      height: 40,
    },
    row: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'EmailChargeSheet' },
)

export interface EmailChargeSheetProps {
  clientId: string | Nil
  expandedGroups?: GroupIdentifier[] | Nil
  onOk?: () => void
}

const EmailChargeSheet = ({
  clientId,
  expandedGroups,
  onOk,
}: EmailChargeSheetProps) => {
  const { t } = useTranslation(['Common', 'Finance'])

  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(getClientFinanceLoading)
  const currentBusiness = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId))

  const clientName = Utils.getPersonString(client)

  const emailFormRef = useRef<EmailFormHandle>(null)

  const messageBody = t('Finance:EMAIL_CHARGE_SHEET.BODY_MESSAGE')

  const defaultSubject = t('Finance:EMAIL_CHARGE_SHEET.SUBJECT', { clientName })

  const initialMessage = t('Finance:EMAIL_INITIAL_MESSAGE', {
    clientFirstName: client?.firstName,
    messageBody,
    sender: currentBusiness?.name,
  })

  const {
    fields: { subject, message, to },
    validate,
  } = useNewConversationValidationForm(
    { transport: ConversationTransport.Email, client },
    { initialSubject: defaultSubject, initialMessage },
  )

  useEffect(() => {
    if (!client?.email) {
      dispatch(fetchClient({ clientId }))
    }
  }, [clientId])

  const formRecipients = () => emailFormRef.current?.formRecipients() || []

  const getEmailData = (): ChargeSheetEmailDto => ({
    businessId: currentBusiness?.id,
    clientId,
    expandedGroups: expandedGroups || [],
    recipients: formRecipients(),
    subject: subject.value,
    message: message.value,
  })

  const displayConversationCreationResult = useCreatedConversationsInfo({
    getIsConversationCreating: getClientFinanceLoading,
    onConversationCreationSuccess: onOk,
    createdInfoDialogProps: {
      titleMessageName: LanguageUtils.capitalize(
        t('Finance:EMAIL_CHARGE_SHEET.CREATED_INFO_DIALOG_TITLE', {
          clientName,
        }),
      ),
    },
  })

  const send = (emailData: ChargeSheetEmailDto) => {
    if (validate()) {
      displayConversationCreationResult()
      dispatch(emailChargeSheet(emailData))
    }
  }

  const sendEmail = () => send(getEmailData())

  const [generatePdf, isGenerating] = useDownloadContentAsPdf(
    GeneratingPdfContentKind.CHARGE_SHEET,
  )

  const handleDownloadClick = () => {
    generatePdf({ clientId })
  }

  const handleAskForSaveEmailAndSend = useSaveEmailAndSendMessage(
    sendEmail,
    client,
    formRecipients,
    to.value,
  )
  const handleSendClick = () => validate() && handleAskForSaveEmailAndSend()

  return (
    <Grid container className={classes.root} direction="column" p={3}>
      <EmailForm
        clientId={clientId}
        message={message}
        ref={emailFormRef}
        subject={subject}
        to={to}
      />
      <Grid item className={classes.row}>
        <RequiredFieldsNotice />
      </Grid>
      <Grid
        container
        item
        className={classes.row}
        justifyContent="space-between"
      >
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          onClick={handleSendClick}
        >
          {t('Common:SEND_ACTION')}
        </ButtonWithLoader>
        <DownloadPdfContentButton
          loading={isGenerating}
          onClick={handleDownloadClick}
        />
      </Grid>
    </Grid>
  )
}

export default EmailChargeSheet

import { gql } from '@apollo/client/core'

export const CREATE_CREDIT_ADJUSTMENT = gql`
  mutation CreateCreditAdjustment(
    $clientId: ID!
    $input: CreditAdjustmentInput!
  ) {
    createCreditAdjustment(clientId: $clientId, input: $input) {
      amount
      id
      links {
        invoiceTotalAmountNoFee
        invoice {
          __typename
          ... on Invoice {
            id
          }
          ... on RefundInvoice {
            id
          }
          ... on Estimate {
            id
          }
        }
      }
      businessId
      clientId
    }
  }
`

export const LINK_UNAPPLIED_PAYMENT = gql`
  mutation LinkUnappliedPayment($invoiceIds: [ID!]!, $paymentId: ID!) {
    linkUnappliedPayment(invoiceIds: $invoiceIds, paymentId: $paymentId) {
      invoiceTotalAmountNoFee
      split
      paidAmountNoFee
      serviceFeeIncAmount
      paidAmount
    }
  }
`

export const UNAPPLY_INVOICE_PAYMENT = gql`
  mutation UnApplyInvoicePayment($paymentId: ID!, $invoiceId: ID!) {
    unApplyInvoicePayment(paymentId: $paymentId, invoiceId: $invoiceId)
  }
`

export const LINK_UNAPPLIED_PAYMENTS = gql`
  mutation LinkUnappliedPayments($paymentIds: [ID!], $invoiceIds: [ID!]) {
    linkUnappliedPayments(paymentIds: $paymentIds, invoiceIds: $invoiceIds) {
      invoiceNo
    }
  }
`

export const CREATE_REFUND_ON_ACCOUNT = gql`
  mutation CreateRefundOnAccount($input: RefundOnAccountInput!) {
    createRefundOnAccount(input: $input) {
      amount
      id
    }
  }
`

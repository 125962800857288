import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import {
  ClientAndPatient as ClientAndPatientIcon,
  Communications as CommunicationsIcon,
  Estimates as EstimatesIcon,
  Print as PrintIcon,
  Sms as SmsIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import AppointmentStateLabel from '~/components/common/labels/AppointmentStateLabel'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  LandingType,
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import { BaseRoute } from '~/constants/routes'
import i18n from '~/locales/i18n'
import {
  getWidgetData,
  getWidgetDataIsLoading,
  getWidgetDataTotalCount,
} from '~/store/duck/landing'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  getAppointmentsWidgetAssignedMode,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { getMultipleTimetableEvents } from '~/store/reducers/timetable'
import { TimetableEvent, WidgetColumn } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useDialog from '~/utils/useDialog'
import useIsDymoConnectEnabled from '~/utils/useIsDymoEnabled'

import AppointmentTypeCell from '../AppointmentTypeCell'
import ClientAndPatientCell from '../ClientAndPatientCell'
import DateGroupingCell from '../DateGroupingCell'
import DateRangeCell from '../DateRangeCell'
import LandingWidget, { LandingWidgetProps } from '../LandingWidget'
import NoRecords from '../NoRecords'
import TableWidget from '../TableWidget'
// @ts-ignore
import useEnrichGroupedItemsByDate from '../useEnrichGroupedItemsByDate'
import { getVisibleWidgetColumns } from '../widgetUtils'

const columns: WidgetColumn<TimetableEvent>[] = [
  {
    id: 'dateGrouping',
    prop: (item) => <DateGroupingCell title={item?.dateTitle} />,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 1.5,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 1.5,
      [WidgetWidthType.HALF_WIDTH]: 1.5,
      [WidgetWidthType.THIRD_WIDTH]: 2,
    },
    label: i18n.t('Common:DATE_TIME'),
  },
  {
    id: 'dateRange',
    component: DateRangeCell,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 2,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 2,
      [WidgetWidthType.HALF_WIDTH]: 2,
      [WidgetWidthType.THIRD_WIDTH]: 2,
    },
    label: i18n.t('Common:TIME'),
  },
  {
    id: 'clientAndPatient',
    component: ClientAndPatientCell,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 2,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 2,
      [WidgetWidthType.HALF_WIDTH]: 2,
      [WidgetWidthType.THIRD_WIDTH]: 2.5,
    },
    label: i18n.t('Common:CLIENT_AND_PATIENT'),
  },
  {
    id: 'notes',
    prop: 'notes',
    width: 1.5,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:NOTES'),
  },
  {
    id: 'appointmentType',
    component: AppointmentTypeCell,
    width: 1.5,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:APPOINTMENT_TYPE'),
  },
  {
    id: 'spaceName',
    prop: (item) => item.space?.name,
    width: 1.5,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:SPACE_ONE'),
  },
  {
    id: 'appointmentState',
    prop: (item) => <AppointmentStateLabel item={item} variant="small" />,
    width: 1,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:STATUS'),
  },
]

interface OpenSoapWidgetProps extends LandingWidgetProps<TimetableEvent> {
  onExpand: () => void
  rowsCount?: number
  widthType?: WidgetWidthType
}

const OpenSoapWidget = ({
  widthType = WidgetWidthType.FULL_WIDTH,
  rowsCount = 10,
  onExpand,
  ...rest
}: OpenSoapWidgetProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['Common', 'Landing'])

  const isDymoConnectEnabled = useIsDymoConnectEnabled()
  const AppointmentsWidgetAssignedMode = useSelector(
    getAppointmentsWidgetAssignedMode,
  )
  const appointmentIds = useSelector(
    getWidgetData(LandingType.LANDING_DASHBOARD, LandingWidgetName.OPEN_SOAPS),
  )
  const totalCount = useSelector(
    getWidgetDataTotalCount(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.OPEN_SOAPS,
    ),
  )
  const isLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.OPEN_SOAPS,
    ),
  )
  const appointments = useSelector(getMultipleTimetableEvents(appointmentIds))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const appointmentsList = useEnrichGroupedItemsByDate({
    dateProp: 'scheduledStartDatetime',
    titleProp: 'dateTitle',
    items: appointments,
  })

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openPrintInvoiceDialog] = useDialog(DialogNames.PRINT_INVOICE)
  const [openPrintCageLabelDialog] = useDialog(DialogNames.PRINT_CAGE_LABEL)
  const [openPrintFolderLabelDialog] = useDialog(DialogNames.PRINT_FOLDER_LABEL)
  const [openNewConversationDialog] = useDialog(
    DialogNames.NEW_CONVERSATION_WITH_STEPS,
  )
  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)

  const openInvoice = useOpenInvoice(null, openInvoiceDialog)

  const goToSoap = (item?: TimetableEvent) => {
    if (item) {
      navigate(
        addOriginalBusinessId(
          `/soap/${item.soapId}`,
          isPatientSharingEnabled ? item.businessId : null,
        ),
      )
    }
  }

  const handleNavigateToItemDetails = goToSoap

  const handleGoToSoap = goToSoap

  const handleOpenInvoice = (item: TimetableEvent) => {
    openInvoice({
      clientId: item.clientId,
      patientId: item.patientId,
      invoiceId: item.invoiceId,
      soapId: item.soapId,
    })
  }

  const handlePrintInvoice = (item: TimetableEvent) =>
    openPrintInvoiceDialog({
      invoiceIds: item.invoiceId ? [item.invoiceId] : [],
    })

  const handleGoToPatient = (item?: TimetableEvent) => {
    if (item) {
      navigate(`/client/${item.clientId}/patient/${item.patientId}`)
    }
  }

  const handlePrintCageCard = (item?: TimetableEvent) => {
    if (item) {
      openPrintCageLabelDialog({
        clientId: item.clientId,
        patientId: item.parentId,
      })
    }
  }
  const handlePrintFolderLabel = (item?: TimetableEvent) => {
    if (item) {
      openPrintFolderLabelDialog({
        clientId: item.clientId,
        patientId: item.parentId,
      })
    }
  }

  const navigateToConversation = (id?: string) => {
    if (id) {
      navigate(`${BaseRoute.COMMUNICATIONS}/${id}`)
    }
  }

  const handleCreateNewConversation = (item?: TimetableEvent) => {
    openNewConversationDialog({
      clientId: item?.clientId,
      patientId: item?.parentId,
      appointmentId: item?.id,
    })
  }

  const handleOpenConversation = ({ conversationId }: TimetableEvent) =>
    navigateToConversation(conversationId)

  const handleCreateAppointment = () => {
    openAppointmentDialog()
  }

  const handleNavigateToScheduler = () => navigate('/scheduler')

  const getActions = (item?: TimetableEvent) =>
    [
      {
        id: 'soap',
        isGroup: true,
        items: [
          {
            id: 'goToSoap',
            label: t('Common:GO_TO_SOAP'),
            Icon: ArrowForwardIcon,
            onClick: () => handleGoToSoap(item),
          },
        ],
      },
      item?.invoiceId && {
        id: 'invoice',
        title: t('Common:INVOICE'),
        isGroup: true,
        items: [
          {
            id: 'openInvoice',
            label: t('Common:OPEN_INVOICE'),
            Icon: EstimatesIcon,
            onClick: () => handleOpenInvoice(item),
          },
          {
            id: 'printInvoice',
            label: t('Common:PRINT_INVOICE'),
            Icon: PrintIcon,
            onClick: () => handlePrintInvoice(item),
          },
        ],
      },
      {
        id: 'patient',
        title: t('Common:PATIENT'),
        isGroup: true,
        items: [
          {
            id: 'goToPatientDetailsInvoice',
            label: t('Common:GO_TO_PATIENT_DETAILS'),
            Icon: ClientAndPatientIcon,
            onClick: () => handleGoToPatient(item),
          },
          {
            id: 'printCageCardLabel',
            label: t('Common:PRINT_CAGE_CARD_LABEL'),
            Icon: PrintIcon,
            onClick: () => handlePrintCageCard(item),
          },
          isDymoConnectEnabled && {
            id: 'printFolderLabel',
            label: t('Common:PRINT_FOLDER_LABEL'),
            Icon: PrintIcon,
            onClick: () => handlePrintFolderLabel(item),
          },
        ],
      },
      {
        id: 'client',
        title: t('Common:CLIENT'),
        isGroup: true,
        items: [
          item?.conversationId
            ? {
                id: 'openConversation',
                label: t('Common:OPEN_CONVERSATION'),
                Icon: SmsIcon,
                onClick: () => handleOpenConversation(item),
              }
            : {
                id: 'newConversation',
                label: t('Common:NEW_CLIENT_CONVERSATION'),
                Icon: CommunicationsIcon,
                onClick: () => handleCreateNewConversation(item),
              },
        ],
      },
    ] as PopperAction[]

  const visibleColumns = getVisibleWidgetColumns(columns, widthType)

  return (
    <LandingWidget
      showHeaderTitles
      assignedModes={AppointmentsWidgetAssignedMode}
      columns={visibleColumns}
      component={TableWidget}
      data={appointmentsList}
      getActions={getActions}
      isLoading={isLoading}
      navigateToDetailsTooltip={t('Common:GO_TO_SCHEDULER')}
      navigateToItemDetailsTooltip={t('Common:GO_TO_SOAP')}
      noRecords={
        <NoRecords
          action={t('Common:ADD_APPOINTMENT')}
          text={t('Landing:OPEN_SOAP_WIDGET.NO_OPEN_SOAPS')}
          onClick={handleCreateAppointment}
        />
      }
      rowsCount={rowsCount}
      totalCount={totalCount}
      onExpand={onExpand}
      onNavigateToDetails={handleNavigateToScheduler}
      onNavigateToItemDetails={handleNavigateToItemDetails}
      {...rest}
    />
  )
}

export default OpenSoapWidget

/* eslint-disable max-lines */
import { Task as SagaTask } from '@redux-saga/types'
import * as R from 'ramda'
import {
  all,
  call,
  cancel,
  debounce,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import {
  ApiError,
  CountryCode,
  Defaults,
  Nil,
  OrderFiltersConstant,
  Utils,
} from '@pbt/pbt-ui-components'

import * as API from '~/api'
import {
  MutationApproveActiveRxArgs,
  MutationSaveActiveRxArgs,
  MutationSubmitActiveRxArgs,
} from '~/api/graphql/generated/types'
import ApiErrorTypes from '~/constants/apiErrorTypes'
import { BUNDLE_INCLUDES_ZERO_USED } from '~/constants/errorMessages'
import FeatureToggle from '~/constants/featureToggle'
import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import { OrderType } from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import { fetchInvoiceV3 } from '~/store/reducers/invoiceV3'
import {
  CRUDOrderOptions,
  FetchOrdersOptions,
  Order,
  OrderFilter,
  Prescription,
  PrescriptionV2,
  Task,
} from '~/types'
import { getServerValidationError } from '~/utils/errors'
import { isOrderTask } from '~/utils/orderUtils'
import {
  convertOrderToRxInput,
  convertPrescriptionV2ToPrescription,
  getPrescriptionType,
  getPrescriptionWorkflowType,
} from '~/utils/prescription'

import { fetchClient } from '../actions/clients'
import { clearAppointmentInvoiceItems, fetchInvoice } from '../actions/finance'
import {
  cancelRefills,
  cancelRefillsFailure,
  cancelRefillsSuccess,
  clearRemountWebsocketChargesWidget,
  createOrder,
  createOrderFailure,
  createOrderSuccess,
  createUnifiedOrder,
  createUnifiedOrderFailure,
  createUnifiedOrderSuccess,
  declineLabOrder,
  declineLabOrderAbolish,
  declineLabOrderFailure,
  declineLabOrderSuccess,
  editOrder,
  editOrderAbolish,
  editOrderFailure,
  editOrders,
  editOrdersFailure,
  editOrdersStatuses,
  editOrdersStatusesFailure,
  editOrdersStatusesSuccess,
  editOrdersSuccess,
  editOrderSuccess,
  fetchMoreForSearch,
  fetchMoreForSearchFailure,
  fetchMoreForSearchSuccess,
  fetchMoreOrders,
  fetchMoreOrdersFailure,
  fetchMoreOrdersSuccess,
  fetchOrderCountsByType,
  fetchOrderCountsByTypeFailure,
  fetchOrderCountsByTypeSuccess,
  fetchOrderDetails,
  fetchOrderDetailsFailure,
  fetchOrderDetailsGrouped,
  fetchOrderDetailsGroupedFailure,
  fetchOrderDetailsGroupedSuccess,
  fetchOrderDetailsSuccess,
  fetchOrders,
  fetchOrdersFailure,
  fetchOrdersFilters,
  fetchOrdersFiltersFailure,
  fetchOrdersFiltersSuccess,
  fetchOrdersSuccess,
  fetchPrescriptionEmailTemplate,
  fetchPrescriptionEmailTemplateFailure,
  fetchPrescriptionEmailTemplateSuccess,
  fetchPrescriptionPrintInfo,
  fetchPrescriptionPrintInfoFailure,
  fetchUnifiedOrder,
  fetchUnifiedOrderByEntityType,
  fetchUnifiedOrderByEntityTypeFailure,
  fetchUnifiedOrderByEntityTypeSuccess,
  fetchUnifiedOrderByTypeAndLogId,
  fetchUnifiedOrderByTypeAndLogIdFailure,
  fetchUnifiedOrderByTypeAndLogIdSuccess,
  fetchUnifiedOrderFailure,
  fetchUnifiedOrderSuccess,
  orderBundle,
  orderBundleFailure,
  orderBundleSuccess,
  partialEditOrder,
  partialEditOrderAbolish,
  partialEditOrderFailure,
  partialEditOrderSuccess,
  refreshOrders,
  removeLabOrder,
  removeLabOrderAbolish,
  removeOrder,
  removeOrderFailure,
  removeOrders,
  removeOrdersFailure,
  removeOrdersSuccess,
  removeOrderSuccess,
  removeOrderUpdatePatient,
  removeOrderUpdatePatientFailure,
  removeOrderUpdatePatientSuccess,
  removeUnifiedOrder,
  removeUnifiedOrderFailure,
  removeUnifiedOrderSuccess,
  searchOrders,
  searchOrdersFailure,
  searchOrdersSuccess,
  setOrderCanNotBeRemovedMessage,
  setOrderValidationErrorMessage,
  setPrescriptionForEmail,
  setPrescriptionForPrint,
  signPrescription,
  signPrescriptionFailure,
  signPrescriptionSuccess,
  subtractRefillRemains,
  unorderBundle,
  unorderBundleFailure,
  unorderBundleSuccess,
  unOrderBundleWithoutOrder,
  unOrderBundleWithoutOrderFailure,
  unOrderBundleWithoutOrderSuccess,
  updateCurrentOrderDetails,
  updateMultipleOrders,
  updateOrderStatus,
  updateOrderStatusFailure,
  updateOrderStatusSuccess,
  updateUnifiedOrder,
  updateUnifiedOrderFailure,
  updateUnifiedOrderSuccess,
} from '../actions/orders'
import {
  fetchSOAPOrderFilters,
  fetchSOAPOrders,
  saveSOAP,
  saveSOAPAbolish,
  saveSOAPFailure,
  saveSOAPSuccess,
  selectDoctor,
} from '../actions/soap'
import { updateTasks } from '../actions/tasks'
import {
  fetchMoreItemsForTimeline,
  fetchTimeline as fetchTimelineAction,
} from '../actions/timeline'
import {
  CANCEL_REFILLS,
  CLEAR_ORDERS,
  CLEAR_SEARCH_ORDERS,
  CREATE_ORDER,
  CREATE_UNIFIED_ORDER,
  DECLINE_LAB_ORDER,
  EDIT_ORDER,
  EDIT_ORDERS,
  EDIT_ORDERS_STATUSES,
  FETCH_MORE_FOR_SEARCH,
  FETCH_MORE_ORDERS,
  FETCH_ORDER_COUNTS_BY_TYPE,
  FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_GROUPED,
  FETCH_ORDERS,
  FETCH_ORDERS_FILTERS,
  FETCH_PRESCRIPTION_EMAIL_TEMPLATE,
  FETCH_PRESCRIPTION_PRINT_INFO,
  FETCH_UNIFIED_ORDER,
  FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE,
  FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID,
  ORDER_BUNDLE,
  PARTIAL_EDIT_ORDER,
  REFRESH_ORDERS,
  REMOUNT_WEBSOCKET_CHARGES_WIDGET,
  REMOVE_LAB_ORDER,
  REMOVE_ORDER,
  REMOVE_ORDER_UPDATE_PATIENT,
  REMOVE_ORDERS,
  REMOVE_UNIFIED_ORDER,
  SEARCH_ORDERS,
  SEARCH_ORDERS_SUCCESS,
  SIGN_PRESCRIPTION,
  UNORDER_BUNDLE,
  UNORDER_BUNDLE_WITHOUT_ORDER,
  UPDATE_ORDER_STATUS,
  UPDATE_UNIFIED_ORDER,
} from '../actions/types/orders'
import {
  fetchClientFinanceCharges,
  getClientFinanceLoading,
} from '../duck/clientFinanceData'
import { fetchWidgetsData } from '../duck/landing'
import {
  addActiveRxListItem,
  getChargeSheetPendingChewyActiveRxList,
  getSoapPendingChewyActiveRxList,
  removeActiveRxListItem,
} from '../duck/prescriptions'
import { finishLoading, startLoading } from '../duck/progress'
import { registerWarnAlert } from '../duck/uiAlerts'
import {
  getCurrentBusinessCountryCatalogCode,
  getCurrentBusinessId,
} from '../reducers/auth'
import { getFeatureToggle, getOrderFilters } from '../reducers/constants'
import {
  getAppliedPatientRestrictions,
  getLastRequestedOrderFilters,
  getLastSearchOrderFilters,
  getOrdersIsSending,
  getRemountChargesWidget,
  getUnifiedOrder,
} from '../reducers/orders'
import {
  getAppointmentId,
  getClientId,
  getInvoiceId,
  getPatientId,
  getSoapBusinessId,
  getSoapId,
} from '../reducers/soap'
import { getTasksMap } from '../reducers/tasks'
import { handlePatientUpdatedByOrder } from './utils/patients'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

const isZeroUsedOrderItem = (item: Order) =>
  item && item.prepaid && item.usedQuantity === 0
const isZeroUsedBundle = (order: Order) =>
  order?.items && order.items.some(isZeroUsedOrderItem)
const isChargeOnlyBundle = (createdLogItems: Order[], order: Order) =>
  order?.items?.length !== createdLogItems?.length

const convertGraphQlPrescriptionV2ResultToPrescription = (result: any) => {
  if (
    result?.entities?.prescriptionsV2 &&
    result?.result &&
    result.entities.prescriptionsV2[result.result]
  ) {
    return convertPrescriptionV2ToPrescription(
      result.entities.prescriptionsV2[result.result],
    )
  }
  return null
}

function* handleOrderUpdateConflict(error: ApiError) {
  if (error?.response?.status === 409) {
    yield put(
      setOrderValidationErrorMessage(getServerValidationError(error) as string),
    )
  }
}

function* fetchCurrentSoapClient() {
  const clientId: string = yield select(getClientId)
  if (clientId) {
    yield put(fetchClient({ clientId }))
  }
}

function* updateTasksIfNeeded(orders: Order[]) {
  const tasksMap: Record<string, Task> = yield select(getTasksMap)
  const tasks = Object.values(tasksMap)

  const orderTasks = orders.map((order) => {
    if (!order?.taskStateId) {
      return []
    }

    return tasks
      .filter((task) => isOrderTask(task, order))
      .map((task) => ({ [task.id]: { stateId: order.taskStateId } }))
  })

  const newTasks: Record<string, Partial<Task>> = R.mergeAll(
    R.flatten(orderTasks),
  )

  if (!R.isEmpty(newTasks)) {
    yield put(updateTasks(newTasks))
  }
}

function* fetchInvoiceOnOrderChange(invoiceId?: string | Nil) {
  if (invoiceId) {
    yield put(fetchInvoice(invoiceId))
    return
  }

  const id: string | undefined = yield select(getInvoiceId)

  if (id) {
    yield put(fetchInvoice(id))
  }
}

type GetOrdersV2Data = {
  filter: OrderFiltersConstant[]
  item: Order
}

export function* fetchOrdersSaga({
  categories,
  subcategories,
  entityType,
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  forShipments,
  labVendorId,
  applyPatientRestriction,
  from: fromProp,
  to: toProp,
}: ReturnType<typeof fetchOrders>) {
  try {
    const isLoadingBundles = entityType === OrderType.BUNDLE
    const isLoadingReminders = entityType === OrderType.REMINDER

    const from = isLoadingReminders || isLoadingBundles ? 0 : fromProp
    // Little hack to avoid pagination for reminders and bundles (when charges widget is enabled)
    const to = isLoadingReminders || isLoadingBundles ? 9999 : toProp

    const catalogCode: CountryCode = yield select(
      getCurrentBusinessCountryCatalogCode,
    )
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )

    const { data, totalCount } = yield* requestAPI(
      isFoodCatalogEnabled ? API.getOrdersV3 : API.getOrdersV2,
      categories,
      subcategories,
      entityType,
      listType,
      patientId,
      clientId,
      eventId,
      withTasks,
      forShipments,
      labVendorId,
      applyPatientRestriction,
      catalogCode,
      from,
      to,
    )

    const filters = {
      categories,
      subcategories,
      entityType,
      listType,
      patientId,
      clientId,
      eventId,
      withTasks,
      forShipments,
      labVendorId,
      applyPatientRestriction,
      from,
      to,
    }

    const orders = isLoadingReminders
      ? // mark first reminder from each status category
        data.map((order: GetOrdersV2Data, index: number) => ({
          ...order.item,
          filter: order.filter,
          isFirstItemInCategory:
            data[index - 1]?.item?.categoryName !== order.item?.categoryName,
          isLastItemInCategory:
            data[index + 1]?.item?.categoryName !== order.item?.categoryName,
        }))
      : data.map((order: GetOrdersV2Data) => ({
          ...order.item,
          filter: order.filter,
        }))

    yield put(fetchOrdersSuccess(orders, totalCount, filters))
  } catch (error) {
    yield put(fetchOrdersFailure(error as ApiError))
  }
}

export function* fetchMoreOrdersCancelable({
  categories,
  subcategories,
  entityType,
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  forShipments,
  labVendorId,
  applyPatientRestriction,
  from,
  to,
}: ReturnType<typeof fetchMoreOrders>) {
  try {
    const catalogCode: CountryCode = yield select(
      getCurrentBusinessCountryCatalogCode,
    )
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )

    const { data: orders } = yield* requestAPI(
      isFoodCatalogEnabled ? API.getOrdersV3 : API.getOrdersV2,
      categories,
      subcategories,
      entityType,
      listType,
      patientId,
      clientId,
      eventId,
      withTasks,
      forShipments,
      labVendorId,
      applyPatientRestriction,
      catalogCode,
      from,
      to,
    )

    const ordersWithFilter = orders.map((order: GetOrdersV2Data) => ({
      ...order.item,
      filter: order.filter,
    }))

    const filters = {
      categories,
      subcategories,
      entityType,
      listType,
      patientId,
      clientId,
      eventId,
      withTasks,
      forShipments,
      labVendorId,
      applyPatientRestriction,
      from,
      to,
    }
    yield put(fetchMoreOrdersSuccess(ordersWithFilter, from, filters))
  } catch (error) {
    yield put(fetchMoreOrdersFailure(error as ApiError))
  }
}

export function* fetchMoreOrdersSaga(
  params: ReturnType<typeof fetchMoreOrders>,
) {
  const task: SagaTask = yield fork(fetchMoreOrdersCancelable, params)
  yield take([FETCH_ORDERS, SEARCH_ORDERS, CLEAR_ORDERS])
  yield cancel(task)
}

export function* fetchOrderDetailsSaga({
  order,
}: ReturnType<typeof fetchOrderDetails>) {
  try {
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )
    const orderDetails = yield* requestAPI(
      API.getOrderDetails,
      R.path<string>(['inventory', 'id'])(order),
      R.path<string>([
        isFoodCatalogEnabled ? 'globalInventoryMapping' : 'globalInventory',
        'id',
      ])(order),
    )
    yield put(fetchOrderDetailsSuccess(orderDetails))
  } catch (error) {
    yield put(fetchOrderDetailsFailure(error as ApiError))
  }
}

export function* fetchOrderDetailsGroupedSaga({
  businessItemId,
  globalInventoryCommonOrMappingId,
  matchChewyItems,
}: ReturnType<typeof fetchOrderDetailsGrouped>) {
  try {
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )
    const groupedOrderDetails = yield* requestAPI(
      isFoodCatalogEnabled
        ? API.fetchOrderDetailsGroupedV2
        : API.fetchOrderDetailsGrouped,
      businessItemId,
      globalInventoryCommonOrMappingId,
      matchChewyItems,
    )
    yield put(fetchOrderDetailsGroupedSuccess(groupedOrderDetails))
  } catch (error) {
    yield put(fetchOrderDetailsGroupedFailure(error as ApiError))
  }
}

export function* fetchUnifiedOrderSaga({
  id,
  logType,
}: ReturnType<typeof fetchUnifiedOrder>) {
  try {
    yield put(startLoading('unifiedOrder'))
    const { unifiedOrder } = yield* requestAPI(
      API.fetchUnifiedOrder,
      id,
      logType,
    )
    yield put(fetchUnifiedOrderSuccess(unifiedOrder))
  } catch (error) {
    yield put(fetchUnifiedOrderFailure(error as ApiError))
  } finally {
    yield put(finishLoading('unifiedOrder'))
  }
}

export function* fetchUnifiedOrderByEntityTypeSaga({
  entityId,
  entityType,
  withTasks,
}: ReturnType<typeof fetchUnifiedOrderByEntityType>) {
  try {
    const order = yield* requestAPI(
      API.fetchUnifiedOrderByEntityType,
      entityId,
      entityType,
      withTasks,
    )
    yield put(fetchUnifiedOrderByEntityTypeSuccess(order))
  } catch (error) {
    yield put(fetchUnifiedOrderByEntityTypeFailure(error as ApiError))
  }
}

export function* fetchOrdersFiltersSaga({
  options,
}: ReturnType<typeof fetchOrdersFilters>) {
  try {
    const ordersFilters = yield* requestAPI(API.getOrdersFilters)
    const allowedFilters = options?.allowedTypes
      ? ordersFilters.filter(({ type }: OrderFilter) =>
          options.allowedTypes.includes(type),
        )
      : ordersFilters
    yield put(fetchOrdersFiltersSuccess(allowedFilters))
  } catch (error) {
    yield put(fetchOrdersFiltersFailure(error as ApiError))
  }
}

export function* createOrderSaga({
  order,
  options: {
    print,
    email,
    signatureInfo,
    outsideSoap,
    fromTimeline,
    soapId: soapIdParam,
    invoiceId,
  } = {},
}: ReturnType<typeof createOrder>) {
  try {
    if (!outsideSoap) {
      yield put(saveSOAP())
    }

    const appointmentId: string = yield select(getAppointmentId)
    const soapId = soapIdParam || (yield select(getSoapId))
    const soapBusinessId = soapIdParam || (yield select(getSoapBusinessId))
    const createOrderResponse = outsideSoap
      ? yield* requestAPI(
          API.createOrderOutsideSoap,
          order.type,
          { ...order, invoiceId },
          print,
        )
      : yield* requestAPI(API.createOrder, soapId, order.type, order, print)

    const createdOrder = outsideSoap
      ? createOrderResponse.prescription
      : createOrderResponse

    yield call(fetchInvoiceOnOrderChange, invoiceId)

    if (fromTimeline) {
      yield put(fetchTimelineAction())
    }

    yield put(createOrderSuccess(createdOrder, order))

    if (print) {
      yield put(setPrescriptionForPrint(createdOrder))
    }

    if (email) {
      yield put(setPrescriptionForEmail(createdOrder))
    }

    if (signatureInfo?.signerId && signatureInfo?.signature) {
      yield put(
        signPrescription(
          outsideSoap ? undefined : soapId,
          createdOrder.id,
          signatureInfo.signerId,
          signatureInfo.signature,
        ),
      )
    }

    yield put(clearAppointmentInvoiceItems())
    yield put(refreshOrders(order))
    if (!outsideSoap) {
      yield fetchCurrentSoapClient()
      yield put(saveSOAPSuccess(undefined))
      if (appointmentId) {
        yield put(fetchSOAPOrderFilters(appointmentId, soapBusinessId))
      }
    }

    // refill
    if (order.parentId) {
      yield put(subtractRefillRemains(order))
    }
  } catch (e) {
    const error = e as ApiError
    yield handleOrderUpdateConflict(error)
    yield put(createOrderFailure(error, order))
    yield put(saveSOAPFailure(error))
  }
}

export function* editOrdersSaga({ orders }: ReturnType<typeof editOrders>) {
  try {
    yield put(saveSOAP())
    const soapId: string = yield select(getSoapId)
    const soapBusinessId: string | Nil = yield select(getSoapBusinessId)
    const appointmentId: string = yield select(getAppointmentId)
    const updatedOrders = yield* requestAPI(API.editOrders, soapId, orders)
    yield call(updateTasksIfNeeded, updatedOrders)
    yield fetchCurrentSoapClient()
    yield put(clearAppointmentInvoiceItems())
    yield put(editOrdersSuccess(updatedOrders))
    yield put(saveSOAPSuccess(undefined))
    if (appointmentId) {
      yield put(fetchSOAPOrderFilters(appointmentId, soapBusinessId))
    }
  } catch (e) {
    const error = e as ApiError
    yield handleOrderUpdateConflict(error)
    yield put(editOrdersFailure(error))
    yield put(saveSOAPFailure(error))
  }
}

export function* editOrdersStatusesSaga({
  orders,
}: ReturnType<typeof editOrdersStatuses>) {
  try {
    yield put(saveSOAP())
    const soapId: string = yield select(getSoapId)
    const soapBusinessId: string | Nil = yield select(getSoapBusinessId)
    const appointmentId: string = yield select(getAppointmentId)
    const updatedOrders = yield* requestAPI(
      API.editOrdersStatuses,
      soapId,
      orders,
    )
    yield call(updateTasksIfNeeded, updatedOrders)
    yield fetchCurrentSoapClient()
    yield put(clearAppointmentInvoiceItems())
    yield put(editOrdersStatusesSuccess(updatedOrders))
    yield put(saveSOAPSuccess(undefined))
    if (appointmentId) {
      yield put(fetchSOAPOrderFilters(appointmentId, soapBusinessId))
    }
  } catch (e) {
    const error = e as ApiError
    yield handleOrderUpdateConflict(error)
    yield put(editOrdersStatusesFailure(error))
    yield put(saveSOAPFailure(error))
  }
}

export function* editOrderSaga({
  order,
  options: {
    print,
    email,
    signatureInfo,
    outsideSoap,
    fromTimeline,
    soapId: soapIdParam,
    invoiceId,
    fromInvoice,
  } = {},
}: ReturnType<typeof editOrder>) {
  try {
    if (!outsideSoap && soapIdParam) {
      yield put(saveSOAP())
    }
    const appointmentId: string = yield select(getAppointmentId)
    const soapId = soapIdParam || (yield select(getSoapId)) || order.soapId
    const soapBusinessId: string | Nil = yield select(getSoapBusinessId)
    const updatedOrderResponse =
      outsideSoap || !soapId
        ? order.type === OrderType.LAB_TEST
          ? yield* requestAPI(
              API.updateOrderStatusDecoupling,
              order.id,
              order.stateId,
            )
          : yield* requestAPI(API.editOrderOutsideSoap, order.type, order)
        : yield* requestAPI(API.editOrder, soapId, order.type, {
            ...order,
            soapId,
          })

    const updatedOrder = outsideSoap
      ? updatedOrderResponse.prescription
      : updatedOrderResponse

    yield handlePatientUpdatedByOrder(updatedOrder)

    yield call(fetchInvoiceOnOrderChange, invoiceId)

    if (fromTimeline) {
      yield put(fetchTimelineAction())
    }

    if (print) {
      yield put(setPrescriptionForPrint(updatedOrder))
    }

    if (email) {
      yield put(setPrescriptionForEmail(updatedOrder))
    }

    if (fromInvoice && invoiceId) {
      yield put(fetchInvoiceV3({ id: invoiceId }))
    }

    yield put(clearAppointmentInvoiceItems())
    yield call(updateTasksIfNeeded, [updatedOrder])
    yield put(editOrderSuccess(updatedOrder))
    yield put(refreshOrders(updatedOrder))
    if (!outsideSoap && soapIdParam) {
      yield fetchCurrentSoapClient()
      yield put(saveSOAPSuccess(undefined))
      if (appointmentId) {
        yield put(fetchSOAPOrderFilters(appointmentId, soapBusinessId))
      }
    }
    if (signatureInfo?.signerId && signatureInfo?.signature) {
      yield put(
        signPrescription(
          outsideSoap ? undefined : soapId,
          updatedOrder.id,
          signatureInfo.signerId,
          signatureInfo.signature,
        ),
      )
    }
  } catch (e) {
    const isDeleteFinalizedLabTestsEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.DELETE_FINALIZED_LAB_TESTS),
    )
    const error = e as ApiError
    const idexxErrors = error?.responseBody?.idexxErrors
    const firstIdexxErrorCode = idexxErrors?.[0]?.errorCode
    if (
      isDeleteFinalizedLabTestsEnabled &&
      firstIdexxErrorCode === ApiErrorTypes.ORDER_CANNOT_BE_MODIFIED
    ) {
      yield put(
        registerWarnAlert(i18n.t('Errors:API_ERROR.ORDER_CANNOT_BE_DECLINED')),
      )
      yield put(editOrderAbolish())
      yield put(saveSOAPAbolish())
    } else {
      yield handleOrderUpdateConflict(error)
      yield put(editOrderFailure(error))
      yield put(saveSOAPFailure(error))
    }
  }
}

export function* handleRemoveActiveRxListItem(id: string) {
  const soapPendingChewyActiveRxList: PrescriptionV2[] = yield select(
    getSoapPendingChewyActiveRxList,
  )
  const chargeSheetPendingChewyActiveRxList: PrescriptionV2[] = yield select(
    getChargeSheetPendingChewyActiveRxList,
  )

  if (
    soapPendingChewyActiveRxList?.length > 0 ||
    chargeSheetPendingChewyActiveRxList?.length > 0
  ) {
    yield put(removeActiveRxListItem({ id }))
  }
}

function* handleDeclineActiveRx({
  orderId,
  soapId,
}: {
  orderId: string
  soapId: string
}) {
  const normalizedResponse = yield* requestAPI(API.declineActiveRx, {
    prescriptionId: orderId,
  })

  const updatedOrder =
    convertGraphQlPrescriptionV2ResultToPrescription(normalizedResponse)

  if (soapId) {
    yield handleRemoveActiveRxListItem(normalizedResponse.result)
  }

  return updatedOrder
}

function* handleUndeclineActiveRx({
  orderId,
  soapId,
}: {
  orderId: string
  soapId: string
}) {
  const normalizedResponse = yield* requestAPI(API.undeclineActiveRx, {
    prescriptionId: orderId,
  })

  const updatedOrder =
    convertGraphQlPrescriptionV2ResultToPrescription(normalizedResponse)

  if (soapId) {
    const prescriptionId = normalizedResponse.result
    yield put(
      addActiveRxListItem({
        prescription:
          normalizedResponse.entities.prescriptionsV2[prescriptionId],
      }),
    )
  }

  return updatedOrder
}

export function* partialEditOrderSaga({
  order,
  options: {
    print,
    email,
    signatureInfo,
    outsideSoap,
    fromTimeline,
    soapId: soapIdParam,
    invoiceId,
    isActiveRxDecline,
    isActiveRxUndecline,
  } = {},
}: ReturnType<typeof partialEditOrder>) {
  try {
    if (!outsideSoap) {
      yield put(saveSOAP())
    }
    const appointmentId: string = yield select(getAppointmentId)
    const soapId = soapIdParam || (yield select(getSoapId)) || order.soapId
    const soapBusinessId: string | Nil = yield select(getSoapBusinessId)

    let updatedOrder

    if (isActiveRxDecline) {
      updatedOrder = yield* handleDeclineActiveRx({ orderId: order.id, soapId })
    } else if (isActiveRxUndecline) {
      updatedOrder = yield* handleUndeclineActiveRx({
        orderId: order.id,
        soapId,
      })
    } else {
      const updatedOrderResponse = outsideSoap
        ? yield* requestAPI(API.editOrderOutsideSoap, order.type, order)
        : yield* requestAPI(API.partialEditOrder, soapId, order.type, {
            ...order,
            soapId,
          })

      updatedOrder = outsideSoap
        ? updatedOrderResponse.prescription
        : updatedOrderResponse
    }

    yield handlePatientUpdatedByOrder(updatedOrder)

    yield call(fetchInvoiceOnOrderChange, invoiceId)

    if (fromTimeline) {
      yield put(fetchTimelineAction())
    }

    if (print) {
      yield put(setPrescriptionForPrint(updatedOrder))
    }

    if (email) {
      yield put(setPrescriptionForEmail(updatedOrder))
    }

    yield put(clearAppointmentInvoiceItems())
    yield call(updateTasksIfNeeded, [updatedOrder])
    yield put(partialEditOrderSuccess(updatedOrder))
    yield put(refreshOrders(updatedOrder))
    if (!outsideSoap) {
      yield fetchCurrentSoapClient()
      yield put(saveSOAPSuccess(undefined))
      if (appointmentId) {
        yield put(fetchSOAPOrderFilters(appointmentId, soapBusinessId))
      }
    }
    if (signatureInfo?.signerId && signatureInfo?.signature) {
      yield put(
        signPrescription(
          outsideSoap ? undefined : soapId,
          updatedOrder.id,
          signatureInfo.signerId,
          signatureInfo.signature,
        ),
      )
    }
  } catch (e) {
    const isDeleteFinalizedLabTestsEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.DELETE_FINALIZED_LAB_TESTS),
    )
    const error = e as ApiError
    const idexxErrors = error?.responseBody?.idexxErrors
    const firstIdexxErrorCode = idexxErrors?.[0]?.errorCode
    if (
      isDeleteFinalizedLabTestsEnabled &&
      firstIdexxErrorCode === ApiErrorTypes.ORDER_CANNOT_BE_MODIFIED
    ) {
      yield put(
        registerWarnAlert(i18n.t('Errors:API_ERROR.ORDER_CANNOT_BE_DECLINED')),
      )
      yield put(partialEditOrderAbolish())
      yield put(saveSOAPAbolish())
    } else {
      yield handleOrderUpdateConflict(error)
      yield put(partialEditOrderFailure(error))
      yield put(saveSOAPFailure(error))
    }
  }
}

function* declineLabOrderSaga({
  vendorId,
  orderId,
}: ReturnType<typeof declineLabOrder>) {
  try {
    const soapId: string = yield select(getSoapId)
    const soapBusinessIdId: string = yield select(getSoapBusinessId)
    const { order } = yield* requestAPI(API.declineLabOrder, vendorId, orderId)
    yield put(declineLabOrderSuccess(order))
    yield put(fetchSOAPOrders(soapId, soapBusinessIdId))
  } catch (e) {
    const error = e as ApiError
    const idexxErrors = error?.responseBody?.idexxErrors
    const firstIdexxErrorCode = idexxErrors?.[0]?.errorCode
    if (firstIdexxErrorCode === ApiErrorTypes.ORDER_CANNOT_BE_MODIFIED) {
      yield put(
        registerWarnAlert(i18n.t('Errors:API_ERROR.ORDER_CANNOT_BE_DECLINED')),
      )
      yield put(declineLabOrderAbolish())
    } else {
      yield put(declineLabOrderFailure(error))
    }
  }
}

function* handleOrderRemovalFailureCustomMessage(error: ApiError) {
  const errorType = error?.responseBody?.type
  if (
    errorType === ApiErrorTypes.PREPAID_IN_USE ||
    errorType === ApiErrorTypes.RX_HAS_REFILLS ||
    errorType === ApiErrorTypes.CANT_DELETE_SOAP_LOG
  ) {
    yield put(registerWarnAlert(error?.responseBody?.message))
  }
}

export function* removeOrdersSaga({ orders }: ReturnType<typeof removeOrders>) {
  try {
    yield put(saveSOAP())
    const soapId: string = yield select(getSoapId)
    const soapBusinessId: string | Nil = yield select(getSoapBusinessId)
    const appointmentId: string = yield select(getAppointmentId)

    yield* requestAPI(API.removeOrders, soapId, orders)
    yield fetchCurrentSoapClient()
    yield put(refreshOrders())
    yield put(removeOrdersSuccess(orders))
    yield put(saveSOAPSuccess(undefined))
    if (appointmentId) {
      yield put(fetchSOAPOrderFilters(appointmentId, soapBusinessId))
    }
  } catch (e) {
    const error = e as ApiError
    yield handleOrderUpdateConflict(error)
    yield handleOrderRemovalFailureCustomMessage(error)
    yield put(removeOrdersFailure(error, orders))
    yield put(saveSOAPFailure(error))
  }
}

function* deleteOrder(order: Order, options?: CRUDOrderOptions) {
  yield put(saveSOAP())
  const soapIdFromStore: string = yield select(getSoapId)
  const soapBusinessId: string | Nil = yield select(getSoapBusinessId)
  const appointmentId: string = yield select(getAppointmentId)

  const soapId = soapIdFromStore || order.soapId

  const { isChewyActiveRx } = getPrescriptionWorkflowType({
    orderType: order.type,
    prescriptionType: order.prescriptionType,
    origin: order.origin,
  })

  if (isChewyActiveRx) {
    yield* requestAPI(API.deleteActiveRx, { prescriptionId: order.id })
    yield handleRemoveActiveRxListItem(order.id)
  } else {
    yield* requestAPI(API.removeOrderDecoupling, soapId, order.type, order.id)
  }

  yield fetchCurrentSoapClient()

  yield call(fetchInvoiceOnOrderChange, options?.invoiceId)

  yield put(refreshOrders(order))
  yield put(removeOrderSuccess(order.id))
  yield put(saveSOAPSuccess(undefined))
  if (appointmentId) {
    yield put(fetchSOAPOrderFilters(appointmentId, soapBusinessId))
  }
}

function* handleOrderRemovalError(error: ApiError, order: Order) {
  yield handleOrderUpdateConflict(error)
  yield handleOrderRemovalFailureCustomMessage(error)
  yield put(removeOrderFailure(error, order))
  yield put(saveSOAPFailure(error))
}

export function* removeOrderSaga({
  order,
  options,
}: ReturnType<typeof removeOrder>) {
  try {
    yield deleteOrder(order, options)
  } catch (e) {
    const error = e as ApiError
    yield* handleOrderRemovalError(error, order)
  }
}

function* removeLabOrderSaga({ order }: ReturnType<typeof removeLabOrder>) {
  try {
    yield deleteOrder(order)
  } catch (e) {
    const error = e as ApiError
    const idexxErrors = error?.responseBody?.idexxErrors
    const firstIdexxErrorCode = idexxErrors?.[0]?.errorCode
    if (firstIdexxErrorCode === ApiErrorTypes.ORDER_CANNOT_BE_MODIFIED) {
      const message = i18n.t('Errors:API_ERROR.ORDER_CANNOT_BE_DELETED')
      yield put(registerWarnAlert(message))
      yield put(saveSOAPAbolish())
      yield put(removeLabOrderAbolish())
    } else {
      yield* handleOrderRemovalError(error, order)
    }
  }
}

export function* removeOrderUpdatePatientSaga({
  order,
}: ReturnType<typeof removeOrderUpdatePatient>) {
  try {
    yield put(saveSOAP())
    const soapIdFromStore: string = yield select(getSoapId)
    const soapId = soapIdFromStore || order.soapId
    const soapBusinessId: string | Nil = yield select(getSoapBusinessId)
    const appointmentId: string = yield select(getAppointmentId)
    const updatedOrder = yield* requestAPI(
      API.removeOrderUpdatePatient,
      soapId,
      order.type,
      order.id,
    )
    yield handlePatientUpdatedByOrder(updatedOrder)
    yield call(fetchInvoiceOnOrderChange)

    yield fetchCurrentSoapClient()
    yield put(refreshOrders(order))
    yield put(removeOrderUpdatePatientSuccess(order.id))
    yield put(saveSOAPSuccess(undefined))
    if (appointmentId) {
      yield put(fetchSOAPOrderFilters(appointmentId, soapBusinessId))
    }
  } catch (e) {
    const error = e as ApiError
    yield handleOrderUpdateConflict(error)
    yield handleOrderRemovalFailureCustomMessage(error)
    yield put(removeOrderUpdatePatientFailure(error, order))
    yield put(saveSOAPFailure(error))
  }
}

export function* updateOrderStatusSaga({
  logEntryId,
  statusId,
  reloadTimeline,
}: ReturnType<typeof updateOrderStatus>) {
  try {
    yield* requestAPI(API.updateOrderStatusDecoupling, logEntryId, statusId)
    yield put(updateOrderStatusSuccess())
    if (reloadTimeline) {
      yield put(
        fetchMoreItemsForTimeline(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT),
      )
    }
  } catch (e) {
    const error = e as ApiError
    yield handleOrderUpdateConflict(error)
    yield put(updateOrderStatusFailure(error as ApiError))
  }
}

export function* fetchPrescriptionPrintInfoSaga({
  prescription,
}: ReturnType<typeof fetchPrescriptionPrintInfo>) {
  try {
    const createdOrder = yield* requestAPI(API.fetchPrintInfo, prescription.id)
    yield put(setPrescriptionForPrint(createdOrder))
  } catch (error) {
    yield put(fetchPrescriptionPrintInfoFailure(error as ApiError))
  }
}

export function* searchOrdersSaga({
  searchTerm,
  filters = [],
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  labVendorId,
  applyPatientRestriction,
  forShipments,
  from,
  to,
}: ReturnType<typeof searchOrders>) {
  try {
    const catalogCode: CountryCode = yield select(
      getCurrentBusinessCountryCatalogCode,
    )
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )
    const { data, totalCount } = yield* requestAPI(
      API.searchOrders,
      searchTerm,
      R.uniq(filters).join(','),
      listType,
      patientId,
      clientId,
      eventId,
      withTasks,
      labVendorId,
      applyPatientRestriction,
      forShipments,
      catalogCode,
      from,
      to,
      isFoodCatalogEnabled,
    )
    yield put(searchOrdersSuccess(data, totalCount))
  } catch (error) {
    yield put(searchOrdersFailure(error as ApiError))
  }
}

export function* fetchMoreForSearchOrdersSagaCancelable({
  searchTerm,
  filters = [],
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  labVendorId,
  applyPatientRestriction,
  forShipments,
  from,
  to,
}: ReturnType<typeof fetchMoreForSearch>) {
  try {
    const catalogCode: CountryCode = yield select(
      getCurrentBusinessCountryCatalogCode,
    )
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )
    const { data, totalCount } = yield* requestAPI(
      API.searchOrders,
      searchTerm,
      R.uniq(filters).join(','),
      listType,
      patientId,
      clientId,
      eventId,
      withTasks,
      labVendorId,
      applyPatientRestriction,
      forShipments,
      catalogCode,
      from,
      to,
      isFoodCatalogEnabled,
    )
    yield put(fetchMoreForSearchSuccess(data, totalCount, from))
  } catch (error) {
    yield put(fetchMoreForSearchFailure(error as ApiError))
  }
}

export function* fetchMoreForSearchOrdersSaga(
  params: ReturnType<typeof fetchMoreForSearch>,
) {
  const task: SagaTask = yield fork(
    fetchMoreForSearchOrdersSagaCancelable,
    params,
  )
  yield take([
    CLEAR_SEARCH_ORDERS,
    SEARCH_ORDERS,
    SEARCH_ORDERS_SUCCESS,
    FETCH_ORDERS,
  ])
  yield cancel(task)
}

export function* fetchPrescriptionEmailTemplateSaga({
  soapId,
  orderId,
  config,
}: ReturnType<typeof fetchPrescriptionEmailTemplate>) {
  try {
    const template = soapId
      ? yield* requestAPI(
          API.loadOrderEmailTemplate,
          soapId,
          orderId,
          OrderType.PRESCRIPTION,
          config,
        )
      : yield* requestAPI(
          API.loadOrderEmailTemplateOutsideSoap,
          orderId,
          OrderType.PRESCRIPTION,
          config,
        )

    yield put(fetchPrescriptionEmailTemplateSuccess(template))
  } catch (error) {
    yield put(fetchPrescriptionEmailTemplateFailure(error as ApiError))
  }
}

export function* signPrescriptionSaga({
  soapId,
  orderId,
  signerId,
  signature,
}: ReturnType<typeof signPrescription>) {
  try {
    const updatedOrder = soapId
      ? yield* requestAPI(
          API.signPrescription,
          soapId,
          orderId,
          signerId,
          signature,
        )
      : yield* requestAPI(
          API.signPrescriptionOutsideSoap,
          orderId,
          signerId,
          signature,
        )
    yield put(updateCurrentOrderDetails(updatedOrder))
    yield put(signPrescriptionSuccess(updatedOrder))
  } catch (error) {
    yield put(signPrescriptionFailure(error as ApiError))
  }
}

export function* orderBundleSaga({
  order,
  soapId,
}: ReturnType<typeof orderBundle>) {
  try {
    const createdLogItems = yield* requestAPI(
      API.orderBundle,
      order.bundleId,
      soapId,
    )
    yield put(clearAppointmentInvoiceItems())
    yield fetchCurrentSoapClient()
    yield call(fetchInvoiceOnOrderChange)
    yield put(orderBundleSuccess(order))
    yield put(updateMultipleOrders(createdLogItems))
    if (isChargeOnlyBundle(createdLogItems, order) && isZeroUsedBundle(order)) {
      yield put(setOrderValidationErrorMessage(BUNDLE_INCLUDES_ZERO_USED))
    }
  } catch (error) {
    yield put(orderBundleFailure(error as ApiError, order))
  }
}

function* unOrderBundle(bundleId: string, soapId: string, order?: Order) {
  try {
    const removedLogItems = yield* requestAPI(
      API.unorderBundle,
      bundleId,
      soapId,
    )
    yield put(clearAppointmentInvoiceItems())
    yield fetchCurrentSoapClient()
    yield call(fetchInvoiceOnOrderChange)
    yield put(
      order
        ? unorderBundleSuccess(order, removedLogItems)
        : unOrderBundleWithoutOrderSuccess(removedLogItems),
    )
  } catch (e) {
    const error = e as ApiError
    if (error?.response?.status === 409) {
      yield put(
        setOrderCanNotBeRemovedMessage(
          getServerValidationError(error) as string,
        ),
      )
    }
    yield put(
      order
        ? unorderBundleFailure(error, order)
        : unOrderBundleWithoutOrderFailure(error),
    )
  }
}

export function* unOrderBundleWithoutOrderSaga({
  bundleId,
  soapId,
}: ReturnType<typeof unOrderBundleWithoutOrder>) {
  yield unOrderBundle(bundleId, soapId)
}

export function* unorderBundleSaga({
  order,
  soapId,
}: ReturnType<typeof unorderBundle>) {
  if (order.bundleId) {
    yield unOrderBundle(order.bundleId, soapId, order)
  }
}

export function* cancelRefillsSaga({
  prescriptionId,
}: ReturnType<typeof cancelRefills>) {
  try {
    yield* requestAPI(API.cancelRefills, prescriptionId)
    yield put(cancelRefillsSuccess())
    yield put(fetchTimelineAction())
  } catch (error) {
    yield put(cancelRefillsFailure(error as ApiError))
  }
}

const refreshableEntityTypes = [
  OrderType.REFILL,
  OrderType.BUNDLE,
  OrderType.BENEFIT,
  OrderType.WPLANS,
]

export function* refreshOrdersSaga({
  order,
}: ReturnType<typeof refreshOrders>) {
  const orderFilters: FetchOrdersOptions = yield select(
    getLastRequestedOrderFilters,
  )
  const searchFilters: FetchOrdersOptions = yield select(
    getLastSearchOrderFilters,
  )

  if (
    R.includes(orderFilters.entityType, refreshableEntityTypes) ||
    order?.cover
  ) {
    if (R.isEmpty(searchFilters) && !R.isEmpty(orderFilters)) {
      yield call(fetchOrdersSaga, fetchOrders(orderFilters))
    } else if (!R.isEmpty(searchFilters)) {
      yield call(searchOrdersSaga, searchOrders(searchFilters))
    }
  }
}

export function* saveActiveRxSaga({
  orderToSave,
  options,
  isFoodCatalogEnabled,
}: {
  isFoodCatalogEnabled: boolean
  options: ReturnType<typeof createUnifiedOrder>['options']
  orderToSave: Partial<Order>
}) {
  const {
    isActiveRxSaveDraft,
    isActiveRxApprove,
    isActiveRxApproveAndSubmit,
    outsideSoap,
    signatureInfo,
    submitterId,
  } = options

  const businessId: string = yield select(getCurrentBusinessId)

  const { signatureUrl, signerId: orderSignerId } = orderToSave
  const signerId = signatureInfo?.signerId || orderSignerId

  const mutationArgs:
    | MutationSaveActiveRxArgs
    | MutationApproveActiveRxArgs
    | MutationSubmitActiveRxArgs = {
    prescriptionId: orderToSave.id,
    input: {
      ...convertOrderToRxInput(
        orderToSave as unknown as Prescription,
        isFoodCatalogEnabled,
        orderToSave?.autoshipUnitId,
        orderToSave?.autoshipFrequency,
      ),
      signerId,
      signatureUrl,
      businessId,
      submitterId,
    },
  }

  let result
  if (isActiveRxSaveDraft) {
    result = yield* requestAPI(API.saveActiveRx, mutationArgs)
  } else if (isActiveRxApprove) {
    result = yield* requestAPI(API.approveActiveRx, mutationArgs)
  } else if (isActiveRxApproveAndSubmit) {
    result = yield* requestAPI(API.submitActiveRx, mutationArgs)
  }

  if (result.entities) {
    yield call(updateEntities, result.entities)
  }

  // Ensure that right rail and selected orders inside SOAP populate correctly
  if (!outsideSoap && result) {
    yield* fetchUnifiedOrderSaga({
      type: FETCH_UNIFIED_ORDER,
      id: result.result,
      logType: OrderType.PRESCRIPTION,
    })
    const unifiedOrder: Order = yield select(getUnifiedOrder)
    return unifiedOrder
  }

  return convertGraphQlPrescriptionV2ResultToPrescription(result)
}

export function* createUnifiedOrderSaga({
  order,
  options,
}: ReturnType<typeof createUnifiedOrder>) {
  try {
    const {
      print,
      email,
      signatureInfo,
      outsideSoap,
      fromTimeline,
      soapId: soapIdParam,
      invoiceId,
      isActiveRxSaveDraft,
      isActiveRxApprove,
      isActiveRxApproveAndSubmit,
    } = options

    if (!outsideSoap) {
      yield put(saveSOAP())
    }

    const signatureDoctorId = options?.signatureInfo?.signerId
    // set SOAP doctor based on signing doctor
    if (!outsideSoap && !options.doctorId && signatureDoctorId) {
      yield put(selectDoctor(signatureDoctorId, true))
      order.doctorId = signatureDoctorId
    }

    const appointmentId: string = yield select(getAppointmentId)
    const currentSoapId: string = yield select(getSoapId)
    const currentSoapBusinessId: string | Nil = yield select(getSoapBusinessId)
    const clientId: string = yield select(getClientId)

    const soapId = outsideSoap ? undefined : soapIdParam || currentSoapId

    const { name, clientId: orderClientId, ...orderWithoutName } = order

    const orderToSave = {
      ...orderWithoutName,
      invoiceId,
      soapId,
      clientId: orderClientId || clientId,
    }

    const isActiveRx =
      isActiveRxSaveDraft || isActiveRxApprove || isActiveRxApproveAndSubmit

    let createdOrder

    if (isActiveRx) {
      const isFoodCatalogEnabled: boolean = yield select(
        getFeatureToggle(FeatureToggle.FOOD_CATALOG),
      )
      createdOrder = yield* saveActiveRxSaga({
        orderToSave,
        options,
        isFoodCatalogEnabled,
      })
    } else {
      const createOrderResponse = yield* requestAPI(
        API.createUnifiedOrder,
        orderToSave,
        { ...options, type: order.type },
      )

      createdOrder = outsideSoap
        ? createOrderResponse.unifiedOrder
        : createOrderResponse
    }

    yield call(fetchInvoiceOnOrderChange, invoiceId)

    if (fromTimeline || outsideSoap) {
      yield put(fetchTimelineAction())
      yield put(
        fetchWidgetsData([SnapshotsAliasTypes.Prescriptions], {
          quiet: false,
          landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
          patientId: order.patientId,
        }),
      )
    }

    yield put(createUnifiedOrderSuccess(createdOrder, order))

    if (print) {
      yield put(setPrescriptionForPrint(createdOrder))
    }

    if (email) {
      yield put(setPrescriptionForEmail(createdOrder))
    }

    if (signatureInfo?.signerId && signatureInfo?.signature) {
      yield put(
        signPrescription(
          outsideSoap ? undefined : soapId,
          createdOrder.id,
          signatureInfo.signerId,
          signatureInfo.signature,
        ),
      )
    }

    yield put(refreshOrders())
    if (!outsideSoap) {
      yield fetchCurrentSoapClient()
      yield put(saveSOAPSuccess(undefined))
      if (appointmentId) {
        yield put(fetchSOAPOrderFilters(appointmentId, currentSoapBusinessId))
      }
    }

    // refill
    if (order.parentId) {
      yield put(subtractRefillRemains(order))
    }
  } catch (e) {
    const error = e as ApiError
    yield handleOrderUpdateConflict(error)
    yield put(createUnifiedOrderFailure(error, order))
    yield put(saveSOAPFailure(error))
  }
}

export function* updateUnifiedOrderSaga({
  order,
  options = {},
}: ReturnType<typeof updateUnifiedOrder>) {
  try {
    yield put(startLoading('updateUnifiedOrder'))
    const {
      print,
      email,
      signatureInfo,
      outsideSoap,
      fromInvoice,
      fromTimeline,
      soapId: soapIdParam,
      isActiveRxSaveDraft,
      isActiveRxApprove,
      isActiveRxApproveAndSubmit,
    } = options

    const invoiceId = options.invoiceId ?? order.invoiceId

    if (!outsideSoap) {
      yield put(saveSOAP())
    }

    const appointmentId: string = yield select(getAppointmentId)
    const currentSoapId: string = yield select(getSoapId)
    const currentSoapBusinessId: string | Nil = yield select(getSoapBusinessId)
    const { name, ...orderWithoutName } = order
    const soapId = outsideSoap
      ? orderWithoutName.soapId || soapIdParam
      : soapIdParam || currentSoapId

    const orderToSave = {
      ...orderWithoutName,
      invoiceId,
      soapId,
    }

    const isActiveRx =
      isActiveRxSaveDraft || isActiveRxApprove || isActiveRxApproveAndSubmit

    let updatedOrder

    if (isActiveRx) {
      const isFoodCatalogEnabled: boolean = yield select(
        getFeatureToggle(FeatureToggle.FOOD_CATALOG),
      )
      updatedOrder = yield* saveActiveRxSaga({
        orderToSave,
        options,
        isFoodCatalogEnabled,
      })
    } else {
      const updatedOrderResponse = yield* requestAPI(
        API.updateUnifiedOrder,
        orderToSave,
        {
          ...options,
          type: order.type,
          previousEntityType: order.previousEntityType,
        },
      )

      updatedOrder = outsideSoap
        ? updatedOrderResponse.unifiedOrder
        : updatedOrderResponse
    }

    yield call(fetchInvoiceOnOrderChange, invoiceId)

    if (fromTimeline || outsideSoap) {
      yield put(fetchTimelineAction())
      yield put(
        fetchWidgetsData([SnapshotsAliasTypes.Prescriptions], {
          quiet: false,
          landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
          patientId: order.patientId,
        }),
      )
    }

    if (print) {
      yield put(setPrescriptionForPrint(updatedOrder))
    }

    if (email) {
      yield put(setPrescriptionForEmail(updatedOrder))
    }

    if (fromInvoice && invoiceId) {
      yield put(fetchInvoiceV3({ id: invoiceId }))
    }

    yield call(updateTasksIfNeeded, [updatedOrder])
    yield put(updateUnifiedOrderSuccess(order, updatedOrder))
    yield put(refreshOrders())
    if (!outsideSoap) {
      yield fetchCurrentSoapClient()
      yield put(saveSOAPSuccess(undefined))
      if (appointmentId) {
        yield put(fetchSOAPOrderFilters(appointmentId, currentSoapBusinessId))
      }
    }
    if (signatureInfo?.signerId && signatureInfo?.signature) {
      yield put(
        signPrescription(
          outsideSoap ? undefined : soapId,
          updatedOrder.id,
          signatureInfo.signerId,
          signatureInfo.signature,
        ),
      )
    }
  } catch (e) {
    const error = e as ApiError
    yield handleOrderUpdateConflict(error)
    yield put(updateUnifiedOrderFailure(error))
    yield put(saveSOAPFailure(error))
  } finally {
    yield put(finishLoading('updateUnifiedOrder'))
  }
}

export function* removeUnifiedOrderSaga({
  order,
}: ReturnType<typeof removeUnifiedOrder>) {
  try {
    yield put(saveSOAP())
    const appointmentId: string = yield select(getAppointmentId)
    const soapBusinessId: string = yield select(getSoapBusinessId)

    yield* requestAPI(
      API.removeUnifiedOrder,
      order,
      order.type || order.logType,
    )
    const { isChewyActiveRx } = getPrescriptionType(
      order.prescriptionType,
      order.origin,
    )
    if (isChewyActiveRx) {
      yield handleRemoveActiveRxListItem(order.id)
    }
    yield call(fetchInvoiceOnOrderChange)
    yield fetchCurrentSoapClient()
    yield put(refreshOrders())
    yield put(removeUnifiedOrderSuccess(order.id))
    yield put(saveSOAPSuccess(undefined))
    if (appointmentId) {
      yield put(fetchSOAPOrderFilters(appointmentId, soapBusinessId))
    }
  } catch (e) {
    const error = e as ApiError
    yield handleOrderUpdateConflict(error)
    const errorType = error?.responseBody?.type
    if (
      errorType === ApiErrorTypes.PREPAID_IN_USE ||
      errorType === ApiErrorTypes.RX_HAS_REFILLS
    ) {
      yield put(registerWarnAlert(error?.responseBody?.message))
    }
    yield put(removeUnifiedOrderFailure(error, order))
    yield put(saveSOAPFailure(error))
  }
}

type FetchOrderCountsByTypeResponse = {
  count: number
  typeId: string
}

export function* fetchOrderCountsByTypeSaga({
  clientId,
  patientId,
  eventId,
  applyPatientRestriction,
  types,
}: ReturnType<typeof fetchOrderCountsByType>) {
  try {
    const response = yield* requestAPI(
      API.fetchOrderCountsByType,
      clientId,
      patientId,
      eventId,
      applyPatientRestriction,
      types.join(','),
    )
    const OrderFilters: OrderFiltersConstant[] = yield select(getOrderFilters)

    const totalCountByType: Record<Partial<OrderType>, number> = Object.assign(
      {},
      ...response.map((item: FetchOrderCountsByTypeResponse) => {
        const { type } = Utils.findById(item.typeId, OrderFilters) || {}

        if (!type) {
          return null
        }

        return {
          [type]: item.count,
        }
      }),
    )

    yield put(fetchOrderCountsByTypeSuccess(totalCountByType))
  } catch (e) {
    const error = e as ApiError
    yield put(fetchOrderCountsByTypeFailure(error))
  }
}

export function* remountWebsocketChargesWidgetSaga() {
  const remount: boolean = yield select(getRemountChargesWidget)
  const soapId: string | undefined = yield select(getSoapId)
  const soapBusinessId: string | Nil = yield select(getSoapBusinessId)
  const appointmentId: string | undefined = yield select(getAppointmentId)
  const areOrdersSending: boolean | undefined = yield select(getOrdersIsSending)
  const isFetchingChargeSheet: boolean | undefined = yield select(
    getClientFinanceLoading,
  )
  const clientId: string | null = yield select(getClientId)
  const patientId: string | Nil = yield select(getPatientId)
  const appliedPatientRestrictions: boolean = yield select(
    getAppliedPatientRestrictions,
  )

  if (remount) {
    if (soapId) {
      yield put(fetchSOAPOrders(soapId, soapBusinessId))
    }

    if (soapId && clientId && !areOrdersSending && !isFetchingChargeSheet) {
      yield put(fetchClientFinanceCharges({ id: clientId, soapId }))
    }
    if (clientId && patientId && appointmentId) {
      yield put(
        fetchOrderCountsByType(
          clientId,
          patientId,
          appointmentId,
          appliedPatientRestrictions,
          [
            OrderType.PREPAID,
            OrderType.REFILL,
            OrderType.REMINDER,
            OrderType.BUNDLE,
          ],
        ),
      )
    }
  }

  yield put(clearRemountWebsocketChargesWidget())
}

export function* fetchUnifiedOrderByByTypeAndLogIdSaga({
  logId,
  logType,
}: ReturnType<typeof fetchUnifiedOrderByTypeAndLogId>) {
  try {
    const order = yield* requestAPI(
      API.fetchUnifiedOrderByTypeAndLogId,
      logId,
      logType,
    )
    yield put(fetchUnifiedOrderByTypeAndLogIdSuccess(order))
  } catch (error) {
    yield put(fetchUnifiedOrderByTypeAndLogIdFailure(error as ApiError))
  }
}

function* watchDeclineLabOrder() {
  yield takeLatest(DECLINE_LAB_ORDER, declineLabOrderSaga)
}

function* watchRefreshOrders() {
  yield takeLatest(REFRESH_ORDERS, refreshOrdersSaga)
}

function* watchFetchOrders() {
  yield takeLatest(FETCH_ORDERS, fetchOrdersSaga)
}

function* watchFetchMoreOrders() {
  yield takeLatest(FETCH_MORE_ORDERS, fetchMoreOrdersSaga)
}

function* watchFetchOrdersFilters() {
  yield takeLatest(FETCH_ORDERS_FILTERS, fetchOrdersFiltersSaga)
}

function* watchFetchOrderDetails() {
  yield takeLatest(FETCH_ORDER_DETAILS, fetchOrderDetailsSaga)
}

function* watchFetchOrderDetailsGrouped() {
  yield takeLatest(FETCH_ORDER_DETAILS_GROUPED, fetchOrderDetailsGroupedSaga)
}

function* watchCreateOrder() {
  yield takeEvery(CREATE_ORDER, createOrderSaga)
}

function* watchEditOrders() {
  yield takeEvery(EDIT_ORDERS, editOrdersSaga)
}

function* watcheditOrdersStatuses() {
  yield takeEvery(EDIT_ORDERS_STATUSES, editOrdersStatusesSaga)
}

function* watchEditOrderUpdatePatient() {
  yield takeEvery(EDIT_ORDER, editOrderSaga)
}

function* watchPartialEditOrder() {
  yield takeEvery(PARTIAL_EDIT_ORDER, partialEditOrderSaga)
}

function* watchRemoveOrders() {
  yield takeEvery(REMOVE_ORDERS, removeOrdersSaga)
}

function* watchRemoveOrder() {
  yield takeEvery(REMOVE_ORDER, removeOrderSaga)
}

function* watchRemoveLabOrder() {
  yield takeEvery(REMOVE_LAB_ORDER, removeLabOrderSaga)
}

function* watchRemoveOrderUpdatePatient() {
  yield takeEvery(REMOVE_ORDER_UPDATE_PATIENT, removeOrderUpdatePatientSaga)
}

function* watchUpdateOrderStatus() {
  yield takeEvery(UPDATE_ORDER_STATUS, updateOrderStatusSaga)
}

function* watchFetchPrescriptionPrintInfo() {
  yield takeEvery(FETCH_PRESCRIPTION_PRINT_INFO, fetchPrescriptionPrintInfoSaga)
}

function* watchSearchOrders() {
  yield takeLatest(SEARCH_ORDERS, searchOrdersSaga)
}

function* watchFetchMoreForSearchOrders() {
  yield takeLatest(FETCH_MORE_FOR_SEARCH, fetchMoreForSearchOrdersSaga)
}

function* watchFetchPrescriptionEmailTemplate() {
  yield takeEvery(
    FETCH_PRESCRIPTION_EMAIL_TEMPLATE,
    fetchPrescriptionEmailTemplateSaga,
  )
}

function* watchSignPrescription() {
  yield takeLatest(SIGN_PRESCRIPTION, signPrescriptionSaga)
}

function* watchOrderBundle() {
  yield takeEvery(ORDER_BUNDLE, orderBundleSaga)
}

function* watchUnorderBundle() {
  yield takeEvery(UNORDER_BUNDLE, unorderBundleSaga)
}

function* watchUnOrderBundleWithoutOrder() {
  yield takeEvery(UNORDER_BUNDLE_WITHOUT_ORDER, unOrderBundleWithoutOrderSaga)
}

function* watchFetchUnifiedOrder() {
  yield takeEvery(FETCH_UNIFIED_ORDER, fetchUnifiedOrderSaga)
}

function* watchFetchUnifiedOrderByEntityType() {
  yield takeEvery(
    FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE,
    fetchUnifiedOrderByEntityTypeSaga,
  )
}

function* watchCancelRefills() {
  yield takeEvery(CANCEL_REFILLS, cancelRefillsSaga)
}

function* watchCreateUnifiedOrder() {
  yield takeEvery(CREATE_UNIFIED_ORDER, createUnifiedOrderSaga)
}

function* watchUpdateUnifiedOrder() {
  yield takeEvery(UPDATE_UNIFIED_ORDER, updateUnifiedOrderSaga)
}

function* watchRemoveUnifiedOrder() {
  yield takeEvery(REMOVE_UNIFIED_ORDER, removeUnifiedOrderSaga)
}

function* watchFetchOrderCountsByType() {
  yield takeEvery(FETCH_ORDER_COUNTS_BY_TYPE, fetchOrderCountsByTypeSaga)
}

function* watchRemountWebsocketChargesWidget() {
  yield debounce(
    500,
    REMOUNT_WEBSOCKET_CHARGES_WIDGET,
    remountWebsocketChargesWidgetSaga,
  )
}

function* watchFetchUnifiedOrderByEntityTypeAndLogId() {
  yield takeLatest(
    FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID,
    fetchUnifiedOrderByByTypeAndLogIdSaga,
  )
}

export default function* ordersSaga() {
  yield all([
    watchDeclineLabOrder(),
    watchRefreshOrders(),
    watchFetchOrders(),
    watchFetchMoreOrders(),
    watchFetchOrdersFilters(),
    watchFetchOrderDetails(),
    watchFetchOrderDetailsGrouped(),
    watchCreateOrder(),
    watchEditOrders(),
    watcheditOrdersStatuses(),
    watchEditOrderUpdatePatient(),
    watchPartialEditOrder(),
    watchRemoveOrders(),
    watchRemoveOrder(),
    watchRemoveLabOrder(),
    watchRemoveOrderUpdatePatient(),
    watchUpdateOrderStatus(),
    watchFetchPrescriptionPrintInfo(),
    watchSearchOrders(),
    watchFetchMoreForSearchOrders(),
    watchFetchPrescriptionEmailTemplate(),
    watchSignPrescription(),
    watchOrderBundle(),
    watchUnorderBundle(),
    watchUnOrderBundleWithoutOrder(),
    watchCancelRefills(),
    watchCreateUnifiedOrder(),
    watchUpdateUnifiedOrder(),
    watchRemoveUnifiedOrder(),
    watchFetchUnifiedOrder(),
    watchFetchUnifiedOrderByEntityType(),
    watchFetchOrderCountsByType(),
    watchRemountWebsocketChargesWidget(),
    watchFetchUnifiedOrderByEntityTypeAndLogId(),
  ])
}

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import {
  SupportedAnalyticsDashboard,
  supportedAnalyticsDashboards,
} from '~/constants/analytics'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getAnalyticsMenuItem } from '~/utils/analytics'

import { MenuItem } from '../LeftMenu'

const menuItemsSortFn = ({ text: a }: MenuItem, { text: b }: MenuItem) =>
  a > b ? 1 : -1

export const getMenu = (children: MenuItem[]) => children.sort(menuItemsSortFn)

export const getMenuItem = () => {}

export const omnichannelAnalyticsDashboardMapping = {
  [SupportedAnalyticsDashboard.GROUP_PRICE_LISTS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.GROUP_PRICE_LISTS.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.GROUP_PRICE_LISTS'),
  ),
  [SupportedAnalyticsDashboard.CLIENT_AND_PATIENT_STATUS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.CLIENT_AND_PATIENT_STATUS.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.CLIENT_AND_PATIENT_STATUS'),
  ),
  [SupportedAnalyticsDashboard.DAILY_RECONCILIATION]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.DAILY_RECONCILIATION.V3,
    i18n.t(
      'Menu:OMNICHANNEL_ANALYTICS.DAILY_RECONCILIATION_SALES_AND_PAYMENTS',
    ),
  ),
  [SupportedAnalyticsDashboard.OPEN_AND_UNAPPLIED_BILLING]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.OPEN_AND_UNAPPLIED_BILLING.V1,
      i18n.t('Menu:OMNICHANNEL_ANALYTICS.OPEN_AND_UNAPPLIED_BILLING'),
    ),
  [SupportedAnalyticsDashboard.BANK_RECONCILIATION]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.BANK_RECONCILIATION.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.BANK_RECONCILIATION'),
  ),
  [SupportedAnalyticsDashboard.REMINDERS_COMPLIANCE]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.REMINDERS_COMPLIANCE.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.REMINDERS_COMPLIANCE'),
  ),
  [SupportedAnalyticsDashboard.RABIES_VACCINE_HISTORY]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.RABIES_VACCINE_HISTORY.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.RABIES_VACCINE_HISTORY'),
  ),
  [SupportedAnalyticsDashboard.PRACTICE_PERFORMANCE]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.PRACTICE_PERFORMANCE.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.PRACTICE_PERFORMANCE'),
  ),
  [SupportedAnalyticsDashboard.MEDICAL_INSIGHTS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.MEDICAL_INSIGHTS.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.MEDICAL_INSIGHTS'),
  ),
  [SupportedAnalyticsDashboard.INVENTORY_USAGE]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.INVENTORY_USAGE.V1,
    i18n.t('Menu:INVENTORY_USAGE'),
  ),
  [SupportedAnalyticsDashboard.FORWARD_BOOKINGS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.FORWARD_BOOKINGS.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.FORWARD_BOOKINGS'),
  ),
  [SupportedAnalyticsDashboard.ACQUISITION_AND_LIFETIME_VALUE]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.ACQUISITION_AND_LIFETIME_VALUE.V1,
      i18n.t('Menu:OMNICHANNEL_ANALYTICS.ACQUISITION_AND_LIFETIME_VALUE'),
    ),
  [SupportedAnalyticsDashboard.CONTROLLED_SUBSTANCE_LOG]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.CONTROLLED_SUBSTANCE_LOG.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.CONTROLLED_SUBSTANCE_LOG'),
  ),
  [SupportedAnalyticsDashboard.GROUP_PERFORMANCE]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.GROUP_PERFORMANCE.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.GROUP_PERFORMANCE'),
  ),
  [SupportedAnalyticsDashboard.SALES_SUMMARY_EXCLUDING_UNPAID_OTC]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.SALES_SUMMARY_EXCLUDING_UNPAID_OTC.V1,
      i18n.t('Menu:OMNICHANNEL_ANALYTICS.SALES_SUMMARY_EXCLUDING_UNPAID_OTC'),
    ),
  [SupportedAnalyticsDashboard.PRACTICE_SALES]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.PRACTICE_SALES.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.PRACTICE_SALES'),
  ),
  [SupportedAnalyticsDashboard.PRODUCER_SALES]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.PRODUCER_SALES.V2,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.PRODUCER_SALES'),
  ),
  [SupportedAnalyticsDashboard.SCHEDULE_MANAGEMENT]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.SCHEDULE_MANAGEMENT.V2,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.APPOINTMENT_BREAKDOWN'),
  ),
  [SupportedAnalyticsDashboard.CANCELLATIONS_AND_NO_SHOWS]: {
    [supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V1.id]:
      getAnalyticsMenuItem(
        supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V1,
        i18n.t('Menu:OMNICHANNEL_ANALYTICS.CANCELLATIONS_AND_NO_SHOWS'),
      ),
    [supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V2.id]:
      getAnalyticsMenuItem(
        supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V2,
        i18n.t('Menu:OMNICHANNEL_ANALYTICS.CANCELLATIONS_AND_NO_SHOWS'),
      ),
  },
  [SupportedAnalyticsDashboard.CLIENT_INITIATED_REQUESTS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.CLIENT_INITIATED_REQUESTS.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.CLIENT_INITIATED_REQUESTS'),
  ),
  [SupportedAnalyticsDashboard.DENTAL_AND_HEARTWORM_OVERVIEW]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.DENTAL_AND_HEARTWORM_OVERVIEW.V1,
      i18n.t('Menu:OMNICHANNEL_ANALYTICS.DENTAL_AND_HEARTWORM_OVERVIEW'),
    ),
  [SupportedAnalyticsDashboard.APPOINTMENT_MIX_BENCHMARK]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.APPOINTMENT_MIX_BENCHMARK.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.APPOINTMENT_MIX_BENCHMARK'),
  ),
  [SupportedAnalyticsDashboard.INVOICE_ACTIVITY]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.INVOICE_ACTIVITY.V3,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.INVOICE_ACTIVITY'),
  ),
  [SupportedAnalyticsDashboard.BILLING_AGING]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.BILLING_AGING.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.BILLING_AGING_REPORT'),
  ),
  [SupportedAnalyticsDashboard.INVENTORY_USAGE_AND_AVERAGE_INVOICE]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.INVENTORY_USAGE_AND_AVERAGE_INVOICE.V1,
      i18n.t('Menu:OMNICHANNEL_ANALYTICS.INVENTORY_USAGE_AND_AVERAGE_INVOICE'),
    ),
  [SupportedAnalyticsDashboard.REPEAT_AND_RECHECK_PATIENTS]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.REPEAT_AND_RECHECK_PATIENTS.V1,
      i18n.t('Menu:OMNICHANNEL_ANALYTICS.REPEAT_AND_RECHECK_PATIENTS'),
    ),
  [SupportedAnalyticsDashboard.RESOLUTION_RATE]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.RESOLUTION_RATE.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.RESOLUTION_RATE'),
  ),
  [SupportedAnalyticsDashboard.DISCOUNT_USAGE]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.DISCOUNT_USAGE.V2,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.DISCOUNT_USAGE'),
  ),
  [SupportedAnalyticsDashboard.INVENTORY_AND_CATALOG_AUDIT]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.INVENTORY_AND_CATALOG_AUDIT.V1,
      i18n.t('Menu:OMNICHANNEL_ANALYTICS.INVENTORY_AND_CATALOG_AUDIT'),
    ),
  [SupportedAnalyticsDashboard.OVERDUE_TASKS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.OVERDUE_TASKS.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.OVERDUE_TASKS'),
  ),
  [SupportedAnalyticsDashboard.SMS_ACTIVITY]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.SMS_ACTIVITY.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.CLIENT_COMMUNICATIONS'),
  ),
  [SupportedAnalyticsDashboard.ONLINE_SICK_APPOINTMENTS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.ONLINE_SICK_APPOINTMENTS.V1,
    i18n.t('Menu:ONLINE_SCHEDULE_APPOINTMENTS'),
  ),
  [SupportedAnalyticsDashboard.DAILY_BILLING_ACTIVITY]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.DAILY_BILLING_ACTIVITY.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.DAILY_BILLING_ACTIVITY'),
  ),
  [SupportedAnalyticsDashboard.CLIENT_LIST]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.CLIENT_LIST.V2,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.CLIENT_LIST'),
  ),
  [SupportedAnalyticsDashboard.BUNDLES_AND_WELLNESS_PLANS]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.BUNDLES_AND_WELLNESS_PLANS.V2,
      i18n.t('Menu:OMNICHANNEL_ANALYTICS.BUNDLES_AND_WELLNESS_PLANS'),
    ),
  [SupportedAnalyticsDashboard.END_OF_DAY_AUDIT]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.END_OF_DAY_AUDIT.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.END_OF_DAY_AUDIT'),
  ),
}

export const useOmnichannelAnalyticsMenu = (): MenuItem[] => {
  const isCancellationsAndNoShowsReportV2Enabled = useSelector(
    getFeatureToggle(FeatureToggle.CANCELLATIONS_AND_NOSHOWS_DASHBOARD_V2),
  )
  const { t } = useTranslation(['Common'])

  const OmnichannelAnalyticsFinancialsSection: MenuItem[] = [
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.BILLING_AGING
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.BANK_RECONCILIATION
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.INVOICE_ACTIVITY
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.DAILY_RECONCILIATION
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.PRACTICE_SALES
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.PRODUCER_SALES
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.OPEN_AND_UNAPPLIED_BILLING
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.DAILY_BILLING_ACTIVITY
    ],
  ]

  const OmnichannelAnalyticsOperationsSection: MenuItem[] = [
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.SCHEDULE_MANAGEMENT
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.ACQUISITION_AND_LIFETIME_VALUE
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.APPOINTMENT_MIX_BENCHMARK
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.CANCELLATIONS_AND_NO_SHOWS
    ][
      isCancellationsAndNoShowsReportV2Enabled
        ? supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V2.id
        : supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V1.id
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.CLIENT_AND_PATIENT_STATUS
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.CLIENT_INITIATED_REQUESTS
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.CONTROLLED_SUBSTANCE_LOG
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.DENTAL_AND_HEARTWORM_OVERVIEW
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.FORWARD_BOOKINGS
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.GROUP_PRICE_LISTS
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.INVENTORY_USAGE
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.MEDICAL_INSIGHTS
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.PRACTICE_PERFORMANCE
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.RABIES_VACCINE_HISTORY
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.REMINDERS_COMPLIANCE
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.RESOLUTION_RATE
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.DISCOUNT_USAGE
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.INVENTORY_AND_CATALOG_AUDIT
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.OVERDUE_TASKS
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.SMS_ACTIVITY
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.ONLINE_SICK_APPOINTMENTS
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.REPEAT_AND_RECHECK_PATIENTS
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.BUNDLES_AND_WELLNESS_PLANS
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.CLIENT_LIST
    ],
    omnichannelAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.END_OF_DAY_AUDIT
    ],
  ]

  return [
    {
      id: uuid(),
      text: t('Menu:OMNICHANNEL_ANALYTICS.FINANCIALS'),
      expanded: true,
      children: getMenu(OmnichannelAnalyticsFinancialsSection),
    },
    {
      id: uuid(),
      text: t('Menu:OMNICHANNEL_ANALYTICS.OPERATIONS'),
      expanded: true,
      children: getMenu(OmnichannelAnalyticsOperationsSection),
    },
  ]
}

import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  DocumentNode,
  InMemoryCache,
  OperationVariables,
  ServerError,
  ServerParseError,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { normalize, Schema } from 'normalizr'
import * as R from 'ramda'
import {
  ApiError,
  ErrorTypes,
  getSessionId,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  Environment,
  Region,
} from '@pbt/pbt-ui-components/src/constants/environment'
import { getDomainContext } from '@pbt/pbt-ui-components/src/utils'

import { tokenHolder } from '../utils/token'

const PublicApiMap: Record<Region, Partial<Record<Environment, string>>> = {
  [Region.US]: {
    [Environment.PROD]: 'https://api.rhapsody.vet',
    [Environment.MASTER]: 'https://api.master.rhapsody.vet',
    [Environment.STAGE]: 'https://api.stage.rhapsody.vet',
  },
  [Region.EU]: {
    [Environment.PROD]: 'https://api.eu.rhapsody.vet',
    [Environment.MASTER]: 'https://api.eu.master.rhapsody.vet',
    [Environment.STAGE]: 'https://api.eu.stage.rhapsody.vet',
  },
}

type GQLRequestContext = {
  headers: Record<string, string | boolean | null>
}

const domainContext = getDomainContext()
const envApiMap = PublicApiMap[domainContext.region]
const defaultApiUrl = envApiMap[Environment.STAGE]
const apiUrl = envApiMap[domainContext.env] || defaultApiUrl

const uploadLink = createUploadLink({
  uri: ({ operationName }) =>
    `${Utils.isLocalEnvironment() ? '' : apiUrl}/api-public/${operationName}`,
})

const authLink = setContext(async (_, { headers }) => {
  const token = await tokenHolder.getToken()

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'X-Business-Id': localStorage.getItem('businessId'),
      'X-PBT-SessionId': getSessionId(),
    },
  }
})
const link = ApolloLink.from([authLink, uploadLink])

export const clientForApolloFlow = new ApolloClient({
  link,
  cache: new InMemoryCache({}),
  devtools: {
    enabled: !Utils.isProduction(),
    name: 'Apollo Client',
  },
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export const clientForLegacyReduxFlow = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache({
    addTypename: false,
    resultCaching: false,
  }),
  devtools: {
    enabled: !Utils.isProduction(),
    name: 'Legacy Redux Apollo',
  },
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
    mutate: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
  },
})

const convertApolloToApiError = (error: ApolloError): ApiError =>
  new ApiError(
    {
      message: error.message,
      response:
        (error.networkError as ServerParseError | ServerError)?.response || {},
    },
    {},
  )

export const getRequestContext = async (businessId: string, auth: boolean) => {
  const context: GQLRequestContext = {
    headers: {},
  }

  if (Utils.isLocalEnvironment()) {
    const devEnv = window.pbt.getDevEnv() as Environment
    const devRegion = window.pbt.getDevRegion() as Region

    context.headers = {
      ...context.headers,
      'X-PBT-DevEnv': devEnv,
      'X-PBT-DevRegion': devRegion,
    }
  }

  if (auth) {
    const token = await tokenHolder.getToken()

    context.headers = {
      ...context.headers,
      Authorization: `Bearer ${token}`,
      'X-Business-Id': businessId,
      'X-PBT-SessionId': getSessionId(),
    }
  }

  return context
}

interface CommonQueryParameters<P, T extends P[]> {
  auth?: boolean
  businessIdHeaderOverrider?: (
    currentBusinessId: string,
    ...restVariables: T
  ) => string
  firstDataAliasPick?: boolean
  schema?: Schema
  variablesHandler?: (
    currentBusinessId: string,
    ...restVariables: T
  ) => OperationVariables
}

interface RequestQuery<P, T extends P[]> extends CommonQueryParameters<P, T> {
  expectedErrors?: string[]
  query: DocumentNode
}

interface RequestMutation<P, T extends P[]>
  extends CommonQueryParameters<P, T> {
  mutation: DocumentNode
}

export const requestQuery =
  <P, T extends P[]>({
    query,
    variablesHandler,
    schema,
    auth = true,
    firstDataAliasPick = true,
    businessIdHeaderOverrider = R.identity as any,
    expectedErrors,
  }: RequestQuery<P, T>) =>
  async (currentBusinessId: string, ...restVariables: T) => {
    try {
      const variables = variablesHandler
        ? variablesHandler(currentBusinessId, ...restVariables)
        : undefined
      const businessIdHeader = businessIdHeaderOverrider(
        currentBusinessId,
        ...restVariables,
      )
      const { data, errors } = await clientForLegacyReduxFlow.query({
        query,
        variables,
        context: await getRequestContext(businessIdHeader, auth),
      })
      let friendlyErrors = {}
      if (expectedErrors && expectedErrors.length > 0 && errors) {
        if (
          errors.every((e) =>
            expectedErrors.includes(e.extensions?.type as string),
          )
        ) {
          friendlyErrors = errors
        } else {
          throw new ErrorTypes.GraphQLError(R.head(errors), query, variables)
        }
      }
      const preparedData = firstDataAliasPick
        ? data[Object.keys(data)[0]]
        : data
      if (schema) {
        return R.isEmpty(friendlyErrors)
          ? normalize(preparedData || {}, schema)
          : {
              errors: friendlyErrors,
              ...normalize(preparedData || {}, schema),
            }
      }
      return preparedData
    } catch (e) {
      if (e instanceof ErrorTypes.GraphQLError) {
        throw e
      }

      throw convertApolloToApiError(e as ApolloError)
    }
  }

export const requestMutation =
  <P, T extends P[]>({
    mutation,
    variablesHandler,
    schema,
    auth = true,
    firstDataAliasPick = true,
    businessIdHeaderOverrider = R.identity as any,
  }: RequestMutation<P, T>) =>
  async (currentBusinessId: string, ...restVariables: T) => {
    try {
      const variables = variablesHandler
        ? variablesHandler(currentBusinessId, ...restVariables)
        : undefined
      const businessIdHeader = businessIdHeaderOverrider(
        currentBusinessId,
        ...restVariables,
      )
      const { data, errors } = await clientForLegacyReduxFlow.mutate({
        mutation,
        variables,
        context: await getRequestContext(businessIdHeader, auth),
      })
      if (errors) {
        throw new ErrorTypes.GraphQLError(R.head(errors), mutation, {
          data,
          variables,
        })
      }
      const preparedData = firstDataAliasPick
        ? data[Object.keys(data)[0]]
        : data
      return schema ? normalize(preparedData || {}, schema) : preparedData
    } catch (e) {
      if (e instanceof ErrorTypes.GraphQLError) {
        throw e
      }

      throw convertApolloToApiError(e as ApolloError)
    }
  }

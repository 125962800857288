import * as React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const SvgAlertIcon = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      clipRule="evenodd"
      d="M12.445 5.252a.506.506 0 0 0-.89 0l-6.524 12a.506.506 0 0 0 .445.748h13.048c.384 0 .628-.41.445-.748zm.8 4.2c0-.576-.542-1.044-1.211-1.044s-1.211.468-1.211 1.044l.838 3.614c.082.351.664.351.745 0zm-.531 6.099c0 .41-.32.741-.714.741a.73.73 0 0 1-.714-.741c0-.41.32-.741.714-.741s.714.332.714.741"
      fill="currentColor"
      fillRule="evenodd"
    />,
  )
export default SvgAlertIcon

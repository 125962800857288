import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Grid, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import { Defaults, Field, Nil } from '@pbt/pbt-ui-components'
import { formatMoney } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import {
  ModifiedChargeSheetLineItemInput,
  RetailOrderLineItem,
  UpdateRetailOrderLineItemInput,
} from '~/api/graphql/generated/types'
import { getIsRetailOrderLineItem } from '~/components/dashboard/invoices/invoiceUtils'
import { Status } from '~/components/elements/Status/Status'
import FeatureToggle from '~/constants/featureToggle'
import InvoiceType from '~/constants/InvoiceType'
import { editRetailOrderLineItem } from '~/store/actions/finance'
import { editChargeSheetItem } from '~/store/duck/clientFinanceData'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { useIsChewyActiveRxReadOnly } from '~/store/hooks/prescription'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Invoice, InvoiceLineItem, RetailOrderLineItemPayload } from '~/types'
import { InvoiceV3 } from '~/types/entities/invoiceV3'
import { getIsCreatedPrescriptionChewyActiveRx } from '~/utils/prescription'
import useFieldsChanged, { FieldCache } from '~/utils/useFieldsChanged'

import { useChargeDetailsFields } from '../utils/useChargeDetailsFields'
import { useGetIsDeclinedLineItem } from '../utils/useDeclineOrderItem'
import ChargeFieldCatalogUnitPrice from './fields/ChargeFieldCatalogUnitPrice'
import ChargeFieldDiscountAmount from './fields/ChargeFieldDiscountAmount'
import ChargeFieldQuantity from './fields/ChargeFieldQuantity'
import ChargeFieldRemainingQuantity from './fields/ChargeFieldRemainingQuantity'
import ChargeFieldRetailDeliveryFee from './fields/ChargeFieldRetailDeliveryFee'
import ChargeFieldSellPrice from './fields/ChargeFieldSellPrice'
import ChargeFieldShippingAmount from './fields/ChargeFieldShippingAmount'
import ChargeFieldSubtotal from './fields/ChargeFieldSubtotal'
import ChargeFieldTax from './fields/ChargeFieldTax'
import ChargeFieldTaxed from './fields/ChargeFieldTaxed'
import ChargeFieldUsedQuantity from './fields/ChargeFieldUsedQuantity'

const useStyles = makeStyles(
  (theme) => ({
    subtotalText: {
      fontWeight: 500,
      color: theme.colors.secondaryText,
    },
    removeUnderline: {
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
    },
    field: {
      width: 98,
      justifyContent: 'flex-end',
    },
    inputField: {
      textAlign: 'end',
    },
    subtotalItem: {
      fontWeight: 500,
      '& p': {
        fontWeight: 500,
      },
      pointerEvents: 'none',
    },
    chewyPriceChange: {
      marginLeft: '.8rem',
      marginTop: '-.5rem',
    },
  }),
  { name: 'ChargeDetailsLineItem' },
)

export interface ChargeDetailsLineItemProps {
  areChargesPostedAndEditable: boolean
  editDisabled: boolean
  invoice: Invoice | InvoiceV3 | Nil
  item: InvoiceLineItem | RetailOrderLineItem
}

const supportedFields = [
  'quantity',
  'usedQuantity',
  'taxed',
  'declined',
  'prepaid',
  'discountAmount',
  'discountPerc',
]

const ChargeDetailsLineItem = ({
  areChargesPostedAndEditable = false,
  editDisabled,
  invoice,
  item,
}: ChargeDetailsLineItemProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()
  const isRetailOrderLineItem = getIsRetailOrderLineItem(item)
  const stateId = isRetailOrderLineItem ? item.logState?.id : item.stateId

  const isDeclined = useGetIsDeclinedLineItem(item)

  const { t } = useTranslation('Soap')

  const fields = useChargeDetailsFields(item)

  const {
    catalogUnitPrice: catalogUnitPriceField,
    discountAmount: discountAmountField,
    itemSubtotal: itemSubtotalField,
    quantity: quantityField,
    usedQuantity: usedQuantityField,
    remainingQuantity: remainingQuantityField,
    sellPrice: sellPriceField,
    tax: taxField,
    taxed: taxedField,
    shippingAmount: shippingAmountField,
    retailDeliveryFee: retailDeliveryFeeField,
  } = fields

  const isChewyActiveRx = isRetailOrderLineItem
    ? true
    : getIsCreatedPrescriptionChewyActiveRx(item.prescriptionType, item.origin)

  const isChewyReadOnly = useIsChewyActiveRxReadOnly({
    isChewyActiveRx,
    stateId,
    isInvoice: isRetailOrderLineItem
      ? item.invoice?.type === InvoiceType.INVOICE
      : false,
  })

  const showQuantityDetails = isRetailOrderLineItem
    ? false
    : (!isChewyActiveRx || isChewyCheckoutEnabled) && item.prepaid
  const showPriceDetails =
    (!isChewyActiveRx || isChewyCheckoutEnabled) && !isDeclined
  const showShippingDetails = isRetailOrderLineItem && isChewyCheckoutEnabled
  const showRetailDeliveryFee =
    isRetailOrderLineItem &&
    isChewyCheckoutEnabled &&
    retailDeliveryFeeField.value > 0

  const recentPriceChange = getIsRetailOrderLineItem(item)
    ? item.price - item.originalPrice
    : 0

  const [detailsLineItem, setDetailsLineItem] = useState({})

  const handleEditInvoiceLineItem = useDebouncedCallback(() => {
    const taxedLineItem = R.prop('taxed', detailsLineItem)
    const data = {
      ...detailsLineItem,
      taxed:
        taxedLineItem !== undefined
          ? Boolean(Number(taxedLineItem))
          : undefined,
      prepaid: (item as InvoiceLineItem).prepaid,
      id: item.id,
      expectedModification: item.modificationDate,
    }
    dispatch(
      editChargeSheetItem({
        updateItemInput: [data] as ModifiedChargeSheetLineItemInput[],
      }),
    )
    setDetailsLineItem({})
  }, Defaults.DEBOUNCE_ACTION_TIME)

  const handleEditRetailOrderLineItem = useDebouncedCallback(
    (payload: RetailOrderLineItemPayload) => {
      const retailItem = item as RetailOrderLineItem
      const data: UpdateRetailOrderLineItemInput = {
        ...payload,
        id: retailItem.id,
        quantity: payload?.quantity || retailItem.quantity,
        declined: payload?.declined || retailItem.declined,
        partNumber: retailItem.partNumber,
        expectedModificationDate: retailItem.modificationDate,
        autoshipFrequency: retailItem.autoshipFrequency,
        autoshipUnitId: retailItem.autoshipUnit?.id,
      }
      dispatch(editRetailOrderLineItem(data))
    },
    Defaults.DEBOUNCE_ACTION_TIME,
  )

  useFieldsChanged((payload: FieldCache) => {
    const data = R.reduce(
      (acc, { name, value }: Pick<Field, 'value' | 'name'>) =>
        supportedFields.includes(name) && R.reduced(R.assoc(name, value, acc)),
      {} as Partial<ModifiedChargeSheetLineItemInput> | boolean,
      payload,
    )

    if (typeof data !== 'boolean') {
      setDetailsLineItem(R.mergeDeepRight(detailsLineItem, data))
    }
  }, fields)

  return (
    <Grid container pl={0.5} pr={1}>
      <Stack
        direction="column"
        // When using Divider with Stack we need to render conditional its child,
        // even if child component returns nothing
        divider={
          <Divider
            component="hr"
            orientation="horizontal"
            sx={{ width: 'auto' }}
          />
        }
      >
        <ChargeFieldQuantity
          className={classes.field}
          disabled={
            isDeclined ||
            (!isRetailOrderLineItem && !item.prepaid && editDisabled) ||
            (!isRetailOrderLineItem &&
              item.prepaid &&
              editDisabled &&
              isEditPostedChargesEnabled) ||
            (isChewyActiveRx && isChewyReadOnly)
          }
          inputClassName={classes.inputField}
          item={item}
          quantityField={quantityField}
          remainingQuantityField={remainingQuantityField}
          onEditInvoiceLineItem={handleEditInvoiceLineItem}
          onEditRetailOrderLineItem={handleEditRetailOrderLineItem}
        />
        {showQuantityDetails && (
          <ChargeFieldUsedQuantity
            className={classes.field}
            disabled={isDeclined || editDisabled}
            inputClassName={classes.inputField}
            invoice={invoice}
            item={item as InvoiceLineItem}
            quantityField={quantityField}
            remainingQuantityField={remainingQuantityField}
            usedQuantityField={usedQuantityField}
            onEditInvoiceLineItem={handleEditInvoiceLineItem}
          />
        )}
        {showQuantityDetails && (
          <ChargeFieldRemainingQuantity
            item={item as InvoiceLineItem}
            remainingQuantityField={remainingQuantityField}
          />
        )}
        {!isDeclined && (
          <ChargeFieldCatalogUnitPrice
            catalogUnitPriceField={catalogUnitPriceField}
            className={classes.field}
            inputClassName={classes.inputField}
            item={item}
          />
        )}
        {showPriceDetails && (
          <ChargeFieldSellPrice
            className={classes.field}
            inputClassName={classes.inputField}
            item={item}
            sellPriceField={sellPriceField}
          />
        )}
        {showPriceDetails && (
          <ChargeFieldTaxed
            className={classNames(classes.field, classes.inputField)}
            disabled={areChargesPostedAndEditable}
            item={item}
            taxField={taxField}
            taxedField={taxedField}
            onEditInvoiceLineItem={handleEditInvoiceLineItem}
          />
        )}
        {showPriceDetails && (
          <ChargeFieldTax
            className={classes.field}
            inputClassName={classes.inputField}
            item={item}
            taxField={taxField}
          />
        )}
        {showShippingDetails && (
          <ChargeFieldShippingAmount
            className={classes.field}
            inputClassName={classes.inputField}
            item={item}
            shippingAmountField={shippingAmountField}
          />
        )}
        {showRetailDeliveryFee && (
          <ChargeFieldRetailDeliveryFee
            className={classes.field}
            inputClassName={classes.inputField}
            item={item}
            retailDeliveryFeeField={retailDeliveryFeeField}
          />
        )}
        {showPriceDetails && (
          <ChargeFieldDiscountAmount
            className={classNames(classes.removeUnderline, classes.field)}
            disabled={areChargesPostedAndEditable}
            discountAmountField={discountAmountField}
            inputClassName={classes.inputField}
            item={item}
            onEditInvoiceLineItem={handleEditInvoiceLineItem}
          />
        )}
        {showPriceDetails && (
          <>
            <ChargeFieldSubtotal
              className={classNames(classes.field, classes.subtotalItem)}
              inputClassName={classNames(
                classes.inputField,
                classes.subtotalItem,
              )}
              item={item}
              itemSubtotalField={itemSubtotalField}
            />
            {recentPriceChange !== 0 && (
              <Status
                withAlertIcon
                className={classes.chewyPriceChange}
                variant="warning"
              >
                {recentPriceChange > 0
                  ? t('Soap:CHEWY_PRICE_INCREASED', {
                      price: formatMoney(recentPriceChange),
                    })
                  : t('Soap:CHEWY_PRICE_DECREASED', {
                      price: formatMoney(Math.abs(recentPriceChange)),
                    })}
              </Status>
            )}
          </>
        )}
      </Stack>
    </Grid>
  )
}

export default ChargeDetailsLineItem

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AppointmentCommunicationTemplate,
  BasePuiDialogProps,
  Business,
  PuiDialog,
  Text,
} from '@pbt/pbt-ui-components'

import AppointmentAutomaticCommunicationPreview from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/appointment-communications/preview/AppointmentAutomaticCommunicationPreview'
import ReminderAutomaticCommunicationPreview from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/reminderCommunication/ReminderAutomaticCommunicationPreview'
import { ReminderCommunicationTemplatePreviewInput } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 985,
      maxWidth: 985,
    },
    titleContainer: {
      padding: theme.spacing(3),
    },
    automaticCommunicationPreview: {
      padding: theme.spacing(0, 3, 3),
    },
  }),
  { name: 'AutomaticCommunicationPreviewDialog' },
)

export enum AutomaticCommunicationType {
  APPOINTMENT = 'APPOINTMENT',
  REMINDER = 'REMINDER',
}

export interface AutomaticCommunicationPreviewDialogProps
  extends BasePuiDialogProps {
  automaticCommunication:
    | AppointmentCommunicationTemplate
    | ReminderCommunicationTemplatePreviewInput
  business: Business
  type: AutomaticCommunicationType
}

const AutomaticCommunicationPreviewDialog = ({
  business,
  automaticCommunication,
  open,
  onClose,
  type,
}: AutomaticCommunicationPreviewDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')

  return (
    <PuiDialog
      aria-labelledby="automatic-communication-preview-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        item
        className={classes.titleContainer}
        direction="column"
      >
        <Text variant="h2">
          {t(
            'Businesses:APPOINTMENT_COMMUNICATIONS.PREVIEW_AUTOMATIC_COMMUNICATION',
          )}
        </Text>
        <Text variant="body2">
          {t('Businesses:PREVIEW_INCLUDES_SAMPLE_CLIENT_AND_PATIENT')}
        </Text>
      </Grid>
      {AutomaticCommunicationType.APPOINTMENT === type ? (
        <AppointmentAutomaticCommunicationPreview
          automaticCommunication={
            automaticCommunication as AppointmentCommunicationTemplate
          }
          business={business}
          className={classes.automaticCommunicationPreview}
        />
      ) : (
        <ReminderAutomaticCommunicationPreview
          automaticCommunication={
            automaticCommunication as ReminderCommunicationTemplatePreviewInput
          }
          business={business}
          className={classes.automaticCommunicationPreview}
        />
      )}
    </PuiDialog>
  )
}

export default AutomaticCommunicationPreviewDialog

import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TreeItem, TreeItemProps } from '@mui/x-tree-view/TreeItem'

import { getProblemById } from '~/store/reducers/problems'

const StyledGroupWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: -15,
    bottom: 16,
    left: -10,
    borderRight: `1.5px solid ${theme.palette.grey[300]}`,
  },
}))

const StyledTreeItem = styled(
  (props: TreeItemProps & { isRootNode: boolean }) => <TreeItem {...props} />,
)(({ theme, isRootNode }) => ({
  position: 'relative',
  paddingLeft: 10,
  '&:before': {
    pointerEvents: 'none',
    content: '""',
    position: 'absolute',
    width: 20,
    left: -8,
    top: 14,
    borderBottom: isRootNode
      ? 'none'
      : `1.5px solid ${theme.palette.grey[300]}`,
  },
  '& .MuiTreeItem-content': {
    height: '30px',
    '&.Mui-selected': {
      backgroundColor: theme.palette.grey[300],
      '&:after': {
        content: '">"',
      },
    },
    '&.Mui-selected.Mui-focused': {
      backgroundColor: theme.palette.grey[300],
    },
    '& .MuiTreeItem-iconContainer': {
      display: 'none',
    },
    '&:hover': {
      '&:after': {
        content: '">"',
      },
    },
  },
}))

interface TreeBranchProps {
  isRootNode?: boolean
  problemId: string
}

const TreeBranch = ({ problemId, isRootNode = false }: TreeBranchProps) => {
  const problem = useSelector(getProblemById(problemId))
  const isLastChild = problem?.type
  const isRemoved = problem?.removed
  const isHidden = problem?.isHidden

  if (!problem || isLastChild || isRemoved || isHidden) {
    return null
  }

  return (
    <StyledTreeItem
      isRootNode={isRootNode}
      itemId={problem.key}
      label={problem.nameTranslation}
    >
      <StyledGroupWrapper>
        {problem.children.map((id: string) => (
          <TreeBranch key={id} problemId={id} />
        ))}
      </StyledGroupWrapper>
    </StyledTreeItem>
  )
}

export default TreeBranch

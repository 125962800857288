import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AlertIconType,
  ButtonWithLoader,
  Nil,
  PermissionArea,
  Utils,
} from '@pbt/pbt-ui-components'

import useCloneEstimate from '~/components/dashboard/soapV2/estimates/utils/useCloneEstimate'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import {
  clearFinanceError,
  clearLineItemCandidates,
  clearLineItemDeleted,
  signEstimate,
} from '~/store/actions/finance'
import {
  fetchRhapsodyPayConfig,
  getRhapsodyPayConfig,
} from '~/store/duck/rhapsodyPay'
import {
  useDisplayRecordDeposit,
  useGetEstimateState,
} from '~/store/hooks/estimate'
import {
  useGetInvoiceLogsByLogType,
  useHasUnfinishedLabs,
  useOutstandingImagingOrders,
} from '~/store/hooks/finance'
import { useGetActiveLabVendorsMap } from '~/store/hooks/labVendorConfig'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getIsPracticeAdministrator,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  FinanceError,
  getFinanceError,
  getFinanceIsEstimateDeleting,
  getFinanceIsFetching,
  getFinanceIsLoading,
} from '~/store/reducers/finance'
import {
  getSOAPisFetchingSoapOrders,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import { BatchInvoice, Estimate, InvoiceOrEstimate, SaveInvoice } from '~/types'
import { getIsCloneError, getIsFinalizedError } from '~/utils/errors'
import { isRhapsodyGoAvailableForPractice } from '~/utils/paymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import ItemsErrorsFormatter from './error-formatters/ItemsErrorsFormatter'
import InvoiceActionsMenu from './InvoiceActionsMenu'
import {
  getIsBatchInvoice,
  getIsEstimateAttachedToThisSoap,
  getItemsHaveZeroQuantity,
  getRecordDepositProps,
  getRecordPaymentProps,
} from './invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      height: 40,
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    addToAppointmentButton: {
      minWidth: 174,
    },
    requestPaymentButton: {
      minWidth: 156,
    },
    saveButton: {
      minWidth: 120,
    },
    recordDepositButton: {
      minWidth: 139,
    },
    recordPaymentButton: {
      minWidth: 148,
    },
    menuButtonIcon: {
      borderRadius: '50%',
      backgroundColor: 'unset',
      border: '1px solid',
      borderColor: theme.colors.lowAccentText,
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    menuButtonSvg: {
      color: theme.colors.lowAccentText,
    },
    listItemIcon: {
      position: 'relative',
    },
    listItemIconProgress: {
      left: theme.spacing(-1),
    },
  }),
  { name: 'InvoiceActions' },
)
const InvoiceErrorFormatters = {
  ITEMS_ERRORS: ItemsErrorsFormatter,
}

export interface InvoiceActionsProps {
  clientId: string | Nil
  eventId?: string
  fromTimeline?: boolean
  hasUnsavedChanges: () => boolean
  includeServiceFee: boolean
  invoice: InvoiceOrEstimate | BatchInvoice
  isEstimate: boolean
  isNew?: boolean
  isPaid: boolean
  isPosted: boolean
  newEstimateFlow?: boolean
  post: () => void
  refetchInvoice: () => void
  reopen: () => void
  resetInvoice: () => void
  save: (invoiceParams?: SaveInvoice) => void
  setAttachingToSoapEstimateId?: (attachingToSoapEstimateId: string) => void
  setSoapToAttachEstimateId?: (soapToAttachEstimateId: string) => void
  showEstimateApprovalAfterCreationOn: (...args: any[]) => void
  soapId: string | Nil
}

const InvoiceActions = ({
  clientId,
  invoice: invoiceProp,
  soapId,
  isEstimate,
  includeServiceFee,
  eventId,
  refetchInvoice,
  resetInvoice,
  fromTimeline,
  showEstimateApprovalAfterCreationOn,
  save,
  post,
  reopen,
  isPaid,
  isPosted,
  hasUnsavedChanges,
  isNew,
  newEstimateFlow,
  setAttachingToSoapEstimateId,
  setSoapToAttachEstimateId,
}: InvoiceActionsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Invoices', 'Tooltips'])
  const isLoadingFinance = useSelector(getFinanceIsLoading)
  const isLoadingEstimateDeleting = useSelector(getFinanceIsEstimateDeleting)
  const isLoading = isLoadingFinance || isLoadingEstimateDeleting
  const business = useSelector(getCurrentBusiness)
  const rhapsodyPayConfig =
    useSelector(getRhapsodyPayConfig(business?.id)) || {}
  const invoicePermissionsUpdate = useSelector(
    getCRUDByArea(PermissionArea.INVOICE),
  ).update
  const appointmentPermissionsUpdate = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  ).update
  const paymentCreatePermissions = useSelector(
    getCRUDByArea(PermissionArea.PAYMENT),
  ).create
  const error = useSelector(getFinanceError)
  const client = useSelector(getUser(clientId)) || {}
  const isSoapLoading = useSelector(getSOAPisLoading)
  const isLoadingSoapOrders = useSelector(getSOAPisFetchingSoapOrders)
  const isFetching =
    useSelector(getFinanceIsFetching) || isSoapLoading || isLoadingSoapOrders

  const isNewEstimateStatusFlowEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_ESTIMATES),
  )

  const SAVE_TOOLTIP_MESSAGE = t('Tooltips:SAVE_WITH_ZERO_QUANTITY')

  const { displayRecordDeposit, hasPermission } = useDisplayRecordDeposit(
    invoiceProp as Estimate,
  )
  const activeLabVendors = useGetActiveLabVendorsMap()

  const isGoAvailableForPractice = isRhapsodyGoAvailableForPractice(
    business,
    rhapsodyPayConfig,
  )
  const isBatchInvoice = getIsBatchInvoice(invoiceProp)
  const groups = invoiceProp.groups || []
  const labLogs = useGetInvoiceLogsByLogType(OrderType.LAB_TEST, invoiceProp)
  const hasUnfinishedLabs = useHasUnfinishedLabs(labLogs)
  const hasLabs = labLogs.some((log) => activeLabVendors[log.labTestVendorId])
  const procedureLogs = useGetInvoiceLogsByLogType(
    OrderType.PROCEDURE,
    invoiceProp,
  )
  const outstandingImagingOrders = useOutstandingImagingOrders(procedureLogs)
  const hasOutstandingImagingOrders = outstandingImagingOrders?.length > 0
  const handleSavingAlertClose = () => {
    dispatch(clearFinanceError())
    dispatch(clearLineItemCandidates())
    dispatch(clearLineItemDeleted())
    resetInvoice()
  }
  const [openPaymentRequestDialog] = useDialog(DialogNames.PAYMENT_REQUEST)
  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const [openSaveInvoiceAlert, , isSaveInvoiceAlertOpen] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
    handleSavingAlertClose,
  )

  const [openSignatureDialog] = useDialog(DialogNames.SIGNATURE_DIALOG)
  const [openConfirmCloseDialog, closeConfirmCloseDialog] = useDialog(
    DialogNames.CONFIRM_CLOSE_DIALOG,
  )
  const [openImagingOrdersManagementDialog] = useDialog(
    DialogNames.BATCH_IMAGING_ORDERS_MANAGEMENT,
  )
  const [openLabOrderManagementDialog] = useDialog(
    DialogNames.LAB_ORDER_MANAGEMENT_DIALOG,
  )
  const [openAppointmentsListDialog] = useDialog(
    DialogNames.APPOINTMENTS_LIST_FOR_ESTIMATE,
  )
  const [onSaveAction, setOnSaveAction] =
    useState<(inv: InvoiceOrEstimate | BatchInvoice) => void>()
  const callbackOnSaveInvoice = useCloseAfterCreation(
    (callback) => callback(),
    getFinanceIsLoading,
  )
  useEffect(() => {
    if (business?.id) {
      dispatch(fetchRhapsodyPayConfig(business.id))
    }
  }, [business?.id])

  const showInvoiceSavingAlert = (err: FinanceError | null) => {
    if (!isSaveInvoiceAlertOpen) {
      const data = err?.data as
        | { type: keyof typeof InvoiceErrorFormatters }
        | undefined
      const Formatter = data?.type
        ? InvoiceErrorFormatters[data.type]
        : undefined
      const prop = Formatter ? 'content' : 'message'
      const value = Formatter ? <Formatter error={data} /> : err?.message
      openSaveInvoiceAlert({
        iconType: AlertIconType.WARN,
        [prop]: value,
      })
      refetchInvoice()
    }
  }

  const { onCloneRequested } = useCloneEstimate({
    clientId,
    estimateId: invoiceProp.id,
    patientId: invoiceProp.patient,
    soapBusinessId: business?.id,
    soapId,
  })

  const onSignInvoiceRequested = (
    invoice: InvoiceOrEstimate | BatchInvoice,
  ) => {
    openSignatureDialog({
      initialSignerValue: Utils.getPersonString(client),
      outputFormat: 'png',
      onSign: (signerName: string, signature: string) => {
        showEstimateApprovalAfterCreationOn()
        if (invoice.clientId) {
          dispatch(
            signEstimate(invoice.id, signature, invoice.clientId, signerName, {
              fromTimeline,
            }),
          )
        }
      },
    })
  }

  const onOpenPaymentRequested = (
    invoice: InvoiceOrEstimate | BatchInvoice,
  ) => {
    openPaymentRequestDialog({ invoice })
  }
  useEffect(() => {
    if (error) {
      const errorType = error.type
      const isFinalizedError = getIsFinalizedError(errorType)
      const isCloneError = getIsCloneError(errorType)

      if (isFinalizedError || isCloneError) {
        return
      }
      showInvoiceSavingAlert(error)
    }
  }, [Boolean(error)])

  useEffect(() => {
    if ((invoiceProp?.id || invoiceProp?.invoices) && onSaveAction) {
      onSaveAction(invoiceProp)
      setOnSaveAction(undefined)
    }
  }, [invoiceProp])
  const openPaymentDialogAfterSaving = (
    invoice: InvoiceOrEstimate | BatchInvoice,
  ) => {
    const recordPaymentDialogProps = getRecordPaymentProps(
      invoice,
      includeServiceFee,
    )
    if (error) {
      showInvoiceSavingAlert(error)
    } else {
      openAddPaymentDialog({
        clientId,
        ComponentProps: recordPaymentDialogProps,
      })
    }
  }
  const openDepositDialogAfterSaving = (
    invoice: InvoiceOrEstimate | BatchInvoice,
  ) => {
    const recordDepositDialogProps = getRecordDepositProps(
      invoice,
      includeServiceFee,
    )
    openAddPaymentDialog({ clientId, ComponentProps: recordDepositDialogProps })
  }
  const saveIfNeeded = (
    handler: (invoice: InvoiceOrEstimate | BatchInvoice) => void,
    invoiceParams?: SaveInvoice,
  ) => {
    if (hasUnsavedChanges() || isNew) {
      save(invoiceParams)
      setOnSaveAction(R.always(handler))
    } else {
      handler(invoiceProp)
    }
  }

  const saveAndSign = () =>
    saveIfNeeded(onSignInvoiceRequested, {
      posted: isPosted,
      preventShowEstimateApprovalDialog: true,
    })

  const saveAndRecordPayment = () =>
    saveIfNeeded(openPaymentDialogAfterSaving, {
      posted: isPosted,
      includePosted: true,
    })

  const saveAndRecordDeposit = () => saveIfNeeded(openDepositDialogAfterSaving)

  const saveAndRequestPayment = () =>
    saveIfNeeded(onOpenPaymentRequested, { includePosted: true })

  const onOpenLabOrderManagementRequested = (
    updatedInvoice: InvoiceOrEstimate | BatchInvoice,
  ) => {
    openLabOrderManagementDialog({
      invoiceId: updatedInvoice.id,
      clientId,
      patientId: updatedInvoice.patient,
    })
  }
  const onOpenImagingManagementRequested = (
    updatedInvoice: InvoiceOrEstimate | BatchInvoice,
  ) => {
    openImagingOrdersManagementDialog({
      hideOrderManagement: true,
      invoiceId: updatedInvoice.id,
    })
  }
  const onLabOrderManagementRequested = () =>
    saveIfNeeded(onOpenLabOrderManagementRequested)
  const onImagingManagementRequested = () =>
    saveIfNeeded(onOpenImagingManagementRequested)
  const onOpenAppointmentsListRequested = () => {
    openAppointmentsListDialog({
      autoCopyItems: true,
      clientId: invoiceProp.client,
      estimateId: invoiceProp.id,
      patientId: invoiceProp.patient,
      onChangeSoapToAttachEstimateId: setSoapToAttachEstimateId,
      onChangeAttachingToSoapEstimateId: setAttachingToSoapEstimateId,
    })
  }
  const addToAppointment = () => {
    if (newEstimateFlow && setAttachingToSoapEstimateId) {
      setAttachingToSoapEstimateId(invoiceProp.id)
    }
    if (hasUnsavedChanges() && invoicePermissionsUpdate) {
      openConfirmCloseDialog({
        onNotOk: () => {
          onOpenAppointmentsListRequested()
          closeConfirmCloseDialog()
        },
        onOk: () => {
          save()
          callbackOnSaveInvoice(() => {
            onOpenAppointmentsListRequested()
          })
          closeConfirmCloseDialog()
        },
      })
    } else {
      onOpenAppointmentsListRequested()
    }
  }

  const isPracticeAdmin = useSelector(getIsPracticeAdministrator(business))
  const showPost = !isEstimate && !isPosted && !isBatchInvoice
  const showReopen =
    !isEstimate && isPracticeAdmin && isPosted && !isBatchInvoice
  const isEstimateLinkedToThisAppointment =
    isEstimate &&
    getIsEstimateAttachedToThisSoap(invoiceProp as Estimate, soapId)

  const { isExpiredDraft, isExpiredApproved, isExpiredDeclined } =
    useGetEstimateState()(invoiceProp.stateId)
  const isExpired = isExpiredDraft || isExpiredApproved || isExpiredDeclined

  return (
    <Grid container item>
      <Grid item xs>
        <Tooltip
          open={getItemsHaveZeroQuantity(groups)}
          title={SAVE_TOOLTIP_MESSAGE}
        >
          <ButtonWithLoader
            className={classNames(classes.button, classes.saveButton)}
            disabled={
              !invoicePermissionsUpdate ||
              isFetching ||
              isLoading ||
              getItemsHaveZeroQuantity(groups)
            }
            loading={isFetching || isLoading}
            onClick={() => save()}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Tooltip>
        {showPost && (
          <ButtonWithLoader
            className={classes.button}
            disabled={
              !invoicePermissionsUpdate ||
              isFetching ||
              isLoading ||
              getItemsHaveZeroQuantity(groups)
            }
            loading={isFetching || isLoading}
            onClick={() => post()}
          >
            {t('Invoices:POST_INVOICE_ACTION')}
          </ButtonWithLoader>
        )}
        {showReopen && (
          <ButtonWithLoader
            className={classes.button}
            disabled={
              !invoicePermissionsUpdate ||
              isFetching ||
              isLoading ||
              getItemsHaveZeroQuantity(groups)
            }
            loading={isFetching || isLoading}
            onClick={() => reopen()}
          >
            {t('Common:RE-OPEN_INVOICE')}
          </ButtonWithLoader>
        )}
        {!isNewEstimateStatusFlowEnabled && isEstimate && (
          <>
            {((!soapId && !eventId) ||
              (newEstimateFlow && !isEstimateLinkedToThisAppointment)) && (
              <Tooltip
                disableFocusListener
                disableHoverListener={Boolean(invoiceProp.id)}
                placement="top"
                title={t('Tooltips:SAVE_ESTIMATE_FIRST')}
              >
                <span>
                  <ButtonWithLoader
                    className={classNames(
                      classes.button,
                      classes.addToAppointmentButton,
                    )}
                    disabled={
                      !appointmentPermissionsUpdate ||
                      isFetching ||
                      isLoading ||
                      !invoiceProp.id
                    }
                    loading={isFetching || isLoading}
                    onClick={addToAppointment}
                  >
                    {t('Common:ADD_TO_APPOINTMENT')}
                  </ButtonWithLoader>
                </span>
              </Tooltip>
            )}
            {displayRecordDeposit && (
              <Tooltip
                disableFocusListener
                disableHoverListener={Boolean(invoiceProp.id)}
                placement="top"
                title={t('Tooltips:SAVE_ESTIMATE_FIRST')}
              >
                <span>
                  <ButtonWithLoader
                    className={classNames(
                      classes.button,
                      classes.recordDepositButton,
                    )}
                    disabled={
                      !hasPermission ||
                      !invoiceProp.id ||
                      isFetching ||
                      isLoading
                    }
                    loading={isFetching || isLoading}
                    onClick={saveAndRecordDeposit}
                  >
                    {t('Common:RECORD_DEPOSIT_ACTION')}
                  </ButtonWithLoader>
                </span>
              </Tooltip>
            )}
          </>
        )}
        {!isNewEstimateStatusFlowEnabled &&
          !isEstimate &&
          !invoiceProp.paid &&
          !isPaid &&
          paymentCreatePermissions && (
            <ButtonWithLoader
              className={classNames(
                classes.button,
                classes.recordPaymentButton,
              )}
              disabled={!invoicePermissionsUpdate || isFetching || isLoading}
              loading={isFetching || isLoading}
              onClick={saveAndRecordPayment}
            >
              {t('Common:RECORD_PAYMENT')}
            </ButtonWithLoader>
          )}
        {!isNewEstimateStatusFlowEnabled && isEstimate && (
          <ButtonWithLoader
            className={classes.button}
            color="secondary"
            disabled={!invoicePermissionsUpdate || isFetching || isLoading}
            loading={isFetching || isLoading}
            onClick={saveAndSign}
          >
            {t('Common:SIGN_ESTIMATE_ACTION')}
          </ButtonWithLoader>
        )}
        {!isNewEstimateStatusFlowEnabled &&
          !isEstimate &&
          !invoiceProp.paid &&
          !isPaid &&
          isGoAvailableForPractice && (
            <ButtonWithLoader
              className={classNames(
                classes.button,
                classes.requestPaymentButton,
              )}
              color="secondary"
              disabled={!invoicePermissionsUpdate || isFetching || isLoading}
              loading={isFetching || isLoading}
              onClick={saveAndRequestPayment}
            >
              {t('Common:REQUEST_PAYMENT')}
            </ButtonWithLoader>
          )}
        {!isNewEstimateStatusFlowEnabled &&
          !isEstimate &&
          hasLabs &&
          !isPosted && (
            <ButtonWithLoader
              className={classes.button}
              color={hasUnfinishedLabs ? 'important' : 'secondary'}
              disabled={!invoicePermissionsUpdate || isFetching || isLoading}
              loading={isFetching || isLoading}
              onClick={onLabOrderManagementRequested}
            >
              {hasUnfinishedLabs
                ? t('Common:FINALIZE_LABS')
                : t('Common:VIEW_LAB_DETAILS')}
            </ButtonWithLoader>
          )}
        {!isNewEstimateStatusFlowEnabled &&
          !isEstimate &&
          hasOutstandingImagingOrders &&
          !isPosted && (
            <ButtonWithLoader
              className={classes.button}
              color="important"
              disabled={!invoicePermissionsUpdate || isFetching || isLoading}
              loading={isFetching || isLoading}
              onClick={onImagingManagementRequested}
            >
              {t('Common:FINALIZE_IMAGING')}
            </ButtonWithLoader>
          )}
        {!isNewEstimateStatusFlowEnabled &&
          isEstimate &&
          invoiceProp.id &&
          isExpired && (
            <ButtonWithLoader
              className={classes.button}
              disabled={isLoading || isFetching}
              loading={isFetching || isLoading}
              onClick={onCloneRequested}
            >
              {t('Common:CLONE_ACTION')}
            </ButtonWithLoader>
          )}
      </Grid>
      <Grid item>
        <InvoiceActionsMenu
          addToAppointment={addToAppointment}
          clientId={clientId}
          includeServiceFee={includeServiceFee}
          invoice={invoiceProp}
          isEstimate={isEstimate}
          saveIfNeeded={saveIfNeeded}
          soapId={soapId}
        />
      </Grid>
    </Grid>
  )
}
export default InvoiceActions

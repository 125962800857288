import LogRocket from 'logrocket'
import { User, Utils } from '@pbt/pbt-ui-components'

import LogRocketSanitizer from './LogRocketSanitizer'

const KEYS: Record<string, string> = {
  Play: 'w2huxx/rhapsody-portal-master',
  Production: 'w2huxx/rhapsody-portal-prod',
  Staging: 'w2huxx/rhapsody-portal-stage-3orpw',
}

export const initLogRocket = () => {
  const environment = Utils.getEnvironmentName()
  const key = KEYS[environment]
  if (key) {
    const { requestSanitizer, responseSanitizer } = LogRocketSanitizer

    LogRocket.init(key, {
      release: process.env.REACT_APP_SENTRY_RELEASE || '0.0.0',
      network: {
        requestSanitizer,
        responseSanitizer,
      },
      mergeIframes: true,
    })
  }
}

export const setLogRocketUser = (user: User) => {
  if (!LogRocket || !user) {
    return
  }
  LogRocket.identify(user.id, {
    name: Utils.getPersonString(user),
    email: user.email || '',
  })
}

export const resetLogRocketUser = () => {
  if (!LogRocket) {
    return
  }
  LogRocket.identify('null')
}

export const getLockRocketSessionURL = () => LogRocket?.sessionURL || undefined

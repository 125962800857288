import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'
import { Nil, PhoneUtils, useFields } from '@pbt/pbt-ui-components'
import { ValidationType } from '@pbt/pbt-ui-components/src/types/validation'

import { ConversationTransport } from '~/api/graphql/generated/types'
import { ConversationContext } from '~/types'

const DEFAULT_FIELDS_TO_VALIDATE = ['to', 'subject', 'message']

export type InitialValuesType = {
  initialMessage?: string | Nil
  initialSubject?: string
  initialTo?: string | Nil
}

export type ConfigType = {
  fieldsToValidate?: string[]
}

export const useNewConversationValidationForm = (
  context: ConversationContext,
  initialValues: InitialValuesType = {},
  config: ConfigType = {},
) => {
  const {
    transport = ConversationTransport.Email,
    client,
    contact,
  } = context || {}
  const { t } = useTranslation('Common')

  const fieldsToValidate = config.fieldsToValidate || DEFAULT_FIELDS_TO_VALIDATE

  const {
    initialSubject = '',
    initialMessage = '',
    initialTo: initialToProp,
  } = initialValues
  const phoneSelect = transport === ConversationTransport.Sms

  const transportsWithoutToValidation = [
    ConversationTransport.LogPhoneCall,
    ConversationTransport.Boop,
  ]

  const initialToValidators: ValidationType[] = R.includes(
    transport,
    transportsWithoutToValidation,
  )
    ? []
    : phoneSelect
      ? ['required', 'phoneList']
      : ['required', 'emailsList']

  const contactInitialTo = phoneSelect
    ? PhoneUtils.formatPhoneNumber(contact?.phone)
    : contact?.email
  const clientInitialTo = phoneSelect
    ? PhoneUtils.formatPhoneNumber(client?.mobilePhone)
    : client?.email

  const initialTo =
    initialToProp || (contact ? contactInitialTo : clientInitialTo)

  const { fields, ...rest } = useFields(
    [
      {
        name: 'to',
        label: t('Common:TO'),
        validators: initialToValidators,
        initialValue: initialTo,
      },
      {
        name: 'subject',
        label: t('Common:SUBJECT'),
        validators: ['required'] as ValidationType[],
        initialValue: initialSubject,
      },
      {
        name: 'message',
        label: t('Common:MESSAGE'),
        validators: ['required'] as ValidationType[],
        initialValue: initialMessage,
      },
    ].filter((it) => R.includes(it.name, fieldsToValidate)),
    false,
  )

  useEffect(() => {
    fields?.to?.setValue(initialTo)
  }, [transport, initialTo])

  return { fields, ...rest }
}

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  Calendar,
  Field,
  PermissionArea,
  PuiCheckbox,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import AutoshipIcon from '~/components/elements/Icons/generated/AutoshipIcon'
import Typography from '~/components/elements/Typography/Typography'
import { useIsAutoshipEnabled } from '~/store/hooks/business'
import { getCRUDByArea } from '~/store/reducers/auth'
import { DataHandle } from '~/types'
import { handleNumberInput } from '~/utils'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import AutoshipFrequencySelect from './AutoshipFrequencySelect'
import { AutoshipRefillTooltip } from './AutoshipRefillTooltip'

const useStyles = makeStyles(
  (theme) => ({
    inputLabel: {
      width: '100%',
      fontWeight: 400,
    },
    refills: {
      maxWidth: 84,
      marginRight: theme.spacing(3),
    },
    refillExpiration: {
      maxWidth: 136,
    },
    addRefills: {
      display: 'flex',
      backgroundColor: theme.colors.brandLight,
      padding: theme.spacing(1),
      marginBottom: '-8px',
      marginTop: '16px',
    },
    addRefillsText: {
      marginLeft: '5px',
      marginRight: '5px',
    },
    autoshipIcon: {
      opacity: '50%',
    },
  }),
  { name: 'RefillableSection' },
)

export interface RefillableSectionProps {
  autoshipFrequency?: Field
  autoshipUnitId?: Field
  doesPrescriptionHaveInvoiceOrderLineItem?: boolean
  enableAutoship?: Field
  isChewyActiveRx: boolean
  onRefChange?: () => void
  quantityNumbRefills: number | undefined
  quantityRefillExpirationDate: string | undefined
}

export interface RefillableSectionDataHandle extends DataHandle {}

export const RefillableSection = forwardRef<
  RefillableSectionDataHandle,
  RefillableSectionProps
>(function RefillableField(
  {
    quantityNumbRefills: quantityNumbRefillsProp,
    quantityRefillExpirationDate: quantityRefillExpirationDateProp,
    onRefChange,
    isChewyActiveRx,
    autoshipFrequency: autoshipFrequencyField,
    autoshipUnitId: autoshipUnitIdField,
    enableAutoship: enableAutoshipField,
    doesPrescriptionHaveInvoiceOrderLineItem,
  },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation('Dialogs')

  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))
  const isAutoshipEnabled = useIsAutoshipEnabled()

  const [refillable, setRefillable] = useState(Boolean(quantityNumbRefillsProp))

  const eligibleForAutoship =
    !doesPrescriptionHaveInvoiceOrderLineItem &&
    isAutoshipEnabled &&
    isChewyActiveRx &&
    autoshipFrequencyField &&
    autoshipUnitIdField &&
    enableAutoshipField

  const displayAutoshipFrequencySelect = eligibleForAutoship && refillable

  const displayAddAutoshipRefillsBox = eligibleForAutoship && !refillable

  const {
    fields: { quantityNumbRefills, quantityRefillExpirationDate },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'quantityNumbRefills',
        label: t(
          'Dialogs:PRESCRIPTION_DIALOG.QUANTITY_SECTION.REFILLS_QUANTITY',
        ),
        validators: refillable ? ['required'] : [],
        initialValue: quantityNumbRefillsProp,
      },
      {
        name: 'quantityRefillExpirationDate',
        label: t(
          'Dialogs:PRESCRIPTION_DIALOG.QUANTITY_SECTION.REFILL_EXPIRATION',
        ),
        validators: ['timestamp'],
        initialValue: quantityRefillExpirationDateProp,
      },
    ],
    false,
  )

  useEffect(() => {
    setRefillable(Boolean(quantityNumbRefillsProp))
  }, [quantityNumbRefillsProp])

  useEffectExceptOnMount(() => {
    onRefChange?.()
  }, [quantityNumbRefills, quantityRefillExpirationDate])

  useEffectExceptOnMount(() => {
    reset()
  }, [quantityNumbRefillsProp, quantityRefillExpirationDateProp])

  useImperativeHandle(ref, () => ({
    validate,
    get: () => ({
      quantityNumbRefills: refillable ? Number(quantityNumbRefills.value) : 0,
      quantityRefillExpirationDate: refillable
        ? quantityRefillExpirationDate.value
        : null,
    }),
  }))

  return (
    <Grid container alignItems="center">
      <Grid item mb={-1} mt={2}>
        <PuiCheckbox
          checked={refillable}
          label={t('Dialogs:PRESCRIPTION_DIALOG.QUANTITY_SECTION.REFILLABLE')}
          onChange={() => setRefillable(!refillable)}
        />
      </Grid>
      {refillable && (
        <>
          <PuiTextField
            InputLabelProps={{
              className: classes.inputLabel,
              shrink: true,
            }}
            className={classes.refills}
            disabled={!permissions.update}
            field={{
              ...quantityNumbRefills,
              set: handleNumberInput(quantityNumbRefills.setValue, 10, 0, true),
            }}
            inputProps={{ maxLength: 10 }}
            label={`${quantityNumbRefills.label}*`}
            margin="none"
          />
          <Grid item>
            <Calendar
              fullWidth
              className={classes.refillExpiration}
              disabled={!permissions.update}
              field={quantityRefillExpirationDate}
              label={quantityRefillExpirationDate.label}
            />
          </Grid>
        </>
      )}
      {displayAddAutoshipRefillsBox && (
        <div className={classes.addRefills}>
          <AutoshipIcon className={classes.autoshipIcon} />
          <Typography.Paragraph
            className={classes.addRefillsText}
            color="grayGray2"
          >
            {t('Soap:AUTOSHIP.ADD_REFILLS_AUTOSHIP')}
          </Typography.Paragraph>
          <AutoshipRefillTooltip />
        </div>
      )}

      {displayAutoshipFrequencySelect && (
        <AutoshipFrequencySelect
          autoshipFrequencyField={autoshipFrequencyField}
          autoshipUnitIdField={autoshipUnitIdField}
          enableAutoshipField={enableAutoshipField}
        />
      )}
    </Grid>
  )
})

import i18n from '~/locales/i18n'

import { OrderType } from './SOAPStates'

export enum InvoiceViewStates {
  REFUND_LANDING = 'Refund landing',
  REFUND_SUMMARY = 'Refund summary',
  REFUND_INVOICE = 'Refund invoice',
  DEFAULT = 'Default',
}

export enum RefundInvoiceOptions {
  REFUND_BY_ITEM = 'Refund by item',
  REFUND_BY_AMOUNT = 'Refund by amount',
}

export const REFUND_INVOICE_OPTIONS_DISPLAY = {
  [RefundInvoiceOptions.REFUND_BY_ITEM]: i18n.t('Invoices:REFUND_BY_ITEM'),
  [RefundInvoiceOptions.REFUND_BY_AMOUNT]: i18n.t('Invoices:REFUND_BY_AMOUNT'),
}

export const REFUND_SELECT_CELL_WIDTH = 67
export const REFUND_PATIENT_CELL_WIDTH = 100
export const REFUND_REASON_CELL_WIDTH = 195
export const REFUND_REASON_SUMMARY_CELL_WIDTH = 170
export const REFUND_REFUND_QTY_CELL_WIDTH = 170
export const REFUND_REFUND_QTY_SUMMARY_CELL_WIDTH = 130
export const REFUND_RESTOCK_QTY_CELL_WIDTH = 200
export const REFUND_RESTOCK_QTY_SUMMARY_CELL_WIDTH = 135
export const REFUND_AMOUNT_PAID_CELL_WIDTH = 105
export const REFUND_REFUND_AMOUNT_CELL_WIDTH = 130
export const REFUND_TAX_CELL_WIDTH = 105
export const REFUND_DISCOUNT_CELL_WIDTH = 105

export const REFUND_LANDING_COLUMNS = [
  {
    id: 1,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.SELECT'),
    width: REFUND_SELECT_CELL_WIDTH,
  },
  { id: 2, label: i18n.t('Common:ITEM') },
  {
    id: 3,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.PATIENT'),
    width: REFUND_PATIENT_CELL_WIDTH,
  },
  { id: 4, label: 'Reason', width: REFUND_REASON_CELL_WIDTH },
  {
    id: 5,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.REFUND_QTY'),
    width: REFUND_REFUND_QTY_CELL_WIDTH,
  },
  {
    id: 6,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.RESTOCK_QTY'),
    width: REFUND_RESTOCK_QTY_CELL_WIDTH,
  },
  {
    id: 7,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.AMOUNT_PAID'),
    width: REFUND_AMOUNT_PAID_CELL_WIDTH,
  },
  {
    id: 8,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.REFUND_AMOUNT'),
    width: REFUND_REFUND_AMOUNT_CELL_WIDTH,
  },
]

export const REFUND_SUMMARY_COLUMNS = [
  { id: 1, label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.RETURNING') },
  {
    id: 2,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.PATIENT'),
    width: REFUND_PATIENT_CELL_WIDTH,
  },
  {
    id: 3,
    label: i18n.t('Invoices:REFUND_REASON_LABEL'),
    width: REFUND_REASON_SUMMARY_CELL_WIDTH,
  },
  {
    id: 4,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.REFUND_QTY'),
    width: REFUND_REFUND_QTY_SUMMARY_CELL_WIDTH,
  },
  {
    id: 5,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.RESTOCK_QTY'),
    width: REFUND_RESTOCK_QTY_SUMMARY_CELL_WIDTH,
  },
  {
    id: 6,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.PAID'),
    width: REFUND_AMOUNT_PAID_CELL_WIDTH,
  },
  {
    id: 7,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.TAX'),
    width: REFUND_TAX_CELL_WIDTH,
  },
  {
    id: 8,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.DISCOUNT'),
    width: REFUND_DISCOUNT_CELL_WIDTH,
  },
  {
    id: 9,
    label: i18n.t('Invoices:REFUND_COLUMN_HEADERS.REFUND_AMOUNT'),
    width: REFUND_REFUND_AMOUNT_CELL_WIDTH,
  },
]

export const RESTOCKABLE_TYPES = [
  OrderType.INVENTORY,
  OrderType.PREPAID,
  OrderType.PRESCRIPTION,
  OrderType.REFILL,
]

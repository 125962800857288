import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import { Field, PuiSelect } from '@pbt/pbt-ui-components'

import ManualInputSelect from '~/components/common/ManualInputSelect'
import ManualInputTimeDurationSelect from '~/components/common/ManualInputTimeDurationSelect'
import {
  getProductForm,
  getRabiesTagDefaultExpiration,
  getVaccineDefaultAmount,
  getVaccineDefaultDeliveryLocation,
  getVaccineDefaultDoseType,
  getVaccineDefaultDurationOfImmunity,
  getVaccineDeliveryMethod,
  getVaccineType,
} from '~/store/reducers/constants'

export interface VaccinationCompoundInfoProps {
  fields: {
    customForm: Field
    formId: Field
    rabiesTagExpiration: Field
    rabiesTagExpirationOptionId: Field
    rabiesTagExpirationUnitId: Field
    vaccineDefaultAmount: Field
    vaccineDefaultAmountId: Field
    vaccineDefaultDeliveryLocation: Field
    vaccineDefaultDeliveryLocationId: Field
    vaccineDefaultDoseTypeId: Field
    vaccineDeliveryMethodId: Field
    vaccineDurationOfImmunity: Field
    vaccineDurationOfImmunityOptionId: Field
    vaccineDurationOfImmunityUnitId: Field
    vaccineTypeId: Field
  }
  isRabies: boolean
}

const VaccinationCompoundInfo = ({
  fields,
  isRabies,
}: VaccinationCompoundInfoProps) => {
  const ProductForm = useSelector(getProductForm)
  const VaccineDeliveryMethod = useSelector(getVaccineDeliveryMethod)
  const VaccineDefaultDeliveryLocation = useSelector(
    getVaccineDefaultDeliveryLocation,
  )
  const VaccineType = useSelector(getVaccineType)
  const VaccineDefaultDurationOfImmunity = useSelector(
    getVaccineDefaultDurationOfImmunity,
  )
  const VaccineDefaultDoseType = useSelector(getVaccineDefaultDoseType)
  const VaccineDefaultAmount = useSelector(getVaccineDefaultAmount)
  const RabiesTagDefaultExpiration = useSelector(getRabiesTagDefaultExpiration)
  const { t } = useTranslation(['Admin', 'Common'])

  const {
    formId,
    customForm,
    vaccineDefaultDeliveryLocationId,
    vaccineDefaultDeliveryLocation,
    vaccineTypeId,
    vaccineDurationOfImmunityOptionId,
    vaccineDurationOfImmunityUnitId,
    vaccineDurationOfImmunity,
    vaccineDefaultDoseTypeId,
    vaccineDeliveryMethodId,
    vaccineDefaultAmountId,
    vaccineDefaultAmount,
    rabiesTagExpirationOptionId,
    rabiesTagExpirationUnitId,
    rabiesTagExpiration,
  } = fields

  return (
    <Grid container alignItems="center" spacing={3}>
      <ManualInputSelect
        fields={{
          unitId: formId,
          customUnit: customForm,
        }}
        label={`${formId.label || t('Common:FORM_NOUN')}*`}
        options={ProductForm}
        otherGridWidth={2}
        unitGridWidth={2}
      />
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel htmlFor="delivery-method-select">
            {vaccineDeliveryMethodId.label || t('Common:DRUG_DELIVERY_METHOD')}*
          </InputLabel>
          <PuiSelect
            field={vaccineDeliveryMethodId}
            input={<Input id="delivery-method-select" />}
            items={VaccineDeliveryMethod}
          />
        </FormControl>
      </Grid>
      <ManualInputSelect
        fields={{
          customUnit: vaccineDefaultDeliveryLocation,
          unitId: vaccineDefaultDeliveryLocationId,
        }}
        label={
          vaccineDefaultDeliveryLocationId.label ||
          t('Admin:CATALOG.VARIATION.VACCINE_DEFAULT_DELIVERY_LOCATION')
        }
        options={VaccineDefaultDeliveryLocation}
        otherGridWidth={2}
        otherLabel={
          vaccineDefaultDeliveryLocation.label || t('Common:OTHER_LOCATION')
        }
      />
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel htmlFor="vaccine-type-select">
            {vaccineTypeId.label}*
          </InputLabel>
          <PuiSelect
            field={vaccineTypeId}
            input={<Input id="vaccine-type-select" />}
            items={VaccineType}
          />
        </FormControl>
      </Grid>
      <ManualInputTimeDurationSelect
        fields={{
          customUnitId: vaccineDurationOfImmunityUnitId,
          customUnit: vaccineDurationOfImmunity,
          unitId: vaccineDurationOfImmunityOptionId,
        }}
        label={
          vaccineDurationOfImmunityOptionId.label ||
          t('Admin:CATALOG.VARIATION.DURATION_OF_IMMUNITY')
        }
        options={VaccineDefaultDurationOfImmunity}
        otherGridWidth={4}
        otherLabel={
          vaccineDurationOfImmunity.label || t('Common:OTHER_DURATION')
        }
      />
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel htmlFor="default-dose-type-select">
            {vaccineDefaultDoseTypeId.label ||
              t('Admin:CATALOG.VARIATION.VACCINE_DEFAULT_DOSE_TYPE')}
          </InputLabel>
          <PuiSelect
            field={vaccineDefaultDoseTypeId}
            input={<Input id="default-dose-type-select" />}
            items={VaccineDefaultDoseType}
          />
        </FormControl>
      </Grid>
      <ManualInputSelect
        fields={{
          customUnit: vaccineDefaultAmount,
          unitId: vaccineDefaultAmountId,
        }}
        label={vaccineDefaultAmountId.label || t('Common:DEFAULT_AMOUNT')}
        options={VaccineDefaultAmount}
        otherGridWidth={2}
        otherLabel={vaccineDefaultAmount.label || t('Common:OTHER_AMOUNT')}
      />
      {isRabies && (
        <ManualInputTimeDurationSelect
          fields={{
            customUnitId: rabiesTagExpirationUnitId,
            customUnit: rabiesTagExpiration,
            unitId: rabiesTagExpirationOptionId,
          }}
          label={
            rabiesTagExpirationOptionId.label ||
            t('Admin:CATALOG.VACCINATION_COMPOUND_INFO.RABIES_TAG')
          }
          options={RabiesTagDefaultExpiration}
          otherGridWidth={5}
          otherLabel={
            rabiesTagExpiration.label ||
            t('Admin:CATALOG.VACCINATION_COMPOUND_INFO.OTHER_EXPIRATION_DATE')
          }
          unitGridWidth={4}
        />
      )}
    </Grid>
  )
}

export default VaccinationCompoundInfo

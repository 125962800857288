import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { PuiCheckbox } from '@pbt/pbt-ui-components'

import Typography from '~/components/elements/Typography/Typography'
import DialogNames from '~/constants/DialogNames'
import {
  CheckboxGroupState,
  CheckboxOptionState,
  DocumentElementStateItem,
  internalDisplayPreferenceLabel,
} from '~/types'
import useDialog from '~/utils/useDialog'

import { DocumentElementAccordion } from './DocumentElementAccordion'

interface MultipleSelectProps {
  documentElement: CheckboxGroupState
  isDragging?: boolean
  onDelete?: () => void
  onUpdate?: (documentElement: DocumentElementStateItem) => void
}

export const MultipleSelect = ({
  documentElement,
  isDragging,
  onUpdate,
  onDelete,
}: MultipleSelectProps) => {
  const { t } = useTranslation('Common')

  const [openMultipleSelectDialog] = useDialog(
    DialogNames.MULTIPLE_SELECT_DIALOG,
  )

  const getDisplayPreference = (option: CheckboxOptionState) =>
    option?.internalName ||
    internalDisplayPreferenceLabel[option?.internalDisplayPreference]

  return (
    <Box mb={2} px={3}>
      <DocumentElementAccordion
        isDragging={isDragging}
        title={t('Common:CHECK_BOXES')}
        onDelete={onDelete}
        onEdit={
          onUpdate
            ? () =>
                openMultipleSelectDialog({
                  onSave: onUpdate,
                  documentElement,
                })
            : undefined
        }
      >
        <Grid container p={2}>
          <Grid item pb={1} xs={12}>
            <Typography.H2 color="grayGray1">
              {documentElement.label || ''}
            </Typography.H2>
          </Grid>
          {documentElement.options?.map((option) => {
            const displayPreference = getDisplayPreference(option)

            return (
              <Grid container key={option.sequenceNumber}>
                <Grid item>
                  <PuiCheckbox
                    label={option?.name || ''}
                    labelProps={{ sx: { alignItems: 'start' } }}
                    sx={{ paddingY: 0 }}
                  />
                </Grid>
                {Boolean(displayPreference) && (
                  <Grid item pb={1} pl={4} xs={12}>
                    <Typography.Paragraph color="grayGray2">
                      {`${t('Common:RHAPSODY_DISPLAY')}: ${displayPreference}`}
                    </Typography.Paragraph>
                  </Grid>
                )}
              </Grid>
            )
          })}
        </Grid>
      </DocumentElementAccordion>
    </Box>
  )
}

import { all, race, take } from 'redux-saga/effects'

import { tokenHolder } from '~/api/utils/token'
import { auth0Enabled } from '~/utils'

import * as AuthActions from '../actions/types/auth'
import { analyticsSaga as analytics } from '../duck/analytics'
import { automaticCommunicationsSaga as automaticCommunications } from '../duck/automaticCommunications'
import { benchmarkingSaga as benchmarking } from '../duck/benchmarking'
import { benchmarkingPlusSaga as benchmarkingPlus } from '../duck/benchmarkingPlus'
import { boopSaga as boop } from '../duck/boop'
import { ckeditorSaga as ckeditor } from '../duck/ckeditor'
import { conferencingSaga as conferencing } from '../duck/conferencing'
import { documentsV2Saga as documentsV2 } from '../duck/documentsV2'
import { emailAppointmentSaga as emailAppointment } from '../duck/emailAppointment'
import { errorsSaga as errors } from '../duck/errors'
import { filesSaga as files } from '../duck/files'
import { imagingOrdersSaga as imagingOrders } from '../duck/imagingOrders'
import { imagingVendorConfigSaga as imagingVendorConfig } from '../duck/imagingVendorConfig'
import { labelPrintingSaga as labelPrinting } from '../duck/labelPrinting'
import { labOrdersSaga as labOrders } from '../duck/labOrders'
import { labVendorConfigSaga as labVendorConfig } from '../duck/labVendorConfig'
import { lastAppointmentsSaga as lastAppointments } from '../duck/lastAppointments'
// @ts-ignore
import { loyaltyPointsSaga as loyaltyPoints } from '../duck/loyaltyPoints'
import { medicalHistoryCardSaga as medicalHistoryCard } from '../duck/medicalHistoryCard'
// @ts-ignore
import { patientMembershipsSaga as patientMemberships } from '../duck/patientMemberships'
import { pdmpReportSaga as pdmpReport } from '../duck/pdmpReport'
// @ts-ignore
import { practiceExportSaga as practiceExport } from '../duck/practiceExport'
// @ts-ignore
import { practiceImportSaga as practiceImport } from '../duck/practiceImport'
import { prescriptionTemplatesSaga as prescriptionTemplates } from '../duck/prescriptionTemplates'
import { reminderProtocolSettingsSaga as reminderProtocolSettings } from '../duck/reminderProtocolSettings'
// @ts-ignore
import { remindersCatalogSaga as remindersCatalog } from '../duck/remindersCatalog'
import { schedulerSettingsSaga as schedulerSettings } from '../duck/schedulerSettings'
import { shipmentImportSaga as shipmentImport } from '../duck/shipmentImport'
import { soapDiagnosesSaga as soapDiagnoses } from '../duck/soapDiagnoses'
import { vaccinationSaga as vaccination } from '../duck/vaccination'
import { vetcoveConfigSaga as vetcoveConfig } from '../duck/vetcoveConfig'
import { websocketSaga as websocket } from '../duck/websocket'
// @ts-ignore
import { wellnessPlansCatalogSaga as wellnessPlansCatalog } from '../duck/wellnessPlansCatalog'
import addendums from './addendums'
import appointmentTypes from './appointmentTypes'
import auth from './auth'
import availabilityRules from './availabilityRules'
import bulkPrices from './bulkPrices'
import bundles from './bundles'
import businesses from './businesses'
import charges from './charges'
import chargeSheet from './chargeSheet'
import chat from './chat'
import chewyPets from './chewyPets'
import clientBillingActivity from './clientBillingActivity'
import clientPreferences from './clientPreferences'
import clients from './clients'
import communications from './communications'
import connectedDevices from './connectedDevices'
import constants from './constants'
import contacts from './contacts'
import conversationMessages from './conversationMessages'
import conversations from './conversations'
import coparents from './coparents'
import departments from './departments'
import discountGroups from './discountGroups'
import discountReasons from './discountReasons'
import documents from './documents'
import finance from './finance'
import globalInventoryCatalog from './globalInventoryCatalog'
import imagingDashboard from './imagingDashboard'
import inventories from './inventories'
import inventoryAdjustments from './inventoryAdjustments'
import invoiceV3 from './invoiceV3'
import labTests from './labTests'
import labTestsDashboard from './labTestsDashboard'
import landing from './landing'
import marketplace from './marketplace'
import medicalHistory from './medicalHistory'
import members from './members'
import membershipPayments from './membershipPayments'
import migration from './migration'
import migrationV2 from './migrationV2'
import monitors from './monitors'
import notifications from './notifications'
import onHandCatalog from './onHandCatalog'
import orders from './orders'
import patientPreferences from './patientPreferences'
import patients from './patients'
import pausedPatientMemberships from './pausedPatientMemberships'
import payments from './payments'
import { prescriptionsSaga as prescriptions } from './prescriptions'
import { problemSaga as problems } from './problems'
import procedures from './procedures'
import questions from './questions'
import refunds from './refunds'
import registration from './registration'
import reminderProtocols from './reminderProtocols'
import reminderProtocolsV2 from './reminderProtocolsV2'
import reminders from './reminders'
import reportCard from './reportCard'
import rhapsodyPay from './rhapsodyPay'
import roles from './roles'
import scheduler from './scheduler'
import search from './search'
import shipmentItems from './shipmentItems'
import shipments from './shipments'
import soap from './soap'
import soapV2 from './soapV2'
import spaces from './spaces'
import supportUsers from './supportUsers'
import tasks from './tasks'
import timeline from './timeline'
import timetable from './timetable'
import timeTracker from './timeTracker'
import users from './users'
import variations from './variations'
import { vitalsSaga as vitals } from './vitals'
import wellnessPlans from './wellnessPlans'
import whiteboard from './whiteboard'

export default function* rootSaga(): any {
  while (true) {
    const [cancel] = yield race([
      take(AuthActions.LOGOUT),
      all([
        addendums(),
        appointmentTypes(),
        analytics(),
        auth(),
        automaticCommunications(),
        availabilityRules(),
        clientBillingActivity(),
        benchmarking(),
        benchmarkingPlus(),
        boop(),
        bulkPrices(),
        bundles(),
        businesses(),
        charges(),
        chargeSheet(),
        chat(),
        chewyPets(),
        ckeditor(),
        clientPreferences(),
        clients(),
        communications(),
        conferencing(),
        connectedDevices(),
        constants(),
        contacts(),
        conversationMessages(),
        conversations(),
        coparents(),
        departments(),
        discountGroups(),
        discountReasons(),
        documents(),
        documentsV2(),
        emailAppointment(),
        errors(),
        files(),
        finance(),
        globalInventoryCatalog(),
        imagingDashboard(),
        imagingOrders(),
        imagingVendorConfig(),
        inventories(),
        inventoryAdjustments(),
        invoiceV3(),
        labelPrinting(),
        labOrders(),
        labTests(),
        labTestsDashboard(),
        labVendorConfig(),
        landing(),
        lastAppointments(),
        loyaltyPoints(),
        marketplace(),
        medicalHistory(),
        medicalHistoryCard(),
        members(),
        membershipPayments(),
        migration(),
        migrationV2(),
        monitors(),
        notifications(),
        onHandCatalog(),
        orders(),
        patientMemberships(),
        patientPreferences(),
        pausedPatientMemberships(),
        patients(),
        payments(),
        pdmpReport(),
        practiceExport(),
        practiceImport(),
        prescriptions(),
        prescriptionTemplates(),
        problems(),
        procedures(),
        questions(),
        refunds(),
        registration(),
        reminderProtocols(),
        reminderProtocolsV2(),
        reminderProtocolSettings(),
        reminders(),
        remindersCatalog(),
        reportCard(),
        rhapsodyPay(),
        roles(),
        scheduler(),
        schedulerSettings(),
        search(),
        shipmentImport(),
        shipmentItems(),
        shipments(),
        soap(),
        soapDiagnoses(),
        soapV2(),
        spaces(),
        supportUsers(),
        tasks(),
        timeline(),
        timetable(),
        timeTracker(),
        users(),
        vaccination(),
        variations(),
        vetcoveConfig(),
        vitals(),
        websocket(),
        wellnessPlans(),
        wellnessPlansCatalog(),
        whiteboard(),
      ]),
    ])

    // if cancelled because of LOGOUT action, clear tokens
    if (!auth0Enabled && cancel) {
      tokenHolder.setToken(null)
    }
  }
}

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AppointmentEventType,
  LanguageUtils,
  LinkButton,
  moment,
  Nil,
  PermissionArea,
  Utils,
} from '@pbt/pbt-ui-components'

import { AppointmentHistoryLabel } from '~/components/common/labels/AppointmentHistoryLabel'
import ItemHistoryLabel from '~/components/common/labels/ItemHistoryLabel'
import Link from '~/components/common/link/Link'
import { AppointmentTypeName } from '~/constants/appointmentTypes'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getEventType, getFeatureToggle } from '~/store/reducers/constants'
import { AppointmentTimelineItem, AppointmentTimelineItemEntity } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import {
  getLogIdFromTimelineAppoitment,
  getLogTypeFromTimelineAppoitment,
} from '~/utils/timeline'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'
import MultipleSoapAppointment from './MultipleSoapAppointment'
import SingleSoapAppointment from './SingleSoapAppointment'

const useStyles = makeStyles(
  (theme) => ({
    actionsRoot: {
      paddingBottom: 0,
      minHeight: theme.spacing(5),
    },
  }),
  { name: 'AppointmentCard' },
)

interface AppointmentCardProps extends TimelineCardProps {
  clientId: string
  item: AppointmentTimelineItem
  patientId: string
}

const AppointmentCard = ({
  item,
  clientId,
  patientId,
  ...rest
}: AppointmentCardProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const EventType = useSelector(getEventType)
  const soapPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isAppointmentCancellationReasonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_CANCELLATION_REASON),
  )

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const onViewInvoiceClick = useCallback(() => {
    const logId = getLogIdFromTimelineAppoitment(item) as unknown as
      | string
      | Nil
    const logType = getLogTypeFromTimelineAppoitment(item) as unknown as
      | string
      | Nil
    openInvoice({
      clientId,
      patientId,
      invoiceId: item.invoiceId,
      soapId: R.pipe(R.prop('entries'), R.head, R.prop('soapId'))(item),
      logId,
      logType,
    })
  }, [clientId, patientId, item.invoiceId])

  const hasOneSoap = item.entries?.length === 1 && item.entries[0]?.soapId
  const hasMultipleSoaps = item.entries?.length > 1
  const hasNoSoap = !hasOneSoap && !hasMultipleSoaps

  const isContextItem = useIsCurrentContextItem(item)
  const hasSoap = R.pipe(
    R.prop<AppointmentTimelineItemEntity[]>('entries'),
    R.any(R.pipe(R.prop('soapId'), R.complement(R.either(R.isNil, R.isEmpty)))),
  )(item)
  const hasInvoice = Boolean(item.invoiceId)
  const isPastAppointment = moment().isAfter(item.date, 'days')

  const AppointmentEvent: AppointmentEventType = Utils.findConstantByName(
    'Appointment',
    EventType,
  )

  const appointmentTypeColor = item.businessAppointmentType?.color

  const title = item.businessAppointmentType?.name

  const appointmentState =
    AppointmentEvent && Utils.findById(item.state, AppointmentEvent.states)

  const originalBusinessId = isPatientSharingEnabled ? item.businessId : null
  const showCreateSoapLink =
    hasNoSoap &&
    !isPastAppointment &&
    soapPermissions.create &&
    item.appointmentTypeName !== AppointmentTypeName.OTC_SALE &&
    isContextItem

  return (
    <TimelineCard
      doubleMargin={hasMultipleSoaps}
      originBusinessId={item.businessId}
      title={title}
      titlePinColor={appointmentTypeColor}
      {...item}
      {...rest}
    >
      <TimelineCardContent noTypography noPadding={hasMultipleSoaps}>
        {hasMultipleSoaps ? (
          <MultipleSoapAppointment
            striped
            clientId={clientId}
            item={item}
            openInvoice={openInvoice}
            patientId={patientId}
          />
        ) : (
          <SingleSoapAppointment
            clientId={clientId}
            item={item}
            patientId={patientId}
          />
        )}
      </TimelineCardContent>
      <TimelineCardActions
        classes={{
          root: classes.actionsRoot,
        }}
        state={
          appointmentState &&
          LanguageUtils.getTranslatedFieldName(appointmentState)
        }
      >
        {(hasInvoice || hasSoap) &&
          invoicePermissions.read &&
          isContextItem &&
          !hasMultipleSoaps && (
            <LinkButton onClick={onViewInvoiceClick}>
              {t('Common:VIEW_INVOICE')}
            </LinkButton>
          )}
        {showCreateSoapLink && (
          <Link
            to={addOriginalBusinessId(
              `/soap/create/${item.id}`,
              originalBusinessId,
            )}
          >
            {t('Common:CREATE_SOAP')}
          </Link>
        )}
        {hasMultipleSoaps &&
          !isPastAppointment &&
          soapPermissions.create &&
          isContextItem && (
            <Link
              to={addOriginalBusinessId(
                `/soap/add/${item.id}`,
                originalBusinessId,
              )}
            >
              {t('Common:ADD_SOAP')}
            </Link>
          )}
        {hasOneSoap && soapPermissions.read && (
          <Link
            to={addOriginalBusinessId(
              `/soap/${item.entries[0].soapId}`,
              originalBusinessId,
            )}
          >
            {t('Common:VIEW_SOAP')}
          </Link>
        )}
      </TimelineCardActions>
      <Grid container pb={1.5} px={2}>
        {isAppointmentCancellationReasonEnabled ? (
          <AppointmentHistoryLabel
            item={{
              ...item,
              cancellationReason: item.appointmentCancellationReason,
              cancellationReasonId: item.appointmentCancellationReasonId,
              stateId: item.state,
            }}
          />
        ) : (
          <ItemHistoryLabel item={item} />
        )}
      </Grid>
    </TimelineCard>
  )
}

export default AppointmentCard

import LabVendorNames from '~/constants/labVendorName'
import i18n from '~/locales/i18n'

export enum MemberDetailsPanels {
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  LICENSES = 'LICENSES',
  NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS',
  ROLES = 'ROLES',
  TIME_TRACKING = 'TIME_TRACKING',
  LOCK_ACCESS = 'LOCK_ACCESS',
  ZOETIS_REFERENCE = 'ZOETIS_REFERENCE',
  USER_DEPARTMENT = 'USER_DEPARTMENT',
}

export const MemberDetailsPanelsList = Object.values(MemberDetailsPanels)

export const MemberDetailsPanelsLabels = {
  [MemberDetailsPanels.EMPLOYEE_ID]: i18n.t(
    'Admin:MEMBER.DETAILS_LABEL.EMPLOYEE_ID',
  ),
  [MemberDetailsPanels.LICENSES]: i18n.t('Admin:MEMBER.LICENSE.LICENSES'),
  [MemberDetailsPanels.NOTIFICATION_SETTINGS]: i18n.t(
    'Common:NOTIFICATION_SETTINGS',
  ),
  [MemberDetailsPanels.ROLES]: i18n.t('Admin:MEMBER.DETAILS_LABEL.ROLES'),
  [MemberDetailsPanels.TIME_TRACKING]: i18n.t(
    'Admin:MEMBER.DETAILS_LABEL.TIME_TRACKING',
  ),
  [MemberDetailsPanels.LOCK_ACCESS]: i18n.t(
    'Admin:MEMBER.DETAILS_LABEL.LOCK_ACCESS',
  ),
  [MemberDetailsPanels.ZOETIS_REFERENCE]: LabVendorNames.ZN_LABS,
  [MemberDetailsPanels.USER_DEPARTMENT]: i18n.t('Admin:PRACTICE.DEPARTMENT'),
}

/* eslint-disable simple-import-sort/imports */
// english namespaces imports
import AbbreviationsEnUS from './en-US/Abbreviations.json'
import AdminEnUS from './en-US/Admin.json'
import AuthEnUS from './en-US/Auth.json'
import BusinessesEnUS from './en-US/Businesses.json'
import ClientsEnUS from './en-US/Clients.json'
import CommonEnUS from './en-US/Common.json'
import CommunicationsEnUS from './en-US/Communications.json'
import ConstantsEnUS from './en-US/Constants.json'
import DialogsEnUS from './en-US/Dialogs.json'
import ErrorsEnUS from './en-US/Errors.json'
import InvoicesEnUS from './en-US/Invoices.json'
import FinanceEnUS from './en-US/Finance.json'
import LabTestsEnUS from './en-US/LabTests.json'
import LandingEnUS from './en-US/Landing.json'
import MedicalHistoryEnUS from './en-US/MedicalHistory.json'
import MembershipsEnUS from './en-US/Memberships.json'
import MenuEnUS from './en-US/Menu.json'
import MockEnUS from './en-US/Mock.json'
import NotificationsEnUS from './en-US/Notifications.json'
import PageTitlesEnUs from './en-US/PageTitles.json'
import PaymentsEnUS from './en-US/Payments.json'
import PluralsEnUS from './en-US/Plurals.json'
import PricesEnUS from './en-US/Prices.json'
import ProfileEnUS from './en-US/Profile.json'
import RegistrationEnUS from './en-US/Registration.json'
import RemindersEnUS from './en-US/Reminders.json'
import SearchEnUS from './en-US/Search.json'
import SoapEnUS from './en-US/Soap.json'
import TasksEnUS from './en-US/Tasks.json'
import TermsAndConditionsUSAEnUS from './en-US/TermsAndConditionsUSA.json'
import TermsAndConditionsUSAEulaV2EnUS from './en-US/TermsAndConditionsUSAEulaV2.json'
import TermsAndConditionsUSAEulaV3EnUS from './en-US/TermsAndConditionsUSAEulaV3.json'
import TermsAndConditionsSpainEnUS from './en-US/TermsAndConditionsSpain.json'
import PrivacyNoticeUSAEnUS from './en-US/PrivacyNoticeUSA.json'
import PrivacyNoticeSpainEnUS from './en-US/PrivacyNoticeSpain.json'
import TimeEnUS from './en-US/Time.json'
import TimeTableEnUS from './en-US/TimeTable.json'
import TooltipsEnUS from './en-US/Tooltips.json'
import UtilsEnUS from './en-US/Utils.json'
import ValidationsEnUS from './en-US/Validations.json'
import WatcherEnUS from './en-US/Watcher.json'
import WellnessPlansEnUS from './en-US/WellnessPlans.json'

export const TRANSLATIONS_EN_US = {
  Abbreviations: AbbreviationsEnUS,
  Admin: AdminEnUS,
  Auth: AuthEnUS,
  Businesses: BusinessesEnUS,
  Clients: ClientsEnUS,
  Common: CommonEnUS,
  Communications: CommunicationsEnUS,
  Constants: ConstantsEnUS,
  Dialogs: DialogsEnUS,
  Errors: ErrorsEnUS,
  Invoices: InvoicesEnUS,
  Finance: FinanceEnUS,
  LabTests: LabTestsEnUS,
  Landing: LandingEnUS,
  MedicalHistory: MedicalHistoryEnUS,
  Memberships: MembershipsEnUS,
  Menu: MenuEnUS,
  Mock: MockEnUS,
  Notifications: NotificationsEnUS,
  PageTitles: PageTitlesEnUs,
  Payments: PaymentsEnUS,
  Plurals: PluralsEnUS,
  Prices: PricesEnUS,
  Profile: ProfileEnUS,
  Registration: RegistrationEnUS,
  Reminders: RemindersEnUS,
  Search: SearchEnUS,
  Soap: SoapEnUS,
  Tasks: TasksEnUS,
  TermsAndConditionsUSA: TermsAndConditionsUSAEnUS,
  TermsAndConditionsUSAEulaV2: TermsAndConditionsUSAEulaV2EnUS,
  TermsAndConditionsUSAEulaV3: TermsAndConditionsUSAEulaV3EnUS,
  TermsAndConditionsSpain: TermsAndConditionsSpainEnUS,
  PrivacyNoticeUSA: PrivacyNoticeUSAEnUS,
  PrivacyNoticeSpain: PrivacyNoticeSpainEnUS,
  Time: TimeEnUS,
  TimeTable: TimeTableEnUS,
  Tooltips: TooltipsEnUS,
  Utils: UtilsEnUS,
  Validations: ValidationsEnUS,
  Watcher: WatcherEnUS,
  WellnessPlans: WellnessPlansEnUS,
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { deleteChargeSheetItems } from '~/store/duck/clientFinanceData'
import { getCurrentUserId } from '~/store/reducers/auth'
import { InvoiceLineItem } from '~/types'

const useStyles = makeStyles(
  () => ({
    paper: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
  { name: 'DeleteLineItemDialog' },
)

interface DeleteLineItemDialogProps extends BasePuiDialogProps {
  clientId: string
  lineItems: (InvoiceLineItem | RetailOrderLineItem)[]
}

export const DeleteLineItemDialog = ({
  onClose,
  lineItems,
  clientId,
  open,
}: DeleteLineItemDialogProps) => {
  const { t } = useTranslation('Common')
  const classes = useStyles()
  const dispatch = useDispatch()
  const modifierId = useSelector(getCurrentUserId)

  const onAdd = () => {
    if (modifierId && lineItems) {
      const deleteItems = lineItems.map((lineItem) => ({
        expectedModificationDate: lineItem.modificationDate,
        id: lineItem.id,
      }))
      dispatch(
        deleteChargeSheetItems({
          items: deleteItems,
          modifierId,
          clientId,
        }),
      )
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <ButtonWithLoader onClick={onAdd}>
          {t('Common:DELETE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="delete-corrupted-line-item-dialog"
      classes={{
        paper: classes.paper,
      }}
      maxWidth="md"
      open={open}
      title={t('Common:CHARGES_HAS_ISSUE_TITLE')}
      onClose={onClose}
    >
      <Grid
        container
        item
        alignItems="center"
        direction="column"
        justifyContent="flex-end"
        p={2}
        spacing={1}
        wrap="nowrap"
      >
        <Grid container item>
          {t('Common:CHARGES_HAS_ISSUE_WANT_TO_DELETE')}
        </Grid>
        {lineItems.map((lineItem) => (
          <Grid container item key={lineItem.id}>
            {lineItem.name}
          </Grid>
        ))}
      </Grid>
    </PuiDialog>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  CurrencyTextField,
  CustomFieldValidatorState,
  Field,
  PuiTextField,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import { RefundPaymentMethods } from '~/constants/financeConstants'
import { handleNumberInput } from '~/utils'

const useStyles = makeStyles(
  (theme) => ({
    currencyFieldRefund: {
      maxWidth: 100,
      paddingLeft: theme.spacing(1),
    },
    refundCheckNumber: {
      maxWidth: 100,
      paddingLeft: theme.spacing(1),
    },
    currencyTextField: {
      textAlign: 'right',
    },
    currencyTextFieldBold: {
      fontWeight: 500,
    },
  }),
  { name: 'PaymentInvoiceRefundPaymentSelection' },
)

interface PaymentInvoiceRefundPaymentAmountInputProps {
  fields: {
    invoiceRefundMethod: Field
    notes: Field
  }
  initialValue?: number
  isLoading: boolean
  maxRefund: number
  onSetRefundValue: (value: number) => void
}

export const PaymentInvoiceRefundPaymentAmountInput = ({
  isLoading,
  initialValue = 0,
  fields: { invoiceRefundMethod, notes },
  maxRefund,
  onSetRefundValue,
}: PaymentInvoiceRefundPaymentAmountInputProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Errors', 'Invoices', 'Validations'])

  const maxRefundValue = ({
    state: { inputValue },
  }: CustomFieldValidatorState) => inputValue <= maxRefund

  const {
    fields: { inputValue },
    validate,
  } = useFields(
    [
      {
        name: 'inputValue',
        initialValue,
        validators: [
          'greaterThanZero',
          { validator: maxRefundValue, validatorName: 'maxRefundValue' },
        ],
        messages: {
          maxRefundValue: t('Validations:INCORRECT_REFUND'),
        },
      },
    ],
    false,
  )

  return (
    <Grid container>
      <Grid container item alignItems="baseline" justifyContent="space-between">
        <Text variant="body">{t('Common:PAYMENTS.REFUND_AMOUNT')}:</Text>
        <CurrencyTextField
          className={classes.currencyFieldRefund}
          disabled={isLoading}
          field={{
            ...inputValue,
            set: handleNumberInput(
              (value: string) => {
                const formattedValue = parseFloat(value) || 0
                inputValue.setValue(formattedValue)
                onSetRefundValue(formattedValue)
                validate()
              },
              7,
              2,
              true,
            ),
          }}
          inputProps={{
            className: classNames(
              classes.currencyTextField,
              classes.currencyTextFieldBold,
            ),
          }}
        />
      </Grid>
      {invoiceRefundMethod.value === RefundPaymentMethods.CHECK && (
        <Grid container>
          <Grid
            container
            item
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Text variant="body">
              {t('Common:PAYMENTS.REFUNDS_CHECK_NUMBER')}:
            </Text>
            <PuiTextField
              shrink
              className={classes.refundCheckNumber}
              field={notes}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

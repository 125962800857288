import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, PermissionArea, Utils } from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { getEventState } from '~/store/reducers/constants'
import {
  getAppointmentId,
  getIsCurrentContextSoap,
  getIsFinalized,
} from '~/store/reducers/soap'
import {
  getTimetableEvent,
  getTimetableIsLoading,
} from '~/store/reducers/timetable'

import AttachmentsWidget from '../../soapV2/attachments/AttachmentsWidget'
import AppointmentStatusSelect from '../appointment/AppointmentStatusSelect'
import { useAppointmentStatusChange } from '../useAppointmentStatusChange'
import ChargeSheetSection from './chargeSheetSection/ChargeSheetSection'

const useStyles = makeStyles(
  (theme) => ({
    appointmentContainer: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    appointmentStatusContainer: {
      borderBottom: theme.constants.tableBorder,
    },
    paper: {
      width: 650,
      maxWidth: 650,
    },
    button: {
      height: 42,
      minWidth: 172,
    },
    hidden: {
      display: 'none',
    },
    smallPaper: {
      width: 650,
      height: 270,
      maxWidth: 650,
    },
  }),
  { name: 'CheckOutPage' },
)

const CheckOutPage = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])

  const EventState = useSelector(getEventState)
  const appointmentId = useSelector(getAppointmentId)
  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const isLoading = useSelector(getTimetableIsLoading)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )

  const dischargedId = Utils.findConstantIdByName('Discharged', EventState)

  const appointmentUpdateDisabled = !appointmentPermissions.update

  const isReadOnly = isSoapFinalized || !isCurrentContextSoap

  const { onHandleStatusChange, appointmentStatus } =
    useAppointmentStatusChange(appointment)

  return (
    <>
      <Grid container item mt={2}>
        <AttachmentsWidget />
      </Grid>
      {appointment && (
        <Grid
          container
          item
          className={classes.appointmentContainer}
          direction="column"
          mt={2}
        >
          {!isReadOnly && (
            <Grid
              container
              item
              className={classes.appointmentStatusContainer}
              p={1}
            >
              <Grid container item alignItems="center">
                <AppointmentStatusSelect
                  disabled={appointmentUpdateDisabled}
                  field={appointmentStatus}
                  label={appointmentStatus.label}
                />
              </Grid>
            </Grid>
          )}
          {!isReadOnly && appointment.state?.id !== dischargedId && (
            <Grid container item px={2} py={1}>
              <ButtonWithLoader
                className={classes.button}
                disabled={isLoading || appointmentUpdateDisabled}
                loading={isLoading}
                type="submit"
                onClick={() => onHandleStatusChange(dischargedId)}
              >
                {t('Soap:CHECKOUT_PAGE.DISCHARGE_PATIENT')}
              </ButtonWithLoader>
            </Grid>
          )}
        </Grid>
      )}
      <ChargeSheetSection />
    </>
  )
}

export default CheckOutPage

import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Constant } from '@pbt/pbt-ui-components'

import {
  OutlinedSelect,
  OutlinedSelectProps,
} from '~/components/elements/OutlinedSelect/OutlinedSelect'
import { CardPaymentMethodOrMissingReason } from '~/types/entities/cardPaymentMethodOrMissingReason'

import { ChewyCardPaymentMethodFormatted } from './ChewyCardPaymentMethodFormatted'

const useStyles = makeStyles(
  (themes) => ({
    outLineSelectColor: {
      backgroundColor: themes.colors.grayWhite,
    },
  }),
  { name: 'AppointmentCancellationConsentMessage' },
)

type ChewyCardPaymentMethodOnlySelectProps = Omit<
  OutlinedSelectProps<CardPaymentMethodOrMissingReason | ''>,
  'label' | 'id'
>

export const ChewyCardPaymentMethodOnlySelect = (
  props: ChewyCardPaymentMethodOnlySelectProps,
) => {
  const { t } = useTranslation(['Common'])
  const classes = useStyles()

  const formatCancellationReason = (
    appointmentMissingPaymentMethodReasonConstant: Constant,
  ) => appointmentMissingPaymentMethodReasonConstant.nameTranslation
  return (
    <OutlinedSelect
      className={classes.outLineSelectColor}
      emptyValue=""
      id="chewy-payment-method-select"
      label={t('Common:CHOOSE_CARD_TO_HOLD_APPOINTMENT')}
      renderOption={(value) =>
        typeof value === 'object' &&
        '__typename' in value &&
        value.__typename === 'CardPaymentMethod' ? (
          <ChewyCardPaymentMethodFormatted cardPaymentMethod={value} />
        ) : (
          formatCancellationReason(value as Constant)
        )
      }
      {...props}
    />
  )
}

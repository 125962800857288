import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  LinkButton,
  Nil,
  NumberUtils,
  PermissionArea,
  Text,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  getCRUDByArea,
  getCurrentBusinessWellnessPlansEnabled,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getUserFirstName } from '~/store/reducers/users'
import { WellnessCoverage } from '~/types'
import useDialog from '~/utils/useDialog'

import { getPatientHasMembership } from '../wellness-plans/wellnessPlanUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minHeight: 40,
      padding: theme.spacing(0, 3),
      boxShadow: '3px 3px 20px 0 rgba(168,163,163,0.5)',
    },
    rootMembership: {
      backgroundColor: theme.colors.planBranding,
    },
    rootNoMembership: {
      backgroundColor: theme.colors.secondaryText,
    },
    text: {
      color: theme.colors.profileText,
    },
    button: {
      fontSize: '1.4rem',
      color: theme.colors.profileText,
      '&:not(:last-child)': {
        marginRight: 0,
      },
      minWidth: 'fit-content',
    },
  }),
  { name: 'MembershipSavingsBanner' },
)

export interface MembershipSavingsBannerProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  clientId?: string | Nil
  hideIfHasMembership?: boolean
  hideLink?: boolean
  patientId?: string | Nil
  savingsDialogProps?: any
  wellnessCoverage?: WellnessCoverage
}

const MembershipSavingsBanner = ({
  hideLink = false,
  hideIfHasMembership = false,
  className,
  classes: classesProp,
  clientId,
  patientId,
  wellnessCoverage,
  savingsDialogProps = {},
}: MembershipSavingsBannerProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Memberships', 'Time'])

  const patient = useSelector(getPatient(patientId))
  const name = useSelector(getUserFirstName(clientId))
  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )
  const { create: wellnessPlanCreatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.WELLNESS_PLAN),
  )

  const [openMembershipSignUpDialog] = useDialog(DialogNames.MEMBERSHIP_SIGN_UP)
  const [openWellnessPlanSavingsDialog] = useDialog(
    DialogNames.WELLNESS_PLAN_SAVINGS,
  )

  const hasMembership = getPatientHasMembership(patient)

  const handleSignUpPatient = () => {
    openMembershipSignUpDialog({
      clientId,
      patientId,
    })
  }

  const handleSavingsLink = () => {
    openWellnessPlanSavingsDialog({
      clientId,
      ...savingsDialogProps,
    })
  }

  const getRealCoverageString = () =>
    [
      wellnessCoverage?.real?.savedAmount &&
        t('Memberships:MEMBERSHIP_SAVINGS_BANNER.REAL_COVERAGE.SAVED', {
          name,
          amount: NumberUtils.formatMoney(wellnessCoverage?.real?.savedAmount),
        }),
      wellnessCoverage?.real?.savedAmount &&
        wellnessCoverage?.real?.earnedPoints &&
        t('Common:AND').toLowerCase(),
      wellnessCoverage?.real?.earnedPoints &&
        t('Memberships:EARNED_LOYALTY_POINTS', {
          earnedPoints: wellnessCoverage?.real?.earnedPoints,
        }),
      (wellnessCoverage?.real?.savedAmount ||
        wellnessCoverage?.real?.earnedPoints) &&
        `${t('Time:LABEL.TODAY').toLowerCase()}!`,
    ]
      .filter(Boolean)
      .join(' ')

  const getPotentialCoverageString = () =>
    [
      wellnessCoverage?.potential?.savedAmount &&
        [
          `${name} `,
          <Link
            color="inherit"
            component="button"
            key="link"
            sx={{ cursor: hideLink ? 'none' : 'pointer' }}
            underline={hideLink ? 'none' : 'always'}
            variant="body2"
            onClick={hideLink ? undefined : handleSavingsLink}
          >
            {t(
              'Memberships:MEMBERSHIP_SAVINGS_BANNER.POTENTIAL_COVERAGE.SAVED',
              {
                savedAmount: NumberUtils.formatMoney(
                  wellnessCoverage?.potential?.savedAmount,
                ),
              },
            )}
          </Link>,
        ].filter(Boolean),
      wellnessCoverage?.potential?.savedAmount &&
        wellnessCoverage?.potential?.earnedPoints &&
        ` ${t('Common:AND').toLowerCase()}`,
      wellnessCoverage?.potential?.earnedPoints &&
        ` ${t(
          'Memberships:MEMBERSHIP_SAVINGS_BANNER.POTENTIAL_COVERAGE.EARNED',
          {
            earnedPoints: wellnessCoverage?.potential?.earnedPoints,
          },
        )}`,
      (wellnessCoverage?.potential?.savedAmount ||
        wellnessCoverage?.potential?.earnedPoints) &&
        ` ${t(
          'Memberships:MEMBERSHIP_SAVINGS_BANNER.POTENTIAL_COVERAGE.TODAY_WITH_WELLNESS_PLAN',
        )}`,
    ].filter(Boolean)

  const coverageString = hasMembership
    ? getRealCoverageString()
    : getPotentialCoverageString()

  const shouldHide = hideIfHasMembership && hasMembership
  const hasCoverage = coverageString && coverageString.length > 0

  if (!name || !wellnessPlansEnabled || !hasCoverage || shouldHide) {
    return null
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(className, classes.root, {
        [classes.rootMembership]: hasMembership,
        [classes.rootNoMembership]: !hasMembership,
      })}
      wrap="nowrap"
    >
      <Text className={classes.text}>{coverageString}</Text>
      {!hasMembership && (
        <LinkButton
          className={classes.button}
          disabled={!wellnessPlanCreatePermissions}
          onClick={
            wellnessPlanCreatePermissions ? handleSignUpPatient : undefined
          }
        >
          {isBoopDisablementEnabled
            ? t('Common:ADD_PLAN')
            : t('Common:SIGN_UP_PATIENT')}
        </LinkButton>
      )}
    </Grid>
  )
}

export default MembershipSavingsBanner

import React, { useEffect, useRef, useState } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import WebFont from 'webfontloader'
import { Nil } from '@pbt/pbt-ui-components'
import { isIPad } from '@pbt/pbt-ui-components/src/utils/browserUtils'

import PrintIFrame from './PrintIFrame'

export interface PrintHtmlProps {
  name?: string
  onOk?: () => void
  pageMargins?: string
  pageSize?: string
  source?:
    | string
    | React.ReactElement<any, React.JSXElementConstructor<any>>
    | Nil
}

const PrintHtml = ({
  pageMargins = '0',
  pageSize,
  source,
  name,
  onOk,
  ...rest
}: PrintHtmlProps) => {
  const [opened, setOpened] = useState(false)

  const frameRef = useRef<HTMLIFrameElement>(null)

  const postMessage = () => {
    // Sending message up if we are inside the WebKit WebView
    // @ts-ignore
    const printHandler = window.webkit?.messageHandlers?.printHandler
    if (printHandler) {
      if (typeof source === 'object') {
        printHandler.postMessage({ json: JSON.stringify(source?.props) })
      } else if (typeof source === 'string') {
        if (source.indexOf('</html>') !== -1) {
          printHandler.postMessage({ html: source })
        } else {
          printHandler.postMessage({ text: source })
        }
      }
    }
  }

  const print = () => {
    const isPlainHtml = typeof source === 'string'
    const contentDocument = frameRef.current?.contentDocument as Document
    const contentWindow = frameRef.current?.contentWindow as Window

    const head = contentDocument.getElementsByTagName('head')[0]
    const originalTitle = document.title
    document.title = name || document.title
    contentDocument.title = name || document.title

    const style = contentDocument.createElement('style')
    style.setAttribute('media', 'print')
    style.setAttribute('type', 'text/css')
    style.innerText = `
      @page {
        size: ${pageSize || 'auto'};
        margin: ${pageMargins};
      }
      body {
        font-family: Rubik;
      }

      pre {
        white-space: pre-wrap;
      }
    `
    head.appendChild(style)
    const container = contentDocument.createElement('div')
    contentDocument.body.appendChild(container)
    const root = ReactDOMClient.createRoot(container)

    if (isPlainHtml) {
      contentDocument.body.innerHTML = source
    } else {
      /**
       * Using a unique key to force React to remount the content.
       * Content is being refreshed even if reconciliation skip updates.
       */
      root.render(<div key={Date.now()}>{source}</div>)
    }

    const images = Array.from(
      contentDocument.getElementsByTagName('img'),
    ) as HTMLImageElement[]

    const doPrint = () => {
      postMessage()

      if (isIPad()) {
        const newWindow = window.open() as WindowProxy

        newWindow.document.write(contentDocument.body.innerHTML)
        newWindow.document.close()

        newWindow.focus()
        newWindow.onafterprint = () => {
          newWindow.close()
          if (onOk) {
            onOk()
          }
        }
        newWindow.print()
      } else {
        contentWindow.focus()
        contentWindow.print()
      }
    }

    const onImageLoad = () => {
      const loadedAll = images.every(
        (img) => img.src.startsWith('data:') || img.complete,
      )

      if (loadedAll) {
        doPrint()
      }
    }

    const loadImagesAndPrint = () => {
      if (images.length > 0) {
        images.forEach((img) => {
          img.onload = onImageLoad
        })
        onImageLoad()
      } else {
        doPrint()
      }
    }

    WebFont.load({
      google: {
        families: ['Rubik:300,400,500,700&display=swap'],
      },
      // @ts-ignore
      context: contentWindow,
      active: loadImagesAndPrint,
      inactive: loadImagesAndPrint,
    })

    contentWindow.onafterprint = () => {
      contentWindow.onafterprint = null
      document.title = originalTitle
      // Note: if call next lines directly, browser page will freeze
      window.setTimeout(() => {
        setOpened(false)
        root.unmount()
        if (container.parentNode === contentDocument.body) {
          contentDocument.body.removeChild(container)
        }
        if (onOk) {
          onOk()
        }
      })
    }
  }

  useEffect(() => {
    if (source && !opened) {
      setOpened(true)
      print()
    }
  }, [source])

  return <PrintIFrame ref={frameRef} {...rest} />
}

export default PrintHtml

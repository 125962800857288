import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Schedule as ScheduleIcon,
  TodayOutlined as TodayOutlinedIcon,
} from '@mui/icons-material'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Calendar,
  CustomFieldValidatorState,
  DateUtils,
  LanguageUtils,
  moment,
  Nil,
  PermissionArea,
  PuiCheckbox,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { OrderedWeekConstants } from '@pbt/pbt-ui-components/src/localization'

import WeekdaysButtonGroup from '~/components/common/buttons/WeekdaysButtonGroup'
import QuantityInput from '~/components/common/inputs/QuantityInput'
import TimeSelector from '~/components/common/inputs/time-selector/TimeSelector'
import TimeUnitsSelect from '~/components/common/TimeUnitsSelect'
import TimezoneWarningLabel from '~/components/dashboard/alerts/TimezoneWarningLabel'
import { NumberStepper } from '~/components/elements/NumberStepper/NumberStepper'
import { AppointmentTypeName } from '~/constants/appointmentTypes'
import FeatureToggle from '~/constants/featureToggle'
import { getAppointmentType } from '~/store/reducers/appointmentTypes'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getEventType,
  getFeatureToggle,
  getTimeUnits,
} from '~/store/reducers/constants'
import { DataHandle, ReleasePeriodSpecifier, TimetableEvent } from '~/types'
import {
  aggregateDate,
  aggregateStartAndEndDates,
  getAppointmentEndTime,
  getAppointmentRecurrenceTimeUnitConstant,
  getIsMultidayEvent,
  roundTime,
  TimeUnitDiffMap,
  TimeUnits as TimeUnitsEnum,
} from '~/utils/time'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'
import useFieldsChanged, { FieldCache } from '~/utils/useFieldsChanged'
import useTimetableDate from '~/utils/useTimetableDate'

import TimetableCalendarDayPicker from '../../TimetableCalendarDayPicker'
import TimetableTag from '../../TimetableTag'
import {
  getAppointmentHasFinalizedSoap,
  getIsRepeatedAppointment,
  useGetIsBusyType,
  useGetIsReservedType,
} from './appointmentUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    icon: {
      color: theme.colors.selectedOption,
    },
    emptyTimezoneContainer: {
      width: 15,
    },
    checkboxRoot: {
      paddingRight: theme.spacing(0.5),
    },
    checkbox: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'AppointmentDateSection' },
)

const AllowedRepeatUnits = ['Day', 'Week', 'Month']
const AllowedReleaseUnits = ['Hour', 'Minute']

const ReleaseUnitMap: Record<string, ReleasePeriodSpecifier> = {
  Hour: 'H',
  Minute: 'M',
}

const ReleaseUnitReverseMap: Record<ReleasePeriodSpecifier, string> = {
  H: 'Hour',
  M: 'Minute',
}

export interface AppointmentDateSectionProps {
  appointment: TimetableEvent | Nil
  appointmentTypeId: string
  multiDayEnabled?: boolean
  onFieldsChange: (changedFields: FieldCache) => void
  repeatEnabled?: boolean
}

export interface AppointmentDateSectionHandle extends DataHandle {}

const AppointmentDateSection = forwardRef(function AppointmentDateSection(
  {
    appointmentTypeId,
    appointment,
    onFieldsChange = R.F,
    multiDayEnabled = false,
    repeatEnabled = false,
  }: AppointmentDateSectionProps,
  ref: ForwardedRef<AppointmentDateSectionHandle>,
) {
  const classes = useStyles()
  const EventType = useSelector(getEventType)
  const TimeUnits = useSelector(getTimeUnits)
  const RepeatTimeUnits = TimeUnits.filter((unit) =>
    AllowedRepeatUnits.includes(unit.name),
  )
  const ReleaseTimeUnits = TimeUnits.filter((unit) =>
    AllowedReleaseUnits.includes(unit.name),
  )
  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )

  const businessAppoinmentType = useSelector(
    getAppointmentType(appointmentTypeId),
  )

  const { t } = useTranslation([
    'Common',
    'Constants',
    'Time',
    'Validations',
    'Tooltips',
  ])

  const AppointmentEvent =
    Utils.findConstantByName('Appointment', EventType) || {}
  const AppointmentEventSubTypes = AppointmentEvent.subTypes || []
  const DaycareId = Utils.findConstantIdByName(
    AppointmentTypeName.DAYCARE,
    AppointmentEventSubTypes,
  )
  const BoardingId = Utils.findConstantIdByName(
    AppointmentTypeName.BOARDING,
    AppointmentEventSubTypes,
  )

  const eventTypeId = businessAppoinmentType?.eventTypeId

  const isAppointmentRepeated = getIsRepeatedAppointment(appointment)
  const isFinalizedSoap = getAppointmentHasFinalizedSoap(appointment)
  const isAppointmentReservedEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_RESERVED),
  )

  const getIsBusyType = useGetIsBusyType()
  const isBusyType = getIsBusyType(appointmentTypeId)
  const getIsReservedType = useGetIsReservedType()
  const isReservedType = getIsReservedType(appointmentTypeId)
  const isDaycare = eventTypeId === DaycareId
  const isBoarding = eventTypeId === BoardingId

  const [multiDay, setMultiDay] = useState(getIsMultidayEvent(appointment))
  const [repeat, setRepeat] = useState(isAppointmentRepeated)
  const [removeAutomatically, setRemoveAutomatically] = useState(
    Boolean(appointment?.releaseTimeOffset),
  )

  const showRepeat = isDaycare || isBusyType || repeatEnabled || repeat
  const showMultiday = isBoarding || isBusyType || multiDayEnabled || multiDay
  const showRemoveAutomatically = isAppointmentReservedEnabled && isReservedType

  useEffectExceptOnMount(() => {
    setMultiDay(multiDayEnabled)
  }, [multiDayEnabled])

  useEffectExceptOnMount(() => {
    setRepeat(repeatEnabled)
  }, [repeatEnabled])

  const getEndTime = (startTime: string | Nil) =>
    getAppointmentEndTime(
      moment(startTime),
      moment.duration(businessAppoinmentType?.defaultDuration).asMinutes(),
    )

  const getRecurrenceStartDate = (scheduledStartDatetime: string) =>
    DateUtils.serializeDate(scheduledStartDatetime)

  const getRecurrenceEndDate = (
    scheduledStartDatetime: string,
    numberOfIntervals: number,
    interval: string,
  ) => {
    const appointmentStartDate = moment(scheduledStartDatetime)
    const intervalName = Utils.findById(interval, RepeatTimeUnits)?.name

    // for end date, take last selected day and add X weeks
    return DateUtils.serializeDate(
      moment(appointmentStartDate)
        .add(numberOfIntervals, intervalName)
        .toISOString(),
    )
  }

  const validateNumberOfIntervals = ({
    state: {
      startDate,
      endDate,
      startTime,
      endTime,
      numberOfIntervals,
      interval,
    },
  }: CustomFieldValidatorState) => {
    if (!repeat) {
      return true
    }

    const [scheduledStartDatetime] = aggregateStartAndEndDates({
      startDate: moment(startDate),
      endDate: moment(endDate),
      startTime: moment(startTime),
      endTime: moment(endTime),
      ignoreSeconds: true,
      isMultiday: multiDay,
    })

    const recurrenceStartDate = getRecurrenceStartDate(scheduledStartDatetime)
    const recurrenceEndDate = getRecurrenceEndDate(
      scheduledStartDatetime,
      numberOfIntervals,
      interval,
    )

    return moment(recurrenceEndDate).diff(recurrenceStartDate, 'y', true) < 1
  }

  const recurrenceTimeUnitConstant =
    getAppointmentRecurrenceTimeUnitConstant(appointment)
  const numberOfIntervalsInitial = appointment?.recurrenceEndDate
    ? moment(appointment?.recurrenceEndDate).diff(
        appointment?.recurrenceStartDate,
        TimeUnitDiffMap[recurrenceTimeUnitConstant],
      )
    : 1

  const existingReleaseTimeUnitConstant = appointment?.releaseTimeOffset
    ? (appointment.releaseTimeOffset.charAt(
        appointment.releaseTimeOffset.length - 1,
      ) as ReleasePeriodSpecifier)
    : undefined
  const releaseTimeUnitConstant = existingReleaseTimeUnitConstant
    ? ReleaseUnitReverseMap[existingReleaseTimeUnitConstant]
    : TimeUnitsEnum.HOUR
  const initialReleaseIntervals =
    appointment?.releaseTimeOffset?.match(/PT(\d+)M|H/)?.[1]
  const { selectedDate } = useTimetableDate()

  const initialDate = selectedDate
    ? aggregateDate({
        date: moment(selectedDate),
        time: moment(),
        ignoreSeconds: true,
      }).toISOString()
    : new Date().toISOString()

  const initialStartTime =
    appointment?.scheduledStartDatetime || roundTime(initialDate)

  const getInitialEndTime = () => {
    const isNewAppointment = !appointment?.scheduledEndDatetime
    const hasBusinessAppointmentTypeChanged =
      appointment?.businessAppointmentType &&
      appointmentTypeId !== appointment.businessAppointmentType.id

    return isNewAppointment || hasBusinessAppointmentTypeChanged
      ? getEndTime(initialStartTime)
      : appointment?.scheduledEndDatetime
  }

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'startDate',
        label: t('Common:WHEN'),
        validators: ['required', 'timestamp'],
        initialValue: appointment?.scheduledStartDatetime || initialDate,
      },
      {
        name: 'endDate',
        label: t('Common:SCHEDULED_END'),
        validators: ['required', 'timestamp'],
        initialValue:
          appointment?.scheduledEndDatetime ||
          appointment?.scheduledStartDatetime ||
          initialDate,
      },
      {
        name: 'startTime',
        label: t('Common:TIME'),
        type: 'none',
        validators: ['required', 'timestamp'],
        initialValue: initialStartTime,
      },
      {
        name: 'endTime',
        label: t('Time:TIME_SELECTOR.END_TIME'),
        type: 'none',
        validators: ['required', 'timestamp'],
        initialValue: getInitialEndTime(),
      },
      {
        name: 'numberOfIntervals',
        validators: [
          {
            validator: validateNumberOfIntervals,
            validatorName: 'numberOfIntervals',
          },
        ],
        messages: {
          numberOfIntervals: t(
            'Validations:TIME_BETWEEN_RECURRENCE_START_AND_END',
          ),
        },
        initialValue: numberOfIntervalsInitial,
      },
      { name: 'days', initialValue: appointment?.recurrenceParams?.days || [] },
      {
        name: 'interval',
        type: 'select',
        initialValue: Utils.findConstantIdByName(
          LanguageUtils.capitalize(recurrenceTimeUnitConstant),
          RepeatTimeUnits,
        ),
      },
      {
        name: 'intervalRelease',
        type: 'select',
        initialValue: Utils.findConstantIdByName(
          LanguageUtils.capitalize(releaseTimeUnitConstant),
          ReleaseTimeUnits,
        ),
      },
      {
        name: 'numberOfIntervalsRelease',
        validators: [
          {
            validator: validateNumberOfIntervals,
            validatorName: 'numberOfIntervals',
          },
        ],
        messages: {
          numberOfIntervals: t(
            'Validations:TIME_BETWEEN_RECURRENCE_START_AND_END',
          ),
        },
        initialValue: initialReleaseIntervals
          ? Number(initialReleaseIntervals)
          : 1,
      },
    ],
    false,
  )

  const {
    startDate,
    endDate,
    startTime,
    endTime,
    numberOfIntervals,
    days,
    interval,
    intervalRelease,
    numberOfIntervalsRelease,
  } = fields

  useFieldsChanged(onFieldsChange, fields)

  useEffect(() => {
    if (!showMultiday) {
      setMultiDay(false)
    }
  }, [showMultiday])

  useEffect(() => {
    if (!showRepeat) {
      setRepeat(false)
    }
  }, [showRepeat])

  const onStartValueChange = (newValue: string) => {
    startDate.setValue(newValue)
    if (moment(newValue).isAfter(endDate.value, 'd')) {
      endDate.setValue(newValue)
    }
  }

  const updateMultiDayEndTime = (endTimeValue: string) => {
    if (
      moment(endDate.value).isAfter(startDate.value, 'date') ||
      moment(endTimeValue).isAfter(startTime.value)
    ) {
      endTime.setValue(endTimeValue)
    }
  }

  useEffect(() => {
    reset()
  }, [appointment])

  useEffect(() => {
    const timeChangedManually = startTime.value !== startTime.initialValue
    const dateChangedManually = startDate.value !== startDate.initialValue

    if (timeChangedManually || dateChangedManually) {
      endTime.setValue(getEndTime(startTime.value))
    } else {
      reset()
    }
  }, [appointmentTypeId])

  useImperativeHandle(ref, () => ({
    validate,
    get: () => {
      const [scheduledStartDatetime, scheduledEndDatetime] =
        aggregateStartAndEndDates({
          startDate: moment(startDate.value),
          endDate: moment(endDate.value),
          startTime: moment(startTime.value),
          endTime: moment(endTime.value),
          ignoreSeconds: true,
          isMultiday: multiDay,
        })

      const data: Partial<TimetableEvent> = {
        scheduledStartDatetime,
        scheduledEndDatetime,
      }

      if (repeat) {
        data.recurrenceParams = {
          days:
            days.value.length > 0 ? days.value : R.values(OrderedWeekConstants),
        }

        const shouldUpdateRecurrence =
          !appointment?.id ||
          !R.equals(days.value, days.initialValue) ||
          repeat !== isAppointmentRepeated ||
          numberOfIntervals.value !== numberOfIntervals.initialValue ||
          interval.value !== interval.initialValue

        data.recurrenceStartDate = shouldUpdateRecurrence
          ? getRecurrenceStartDate(scheduledStartDatetime)
          : appointment.recurrenceStartDate

        data.recurrenceEndDate = shouldUpdateRecurrence
          ? getRecurrenceEndDate(
              scheduledStartDatetime,
              numberOfIntervals.value,
              interval.value,
            )
          : appointment.recurrenceEndDate
      }

      if (removeAutomatically && isAppointmentReservedEnabled) {
        const releaseUnit = Utils.getConstantName(
          intervalRelease.value,
          ReleaseTimeUnits,
        )
        data.releaseTimeOffset = `PT${numberOfIntervalsRelease.value}${ReleaseUnitMap[releaseUnit]}`
      }

      return data
    },
  }))

  return (
    <Grid container item className={classes.root} direction="column">
      <Grid container item alignItems="center" wrap="nowrap">
        <Grid
          container
          item
          alignItems="center"
          columnSpacing={1}
          wrap="nowrap"
          xs={isAppointmentReservedEnabled ? 4 : 6}
        >
          <Grid item mt={3}>
            <TodayOutlinedIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            <Calendar
              fullWidth
              InputProps={{
                endAdornment: <TimetableTag selectedDate={startDate.value} />,
              }}
              disabled={!appointmentPermissions.update || isFinalizedSoap}
              field={{ ...startDate, setValue: onStartValueChange }}
              label={startDate.label}
              name="startDate"
              renderDay={TimetableCalendarDayPicker}
            />
          </Grid>
        </Grid>
        <Grid mt={2} mx={1.5}>
          <TimezoneWarningLabel />
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          columnSpacing={1}
          wrap="nowrap"
          xs={isAppointmentReservedEnabled ? 4 : true}
        >
          <Grid item mt={3}>
            <ScheduleIcon className={classes.icon} />
          </Grid>
          <Grid item>
            <TimeSelector
              disabled={!appointmentPermissions.update || isFinalizedSoap}
              endValue={!multiDay && endTime.value}
              label={startTime.label}
              name="startTime"
              range={!multiDay}
              startValue={startTime.value}
              onEndChange={multiDay ? undefined : endTime.set}
              onStartChange={startTime.set}
            />
          </Grid>
        </Grid>
        {showMultiday && isAppointmentReservedEnabled && (
          <Grid container item mx={3} xs={4}>
            <PuiCheckbox
              checkboxClasses={{
                root: classes.checkboxRoot,
              }}
              checked={multiDay}
              className={classes.checkbox}
              disabled={!appointmentPermissions.update || isFinalizedSoap}
              label={t('Time:LABEL.MULTI-DAY')}
              name="multiday"
              onChange={() => setMultiDay(!multiDay)}
            />
          </Grid>
        )}
      </Grid>
      {showMultiday && multiDay && (
        <Grid container item alignItems="center" wrap="nowrap">
          <Grid
            container
            item
            alignItems="center"
            columnSpacing={1}
            wrap="nowrap"
            xs={isAppointmentReservedEnabled ? 4 : 6}
          >
            <Grid item mt={3}>
              <TodayOutlinedIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Calendar
                fullWidth
                InputProps={{
                  endAdornment: <TimetableTag selectedDate={endDate.value} />,
                }}
                disabled={!appointmentPermissions.update || isFinalizedSoap}
                field={endDate}
                label={endDate.label}
                minDate={startDate.value}
                name="endDate"
                renderDay={TimetableCalendarDayPicker}
              />
            </Grid>
          </Grid>
          <Grid className={classes.emptyTimezoneContainer} mx={2} />
          <Grid
            container
            item
            alignItems="center"
            columnSpacing={1}
            wrap="nowrap"
            xs={isAppointmentReservedEnabled ? 4 : true}
          >
            <Grid item mt={3}>
              <ScheduleIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <TimeSelector
                disabled={!appointmentPermissions.update || isFinalizedSoap}
                label={endTime.label}
                name="endTime"
                startValue={endTime.value}
                onStartChange={updateMultiDayEndTime}
              />
            </Grid>
          </Grid>
          {isAppointmentReservedEnabled && (
            <Grid container item mx={3} xs={4} />
          )}
        </Grid>
      )}
      <Grid container item wrap="nowrap">
        {showRepeat && (
          <PuiCheckbox
            checkboxClasses={{
              root: classes.checkboxRoot,
            }}
            checked={repeat}
            className={classes.checkbox}
            disabled={!appointmentPermissions.update || isFinalizedSoap}
            label={t('Common:REPEAT_ACTION')}
            onChange={() => setRepeat(!repeat)}
          />
        )}
        {showMultiday && !isAppointmentReservedEnabled && (
          <PuiCheckbox
            checkboxClasses={{
              root: classes.checkboxRoot,
            }}
            checked={multiDay}
            className={classes.checkbox}
            disabled={!appointmentPermissions.update || isFinalizedSoap}
            label={t('Time:LABEL.MULTI-DAY')}
            name="multiday"
            onChange={() => setMultiDay(!multiDay)}
          />
        )}
      </Grid>
      {showRepeat && repeat && (
        <Grid container item direction="column" pb={1}>
          <Grid container item alignItems="center">
            <Text variant="body2">{t('Common:REPEAT_FOR')}</Text>
            <Grid item pr={1}>
              {isAppointmentReservedEnabled ? (
                <NumberStepper
                  disabled={!appointmentPermissions.update || isFinalizedSoap}
                  field={numberOfIntervals}
                />
              ) : (
                <QuantityInput
                  showControls
                  disabled={!appointmentPermissions.update || isFinalizedSoap}
                  field={numberOfIntervals}
                />
              )}
            </Grid>
            <TimeUnitsSelect
              amount={numberOfIntervals.value}
              field={interval}
              items={RepeatTimeUnits}
              renderEmpty={false}
            />
          </Grid>
          <WeekdaysButtonGroup
            disabled={!appointmentPermissions.update || isFinalizedSoap}
            field={days}
          />
        </Grid>
      )}
      {showRemoveAutomatically && isAppointmentReservedEnabled && (
        <Grid container item flexDirection="column" wrap="nowrap">
          <PuiCheckbox
            checkboxClasses={{
              root: classes.checkboxRoot,
            }}
            checked={removeAutomatically}
            className={classes.checkbox}
            label={
              <TextWithTooltip
                fontSize="1.4rem"
                tooltipText={t('Tooltips:REMOVE_AUTOMATICALLY')}
              >
                {t('Time:LABEL.REMOVE_AUTOMATICALLY')}
              </TextWithTooltip>
            }
            name="removeAutomatically"
            onChange={() => setRemoveAutomatically(!removeAutomatically)}
          />
          {removeAutomatically && (
            <Grid container item direction="column">
              <Grid container item alignItems="center">
                <Text variant="body2">{t('Common:RELEASE')}</Text>
                <Grid item px={1}>
                  <NumberStepper field={numberOfIntervalsRelease} />
                </Grid>
                <TimeUnitsSelect
                  amount={numberOfIntervalsRelease.value}
                  field={intervalRelease}
                  items={ReleaseTimeUnits}
                  renderEmpty={false}
                />
                <Text pl={1} variant="body2">
                  {t('Common:BEFORE_APPOINTMENT_TIME')}
                </Text>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
})

export default AppointmentDateSection

import React, { SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateUtils,
  LanguageUtils,
  Nil,
  NumberUtils,
  PuiTextArea,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { SuccessAlert, WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import { InvoiceType as GQLInvoiceType } from '~/api/graphql/generated/types'
import Link from '~/components/common/link/Link'
import { PaymentDetailsInvoicesItem } from '~/components/dashboard/invoices/payment/payment-details-dialog/PaymentDetailsInvoicesItem'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import InvoiceType from '~/constants/InvoiceType'
import PaymentType from '~/constants/paymentTypes'
import { clearTransactionInfo } from '~/store/actions/payments'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  getCreditAdjustmentReason,
  getFeatureToggle,
  getPaymentSourceType,
  getPaymentTransactionState,
} from '~/store/reducers/constants'
import { getFinanceIsLoading } from '~/store/reducers/finance'
import { getPaymentsIsLoading } from '~/store/reducers/payments'
import { ExtendPayment, Invoice } from '~/types'
import { getPaymentPaidPersonName } from '~/utils/finance'
import {
  extractInvoicesByType,
  getDerrivedPaymentType,
  getPaymentDetailsLabelsString,
  getPaymentFlags,
  getPaymentMethodString,
} from '~/utils/paymentUtils'
import useDialog from '~/utils/useDialog'

import { calculateInvoiceTotal } from '../../invoiceUtils'
import { PaymentDetailsItem } from './PaymentDetailsItem'

export const paymentNotesMaxLength = 250

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(2, 5, 3),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
      },
    },
    icon: {
      width: 38,
      height: 38,
      marginBottom: theme.spacing(2),
    },
    smallIcon: {
      width: 14,
      height: 14,
      marginRight: theme.spacing(2),
    },
    successIcon: {
      color: theme.colors.alertSuccess,
    },
    warnIcon: {
      color: theme.colors.alertWarning,
    },
    progress: {
      height: theme.constants.progressBarHeight,
    },
    invoiceNameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    applyUnappliedLink: {
      color: theme.colors.link,
    },
    link: {
      textDecorationColor: 'inherit',
      color: 'inherit',
    },
    collapseIcon: {
      width: 24,
      height: 24,
    },
  }),
  { name: 'PaymentDetailsChunk' },
)

interface PaymentDetailsChunkProps {
  areNotesEditable?: boolean
  clientId: string | Nil
  expandable?: boolean
  isOriginalPayment?: boolean
  isPaymentRefundOrReverse: boolean
  isPaymentReversal: boolean
  isPossibleToViewInvoice: boolean
  notes?: string
  onClose: () => void
  payment: ExtendPayment
  setNotes?: React.Dispatch<SetStateAction<string>>
}

const PaymentDetailsChunk = ({
  clientId,
  isPossibleToViewInvoice,
  onClose,
  isPaymentReversal,
  payment,
  notes,
  setNotes,
  isPaymentRefundOrReverse,
  areNotesEditable,
  expandable,
  isOriginalPayment,
}: PaymentDetailsChunkProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Constants', 'Invoices'])

  const [isPaymentsCollapsed, setIsPaymentsCollapsed] = useState(true)

  const financeIsLoading = useSelector(getFinanceIsLoading)
  const paymentsIsLoading = useSelector(getPaymentsIsLoading)
  const PaymentSourceType = useSelector(getPaymentSourceType)
  const PaymentTransactionState = useSelector(getPaymentTransactionState)
  const CreditAdjustmentReason = useSelector(getCreditAdjustmentReason)
  const isIpoM0CreditAdjustmentEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_CREDIT_ADJUSTMENT),
  )
  const isIpoM0EstimatesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_ESTIMATES),
  )
  const isIpoM0VoidAndPaymentReversalFTEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_VOID_AND_PAYMENT_REVERSAL),
  )

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openApplyPaymentToInvoiceDialog] = useDialog(
    DialogNames.APPLY_PAYMENT_TO_INVOICE,
  )
  const [openPaymentDetailsDialog] = useDialog(DialogNames.PAYMENT_DETAILS)

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const initialNotes = payment?.notes ?? ''

  useEffect(() => {
    setNotes?.(initialNotes)
  }, [initialNotes])

  const { invoices: invoicesOnly, estimates: estimatesOnly } =
    extractInvoicesByType(payment)

  const invoicesToDisplay = isIpoM0EstimatesEnabled
    ? invoicesOnly
    : (payment.invoices ?? [])

  const {
    isRefund,
    isUndo,
    isVoid,
    isAuth,
    isCreditAdjustment,
    isDebitAdjustment,
    isFailedPayment,
    isCreditPayment,
  } = getPaymentFlags(payment, PaymentTransactionState)

  const loading = paymentsIsLoading || financeIsLoading

  const unappliedAmount =
    R.isNil(payment.unappliedAmount) || payment.unappliedAmount === 0
      ? Math.abs(payment.amount ?? 0) -
        (payment.invoices ? calculateInvoiceTotal(payment.invoices) : 0)
      : payment.unappliedAmount

  const isReversedVoidedOrFullyRefunded =
    payment.reversed || payment.undone || payment?.refundableAmount === 0

  const displayUnappliedAmount =
    isIpoM0EstimatesEnabled &&
    isCreditPayment &&
    !isReversedVoidedOrFullyRefunded &&
    (R.isNil(payment.invoices) ||
      (!R.isNil(payment.invoices) &&
        (unappliedAmount ?? 0) <= Math.abs(payment.amount ?? 0) &&
        unappliedAmount !== 0))
  const displayInvoiceRow =
    !isPaymentReversal &&
    invoicesToDisplay.length > 0 &&
    isPossibleToViewInvoice
  const displayInvoiceTotalRow =
    invoicesToDisplay.length > 0 &&
    !isPaymentReversal &&
    Boolean(payment.invoicesTotalAmountNoFee)
  const displayApplyToInvoiceRow =
    !isIpoM0EstimatesEnabled &&
    invoicesToDisplay.length === 0 &&
    !isUndo &&
    !payment.undone &&
    !isVoid &&
    !isAuth &&
    !isOriginalPayment
  const displayEstimatesRow =
    isIpoM0EstimatesEnabled &&
    !isPaymentReversal &&
    estimatesOnly.length > 0 &&
    isPossibleToViewInvoice
  const displayRecordedByRow =
    payment.person?.firstName &&
    !isVoid &&
    (!isIpoM0VoidAndPaymentReversalFTEnabled || !isUndo)

  const paymentTypeLabel = getDerrivedPaymentType({
    paymentType: payment?.paymentType,
    isRefund: isRefund && !(payment?.reversed ?? false),
    isReversal: isRefund && (payment?.reversed ?? false),
    isVoid,
    isAuth,
    isCreditAdjustment,
    isDebitAdjustment,
    isUndo,
    isIpoM0VoidAndPaymentReversalFTEnabled,
  })
  const {
    amountLabelRaw,
    cashChangeGiven,
    cashReceived,
    dateLabel,
    methodLabel,
    recordedByLabel,
    toLabel,
  } = getPaymentDetailsLabelsString(paymentTypeLabel)

  const amountLabel = R.isNil(payment.actualDeposit)
    ? amountLabelRaw
    : `${amountLabelRaw} (${NumberUtils.toPercentFormat(
        payment.actualDeposit,
      )})`

  const goToInvoice = (invoice: Invoice) => {
    // Declared as InvoiceType, but comes back as GQLInvoiceType. So checking both for saftey.
    const isEstimate =
      invoice.invoiceType === (GQLInvoiceType.Estimate as string) ||
      invoice.invoiceType === InvoiceType.ESTIMATE
    dispatch(clearTransactionInfo())
    openInvoice({
      clientId,
      invoiceId: invoice.id,
      isEstimate,
      chargesEntityType: isEstimate
        ? InvoiceType.ESTIMATE
        : InvoiceType.INVOICE,
      id: invoice.id,
    })
    onClose()
  }

  const onApplyPaymentToInvoice = () => {
    openApplyPaymentToInvoiceDialog({
      paymentId: payment.id,
      clientId,
      paymentAmount: Math.abs(
        (isIpoM0EstimatesEnabled ? unappliedAmount : payment.amount) || 0,
      ),
    })
  }

  const openOriginalPayment = () => {
    onClose()
    openPaymentDetailsDialog({
      clientId,
      paymentId: payment.originPaymentId,
    })
  }

  const ChunkSubtitleLabel: Record<string, string> = {
    [PaymentType.REFUND]: t('Common:REFUND_NOUN'),
    [PaymentType.REVERSE_CHARGE]: t('Common:PAYMENTS.PAYMENT_REVERSAL'),
    [PaymentType.UNDO]: t('Payments:PAYMENT_TYPES.VOID'),
    [PaymentType.VOID]: t('Payments:PAYMENT_TYPES.VOID'),
  }

  const PaymentRowLabel: Record<string, string> = {
    [PaymentType.REFUND]: t('Common:PAYMENTS.REFUNDED'),
    [PaymentType.REVERSE_CHARGE]: t('Common:PAYMENTS.PAYMENT_REVERSAL'),
    [PaymentType.UNDO]: t('Common:PAYMENTS.VOIDED'),
    [PaymentType.VOID]: t('Common:PAYMENTS.VOIDED'),
  }

  const getPaymentAmountRowLabel = () =>
    PaymentRowLabel[paymentTypeLabel ?? ''] ||
    t('Common:PAYMENTS.PAYMENT_REVERSAL')

  const getChunkSubtitleLabel = () => {
    if (isOriginalPayment) {
      return t('Invoices:PAYMENTS.PAYMENT_DETAILS.ORIGINAL_PAYMENT')
    }
    if (!isPaymentReversal) {
      return t('Common:PAYMENTS.PAYMENT')
    }
    return (
      ChunkSubtitleLabel[paymentTypeLabel ?? ''] ||
      t('Common:PAYMENTS.PAYMENT_REVERSAL')
    )
  }

  return (
    <>
      {/* subtitle */}
      {isPaymentRefundOrReverse && (
        <Grid
          container
          alignItems="center"
          onClick={() => setIsPaymentsCollapsed(!isPaymentsCollapsed)}
        >
          <Grid item pb={0} pl={3} pr={1} pt={expandable ? 0 : 1}>
            <Text strong variant="subheading3">
              {getChunkSubtitleLabel()}
            </Text>
          </Grid>
          {expandable && (
            <Grid item pb={0} pt={1}>
              {isPaymentsCollapsed ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
            </Grid>
          )}
        </Grid>
      )}
      {(!expandable || !isPaymentsCollapsed) && (
        <Grid container item className={classes.content}>
          {!isPaymentRefundOrReverse && (
            <Grid item mr={2}>
              {payment.partial || isFailedPayment ? (
                <WarnAlert
                  className={classNames(classes.icon, classes.warnIcon)}
                />
              ) : (
                <SuccessAlert
                  className={classNames(classes.icon, classes.successIcon)}
                />
              )}
            </Grid>
          )}
          <Grid item xs>
            <Grid container item>
              {/* invoice row */}
              {displayInvoiceRow && (
                <PaymentDetailsInvoicesItem
                  invoices={invoicesToDisplay}
                  loading={loading}
                  paymentId={payment.id}
                  onInvoiceClick={goToInvoice}
                />
              )}
              {/* refund invoice row */}
              {payment.refundInvoiceId && payment.invoices?.length > 0 && (
                <PaymentDetailsItem label={t('Common:REFUND_INVOICE')}>
                  <Link
                    className={classes.link}
                    to={`/refund/${payment.invoices[0].id}`}
                    onClick={onClose}
                  >
                    {payment.invoices[0].invoiceNo}
                  </Link>
                </PaymentDetailsItem>
              )}
              {/* invoice total row */}
              {displayInvoiceTotalRow && (
                <PaymentDetailsItem
                  label={
                    invoicesToDisplay.length > 1
                      ? t('Common:INVOICE_TOTAL_OTHER')
                      : t('Common:INVOICE_TOTAL')
                  }
                >
                  {NumberUtils.formatMoney(payment.invoicesTotalAmountNoFee)}
                </PaymentDetailsItem>
              )}
              {/* apply to invoice row */}
              {displayApplyToInvoiceRow && (
                <PaymentDetailsItem label={t('Common:INVOICE')}>
                  <Text link variant="body2" onClick={onApplyPaymentToInvoice}>
                    {t('Invoices:PAYMENTS.PAYMENT_DETAILS.APPLY_TO_INVOICE')}
                  </Text>
                </PaymentDetailsItem>
              )}
              {/* Estimates row */}
              {displayEstimatesRow && (
                <PaymentDetailsItem
                  label={t('Constants:INVOICE_TYPE.ESTIMATE')}
                >
                  {estimatesOnly.map((estimate) => (
                    <Text
                      link
                      key={estimate.id}
                      variant="body2"
                      onClick={() => goToInvoice(estimate)}
                    >
                      <span className={classes.invoiceNameContainer}>
                        {estimate.invoiceNo}
                        {loading && (
                          <CircularProgress
                            size={20}
                            sx={{ marginLeft: 0.5 }}
                          />
                        )}
                      </span>
                    </Text>
                  ))}
                </PaymentDetailsItem>
              )}
              {/* payment amount row */}
              {isPaymentReversal ? (
                <PaymentDetailsItem label={getPaymentAmountRowLabel()}>
                  {payment.amount && NumberUtils.formatMoney(-payment.amount)}
                </PaymentDetailsItem>
              ) : (
                // paid row
                <PaymentDetailsItem
                  label={
                    isPaymentRefundOrReverse
                      ? t('Common:PAYMENTS.PAID')
                      : amountLabel
                  }
                >
                  {payment.amount &&
                    NumberUtils.formatMoney(Math.abs(payment.amount))}
                </PaymentDetailsItem>
              )}
              {/* unapplied amt row ft */}
              {displayUnappliedAmount && (
                <PaymentDetailsItem
                  label={t('Common:PAYMENTS.UNAPPLIED_AMOUNT')}
                >
                  <Grid container item gap={1}>
                    <Text variant="body2">
                      {NumberUtils.formatMoney(Math.abs(unappliedAmount ?? 0))}
                    </Text>
                    {!isAuth && !isOriginalPayment && (
                      <Text
                        link
                        className={classes.applyUnappliedLink}
                        variant="body2"
                        onClick={onApplyPaymentToInvoice}
                      >
                        {t(
                          'Invoices:PAYMENTS.PAYMENT_DETAILS.APPLY_TO_INVOICE',
                        )}
                      </Text>
                    )}
                  </Grid>
                </PaymentDetailsItem>
              )}
              {/* partial payment row */}
              {payment.partial && (
                <Grid
                  container
                  item
                  alignItems="center"
                  py={1}
                  wrap="nowrap"
                  xs={12}
                >
                  <WarnAlert
                    className={classNames(classes.smallIcon, classes.warnIcon)}
                  />
                  <Text strong variant="body2">
                    {t('Invoices:PAYMENTS.PAYMENT_DETAILS.REQUESTED_PAYMENT', {
                      requestedPayment: NumberUtils.formatMoney(
                        payment.requestedAmount,
                      ),
                      paymentAmount: NumberUtils.formatMoney(payment.amount),
                    })}
                  </Text>
                </Grid>
              )}
              {/* paid by person row */}
              {(toLabel || isPaymentRefundOrReverse) && (
                <PaymentDetailsItem
                  label={
                    isPaymentRefundOrReverse
                      ? t('Common:PAYMENTS.PAID_BY')
                      : toLabel
                  }
                >
                  {getPaymentPaidPersonName(payment)}
                </PaymentDetailsItem>
              )}
              {/* recorded by row */}
              {displayRecordedByRow && (
                <PaymentDetailsItem
                  label={
                    isPaymentRefundOrReverse
                      ? t('Common:PAYMENTS.RECORDED_BY')
                      : recordedByLabel
                  }
                >
                  {Utils.getPersonString(payment.person)}
                </PaymentDetailsItem>
              )}
              {/* payent method row */}
              {(methodLabel || isPaymentRefundOrReverse) && (
                <PaymentDetailsItem
                  label={
                    isPaymentRefundOrReverse
                      ? t('Common:PAYMENTS.PAYMENT_METHOD')
                      : methodLabel
                  }
                >
                  {getPaymentMethodString(payment)}
                </PaymentDetailsItem>
              )}
              {/* refund date row */}
              <PaymentDetailsItem label={dateLabel}>
                {DateUtils.formatDateWithHours(payment.creationDate)}
              </PaymentDetailsItem>
              {!R.isNil(payment.cashReceived) &&
                !R.isNil(payment.cashChangeGiven) && (
                  <>
                    {cashReceived && (
                      <PaymentDetailsItem label={cashReceived}>
                        {NumberUtils.formatMoney(payment.cashReceived)}
                      </PaymentDetailsItem>
                    )}
                    {cashChangeGiven && (
                      <PaymentDetailsItem label={cashChangeGiven}>
                        {NumberUtils.formatMoney(payment.cashChangeGiven)}
                      </PaymentDetailsItem>
                    )}
                  </>
                )}
              {payment.originPaymentId && !isPaymentRefundOrReverse && (
                <PaymentDetailsItem
                  label={t(
                    'Invoices:PAYMENTS.PAYMENT_DETAILS.ORIGINAL_PAYMENT',
                  )}
                >
                  <Text link variant="body2" onClick={openOriginalPayment}>
                    {t('Common:PAYMENTS.PAYMENT')}
                  </Text>
                </PaymentDetailsItem>
              )}
              {payment.sourceTypeId && (
                <PaymentDetailsItem
                  label={t('Invoices:PAYMENTS.PAYMENT_DETAILS.SOURCE')}
                >
                  {LanguageUtils.getConstantTranslatedName(
                    payment.sourceTypeId,
                    PaymentSourceType,
                  )}
                </PaymentDetailsItem>
              )}
              {payment.serialNo && (
                <PaymentDetailsItem
                  label={t(
                    'Invoices:PAYMENTS.PAYMENT_DETAILS.TERMINAL_SERIAL_NUMBER',
                  )}
                >
                  {payment.serialNo}
                </PaymentDetailsItem>
              )}
              {(payment.goTxId || payment.posTxId) && (
                <PaymentDetailsItem
                  label={t('Invoices:PAYMENTS.PAYMENT_DETAILS.TRANSACTION_ID')}
                >
                  {payment.goTxId || payment.posTxId}
                </PaymentDetailsItem>
              )}
              {payment.cardBin && (
                <PaymentDetailsItem
                  label={t(
                    'Invoices:PAYMENTS.PAYMENT_DETAILS.CARD_BANK_IDENTIFICATION_NUMBER',
                  )}
                >
                  {payment.cardBin}
                </PaymentDetailsItem>
              )}
              {payment.authCode && (
                <PaymentDetailsItem
                  label={t('Invoices:PAYMENTS.PAYMENT_DETAILS.AUTH_CODE')}
                >
                  {payment.authCode}
                </PaymentDetailsItem>
              )}
              {isIpoM0CreditAdjustmentEnabled &&
                payment.creditAdjustmentReasonId && (
                  <PaymentDetailsItem
                    label={t('Common:CREDIT_ADJUSTMENT_REASON')}
                  >
                    {Utils.getConstantName(
                      payment.creditAdjustmentReasonId,
                      CreditAdjustmentReason,
                    )}
                  </PaymentDetailsItem>
                )}
              <Grid container item direction="column" mt={2}>
                <PuiTextArea
                  InputProps={{
                    inputProps: { maxLength: paymentNotesMaxLength },
                  }}
                  disabled={!areNotesEditable}
                  margin="none"
                  value={areNotesEditable ? notes : initialNotes}
                  onChange={(event) => setNotes?.(event.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default PaymentDetailsChunk

import * as R from 'ramda'
import { AnyAction } from 'redux'
import { all, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError, Constant, Nil, Utils } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { ImagingOrderStatus, ImagingVendorName } from '~/constants/imaging'
import { fetchClientFinanceCharges } from '~/store/duck/clientFinanceData'
import { fetchInvoiceV3 } from '~/store/reducers/invoiceV3'
import { ImagingOrder, ImagingVendorModality, Order } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'
import { isImagingProcedure } from '~/utils/orderUtils'

import { fetchSOAPOrders } from '../actions/soap'
import type { RootState } from '../index'
import {
  getImagingOrderStatuses,
  getImagingVendors,
} from '../reducers/constants'
import { getSelectedOrders } from '../reducers/orders'
import { getSoapBusinessId, getSoapId } from '../reducers/soap'
import requestAPI from '../sagas/utils/requestAPI'
import {
  COMPLETE_ORDER_WITHIN_DASHBOARD,
  EDIT_ORDER_WITHIN_DASHBOARD,
} from './imagingDashboard'
import { notifyNetworkError, notifyNetworkSuccess } from './uiAlerts'

export const CREATE_ORDER = 'imagingOrder/CREATE_ORDER'
export const CREATE_ORDER_SUCCESS = 'imagingOrder/CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAILURE = 'imagingOrder/CREATE_ORDER_FAILURE'

export const BATCH_CREATE_ORDER = 'imagingOrder/BATCH_CREATE_ORDER'
export const BATCH_CREATE_ORDER_SUCCESS =
  'imagingOrder/BATCH_CREATE_ORDER_SUCCESS'
export const BATCH_CREATE_ORDER_FAILURE =
  'imagingOrder/BATCH_CREATE_ORDER_FAILURE'

export const EDIT_ORDER = 'imagingOrder/EDIT_ORDER'
export const EDIT_ORDER_SUCCESS = 'imagingOrder/EDIT_ORDER_SUCCESS'
export const EDIT_ORDER_FAILURE = 'imagingOrder/EDIT_ORDER_FAILURE'

export const UPDATE_IMAGING_ORDERS = 'imagingOrder/UPDATE_IMAGING_ORDERS'

export const CANCEL_ORDER = 'imagingOrder/CANCEL_ORDER'
export const CANCEL_ORDER_SUCCESS = 'imagingOrder/CANCEL_ORDER_SUCCESS'
export const CANCEL_ORDER_FAILURE = 'imagingOrder/CANCEL_ORDER_FAILURE'

export const COMPLETE_ORDER = 'imagingOrder/COMPLETE_ORDER'
export const COMPLETE_ORDER_SUCCESS = 'imagingOrder/COMPLETE_ORDER_SUCCESS'
export const COMPLETE_ORDER_FAILURE = 'imagingOrder/COMPLETE_ORDER_FAILURE'

export const FETCH_MODALITY = 'imagingOrder/FETCH_MODALITY'
export const FETCH_MODALITY_SUCCESS = 'imagingOrder/FETCH_MODALITY_SUCCESS'
export const FETCH_MODALITY_FAILURE = 'imagingOrder/FETCH_MODALITY_FAILURE'

export const FETCH_ORDERS = 'imagingOrder/FETCH_ORDERS'
export const FETCH_ORDERS_SUCCESS = 'imagingOrder/FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_FAILURE = 'imagingOrder/FETCH_ORDERS_FAILURE'

export const FETCH_ORDER = 'imagingOrder/FETCH_ORDER'
export const FETCH_ORDER_SUCCESS = 'imagingOrder/FETCH_ORDER_SUCCESS'
export const FETCH_ORDER_FAILURE = 'imagingOrder/FETCH_ORDER_FAILURE'

export const createOrder = (order: ImagingOrder, vendorLabel: string) => ({
  type: CREATE_ORDER,
  order,
  vendorLabel,
})
export const createOrderSuccess = () => ({ type: CREATE_ORDER_SUCCESS })
export const createOrderFailure = (error: ApiError) => ({
  type: CREATE_ORDER_FAILURE,
  error,
})

export const batchCreateOrder = (batchInfo: {
  clientId: string | Nil
  invoiceId: string | Nil
  orders: Partial<ImagingOrder>[]
  vendorLabel: string
}) => ({
  type: BATCH_CREATE_ORDER,
  clientId: batchInfo.clientId,
  orders: batchInfo.orders,
  vendorLabel: batchInfo.vendorLabel,
  invoiceId: batchInfo.invoiceId,
})
export const batchCreateOrderSuccess = (list: string[]) => ({
  type: BATCH_CREATE_ORDER_SUCCESS,
  list,
})
export const batchCreateOrderFailure = (error: ApiError) => ({
  type: BATCH_CREATE_ORDER_FAILURE,
  error,
})

export const editOrder = (order: ImagingOrder) => ({ type: EDIT_ORDER, order })
export const editOrderSuccess = () => ({ type: EDIT_ORDER_SUCCESS })
export const editOrderFailure = (error: ApiError) => ({
  type: EDIT_ORDER_FAILURE,
  error,
})

export const cancelOrder = (orderId: string) => ({
  type: CANCEL_ORDER,
  orderId,
})
export const cancelOrderSuccess = (orderId: string) => ({
  type: CANCEL_ORDER_SUCCESS,
  orderId,
})
export const cancelOrderFailure = (error: ApiError) => ({
  type: CANCEL_ORDER_FAILURE,
  error,
})

export const completeOrder = (orderId: string) => ({
  type: COMPLETE_ORDER,
  orderId,
})
export const completeOrderSuccess = () => ({ type: COMPLETE_ORDER_SUCCESS })
export const completeOrderFailure = (error: ApiError) => ({
  type: COMPLETE_ORDER_FAILURE,
  error,
})

export const fetchOrders = (soapId: string) => ({ type: FETCH_ORDERS, soapId })
export const fetchOrdersSuccess = (list: string[]) => ({
  type: FETCH_ORDERS_SUCCESS,
  list,
})
export const fetchOrdersFailure = (error: ApiError) => ({
  type: FETCH_ORDERS_FAILURE,
  error,
})

export const fetchOrder = (orderId: string) => ({ type: FETCH_ORDER, orderId })
export const fetchOrderSuccess = () => ({ type: FETCH_ORDER_SUCCESS })
export const fetchOrderFailure = (error: ApiError) => ({
  type: FETCH_ORDER_FAILURE,
  error,
})

export const updateImagingOrders = (orders: Record<string, ImagingOrder>) => ({
  type: UPDATE_IMAGING_ORDERS,
  orders,
})

export const fetchModality = (targetBusinessId: string, force?: boolean) => ({
  type: FETCH_MODALITY,
  targetBusinessId,
  force,
})
export const fetchModalitySuccess = (
  modalityMap: Record<string, ImagingVendorModality[]>,
) => ({
  type: FETCH_MODALITY_SUCCESS,
  modalityMap,
})
export const fetchModalityFailure = (error: ApiError) => ({
  type: FETCH_MODALITY_FAILURE,
  error,
})

export type ImagingOrdersState = {
  error: string | null
  isLoading: boolean
  isModalityLoading: boolean
  list: string[]
  map: Record<string, ImagingOrder>
  modalityVendorMap: Record<string, ImagingVendorModality[]>
}

const INITIAL_STATE: ImagingOrdersState = {
  map: {},
  list: [],
  modalityVendorMap: {},
  isModalityLoading: false,
  isLoading: false,
  error: null,
}

export const imagingOrdersReducer = (
  state: ImagingOrdersState = INITIAL_STATE,
  action: AnyAction,
): ImagingOrdersState => {
  switch (action.type) {
    case CREATE_ORDER:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CANCEL_ORDER:
      return {
        ...state,
        isLoading: true,
      }
    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        list: R.without([action.orderId], state.list),
        map: R.omit([action.orderId], state.map),
        isLoading: false,
      }
    case CANCEL_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case COMPLETE_ORDER:
    case COMPLETE_ORDER_WITHIN_DASHBOARD:
      return {
        ...state,
        isLoading: true,
      }
    case COMPLETE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case COMPLETE_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case BATCH_CREATE_ORDER:
      return {
        ...state,
        isLoading: true,
      }
    case BATCH_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        list: [...state.list, ...action.list],
        isLoading: false,
      }
    case BATCH_CREATE_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_ORDER:
    case EDIT_ORDER_WITHIN_DASHBOARD:
      return {
        ...state,
        isLoading: true,
      }
    case EDIT_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_ORDERS:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        list: action.list,
        isLoading: false,
      }
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_ORDER:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case FETCH_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_IMAGING_ORDERS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.orders),
      }
    case FETCH_MODALITY:
      return {
        ...state,
        isModalityLoading: true,
      }
    case FETCH_MODALITY_SUCCESS:
      return {
        ...state,
        isModalityLoading: false,
        modalityVendorMap: action.modalityMap || {},
      }
    case FETCH_MODALITY_FAILURE:
      return {
        ...state,
        isModalityLoading: false,
      }
    default:
      return state
  }
}

export const getImagingOrders = (state: RootState): ImagingOrdersState =>
  state.imagingOrders
export const getImagingOrdersList = (state: RootState) =>
  getImagingOrders(state).list
export const getImagingIsLoading = (state: RootState) =>
  getImagingOrders(state).isLoading
export const getImagingOrdersMap = (state: RootState) =>
  getImagingOrders(state).map
export const getImagingOrder = (orderId: string | Nil) =>
  createSelector(getImagingOrdersMap, (map) =>
    orderId ? map[orderId] : undefined,
  )
export const getMultipleImagingOrders = (ids: string[]) =>
  createSelector(getImagingOrdersMap, (map) => R.props(ids, map))
export const getImagingModalityIsLoading = (state: RootState) =>
  getImagingOrders(state).isModalityLoading
export const getImagingModalityMap = (state: RootState) =>
  getImagingOrders(state).modalityVendorMap
export const getImagingVendorModality = (vendorId: string) =>
  createSelector(getImagingModalityMap, (map) => R.prop(vendorId, map))
export const getBusinessScopeModalityList = (vendorId: string) =>
  createSelector(getImagingVendorModality(vendorId), (modality) =>
    modality ? R.pluck('modalityId', modality) : [],
  )
export const getHasOrders = R.pipe(
  getImagingOrdersList,
  R.defaultTo([]),
  R.prop('length'),
  Boolean,
)
export const getOutstandingOrders = (state: RootState) => {
  const ImagingVendors = getImagingVendors(state)
  const imagingOrdersList = getImagingOrdersList(state)
  const soapLogs: Order[] = getSelectedOrders(state)
  // @TODO: remove this placeholder when vendorId is added to soap order
  const vendorName = ImagingVendorName.IDEXX
  const idexxVendorId = Utils.findByName(vendorName, ImagingVendors)?.id

  return soapLogs.filter((soapLog) => {
    const { imagingOrderId, procedure } = soapLog
    const businessScopeModalities =
      getBusinessScopeModalityList(idexxVendorId)(state)

    return (
      isImagingProcedure(soapLog) &&
      R.includes(procedure?.modalityId, businessScopeModalities) &&
      !R.includes(imagingOrderId, imagingOrdersList)
    )
  })
}
export const getHasOutstandingOrders = R.pipe(
  getOutstandingOrders,
  R.prop('length'),
  Boolean,
)

function* refreshSoapLogs() {
  const soapId: string = yield select(getSoapId)
  const soapBusinessId: string | Nil = yield select(getSoapBusinessId)

  if (soapId) {
    yield put(fetchSOAPOrders(soapId, soapBusinessId))
  }
}

export function* createOrderSaga({
  order,
  vendorLabel,
}: ReturnType<typeof createOrder>) {
  try {
    const {
      entities: { imagingOrder: createdOrders },
    } = yield* requestAPI(API.placeImagingOrder, order)
    yield refreshSoapLogs()
    yield put(updateImagingOrders(createdOrders))
    yield notifyNetworkSuccess(
      `Your image(s) were requested from ${vendorLabel}`,
    )
    yield put(createOrderSuccess())
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(createOrderFailure(error))
  }
}

export function* batchCreateOrderSaga({
  clientId,
  orders,
  vendorLabel,
  invoiceId,
}: ReturnType<typeof batchCreateOrder>) {
  try {
    const {
      result: list,
      entities: { imagingOrder: createdOrders },
    } = yield* requestAPI(API.batchPlaceImagingOrder, orders)
    yield refreshSoapLogs()
    if (invoiceId) {
      yield put(fetchInvoiceV3({ id: invoiceId }))
    }
    if (clientId) {
      yield put(fetchClientFinanceCharges({ id: clientId }))
    }
    yield put(updateImagingOrders(createdOrders))
    yield notifyNetworkSuccess(
      `Your image(s) were requested from ${vendorLabel}`,
    )
    yield put(batchCreateOrderSuccess(list))
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(batchCreateOrderFailure(error))
  }
}

export function* editOrderSaga({ order }: ReturnType<typeof editOrder>) {
  try {
    const {
      entities: { imagingOrder },
      result: updatedOrderId,
    } = yield* requestAPI(API.editImagingOrder, order)
    yield refreshSoapLogs()
    yield put(updateImagingOrders(imagingOrder))
    yield put(editOrderSuccess())
    return imagingOrder[updatedOrderId]
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(editOrderFailure(error))
    return null
  }
}

export function* cancelOrderSaga({ orderId }: ReturnType<typeof cancelOrder>) {
  try {
    const order: ImagingOrder = yield select(getImagingOrder(orderId))
    const ImagingOrderStatuses: Constant[] = yield select(
      getImagingOrderStatuses,
    )
    const cancelledStatusId = Utils.findByName(
      ImagingOrderStatus.CANCELLED,
      ImagingOrderStatuses,
    )?.id

    const cancelledOrder = {
      ...order,
      id: orderId,
      statusId: cancelledStatusId,
    }

    yield* requestAPI(API.editImagingOrder, cancelledOrder)
    yield refreshSoapLogs()
    yield put(cancelOrderSuccess(orderId))
    return cancelledOrder
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(cancelOrderFailure(error))
    return null
  }
}

export function* completeOrderSaga({
  orderId,
}: ReturnType<typeof completeOrder>) {
  try {
    const order: ImagingOrder = yield select(getImagingOrder(orderId))
    const ImagingOrderStatuses: Constant[] = yield select(
      getImagingOrderStatuses,
    )
    const completedStatusId = Utils.findByName(
      ImagingOrderStatus.COMPLETED,
      ImagingOrderStatuses,
    )?.id

    const completedOrder = {
      ...order,
      id: orderId,
      statusId: completedStatusId,
    }

    const {
      entities: { imagingOrder },
      result: updatedOrderId,
    } = yield* requestAPI(API.editImagingOrder, completedOrder)
    yield put(updateImagingOrders(imagingOrder))
    yield put(completeOrderSuccess())
    return imagingOrder[updatedOrderId]
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(completeOrderFailure(error))
    return null
  }
}

export function* fetchModalitySaga({
  targetBusinessId,
  force,
}: ReturnType<typeof fetchModality>) {
  try {
    const modalityMap = yield* requestAPI(
      API.fetchImagingModality,
      targetBusinessId,
      force,
    )
    yield put(fetchModalitySuccess(modalityMap))
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(fetchModalityFailure(error))
  }
}

export function* fetchOrdersSaga({ soapId }: ReturnType<typeof fetchOrders>) {
  try {
    const {
      entities: { imagingOrder = {} },
    } = yield* requestAPI(API.fetchImagingOrders, soapId)
    yield put(updateImagingOrders(imagingOrder))
    yield put(fetchOrdersSuccess(Object.keys(imagingOrder)))
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(fetchOrdersFailure(error))
  }
}

export function* fetchOrderSaga({ orderId }: ReturnType<typeof fetchOrder>) {
  try {
    const {
      entities: { imagingOrder = {} },
    } = yield* requestAPI(API.fetchImagingOrder, orderId)
    yield put(updateImagingOrders(imagingOrder))
    yield put(fetchOrderSuccess())
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(fetchOrderFailure(error))
  }
}

function* watchCreateOrder() {
  yield takeLeading(CREATE_ORDER, createOrderSaga)
}

function* watchBatchCreateOrder() {
  yield takeLeading(BATCH_CREATE_ORDER, batchCreateOrderSaga)
}

function* watchEditOrder() {
  yield takeLatest(EDIT_ORDER, editOrderSaga)
}

function* watchCancelOrder() {
  yield takeLatest(CANCEL_ORDER, cancelOrderSaga)
}

function* watchCompleteOrder() {
  yield takeLatest(COMPLETE_ORDER, completeOrderSaga)
}

function* watchFetchModality() {
  yield takeLatest(FETCH_MODALITY, fetchModalitySaga)
}

function* watchFetchOrders() {
  yield takeLatest(FETCH_ORDERS, fetchOrdersSaga)
}

function* watchFetchOrder() {
  yield takeLatest(FETCH_ORDER, fetchOrderSaga)
}

export function* imagingOrdersSaga() {
  yield all([
    watchCreateOrder(),
    watchBatchCreateOrder(),
    watchEditOrder(),
    watchCancelOrder(),
    watchCompleteOrder(),
    watchFetchModality(),
    watchFetchOrders(),
    watchFetchOrder(),
  ])
}

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { DateFormat, moment, PuiDialog } from '@pbt/pbt-ui-components'

import { SessionsTypes } from '~/constants/migration'
import i18n from '~/locales/i18n'
import { chooseImportSession } from '~/store/actions/migration'
import {
  getCurrentSession,
  getIsApplyingMappings,
} from '~/store/reducers/migration'
import {
  BaseMigrationDialogProps,
  MigrationSession,
} from '~/types/entities/migration'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import StepReviewExceptions from './StepReviewExceptions'
import StepSelectSession from './StepSelectSession'

const buildReviewExceptionsTitle = (session: MigrationSession) =>
  [
    i18n.t('Businesses:REVIEW_EXCEPTIONS'),
    session.date &&
      moment(session.date).format(DateFormat.FULL_DATE_TIME_SHORT_WITH_PIPE),
  ].join(' | ')

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
    },
  },
  content: {
    position: 'relative',
  },
}))

const OnGoingStandardizationDialog = ({
  open,
  onClose,
  businessId,
}: BaseMigrationDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const currentSession =
    useSelector(getCurrentSession) || ({} as MigrationSession)

  const sessionsFilterTypes = [SessionsTypes.RHAPSODY_ENTERPRISE]
  const isSessionSelected = !R.isEmpty(currentSession)

  const dialogTitle = isSessionSelected
    ? buildReviewExceptionsTitle(currentSession)
    : t('Businesses:MIGRATION.SELECT_SESSION_TO_REVIEW_EXCEPTIONS')
  const dialogSize = isSessionSelected ? 'lg' : 'sm'
  const StepComponent = isSessionSelected
    ? StepReviewExceptions
    : StepSelectSession

  const handleClose = useCallback(() => {
    dispatch(chooseImportSession(null))
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const setCloseOnAppliedMappings = useCloseAfterCreation(
    handleClose,
    getIsApplyingMappings,
  )

  const handleFinish = () => {
    setCloseOnAppliedMappings()
  }

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="on-going-standardization-dialog"
      classes={{
        dialogContentRoot: classes.content,
        paper: classes.paper,
      }}
      maxWidth={dialogSize}
      open={open}
      title={dialogTitle}
      onClose={handleClose}
    >
      <StepComponent
        allowDisabledSessions
        isEnterprise
        businessId={businessId}
        finishLabel={`${t('Common:APPLY_ACTION')} & ${t(
          'Common:FINISH_ACTION',
        )}`}
        sessionsFilterTypes={sessionsFilterTypes}
        onFinishClose={handleFinish}
      />
    </PuiDialog>
  )
}

export default OnGoingStandardizationDialog

import React from 'react'
import { useTranslation } from 'react-i18next'
import { PuiSelect, PuiSelectProps } from '@pbt/pbt-ui-components'

export type YesNoSelectProps = PuiSelectProps

const YesNoSelect = ({ value, ...rest }: YesNoSelectProps) => {
  const { t } = useTranslation('Common')

  const Items = [
    { id: '0', name: t('Common:NO') },
    { id: '1', name: t('Common:YES') },
  ]

  return (
    <PuiSelect
      items={Items}
      renderEmpty={false}
      value={value ? Number(value).toString() : Items[0].id}
      {...rest}
    />
  )
}

export default YesNoSelect

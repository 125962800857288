import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Input, InputLabel } from '@mui/material'
import { Field, PuiSelect, PuiSelectProps, Utils } from '@pbt/pbt-ui-components'

import { fetchClientsMembershipFilters } from '~/store/actions/clients'
import {
  getClientsIsFetchingFilters,
  getClientsMembershipFiltersListItems,
} from '~/store/reducers/clients'

export type MembershipSelectProps = PuiSelectProps & {
  nameField: Field
  valueField: Field
}

const MembershipSelect = ({
  nameField,
  valueField,
  ...rest
}: MembershipSelectProps) => {
  const dispatch = useDispatch()
  const membershipFiltersListItems: any[] = useSelector(
    getClientsMembershipFiltersListItems,
  )
  const isLoading: boolean = useSelector(getClientsIsFetchingFilters)
  const { t } = useTranslation('Common')

  const onOpen = () => {
    dispatch(fetchClientsMembershipFilters())
  }

  const onChange = (id: string) => {
    const [name, value] = id.split('=')

    nameField.setValue(name)
    valueField.setValue(value)
  }

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="membership-select">
        {t('Common:MEMBERSHIP')}
      </InputLabel>
      <PuiSelect
        input={<Input id="membership-select" />}
        isLoading={isLoading}
        items={membershipFiltersListItems}
        value={`${nameField.value}=${valueField.value}`}
        onChange={Utils.handleFormSelectInput(onChange)}
        onOpen={onOpen}
        {...rest}
      />
    </FormControl>
  )
}

export default MembershipSelect

import * as R from 'ramda'
import {
  all,
  call,
  delay,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import {
  ApiError,
  WellnessPlan,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import * as API from '~/api'
import {
  plansList,
  plansMap,
  versionsList,
  versionsMap,
} from '~/api/mocks/autoRenewingPlansInfo'
import FeatureToggle from '~/constants/featureToggle'
import { WellnessPlanSettings, WellnessPlanValidationError } from '~/types'

import { fetchBusiness } from '../actions/businesses'
import {
  CANCEL_WELLNESS_PLAN,
  CANCEL_WELLNESS_PLAN_OLD,
  CREATE_WELLNESS_PLAN_PAYMENT_METHOD,
  CREATE_WELLNESS_PLAN_VERSION,
  CREATE_WELLNESS_PLAN_VERSION_SUCCESS,
  DELETE_WELLNESS_PLAN_VERSION,
  DELETE_WELLNESS_PLAN_VERSION_SUCCESS,
  EDIT_WELLNESS_PLAN_VERSION,
  EDIT_WELLNESS_PLAN_VERSION_SUCCESS,
  EDIT_WELLNESS_PLANS_SETTINGS,
  FETCH_ACTIVE_WELLNESS_PLAN_VERSION,
  FETCH_AUTO_RENEWING_PLANS_INFO,
  FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE,
  FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST,
  FETCH_WELLNESS_PLAN,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE,
  FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS,
  FETCH_WELLNESS_PLAN_SAVINGS,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP,
  FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN,
  FETCH_WELLNESS_PLAN_VERSION,
  FETCH_WELLNESS_PLAN_VERSION_TEMPLATE,
  FETCH_WELLNESS_PLAN_WELCOME_MESSAGE,
  FETCH_WELLNESS_PLANS_LIST,
  FETCH_WELLNESS_PLANS_SETTINGS,
  FETCH_WP_CANCELLATION_INFO,
  GET_WELLNESS_PLAN_PAYMENT_METHOD,
  REPLACE_WELLNESS_PLAN_PAYMENT_METHOD,
  SEND_DEFAULT_WELCOME_EMAIL,
  SEND_WELCOME_EMAIL,
  TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN,
} from '../actions/types/wellnessPlans'
import {
  activeWellnessPlanVersionChanged,
  cancelWellnessPlan,
  cancelWellnessPlanFailure,
  cancelWellnessPlanOld,
  cancelWellnessPlanOldFailure,
  cancelWellnessPlanOldSuccess,
  cancelWellnessPlanOldValidationFailure,
  cancelWellnessPlanSuccess,
  createWellnessPlanPaymentMethod,
  createWellnessPlanPaymentMethodFailure,
  createWellnessPlanPaymentMethodSuccess,
  createWellnessPlanVersion,
  createWellnessPlanVersionFailure,
  createWellnessPlanVersionSuccess,
  createWellnessPlanVersionValidationFailure,
  deleteWellnessPlanVersion,
  deleteWellnessPlanVersionFailure,
  deleteWellnessPlanVersionSuccess,
  editWellnessPlansSettings,
  editWellnessPlansSettingsFailure,
  editWellnessPlansSettingsSuccess,
  editWellnessPlanVersion,
  editWellnessPlanVersionFailure,
  editWellnessPlanVersionSuccess,
  editWellnessPlanVersionValidationFailure,
  fetchActiveWellnessPlanVersion,
  fetchActiveWellnessPlanVersionFailure,
  fetchActiveWellnessPlanVersionSuccess,
  fetchAutoRenewingPlansInfoFailure,
  fetchAutoRenewingPlansInfoSuccess,
  fetchCurrentWellnessPlanWelcomeMessageFailure,
  fetchCurrentWellnessPlanWelcomeMessageSuccess,
  fetchMoreItemsForWellnessPlansList,
  fetchMoreItemsForWellnessPlansListFailure,
  fetchMoreItemsForWellnessPlansListSuccess,
  fetchWellnessPlan,
  fetchWellnessPlanBoopSignUpMessage,
  fetchWellnessPlanBoopSignUpMessageFailure,
  fetchWellnessPlanBoopSignUpMessageSuccess,
  fetchWellnessPlanBoopSignUpTemplate,
  fetchWellnessPlanBoopSignUpTemplateFailure,
  fetchWellnessPlanBoopSignUpTemplateSuccess,
  fetchWellnessPlanFailure,
  fetchWellnessPlanGlobalBenefitGroupsFailure,
  fetchWellnessPlanGlobalBenefitGroupsSuccess,
  fetchWellnessPlanSavings,
  fetchWellnessPlanSavingsDataForInvoice,
  fetchWellnessPlanSavingsDataForInvoiceFailure,
  fetchWellnessPlanSavingsDataForInvoiceSuccess,
  fetchWellnessPlanSavingsDataForSoap,
  fetchWellnessPlanSavingsDataForSoapFailure,
  fetchWellnessPlanSavingsDataForSoapSuccess,
  fetchWellnessPlanSavingsFailure,
  fetchWellnessPlanSavingsSuccess,
  fetchWellnessPlanSignUpToken,
  fetchWellnessPlanSignUpTokenFailure,
  fetchWellnessPlanSignUpTokenSuccess,
  fetchWellnessPlansList,
  fetchWellnessPlansList as fetchWellnessPlansListAction,
  fetchWellnessPlansListFailure,
  fetchWellnessPlansListSuccess,
  fetchWellnessPlansSettings,
  fetchWellnessPlansSettingsFailure,
  fetchWellnessPlansSettingsSuccess,
  fetchWellnessPlanSuccess,
  fetchWellnessPlanVersion,
  fetchWellnessPlanVersionFailure,
  fetchWellnessPlanVersionSuccess,
  fetchWellnessPlanVersionTemplate,
  fetchWellnessPlanVersionTemplateFailure,
  fetchWellnessPlanVersionTemplateSuccess,
  fetchWellnessPlanWelcomeMessage,
  fetchWellnessPlanWelcomeMessageFailure,
  fetchWellnessPlanWelcomeMessageSuccess,
  fetchWpCancellationInfo,
  fetchWpCancellationInfoFailure,
  fetchWpCancellationInfoSuccess,
  getWellnessPlanPaymentMethod,
  getWellnessPlanPaymentMethodFailure,
  getWellnessPlanPaymentMethodSuccess,
  replaceWellnessPlanPaymentMethod,
  replaceWellnessPlanPaymentMethodFailure,
  replaceWellnessPlanPaymentMethodSuccess,
  sendDefaultWelcomeEmail,
  sendDefaultWelcomeEmailFailure,
  sendDefaultWelcomeEmailSuccess,
  sendWelcomeEmail,
  sendWelcomeEmailFailure,
  sendWelcomeEmailSuccess,
  toggleAutoRenewalPlan,
  toggleAutoRenewalPlanFailure,
  toggleAutoRenewalPlanSuccess,
} from '../actions/wellnessPlans'
// @ts-ignore
import { patientMembershipsActions } from '../duck/patientMemberships'
import { finishLoading, startLoading } from '../duck/progress'
import {
  getCurrentBusinessCurrencyId,
  getCurrentBusinessId,
} from '../reducers/auth'
import { getFeatureToggle } from '../reducers/constants'
import { getWellnessPlanVersion } from '../reducers/wellnessPlans'
import { updateConversationsWithNewConversationOrigins } from './communications'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchWellnessPlansListSaga({
  from,
  to,
  query,
  businessId,
}: ReturnType<typeof fetchWellnessPlansList>) {
  try {
    if (!businessId) {
      yield put(startLoading('wellnessPlans'))
    }
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchWellnessPlans, from, to, query, businessId)
    yield call(updateEntities, entities)
    yield put(fetchWellnessPlansListSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchWellnessPlansListFailure(error as ApiError))
  } finally {
    if (!businessId) {
      yield put(finishLoading('wellnessPlans'))
    }
  }
}

export function* fetchMoreItemsForWellnessPlansListSaga({
  from,
  to,
  query,
  businessId,
}: ReturnType<typeof fetchMoreItemsForWellnessPlansList>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchWellnessPlans, from, to, query, businessId)
    yield call(updateEntities, entities)
    yield put(fetchMoreItemsForWellnessPlansListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForWellnessPlansListFailure(error as ApiError))
  }
}

export function* fetchWellnessPlanVersionSaga({
  id,
  withGlobalBenefits,
}: ReturnType<typeof fetchWellnessPlanVersion>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchWellnessPlanVersion,
      id,
      withGlobalBenefits,
    )
    yield call(updateEntities, entities)
    yield put(
      fetchWellnessPlanVersionSuccess(entities.wellnessPlansVersions[result]),
    )
  } catch (error) {
    yield put(fetchWellnessPlanVersionFailure(error as ApiError))
  }
}

export function* fetchActiveWellnessPlanVersionSaga({
  businessId,
}: ReturnType<typeof fetchActiveWellnessPlanVersion>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchActiveWellnessPlanVersion,
      businessId,
    )
    yield call(updateEntities, entities)
    yield put(fetchActiveWellnessPlanVersionSuccess(result))
  } catch (error) {
    yield put(fetchActiveWellnessPlanVersionFailure(error as ApiError))
  }
}

export function* createWellnessPlanVersionSaga({
  wellnessPlanVersion,
}: ReturnType<typeof createWellnessPlanVersion>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.createWellnessPlanVersion,
      wellnessPlanVersion,
    )

    yield call(updateEntities, entities)
    yield put(fetchWellnessPlansListAction())
    yield put(createWellnessPlanVersionSuccess(result))
  } catch (e) {
    const error = e as ApiError
    if (error?.response?.status === 409) {
      yield put(
        createWellnessPlanVersionValidationFailure(
          error as WellnessPlanValidationError,
        ),
      )
    } else {
      yield put(createWellnessPlanVersionFailure(error))
    }
  }
}

export function* editWellnessPlanVersionSaga({
  wellnessPlanVersion,
}: ReturnType<typeof editWellnessPlanVersion>) {
  try {
    const oldWellnessPlanVersion: WellnessPlanVersion = yield select(
      getWellnessPlanVersion(wellnessPlanVersion.id),
    )
    const { entities } = yield* requestAPI(
      API.editWellnessPlanVersion,
      wellnessPlanVersion,
    )
    yield call(updateEntities, entities)
    if (
      wellnessPlanVersion.active &&
      wellnessPlanVersion.active !== oldWellnessPlanVersion.active
    ) {
      yield put(activeWellnessPlanVersionChanged(wellnessPlanVersion.id))
    }
    yield put(editWellnessPlanVersionSuccess())
  } catch (e) {
    const error = e as ApiError
    if (error?.response?.status === 409) {
      yield put(
        editWellnessPlanVersionValidationFailure(
          error as WellnessPlanValidationError,
        ),
      )
    } else {
      yield put(editWellnessPlanVersionFailure(error))
    }
  }
}

export function* deleteWellnessPlanVersionSaga({
  wellnessPlanVersionId,
}: ReturnType<typeof deleteWellnessPlanVersion>) {
  try {
    yield* requestAPI(API.deleteWellnessPlanVersion, wellnessPlanVersionId)

    yield put(deleteWellnessPlanVersionSuccess(wellnessPlanVersionId))
  } catch (error) {
    yield put(deleteWellnessPlanVersionFailure(error as ApiError))
  }
}

export function* fetchWellnessPlanVersionTemplateSaga({
  cloneFromId,
}: ReturnType<typeof fetchWellnessPlanVersionTemplate>) {
  try {
    const result = yield* requestAPI(
      API.fetchWellnessPlanVersionTemplate,
      cloneFromId,
    )
    yield put(fetchWellnessPlanVersionTemplateSuccess(result))
  } catch (error) {
    yield put(fetchWellnessPlanVersionTemplateFailure(error as ApiError))
  }
}

export function* fetchWellnessPlansSettingsSaga({
  businessId,
}: ReturnType<typeof fetchWellnessPlansSettings>) {
  try {
    const { result: settings, entities } = yield requestAPI(
      API.fetchWellnessPlansSettings,
      businessId,
    )
    yield call(updateEntities, entities)
    yield put(fetchWellnessPlansSettingsSuccess(businessId, settings))
  } catch (error) {
    yield put(fetchWellnessPlansSettingsFailure(error as ApiError))
  }
}

export function* editWellnessPlansSettingsSaga({
  businessId,
  settings,
}: ReturnType<typeof editWellnessPlansSettings>) {
  try {
    const newSettings: WellnessPlanSettings = yield requestAPI(
      API.editWellnessPlansSettings,
      businessId,
      settings,
    )
    yield put(editWellnessPlansSettingsSuccess(businessId, newSettings))
  } catch (error) {
    yield put(editWellnessPlansSettingsFailure(error as ApiError))
  }
}

export function* fetchWellnessPlanSaga({
  id,
}: ReturnType<typeof fetchWellnessPlan>) {
  try {
    const wellnessPlan = yield* requestAPI(API.fetchWellnessPlan, id)
    yield put(fetchWellnessPlanSuccess(wellnessPlan))
  } catch (error) {
    yield put(fetchWellnessPlanFailure(error as ApiError))
  }
}

export function* fetchWellnessPlanGlobalBenefitGroupsSaga() {
  try {
    const globalBenefitGroups = yield* requestAPI(
      API.fetchWellnessPlanGlobalBenefitGroups,
    )
    yield put(fetchWellnessPlanGlobalBenefitGroupsSuccess(globalBenefitGroups))
  } catch (error) {
    yield put(fetchWellnessPlanGlobalBenefitGroupsFailure(error as ApiError))
  }
}

export function* fetchWellnessPlanSignUpTokenSaga({
  clientId,
}: ReturnType<typeof fetchWellnessPlanSignUpToken>) {
  try {
    const result = yield* requestAPI(API.fetchWellnessPlanSignUpToken, clientId)
    yield put(fetchWellnessPlanSignUpTokenSuccess(result.token))
  } catch (error) {
    yield put(fetchWellnessPlanSignUpTokenFailure(error as ApiError))
  }
}

export function* cancelWellnessPlanOldSaga({
  clientId,
  patientId,
  planLogIds,
  reason,
  notes,
  confirmToken,
}: ReturnType<typeof cancelWellnessPlanOld>) {
  try {
    const { entities } = yield* requestAPI(
      API.cancelWellnessPlansOld,
      clientId,
      patientId,
      planLogIds,
      reason,
      notes,
      confirmToken,
    )
    yield call(updateEntities, entities)
    if (entities.patientMemberships) {
      yield put(
        patientMembershipsActions.updateItems(entities.patientMemberships),
      )
    }
    yield put(
      cancelWellnessPlanOldSuccess(
        patientId,
        entities.patientMemberships[patientId].plans,
      ),
    )
  } catch (e) {
    const error = e as ApiError
    if (error?.response?.status === 409) {
      yield put(cancelWellnessPlanOldValidationFailure(error))
    } else {
      yield put(cancelWellnessPlanOldFailure(error))
    }
  }
}

export function* fetchWpCancellationInfoSaga({
  clientId,
  patientId,
  cancelId,
}: ReturnType<typeof fetchWpCancellationInfo>) {
  try {
    const result = yield* requestAPI(
      API.fetchWpCancellationInfo,
      clientId,
      patientId,
      cancelId,
    )
    /**
     * cancellation patient membership provides plans that are considered for cancelling,
     * but we need to map only cancellation info for further counting
     */
    yield put(
      patientMembershipsActions.updateItems({
        [result.patientId]: R.omit(['plans'], result),
      }),
    )
    yield put(fetchWpCancellationInfoSuccess(patientId, result.plans))
  } catch (error) {
    yield put(fetchWpCancellationInfoFailure(error as ApiError))
  }
}

export function* cancelWellnessPlanSaga({
  clientId,
  patientId,
  cancelData,
  confirmToken,
}: ReturnType<typeof cancelWellnessPlan>) {
  try {
    const { entities } = yield* requestAPI(
      API.cancelWellnessPlan,
      clientId,
      patientId,
      cancelData,
      confirmToken,
    )
    if (entities.patientMemberships) {
      yield put(
        patientMembershipsActions.updateItems(entities.patientMemberships),
      )
    }
    yield put(
      cancelWellnessPlanSuccess(
        patientId,
        entities?.patientMemberships[patientId]?.plans,
      ),
    )
  } catch (error) {
    yield put(cancelWellnessPlanFailure(error as ApiError))
  }
}

export function* createWellnessPlanPaymentMethodSaga({
  clientId,
}: ReturnType<typeof createWellnessPlanPaymentMethod>) {
  try {
    const currentBusinessCurrencyId: string = yield select(
      getCurrentBusinessCurrencyId,
    )
    const result = yield* requestAPI(
      API.createWellnessPlanPaymentMethod,
      clientId,
      currentBusinessCurrencyId,
    )
    yield put(createWellnessPlanPaymentMethodSuccess(result))
  } catch (error) {
    yield put(createWellnessPlanPaymentMethodFailure(error as ApiError))
  }
}

export function* getWellnessPlanPaymentMethodSaga({
  id,
}: ReturnType<typeof getWellnessPlanPaymentMethod>) {
  try {
    const result = yield* requestAPI(API.getWellnessPlanPaymentMethod, id)
    yield put(getWellnessPlanPaymentMethodSuccess(result))
  } catch (error) {
    yield put(getWellnessPlanPaymentMethodFailure(error as ApiError))
  }
}

export function* replaceWellnessPlanPaymentMethodSaga({
  membershipId,
  paymentMethodId,
}: ReturnType<typeof replaceWellnessPlanPaymentMethod>) {
  try {
    const { entities } = yield* requestAPI(
      API.replaceWellnessPlanPaymentMethod,
      membershipId,
      paymentMethodId,
    )
    yield call(updateEntities, entities)
    if (entities.patientMemberships) {
      yield put(
        patientMembershipsActions.updateItems(entities.patientMemberships),
      )
    }
    yield put(replaceWellnessPlanPaymentMethodSuccess())
  } catch (error) {
    yield put(replaceWellnessPlanPaymentMethodFailure(error as ApiError))
  }
}

export function* fetchWellnessPlanSavingsSaga({
  clientId,
}: ReturnType<typeof fetchWellnessPlanSavings>) {
  try {
    const savings = yield* requestAPI(API.fetchWellnessPlanSavings, clientId)
    yield put(fetchWellnessPlanSavingsSuccess(clientId, savings))
  } catch (error) {
    yield put(fetchWellnessPlanSavingsFailure(error as ApiError))
  }
}

export function* fetchWellnessPlanWelcomeTemplateSaga({
  templateText,
}: ReturnType<typeof fetchWellnessPlanWelcomeMessage>) {
  try {
    const template = yield* requestAPI(
      API.fetchWellnessPlanWelcomeTemplate,
      templateText,
    )
    yield put(fetchWellnessPlanWelcomeMessageSuccess(template))
  } catch (error) {
    yield put(fetchWellnessPlanWelcomeMessageFailure(error as ApiError))
  }
}

export function* fetchWellnessPlanSignUpInBoopTemplateSaga({
  templateText,
}: ReturnType<typeof fetchWellnessPlanBoopSignUpTemplate>) {
  try {
    const template = yield* requestAPI(
      API.fetchWellnessPlanSignUpInBoopTemplate,
      templateText,
    )
    yield put(fetchWellnessPlanBoopSignUpTemplateSuccess(template))
  } catch (error) {
    yield put(fetchWellnessPlanBoopSignUpTemplateFailure(error as ApiError))
  }
}

export function* fetchWellnessPlanSignUpInBoopMessageSaga({
  signUpMessageContext,
}: ReturnType<typeof fetchWellnessPlanBoopSignUpMessage>) {
  try {
    const message = yield* requestAPI(
      API.fetchWellnessPlanSignUpInBoopDefaultMessage,
      signUpMessageContext,
    )
    yield put(fetchWellnessPlanBoopSignUpMessageSuccess(message))
  } catch (error) {
    yield put(fetchWellnessPlanBoopSignUpMessageFailure(error as ApiError))
  }
}

export function* fetchCurrentWellnessPlanWelcomeTemplateSaga() {
  try {
    const template = yield* requestAPI(
      API.fetchCurrentWellnessPlanWelcomeTemplate,
    )
    yield put(fetchCurrentWellnessPlanWelcomeMessageSuccess(template))
  } catch (error) {
    yield put(fetchCurrentWellnessPlanWelcomeMessageFailure(error as ApiError))
  }
}

export function* sendWelcomeEmailSaga({
  messageData,
}: ReturnType<typeof sendWelcomeEmail>) {
  try {
    const sendMessageResult = yield* requestAPI(
      API.sendWellnessPlanWelcomeMessage,
      messageData,
    )
    const createdConversationIds = sendMessageResult.createdConversations || []
    yield call(updateConversationsWithNewConversationOrigins, {
      ids: createdConversationIds,
      ...messageData,
    })
    yield put(sendWelcomeEmailSuccess(createdConversationIds))
  } catch (error) {
    yield put(sendWelcomeEmailFailure(error as ApiError))
  }
}

export function* sendDefaultWelcomeEmailSaga({
  clientId,
  email,
  selection,
}: ReturnType<typeof sendDefaultWelcomeEmail>) {
  try {
    const isWelcomeEmailForOmnichannelEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.WELCOME_EMAIL_FOR_OMNICHANNEL),
    )

    if (isWelcomeEmailForOmnichannelEnabled) {
      yield* requestAPI(API.sendWellnessPlanDefaultWelcomeMessageV2, {
        clientId,
        email,
        patientWPlanIdPairs: selection,
      })
    } else {
      yield* requestAPI(API.sendWellnessPlanDefaultWelcomeMessage, clientId, {
        email,
      })
    }
    yield put(sendDefaultWelcomeEmailSuccess())
  } catch (error) {
    yield put(sendDefaultWelcomeEmailFailure(error as ApiError))
  }
}

export function* fetchWellnessPlanSavingsDataForSoapSaga({
  appointmentId,
}: ReturnType<typeof fetchWellnessPlanSavingsDataForSoap>) {
  try {
    const result = yield* requestAPI(
      API.fetchWellnessPlanSavingsDataForSoap,
      appointmentId,
    )
    yield put(fetchWellnessPlanSavingsDataForSoapSuccess(result))
  } catch (error) {
    yield put(fetchWellnessPlanSavingsDataForSoapFailure(error as ApiError))
  }
}

export function* fetchWellnessPlanSavingsDataForInvoiceSaga({
  invoiceId,
}: ReturnType<typeof fetchWellnessPlanSavingsDataForInvoice>) {
  try {
    const result = yield* requestAPI(
      API.fetchWellnessPlanSavingsDataForInvoice,
      invoiceId,
    )
    yield put(fetchWellnessPlanSavingsDataForInvoiceSuccess(result))
  } catch (error) {
    yield put(fetchWellnessPlanSavingsDataForInvoiceFailure(error as ApiError))
  }
}

export function* toggleAutoRenewalPlanSaga({
  planId,
  autoRenewal,
}: ReturnType<typeof toggleAutoRenewalPlan>) {
  try {
    const updatedPlan: WellnessPlan = yield* requestAPI(
      API.toggleAutoRenewalPlan,
      { input: { planId, autoRenewal } },
    )
    yield put(toggleAutoRenewalPlanSuccess(updatedPlan.versionId, updatedPlan))
  } catch (e) {
    const error = e as ApiError
    yield put(toggleAutoRenewalPlanFailure(error))
  }
}

export function* fetchAutoRenewingPlansInfoSaga() {
  try {
    // will be implemented in CVC-13486
    yield delay(3000)
    yield put(
      fetchAutoRenewingPlansInfoSuccess({
        plansMap,
        plansList,
        versionsList,
        versionsMap,
      }),
    )
  } catch (error) {
    yield put(fetchAutoRenewingPlansInfoFailure(error as ApiError))
  }
}

export function* refetchBusinessAfterWellnessPlanVersionUpdatedSaga() {
  const currentBusinessId: string = yield select(getCurrentBusinessId)
  yield put(fetchBusiness(currentBusinessId))
}

function* watchFetchWellnessPlansList() {
  yield takeLeading(FETCH_WELLNESS_PLANS_LIST, fetchWellnessPlansListSaga)
}

function* watchFetchMoreItemsForWellnessPlansList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST,
    fetchMoreItemsForWellnessPlansListSaga,
  )
}

function* watchFetchWellnessPlanVersion() {
  yield takeLeading(FETCH_WELLNESS_PLAN_VERSION, fetchWellnessPlanVersionSaga)
}

function* watchFetchActiveWellnessPlanVersion() {
  yield takeLeading(
    FETCH_ACTIVE_WELLNESS_PLAN_VERSION,
    fetchActiveWellnessPlanVersionSaga,
  )
}

function* watchCreateWellnessPlanVersion() {
  yield takeLeading(CREATE_WELLNESS_PLAN_VERSION, createWellnessPlanVersionSaga)
}

function* watchEditWellnessPlanVersion() {
  yield takeLeading(EDIT_WELLNESS_PLAN_VERSION, editWellnessPlanVersionSaga)
}

function* watchDeleteWellnessPlanVersion() {
  yield takeLeading(DELETE_WELLNESS_PLAN_VERSION, deleteWellnessPlanVersionSaga)
}

function* watchFetchWellnessPlanVersionTemplate() {
  yield takeLeading(
    FETCH_WELLNESS_PLAN_VERSION_TEMPLATE,
    fetchWellnessPlanVersionTemplateSaga,
  )
}

function* watchFetchWellnessPlansSettings() {
  yield takeLatest(
    FETCH_WELLNESS_PLANS_SETTINGS,
    fetchWellnessPlansSettingsSaga,
  )
}

function* watchEditWellnessPlansSettings() {
  yield takeLatest(EDIT_WELLNESS_PLANS_SETTINGS, editWellnessPlansSettingsSaga)
}

function* watchFetchWellnessPlan() {
  yield takeLeading(FETCH_WELLNESS_PLAN, fetchWellnessPlanSaga)
}

function* watchFetchWellnessPlanGlobalBenefits() {
  yield takeLeading(
    FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS,
    fetchWellnessPlanGlobalBenefitGroupsSaga,
  )
}

function* watchFetchWellnessPlanSignUpToken() {
  yield takeLeading(
    FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN,
    fetchWellnessPlanSignUpTokenSaga,
  )
}

function* watchCancelWellnessPlanOld() {
  yield takeLeading(CANCEL_WELLNESS_PLAN_OLD, cancelWellnessPlanOldSaga)
}

function* watchFetchCancelWellnessPlan() {
  yield takeLeading(FETCH_WP_CANCELLATION_INFO, fetchWpCancellationInfoSaga)
}

function* watchCancelWellnessPlan() {
  yield takeLeading(CANCEL_WELLNESS_PLAN, cancelWellnessPlanSaga)
}

function* watchCreateWellnessPlanPaymentMethod() {
  yield takeLeading(
    CREATE_WELLNESS_PLAN_PAYMENT_METHOD,
    createWellnessPlanPaymentMethodSaga,
  )
}

function* watchGetWellnessPlanPaymentMethod() {
  yield takeLeading(
    GET_WELLNESS_PLAN_PAYMENT_METHOD,
    getWellnessPlanPaymentMethodSaga,
  )
}

function* watchReplaceWellnessPlanPaymentMethod() {
  yield takeLeading(
    REPLACE_WELLNESS_PLAN_PAYMENT_METHOD,
    replaceWellnessPlanPaymentMethodSaga,
  )
}

function* watchFetchWellnessPlanSavings() {
  yield takeLeading(FETCH_WELLNESS_PLAN_SAVINGS, fetchWellnessPlanSavingsSaga)
}

function* watchFetchWellnessPlanWelcomeTemplate() {
  yield takeLeading(
    FETCH_WELLNESS_PLAN_WELCOME_MESSAGE,
    fetchWellnessPlanWelcomeTemplateSaga,
  )
}

function* watchFetchWellnessPlanSignUpInBoopTemplate() {
  yield takeLeading(
    FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE,
    fetchWellnessPlanSignUpInBoopTemplateSaga,
  )
}

function* watchFetchWellnessPlanSignUpInBoopMessage() {
  yield takeLeading(
    FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE,
    fetchWellnessPlanSignUpInBoopMessageSaga,
  )
}

function* watchFetchCurrentWellnessPlanWelcomeTemplate() {
  yield takeLeading(
    FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE,
    fetchCurrentWellnessPlanWelcomeTemplateSaga,
  )
}

function* watchSendDefaultWelcomeEmail() {
  yield takeLatest(SEND_DEFAULT_WELCOME_EMAIL, sendDefaultWelcomeEmailSaga)
}

function* watchSendWelcomeEmail() {
  yield takeLatest(SEND_WELCOME_EMAIL, sendWelcomeEmailSaga)
}

function* watchFetchWellnessPlanSavingsDataForSoap() {
  yield takeLatest(
    FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP,
    fetchWellnessPlanSavingsDataForSoapSaga,
  )
}

function* watchFetchWellnessPlanSavingsDataForInvoice() {
  yield takeLatest(
    FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE,
    fetchWellnessPlanSavingsDataForInvoiceSaga,
  )
}

function* watchToggleAutoRenewalPlan() {
  yield takeLatest(TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN, toggleAutoRenewalPlanSaga)
}

function* watchFetchAutoRenewingPlansInfo() {
  yield takeLatest(
    FETCH_AUTO_RENEWING_PLANS_INFO,
    fetchAutoRenewingPlansInfoSaga,
  )
}

/**
 * Version updates affect several flag fields of related business
 * so that we should refetch it to actualise business state
 * */
function* watchRefetchBusinessAfterWellnessPlanVersionUpdated() {
  yield takeLatest(
    [
      EDIT_WELLNESS_PLAN_VERSION_SUCCESS,
      DELETE_WELLNESS_PLAN_VERSION_SUCCESS,
      CREATE_WELLNESS_PLAN_VERSION_SUCCESS,
    ],
    refetchBusinessAfterWellnessPlanVersionUpdatedSaga,
  )
}

export default function* wellnessPlansSaga() {
  yield all([
    watchFetchWellnessPlansList(),
    watchFetchMoreItemsForWellnessPlansList(),
    watchFetchWellnessPlanVersion(),
    watchFetchActiveWellnessPlanVersion(),
    watchCreateWellnessPlanVersion(),
    watchEditWellnessPlanVersion(),
    watchDeleteWellnessPlanVersion(),
    watchFetchWellnessPlanVersionTemplate(),
    watchFetchWellnessPlansSettings(),
    watchEditWellnessPlansSettings(),
    watchFetchWellnessPlan(),
    watchFetchWellnessPlanGlobalBenefits(),
    watchFetchWellnessPlanSignUpToken(),
    watchCancelWellnessPlanOld(),
    watchCancelWellnessPlan(),
    watchFetchCancelWellnessPlan(),
    watchCreateWellnessPlanPaymentMethod(),
    watchGetWellnessPlanPaymentMethod(),
    watchReplaceWellnessPlanPaymentMethod(),
    watchFetchWellnessPlanSavings(),
    watchFetchWellnessPlanWelcomeTemplate(),
    watchFetchCurrentWellnessPlanWelcomeTemplate(),
    watchSendDefaultWelcomeEmail(),
    watchSendWelcomeEmail(),
    watchFetchWellnessPlanSignUpInBoopTemplate(),
    watchFetchWellnessPlanSignUpInBoopMessage(),
    watchFetchWellnessPlanSavingsDataForSoap(),
    watchFetchWellnessPlanSavingsDataForInvoice(),
    watchToggleAutoRenewalPlan(),
    watchFetchAutoRenewingPlansInfo(),
    watchRefetchBusinessAfterWellnessPlanVersionUpdated(),
  ])
}

import i18n from '~/locales/i18n'

export const WhiteboardColumnsTypes = {
  SCHEDULED: 'scheduled',
  ARRIVED: 'arrived',
  ADMITTED: 'admitted',
  DISCHARGED: 'discharged',
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
  CANCELLED: 'cancelled',
}

export const WhiteboardColumnsTypesList = Object.values(WhiteboardColumnsTypes)

export const WhiteboardColumnsNames = {
  [WhiteboardColumnsTypes.SCHEDULED]: i18n.t(
    'Constants:WHITEBOARD_COLUMNS.SCHEDULED',
  ),
  [WhiteboardColumnsTypes.ARRIVED]: i18n.t(
    'Constants:WHITEBOARD_COLUMNS.ARRIVED',
  ),
  [WhiteboardColumnsTypes.ADMITTED]: i18n.t(
    'Constants:WHITEBOARD_COLUMNS.ADMITTED',
  ),
  [WhiteboardColumnsTypes.DISCHARGED]: i18n.t(
    'Constants:WHITEBOARD_COLUMNS.DISCHARGED',
  ),
  [WhiteboardColumnsTypes.CONFIRMED]: i18n.t(
    'Constants:WHITEBOARD_COLUMNS.CONFIRMED',
  ),
  [WhiteboardColumnsTypes.UNCONFIRMED]: i18n.t(
    'Constants:WHITEBOARD_COLUMNS.UNCONFIRMED',
  ),
  [WhiteboardColumnsTypes.CANCELLED]: i18n.t(
    'Constants:WHITEBOARD_COLUMNS.CANCELLED',
  ),
}

export enum WhiteboardRowHeaderTypes {
  EXAMS = 'EXAMS',
  SURGERY = 'SURGERY',
  DENTAL = 'DENTAL',
  TECH_VISIT = 'TECH_VISIT',
  BOARDING = 'BOARDING',
  GROOMING = 'GROOMING',
  PERSONAL_BUSY = 'PERSONAL_BUSY',
  OTHER = 'OTHER',
}

export const WhiteboardRowHeaderNames = {
  [WhiteboardRowHeaderTypes.EXAMS]: i18n.t(
    'TimeTable:WHITEBOARD_ROW_HEADER_TYPES.EXAMS',
  ),
  [WhiteboardRowHeaderTypes.SURGERY]: i18n.t(
    'TimeTable:WHITEBOARD_ROW_HEADER_TYPES.SURGERY',
  ),
  [WhiteboardRowHeaderTypes.DENTAL]: i18n.t(
    'TimeTable:WHITEBOARD_ROW_HEADER_TYPES.DENTAL',
  ),
  [WhiteboardRowHeaderTypes.TECH_VISIT]: i18n.t(
    'TimeTable:WHITEBOARD_ROW_HEADER_TYPES.TECH_VISIT',
  ),
  [WhiteboardRowHeaderTypes.BOARDING]: i18n.t('TimeTable:BOARDING'),
  [WhiteboardRowHeaderTypes.GROOMING]: i18n.t('TimeTable:GROOMING'),
  [WhiteboardRowHeaderTypes.PERSONAL_BUSY]: i18n.t(
    'TimeTable:WHITEBOARD_ROW_HEADER_TYPES.PERSONAL_BUSY',
  ),
  [WhiteboardRowHeaderTypes.OTHER]: i18n.t(
    'TimeTable:WHITEBOARD_ROW_HEADER_TYPES.OTHER',
  ),
}

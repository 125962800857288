import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import { getFinanceInvoiceIsPaid, getFinanceInvoiceIsPosted } from '~/store/reducers/finance'
import { getInvoiceId } from '~/store/reducers/soap'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2, 3),
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
    },
    icon: {
      width: 20,
      height: 20,
      color: theme.colors.important,
    },
    title: {
      color: theme.colors.important,
    },
  }),
  { name: 'InvoicePostedBanner' },
)

export interface InvoicePostedBannerProps {
  className?: string
}

const InvoicePostedBanner = ({ className }: InvoicePostedBannerProps) => {
  const classes = useStyles()
  const invoiceId = useSelector(getInvoiceId)
  const isInvoicePosted = useSelector(getFinanceInvoiceIsPosted(invoiceId))

  const isInvoicePaid = useSelector(getFinanceInvoiceIsPaid(invoiceId))
  const { t } = useTranslation(['Common', 'Soap'])

  if (!isInvoicePosted) {
    return null
  }

  return (
    <Grid
      container
      item
      className={classNames(className, classes.root)}
      wrap="nowrap"
    >
      <WarnAlert className={classes.icon} />
      <Text strong className={classes.title} ml={1} mr={1.5}>
        {t('Common:INVOICE_IS_POSTED')}
      </Text>
      {!isInvoicePaid &&
        <Text>{t('Soap:INVOICE_POSTED_BANNER.NEED_TO_ORDER_SOMETHING')}</Text>
      } 
    </Grid>
  )
}

export default InvoicePostedBanner

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  BillingAddressFormHandle,
  CountryCode,
  NamedEntity,
  Nil,
  Utils,
} from '@pbt/pbt-ui-components'

import { InvoiceEmailDto } from '~/api/graphql/generated/types'
import type { InvoiceDialogProps } from '~/components/dashboard/invoices/InvoiceDialog'
import { getIsBatchInvoice } from '~/components/dashboard/invoices/invoiceUtils'
import { InvoiceFromSpainMissingFields } from '~/components/dashboard/invoices/locale-invoice-options/spain/InvoiceMissingFieldsDialog'
import { GeneratingPdfContentKind } from '~/constants/communications'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import InvoiceType from '~/constants/InvoiceType'
import { OrderType } from '~/constants/SOAPStates'
import {
  emailInvoiceV2,
  generatePdfForChargeSheet,
  generatePdfForInvoice,
  generatePdfForInvoiceV2,
  generatePdfForMedicalHistory,
  generatePdfForPrescription,
  generatePdfForReportCard,
} from '~/store/actions/communications'
import {
  checkPendingActiveRx,
  checkPendingImaging,
  checkPendingLabTests,
  getClientFinanceLoading,
  getExpandedGroups,
  getHasPendingActiveRxForChargeSheet,
  getHasPendingImagingForChargeSheet,
  getHasPendingLabTestsForChargeSheet,
  getIsCheckingPendingOutstandingOrdersForChargeSheet,
  getIsGeneratingChargeSheetPdf,
  getMultipleChargeSheetSubItems,
} from '~/store/duck/clientFinanceData'
import { getMedicalHistoryCardIsGeneratingPdf } from '~/store/duck/medicalHistoryCard'
import { getReportCardIsGeneratingPdf } from '~/store/duck/reportCard'
import {
  getExpandedInvoiceGroupIds,
  getFinanceInvoice,
  getFinanceInvoiceLocaleOptions,
  getIsGeneratingInvoicePdf,
} from '~/store/reducers/finance'
import { getPrescriptionIsGeneratingPdf } from '~/store/reducers/orders'
import {
  BatchInvoice,
  ChargeSheet,
  ChargeSheetItemSection,
  ImagingOrder,
  InvoiceLineItem,
  InvoiceLineItemGroup,
  InvoiceOrEstimate,
} from '~/types'
import { isChargesInvoiceType } from '~/utils/finance'
import { getIsCreatedPrescriptionChewyActiveRx } from '~/utils/prescription'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import { useNavigatePrevState } from '~/utils/useNavigatePrevState'

import { createBillingAddress, updateBillingAddress } from '../actions/payments'
import {
  fetchChargesByLog,
  fetchSoapCharges,
  getCharges,
  getChargesLoading,
} from '../duck/charges'
import { getHasOpenDialogsByName } from '../duck/dialogs'
import { getBusinessScopeModalityList } from '../duck/imagingOrders'
import { getIsFetchingAllPendingActiveRxForChargeSheet } from '../duck/prescriptions'
import { getCurrentBusinessCIF } from '../reducers/auth'
import {
  getFeatureToggle,
  getProcedureCategory,
  getReadyForOrderLabStates,
} from '../reducers/constants'
import { getBillingAddresses, getPaymentsIsLoading } from '../reducers/payments'
import { getIsCurrentContextSoap, getSoapId } from '../reducers/soap'
import { getUserTaxCertificationNumber } from '../reducers/users'
import { useBusinessLocalization } from './business'
import { useGetIdexxImagingId } from './constants'
import { useGetActiveImagingVendorsMap } from './imagingVendorConfig'
import { useGetActiveLabVendorsMap } from './labVendorConfig'

const isUnusedPrepaidLog = ({ prepaid, usedQuantity }: InvoiceLineItem) =>
  prepaid && !usedQuantity
const isDeclinedLog = R.prop('declined')
const isOfMatchingType = (logType: OrderType) => R.propEq('logType', logType)

export const getChargesLogsByLogType = (
  logType: OrderType,
  groups: (InvoiceLineItemGroup | ChargeSheetItemSection)[],
) => {
  const logsByGroup = groups.map((group) => {
    const items = R.pipe(
      R.map((groupedItem: InvoiceLineItem) =>
        groupedItem.items ? groupedItem.items : groupedItem,
      ),
      R.flatten,
    )(group?.groupedItems || [])

    return R.filter(
      R.allPass([
        isOfMatchingType(logType),
        R.complement(isUnusedPrepaidLog),
        R.complement(isDeclinedLog),
      ]),
      items,
    )
  })

  return R.flatten(logsByGroup)
}

export const useGetInvoiceLogsByLogType = (
  logType: OrderType,
  invoice: InvoiceOrEstimate | BatchInvoice | Nil,
) => {
  const groups = invoice?.groups || []
  return getChargesLogsByLogType(logType, groups)
}

export const useGetChargeSheetLogsByLogType = (
  logType: OrderType,
  chargeSheet: ChargeSheet | Nil,
) => {
  const sections = useSelector(
    getMultipleChargeSheetSubItems(chargeSheet?.sections || []),
  )
  return getChargesLogsByLogType(logType, sections)
}

export const invoiceLineToImagingOrder = (
  invoiceLineItem: InvoiceLineItem,
): ImagingOrder => ({
  name: invoiceLineItem.name,
  notes: invoiceLineItem.notes,
  id: invoiceLineItem.logId,
  procedure: {
    id: invoiceLineItem.priceItemId,
    procedureCode: invoiceLineItem.procedureCode,
    modalityId: invoiceLineItem.procedureModalityId,
  },
  assignedVetId: null,
  soapId: invoiceLineItem.soapId,
})

type UseGetHasOutstandingOrders = {
  clientId: string | Nil
  invoiceId?: string | Nil
  logs: InvoiceLineItem[]
}

export const useGetHasOutstandingChewyOrders = ({
  logs,
  clientId,
}: UseGetHasOutstandingOrders) => {
  const dispatch = useDispatch()

  const isChewyRxFinalizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_RX_FINALIZATION),
  )
  const hasPendingChewyActiveRxOrders = useSelector(
    getHasPendingActiveRxForChargeSheet,
  )
  const isLoadingChargeSheet = useSelector(getClientFinanceLoading)
  const isCheckingPendingOutstandingOrdersForChargeSheet = useSelector(
    getIsCheckingPendingOutstandingOrdersForChargeSheet,
  )
  const isFetchingAllPendingActiveRxForChargeSheet = useSelector(
    getIsFetchingAllPendingActiveRxForChargeSheet,
  )

  const hasOutstandingChewyOrders =
    logs &&
    logs.some((log) =>
      getIsCreatedPrescriptionChewyActiveRx(log.prescriptionType, log.origin),
    )

  const isLoading =
    isLoadingChargeSheet ||
    isCheckingPendingOutstandingOrdersForChargeSheet ||
    isFetchingAllPendingActiveRxForChargeSheet

  useEffect(() => {
    if (!isChewyRxFinalizationEnabled || isLoadingChargeSheet) {
      return
    }

    if (hasOutstandingChewyOrders && clientId) {
      dispatch(checkPendingActiveRx({ clientId }))
    }
  }, [
    isChewyRxFinalizationEnabled,
    isLoadingChargeSheet,
    clientId,
    hasOutstandingChewyOrders,
  ])

  return {
    hasOutstandingChewyOrders,
    hasPendingChewyActiveRxOrders,
    isLoading,
  }
}

export const useGetHasOutstandingLabOrders = ({
  logs,
  clientId,
  invoiceId,
}: UseGetHasOutstandingOrders) => {
  const dispatch = useDispatch()

  const isChewyRxFinalizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_RX_FINALIZATION),
  )
  const hasPendingLabOrders = useSelector(getHasPendingLabTestsForChargeSheet)
  const isLoadingChargeSheet = useSelector(getClientFinanceLoading)
  const isCheckingPendingOutstandingOrdersForChargeSheet = useSelector(
    getIsCheckingPendingOutstandingOrdersForChargeSheet,
  )

  const activeLabVendors = useGetActiveLabVendorsMap()
  const hasLabs = !R.isNil(logs)
    ? logs.some((log) => activeLabVendors[log.labTestVendorId])
    : false
  const isLoading =
    isLoadingChargeSheet || isCheckingPendingOutstandingOrdersForChargeSheet

  useEffect(() => {
    if (!isChewyRxFinalizationEnabled || isLoadingChargeSheet) {
      return
    }

    if (hasLabs && clientId) {
      dispatch(checkPendingLabTests({ clientId, invoiceId }))
    }
  }, [clientId, hasLabs, isChewyRxFinalizationEnabled, isLoadingChargeSheet])

  return {
    hasPendingLabOrders,
    hasLabs,
    isLoading,
  }
}

export const useGetHasOutstandingImagingOrders = ({
  logs,
  clientId,
  invoiceId,
}: UseGetHasOutstandingOrders) => {
  const dispatch = useDispatch()

  const isChewyRxFinalizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_RX_FINALIZATION),
  )
  const hasPendingImagingOrders = useSelector(
    getHasPendingImagingForChargeSheet,
  )
  const isLoadingChargeSheet = useSelector(getClientFinanceLoading)
  const isCheckingPendingOutstandingOrdersForChargeSheet = useSelector(
    getIsCheckingPendingOutstandingOrdersForChargeSheet,
  )

  const hasProcedureLogs = logs && logs.length > 0
  const isLoading =
    isLoadingChargeSheet || isCheckingPendingOutstandingOrdersForChargeSheet

  useEffect(() => {
    if (!isChewyRxFinalizationEnabled || isLoadingChargeSheet) {
      return
    }

    if (hasProcedureLogs && clientId) {
      dispatch(checkPendingImaging({ clientId, invoiceId }))
    }
  }, [
    clientId,
    hasProcedureLogs,
    isChewyRxFinalizationEnabled,
    isLoadingChargeSheet,
  ])

  return {
    hasPendingImagingOrders,
    hasProcedureLogs,
    isLoading,
  }
}

// This rule will be migrated to BE, methods should be using useGetHasOutstandingImagingOrders
export const useOutstandingImagingOrders = (logs: InvoiceLineItem[]) => {
  const ProcedureCategory = useSelector(getProcedureCategory)
  const activeImagingVendors = useGetActiveImagingVendorsMap()
  const idexxImagingVendorId = useGetIdexxImagingId()
  const businessScopeModalities = useSelector(
    getBusinessScopeModalityList(idexxImagingVendorId),
  )

  const ImagingCategoryId = Utils.findConstantIdByName(
    'Imaging',
    ProcedureCategory,
  )

  return logs
    .filter((log) => {
      const imagingVendorId = log.imagingVendorId || idexxImagingVendorId
      const isImagingCategory = log.procedureCategoryId === ImagingCategoryId
      const isFromActiveVendor = activeImagingVendors[imagingVendorId]
      const isBusinessScope = R.includes(
        log.procedureModalityId,
        businessScopeModalities,
      )
      const hasNoProcedureOrder = !log.procedureOrderId

      return (
        isImagingCategory &&
        isFromActiveVendor &&
        isBusinessScope &&
        hasNoProcedureOrder
      )
    })
    .map(invoiceLineToImagingOrder)
}

export const hasImagingOrders = (
  logs: InvoiceLineItem[],
  ProcedureCategory: NamedEntity[],
  activeImagingVendors: Record<string, boolean>,
  idexxImagingVendorId: string,
  businessScopeModalities: string[],
) => {
  const ImagingCategoryId = Utils.findConstantIdByName(
    'Imaging',
    ProcedureCategory,
  )

  const flatItems = R.pipe(
    R.map((item: InvoiceLineItem) => (item.items ? item.items : item)),
    R.flatten,
  )(logs || [])

  const filteredItems = R.filter(
    R.allPass([R.complement(isUnusedPrepaidLog), R.complement(isDeclinedLog)]),
    flatItems,
  )

  return (
    filteredItems.filter((log) => {
      const imagingVendorId = log.imagingVendorId || idexxImagingVendorId
      const isImagingCategory = log.procedureCategoryId === ImagingCategoryId
      const isFromActiveVendor = activeImagingVendors[imagingVendorId]
      const isBusinessScope = R.includes(
        log.procedureModalityId,
        businessScopeModalities,
      )
      const hasNoProcedureOrder = !log.procedureOrderId

      return (
        isImagingCategory &&
        isFromActiveVendor &&
        isBusinessScope &&
        hasNoProcedureOrder
      )
    }).length > 0
  )
}

export const hasUnfinishedLabs = (
  logs: InvoiceLineItem[],
  activeLabVendors: Record<string, boolean>,
  ReadyForOrderLabStates: any[],
) => {
  const sufficientLabStates = R.pluck('id', ReadyForOrderLabStates)

  return logs.some(
    (log) =>
      activeLabVendors[log.labTestVendorId] &&
      !log.labTestOrderId &&
      (R.includes(log.stateId, sufficientLabStates) ||
        R.includes(log.stateEntity?.id, sufficientLabStates)),
  )
}

// This rule will be migrated to BE, methods should be using useGetHasOutstandingLabOrders
export const useHasUnfinishedLabs = (logs: InvoiceLineItem[]) => {
  const activeLabVendors = useGetActiveLabVendorsMap()
  const ReadyForOrderLabStates = useSelector(getReadyForOrderLabStates) || []
  return hasUnfinishedLabs(logs, activeLabVendors, ReadyForOrderLabStates)
}

export const useGetInvoiceCompletenessFromSpain = (
  invoice: InvoiceOrEstimate | BatchInvoice | Nil,
) => {
  const options = useSelector(getFinanceInvoiceLocaleOptions)

  const getInvoiceCompleteness = (id: string | Nil) =>
    R.path<boolean | Nil>([CountryCode.ES, id || '', 'complete'], options)

  // On creation we don't have id, so we need to use another identifier to be able to send the param correctly
  // Another important point, we need to use ?? here as we're using boolean values
  return (
    getInvoiceCompleteness(invoice?.id) ??
    getInvoiceCompleteness(invoice?.invoiceNo)
  )
}

type OpenInvoiceSaveAdditionalParams = {
  chargesEntityType?: string | Nil
  logId?: string | Nil
  logType?: string | Nil
}

export const useOpenInvoice = (
  clientId: string | Nil,
  openInvoiceOld?: (props?: Omit<InvoiceDialogProps, 'open'>) => void,
  openNewTab?: boolean,
) => {
  const dispatch = useDispatch()
  const navigatePrevStateHook = useNavigatePrevState()
  const { t } = useTranslation('Common')

  const navigatePrevState = openNewTab
    ? (url: any) => window.open(url, '_blank')
    : navigatePrevStateHook

  const clientCharges = useSelector(getCharges)
  const soapIdFromSoap = useSelector(getSoapId)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)

  const [openReloadPageAlert, closeReloadPageAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const reload = () => window.location.reload()

  const onCloseReloadAlert = () => {
    closeReloadPageAlert()
    reload()
  }

  const openAfterCheck = useCloseAfterCreation(({ onClose }) => {
    if (R.isNil(clientCharges)) {
      openReloadPageAlert({
        message: t('Common:PLEASE_RELOAD_PAGE'),
        onOk: () => reload(),
        onClose: onCloseReloadAlert,
      })
    } else {
      if (onClose) {
        onClose()
      }
      const isInvoice = isChargesInvoiceType(clientCharges)
      const path = isInvoice ? 'invoice' : 'chargesheet'
      const id = R.prop(isInvoice ? 'id' : 'clientId', clientCharges)
      navigatePrevState(`/${path}/${id}`)
    }
  }, getChargesLoading)

  return (
    props: Partial<InvoiceDialogProps> & OpenInvoiceSaveAdditionalParams,
  ) => {
    const {
      clientId: propClientId,
      isEstimate,
      soapId: soapIdProp,
      logId,
      logType,
      create,
      chargesEntityType,
      invoiceId,
      isOtcInvoice,
      patientId,
      onClose,
    } = props || {}

    if (isEstimate && openInvoiceOld) {
      openInvoiceOld(props)
      return
    }

    const id = propClientId || clientId
    const soapId = soapIdProp || soapIdFromSoap

    const isChargesEntityTypeInvoice =
      chargesEntityType?.toUpperCase() === InvoiceType.INVOICE.toUpperCase()

    const fetchAction =
      id && soapId
        ? () => fetchSoapCharges({ id, soapId })
        : logId && logType
          ? () => fetchChargesByLog({ logId, logType })
          : undefined

    const canRunFetchAction = soapIdFromSoap ? isCurrentContextSoap : true

    if (!create && canRunFetchAction && fetchAction && !chargesEntityType) {
      openAfterCheck({ onClose })
      dispatch(fetchAction())
    } else if (isChargesEntityTypeInvoice && invoiceId) {
      navigatePrevState(`/invoice/${invoiceId}`)
    } else {
      const query = isOtcInvoice
        ? `?isOtcInvoice=true&patientId=${patientId}`
        : ''
      navigatePrevState(`/chargesheet/${id}${query}`)
    }
  }
}

export const useCanPrintOrEmailOrDownloadInvoice = (
  invoiceIds: string[] | undefined,
  runEffects: boolean,
) => {
  const invoice = useSelector(getFinanceInvoice(invoiceIds?.[0]))
  const clientTaxCertificationNumber = useSelector(
    getUserTaxCertificationNumber(invoice?.clientId),
  )
  const businessCIFNumber = useSelector(getCurrentBusinessCIF)
  const isInvoiceDialogOpen = useSelector(
    getHasOpenDialogsByName(DialogNames.INVOICE),
  )

  const isCompletedType = useGetInvoiceCompletenessFromSpain(invoice)
  const { isSpanishBusiness } = useBusinessLocalization()

  const missingClientNIFOrCIF = Boolean(!clientTaxCertificationNumber)
  const missingBusinessCIFNumber = !businessCIFNumber
  const isBatchInvoice = (invoiceIds?.length ?? 0) > 1

  // For outside calls (PRINT and EMAIL actions) we need to open the dialog INVOICE (except for batch invoices)
  const shouldOpenInvoiceDialog =
    !isInvoiceDialogOpen &&
    (!invoice?.groups || R.isNil(isCompletedType)) &&
    !isBatchInvoice

  const canPrintOrEmailOrDownloadInvoice = R.cond([
    // Non spanish business
    [() => !isSpanishBusiness, R.T],
    // When invoice groups were not populated yet
    [() => shouldOpenInvoiceDialog, R.F],
    // Complete invoices from Spanish businesses must have client NIF/CIF number
    [
      () => Boolean(isCompletedType),
      R.always(!missingClientNIFOrCIF && !missingBusinessCIFNumber),
    ],
    // Any invoice from Spanish businesses must have business CIF number
    [() => !shouldOpenInvoiceDialog, R.always(Boolean(businessCIFNumber))],
    [R.T, R.T],
  ])()

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openInvoiceMissingFieldsDialog] = useDialog(
    DialogNames.INVOICE_MISSING_FIELDS_DIALOG,
  )

  const openInvoice = useOpenInvoice(invoice?.clientId, openInvoiceDialog)

  // We need to let the user choose between "complete" and "simple" invoices for spanish invoices
  // as right now we're not persisting this data on BE
  const onOpenInvoiceDialog = () => {
    if (!isSpanishBusiness) {
      return
    }

    if (!invoice && invoiceIds) {
      openInvoice({ invoiceId: invoiceIds[0], id: invoiceIds[0] })
      return
    }

    if (
      invoice &&
      shouldOpenInvoiceDialog &&
      (!missingBusinessCIFNumber || !missingClientNIFOrCIF)
    ) {
      openInvoice({
        clientId: invoice.clientId,
        patientId: invoice.patientId,
        invoiceId: invoice.id,
        id: invoice.id,
        chargesEntityType: invoice.id
          ? InvoiceType.INVOICE
          : InvoiceType.CHARGE_SHEET,
      })
    }
  }

  const onOpenInvoiceMissingFieldsDialog = () => {
    if (!isSpanishBusiness) {
      return
    }

    if (isCompletedType && missingClientNIFOrCIF && missingBusinessCIFNumber) {
      openInvoiceMissingFieldsDialog({
        clientId: invoice?.clientId,
        missingFields: [
          InvoiceFromSpainMissingFields.CLIENT_NIF_OR_CIF_NUMBER,
          InvoiceFromSpainMissingFields.BUSINESS_CIF_NUMBER,
        ],
      })
      return
    }

    if (isCompletedType && missingClientNIFOrCIF) {
      openInvoiceMissingFieldsDialog({
        clientId: invoice?.clientId,
        missingFields: [InvoiceFromSpainMissingFields.CLIENT_NIF_OR_CIF_NUMBER],
      })
      return
    }

    if (missingBusinessCIFNumber) {
      openInvoiceMissingFieldsDialog({
        missingFields: [InvoiceFromSpainMissingFields.BUSINESS_CIF_NUMBER],
      })
    }
  }

  useEffect(() => {
    if (runEffects) {
      onOpenInvoiceDialog()
    }
  }, [])

  useEffect(() => {
    if (runEffects) {
      onOpenInvoiceMissingFieldsDialog()
    }
  }, [onOpenInvoiceMissingFieldsDialog, runEffects])

  return {
    getCanPrintOrEmailOrDownloadInvoice: () => canPrintOrEmailOrDownloadInvoice,
    onOpenInvoiceDialog,
    onOpenInvoiceMissingFieldsDialog,
    isCompletedType,
  }
}

export const useProcessBillingAddress = ({
  billingAddressRef,
  clientId,
  onProceed,
}: {
  billingAddressRef: React.RefObject<BillingAddressFormHandle>
  clientId: string | Nil
  onProceed: () => void
}) => {
  const dispatch = useDispatch()

  const billingAddresses = useSelector(getBillingAddresses)

  const setProceedAfterUpdate = useCloseAfterCreation(
    onProceed,
    getPaymentsIsLoading,
  )

  const processBillingAddresses = () => {
    if (!billingAddressRef.current?.validate()) {
      return
    }
    const address = billingAddressRef.current?.getBillingAddress()
    const existingAddress = Utils.findById(address.id, billingAddresses)
    setProceedAfterUpdate()
    if (clientId) {
      if (existingAddress) {
        dispatch(updateBillingAddress(address, clientId))
      } else {
        dispatch(createBillingAddress(address, clientId))
      }
    }
  }

  return processBillingAddresses
}

const storeParamsByContentKind = {
  [GeneratingPdfContentKind.INVOICE]: {
    getIsGenerating: getIsGeneratingInvoicePdf,
    generatePdfAction: generatePdfForInvoice,
  },
  [GeneratingPdfContentKind.INVOICE_V2]: {
    getIsGenerating: getIsGeneratingInvoicePdf,
    generatePdfAction: generatePdfForInvoiceV2,
  },
  [GeneratingPdfContentKind.CHARGE_SHEET]: {
    getIsGenerating: getIsGeneratingChargeSheetPdf,
    generatePdfAction: generatePdfForChargeSheet,
  },
  [GeneratingPdfContentKind.MEDICAL_HISTORY]: {
    getIsGenerating: getMedicalHistoryCardIsGeneratingPdf,
    generatePdfAction: generatePdfForMedicalHistory,
  },
  [GeneratingPdfContentKind.REPORT_CARD]: {
    getIsGenerating: getReportCardIsGeneratingPdf,
    generatePdfAction: generatePdfForReportCard,
  },
  [GeneratingPdfContentKind.PRESCRIPTION]: {
    getIsGenerating: getPrescriptionIsGeneratingPdf,
    generatePdfAction: generatePdfForPrescription,
  },
  [GeneratingPdfContentKind.NONE]: {
    getIsGenerating: R.always(false),
    generatePdfAction: R.always(undefined),
  },
}

export type UseDownloadContentAsPdfType = [(config: any) => void, boolean]

type InvoiceParams = {
  invoiceId: string | Nil
}

type PrescriptionParams = Record<any, string>

export function useDownloadContentAsPdf(
  pdfContentKind: GeneratingPdfContentKind,
): UseDownloadContentAsPdfType
export function useDownloadContentAsPdf(
  pdfContentKind: GeneratingPdfContentKind.INVOICE,
  options: InvoiceParams,
): UseDownloadContentAsPdfType
export function useDownloadContentAsPdf(
  pdfContentKind: GeneratingPdfContentKind.PRESCRIPTION,
  options: PrescriptionParams,
): UseDownloadContentAsPdfType
export function useDownloadContentAsPdf(
  pdfContentKind: GeneratingPdfContentKind,
  options?: InvoiceParams | PrescriptionParams,
) {
  const dispatch = useDispatch()

  const { getIsGenerating, generatePdfAction } =
    storeParamsByContentKind[pdfContentKind]

  const pdfIsGenerating: boolean = useSelector(getIsGenerating)

  const invoiceOptions = options as InvoiceParams
  const {
    getCanPrintOrEmailOrDownloadInvoice: getCanDownloadInvoice,
    onOpenInvoiceDialog,
    onOpenInvoiceMissingFieldsDialog,
    isCompletedType,
  } = useCanPrintOrEmailOrDownloadInvoice(
    invoiceOptions?.invoiceId ? [invoiceOptions.invoiceId] : undefined,
    false,
  )

  const onGeneratePdfFile = (pdfFileConfig: any) => {
    const isInvoicePdf = pdfContentKind === GeneratingPdfContentKind.INVOICE

    if (!getCanDownloadInvoice() && isInvoicePdf) {
      onOpenInvoiceDialog()
      onOpenInvoiceMissingFieldsDialog()
      return
    }

    dispatch(
      generatePdfAction({
        ...pdfFileConfig,
        ...(getCanDownloadInvoice() && isInvoicePdf
          ? { complete: isCompletedType }
          : {}),
      }),
    )
  }

  return [onGeneratePdfFile, pdfIsGenerating]
}

export const usePrintEmailOrDownloadInvoice = ({
  clientId,
  invoiceProp,
  includeServiceFee,
  isEstimate,
}: {
  clientId: string | Nil
  includeServiceFee: boolean
  invoiceProp: InvoiceOrEstimate | BatchInvoice
  isEstimate: boolean
}) => {
  const expandedGroups: string[] = useSelector(getExpandedInvoiceGroupIds)
  const expandedGroupIdentifiers = useSelector(getExpandedGroups)

  const isInvoiceFromSpainCompleted =
    useGetInvoiceCompletenessFromSpain(invoiceProp)

  const isBatchInvoice = getIsBatchInvoice(invoiceProp)

  const [generatePdfV1, isGeneratingV1] = useDownloadContentAsPdf(
    GeneratingPdfContentKind.INVOICE,
    { invoiceId: invoiceProp.id },
  )

  const [generatePdfV2, isGeneratingV2] = useDownloadContentAsPdf(
    GeneratingPdfContentKind.INVOICE_V2,
  )

  const [
    openEmailInvoiceDialog,
    closeEmailInvoiceDialog,
    isEmailInvoiceDialogOpen,
  ] = useDialog(DialogNames.EMAIL_POSTED_INVOICE)

  const [
    openPrintInvoiceDialog,
    closePrintInvoiceDialog,
    isPrintInvoiceDialogOpen,
  ] = useDialog(DialogNames.PRINT_POSTED_INVOICE)

  const getInvoiceIds = (invoice: InvoiceOrEstimate | BatchInvoice) =>
    isBatchInvoice
      ? R.pluck('id', invoice.invoices as InvoiceOrEstimate[])
      : [invoice.id]

  const onPrintInvoiceRequested = (
    printInvoice: InvoiceOrEstimate | BatchInvoice,
  ) => {
    if (isPrintInvoiceDialogOpen) {
      closePrintInvoiceDialog()
      return
    }

    openPrintInvoiceDialog({
      invoiceId: printInvoice.id,
      expandedGroups: expandedGroupIdentifiers,
      includeServiceFee,
    })
  }

  const getInvoiceEmailData = (
    emailInvoice: InvoiceOrEstimate | BatchInvoice,
  ) => ({
    invoiceIds: getInvoiceIds(emailInvoice),
    isEstimate,
    params: {
      expandedGroups: expandedGroups.join(','),
      includeServiceFee,
      complete: isInvoiceFromSpainCompleted,
    },
  })

  const onEmailInvoiceRequested = (
    invoice: InvoiceOrEstimate | BatchInvoice,
  ) => {
    if (isEmailInvoiceDialogOpen) {
      closeEmailInvoiceDialog()
      return
    }

    openEmailInvoiceDialog({
      clientId,
      includeServiceFee,
      expandedGroups: expandedGroupIdentifiers,
      isGenerating: isGeneratingV2,
      isEstimate,
      downloadAction: () =>
        generatePdfV2({
          invoiceId: invoice.id,
          expandedGroups: expandedGroupIdentifiers,
          includeServiceFee,
        }),
      sendAction: (emailData: InvoiceEmailDto) =>
        emailInvoiceV2({
          ...emailData,
          invoiceId: invoice.id,
        }),
    })
  }

  const onDownloadPdfRequested = (
    invoice: InvoiceOrEstimate | BatchInvoice,
  ) => {
    if (invoice) {
      generatePdfV2({
        invoiceId: invoice.id,
        expandedGroups: expandedGroupIdentifiers,
        includeServiceFee,
      })
    } else {
      generatePdfV1(getInvoiceEmailData(invoice))
    }
  }

  return {
    onPrintInvoiceRequested,
    onEmailInvoiceRequested,
    onDownloadPdfRequested,
    isGenerating: isGeneratingV2 || isGeneratingV1,
  }
}

import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  AlertIconType,
  ButtonWithLoader,
  PermissionArea,
  Utils,
} from '@pbt/pbt-ui-components'

import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { NotificationAreaNames } from '~/constants/notifications'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import { markAllNotificationsAsReadForLinkedItem } from '~/store/actions/notifications'
import {
  clearTasksError,
  deleteTask,
  fetchTask,
  partialEditTask,
} from '~/store/actions/tasks'
import { useTaskEvent } from '~/store/hooks/tasks'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getNotificationArea,
} from '~/store/reducers/constants'
import { getNotificationsForLinkedItemId } from '~/store/reducers/notifications'
import {
  getTask,
  getTasksError,
  getTasksIsDeleting,
  getTasksIsFetching,
  getTasksIsLoading,
  getTasksValidationError,
} from '~/store/reducers/tasks'
import { EasterEggEvents, Task } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import TaskComponent, { TaskHandle } from './Task'
import { getTaskChanges } from './taskUtils'

export interface TaskDetailsProps {
  itemId: string
  onClose: () => void
}

const TaskDetails = ({ itemId, onClose }: TaskDetailsProps) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getTasksIsLoading)
  const isFetching = useSelector(getTasksIsFetching)
  const isDeleting = useSelector(getTasksIsDeleting)
  const tasksError = useSelector(getTasksError)
  const task = useSelector(getTask(itemId))
  const tasksValidationError = useSelector(getTasksValidationError)
  const NotificationAreas = useSelector(getNotificationArea)
  const tasksNotificationAreaId = Utils.findConstantIdByName(
    NotificationAreaNames.TASKS,
    NotificationAreas,
  )
  const notifications = useSelector(
    getNotificationsForLinkedItemId(tasksNotificationAreaId, task?.id),
  )
  const isEasterEggEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EASTER_EGG),
  )
  const isTaskListOracleIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.TASK_LIST_ORACLE_INTEGRATION),
  )
  const taskPermissions = useSelector(getCRUDByArea(PermissionArea.TASK))

  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )

  const setCloseOnDelete = useCloseAfterCreation(onClose, getTasksIsDeleting)
  const initialTaskRef = useRef<Task | null>(null)
  const taskRef = useRef<TaskHandle>(null)

  const checkErrorsAndClose = () => {
    if (!tasksError) {
      initialTaskRef.current = null
      onClose()
    }
  }
  const setCloseOnSave = useCloseAfterCreation(
    checkErrorsAndClose,
    getTasksIsLoading,
  )
  const { t } = useTranslation('Tasks')

  const TaskType = useTaskEvent()
  const TaskStates = TaskType.states
  const DoneState = Utils.findConstantIdByName('Done', TaskStates)
  const OpenState = Utils.findConstantIdByName('Open', TaskStates)

  const [openNewConversationDialog] = useDialog(
    DialogNames.NEW_CONVERSATION_WITH_STEPS,
  )
  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const isDone = task?.stateId === DoneState
  const showStateChange = !task?.forClient

  const isForVCR = task?.forChewyVcr

  const shouldUseVCRBehavior = isTaskListOracleIntegrationEnabled && isForVCR

  useEffect(() => {
    setTimeout(() => {
      if (task && taskRef.current) {
        initialTaskRef.current = taskRef.current.get()
      }
    }, 0)
  }, [task])

  useEffect(() => {
    if (notifications?.length > 0) {
      dispatch(
        markAllNotificationsAsReadForLinkedItem(
          itemId,
          tasksNotificationAreaId,
        ),
      )
    }
  }, [notifications?.length])

  useEffect(() => {
    if (itemId) {
      dispatch(fetchTask(itemId))
    }
  }, [itemId])

  useEffect(() => {
    if (tasksError) {
      openAlert({
        iconType: AlertIconType.WARN,
        message: tasksError,
        onClose: () => {
          dispatch(clearTasksError())
          closeAlert()
        },
      })
    }
  }, [tasksError])

  const handleSave = (stateId?: string) => {
    if (taskRef.current?.validate()) {
      const newTask = taskRef.current.get()
      const changes = getTaskChanges(initialTaskRef.current!, newTask)

      changes.id = newTask.id
      if (stateId) {
        changes.stateId = stateId
      }

      if (tasksError) {
        dispatch(clearTasksError())
      }

      dispatch(partialEditTask(changes))
      setCloseOnSave()
    }
  }

  const onDeleteRequested = () => {
    setCloseOnDelete()
    dispatch(deleteTask(itemId))
  }

  const markAsOpen = () => handleSave(OpenState)

  const markAsDone = () => {
    if (isEasterEggEnabled) {
      dispatch(queueEasterEggEvent({ actionType: EasterEggEvents.TASK_DONE }))
    }
    handleSave(DoneState)
  }

  const messagePetParent = () => {
    openNewConversationDialog({
      clientId: task?.client,
      patientId: task?.patient,
    })
  }

  const getUnsavedData = () =>
    task &&
    !tasksValidationError &&
    (taskRef.current?.hasUnsavedChanges() ?? false)

  return (
    <Expander
      isConfirmToDelete
      additionalButtons={[
        Boolean(task?.forClient) && (
          <ButtonWithLoader
            disabled={isLoading}
            key="message-pet-parent"
            loading={isLoading}
            onClick={messagePetParent}
          >
            {t('Tasks:TASK_DETAILS.BUTTON_SEND_MESSAGE')}
          </ButtonWithLoader>
        ),
        isDone && showStateChange && (
          <ButtonWithLoader
            color="secondary"
            disabled={isLoading}
            key="mark-as-open"
            loading={isLoading}
            onClick={markAsOpen}
          >
            {t('Tasks:LABEL.MARK_AS_TO-DO')}
          </ButtonWithLoader>
        ),
        !isDone && showStateChange && (
          <ButtonWithLoader
            disabled={isLoading || shouldUseVCRBehavior}
            key="mark-as-done"
            loading={isLoading}
            onClick={markAsDone}
          >
            {t('Tasks:LABEL.MARK_AS_DONE')}
          </ButtonWithLoader>
        ),
      ]}
      expandedItemClass="task"
      getUnsavedData={getUnsavedData}
      isDeleting={isDeleting}
      isFetching={isFetching}
      isSaving={isLoading}
      item={task}
      onDeleteRequested={
        !isCvcRolesEnabled || taskPermissions.update
          ? onDeleteRequested
          : undefined
      }
      onSaveRequested={
        !isCvcRolesEnabled || taskPermissions.update
          ? () => handleSave()
          : undefined
      }
    >
      <TaskComponent
        ref={taskRef}
        shouldUseVCRBehavior={shouldUseVCRBehavior}
        task={task}
      />
    </Expander>
  )
}

export default TaskDetails

import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { v4 as uuid } from 'uuid'
import {
  ClassesType,
  Field,
  Nil,
  PuiCheckbox,
  PuiTextField,
  Text,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import PreviewButton from '~/components/common/buttons/PreviewButton'
import { EmailEntityConfigRecipient } from '~/types'
import {
  getCommunicationsMessageMaxLength,
  includesDynamicText,
} from '~/utils/communicationsUtils'

import CommunicationTemplateInput, {
  CommunicationTemplateInputProps,
} from '../template-inputs/CommunicationTemplateInput'
import ToInput, { ToInputHandle, ToInputProps } from './common/ToInput'
import EmailPreviewPopper from './new-conversation-dialog/EmailPreviewPopper'

const useStyles = makeStyles(
  (theme) => ({
    subjectTextInput: {
      height: 40,
      padding: theme.spacing(0, 1),
    },
    messageFormattingArea: {
      width: '100%',
    },
  }),
  { name: 'ConversationMessageFormattingArea' },
)

export enum EditAreaConfig {
  RICH_EDIT = 'RICH_EDIT',
  PLAIN_TEXT = 'PLAIN_TEXT',
  PLAIN_TEXT_WITHOUT_ATTACHMENT = 'PLAIN_TEXT_WITHOUT_ATTACHMENT',
}

const editAreaConfigByTransport = {
  [ConversationTransport.Sms]: EditAreaConfig.PLAIN_TEXT_WITHOUT_ATTACHMENT,
  [ConversationTransport.Boop]: EditAreaConfig.PLAIN_TEXT,
  [ConversationTransport.Email]: EditAreaConfig.RICH_EDIT,
  [ConversationTransport.LogPhoneCall]: EditAreaConfig.RICH_EDIT,
}

export interface ConversationMessageFormattingAreaProps
  extends Omit<CommunicationTemplateInputProps, 'classes'> {
  classes?: ClassesType<typeof useStyles>
  clientId: string | Nil
  editAreaConfig?: EditAreaConfig
  eventId?: string | Nil
  handleClientRemoved?: () => void
  handleClientSelected?: () => void
  hideTo?: boolean
  includeHeaderAndFooter?: boolean
  isShowPreview?: boolean
  isShowSubject?: boolean
  messageField: Field
  patientId: string | Nil
  showAttachment?: boolean
  soapId?: string | Nil
  subjectField?: Field
  to?: Field
  toInputClasses?: ToInputProps['classes']
  toInputProps?: Partial<ToInputProps>
  toggleIncludeHeaderAndFooter?: () => void
  transport: ConversationTransport | Nil
}

export interface ConversationMessageFormattingAreaHandle {
  formRecipients: () => EmailEntityConfigRecipient[]
  resetMessageState: () => void
}

const ConversationMessageFormattingArea = forwardRef(
  function ConversationMessageFormattingArea(
    {
      transport,
      messageField,
      subjectField,
      includeHeaderAndFooter,
      toggleIncludeHeaderAndFooter,
      handleClientSelected,
      handleClientRemoved,
      isShowSubject = true,
      isShowPreview = false,
      hideTo = false,
      classes: classesProp,
      toInputClasses,
      toInputProps,
      clientId,
      patientId,
      eventId,
      soapId,
      showAttachment,
      to,
      editAreaConfig: editAreaConfigProp,
      ...rest
    }: ConversationMessageFormattingAreaProps,
    ref: ForwardedRef<ConversationMessageFormattingAreaHandle>,
  ) {
    const classes = useStyles({ classes: classesProp })
    const { t } = useTranslation(['Common', 'Communications'])

    const toInputRef = useRef<ToInputHandle>(null)
    const rootRef = useRef<HTMLDivElement>(null)

    const [previewOpen, setPreviewOpen] = useState(false)
    const [richEditKey, setRichEditKey] = useState<string>()

    const onPreviewClick = () => setPreviewOpen(true)
    const onPreviewClose = () => setPreviewOpen(false)

    const editAreaPropsByConfig = {
      [EditAreaConfig.RICH_EDIT]: { showAttachment, plainText: false },
      [EditAreaConfig.PLAIN_TEXT]: {
        showAttachment,
        hidePanel: false,
        plainText: true,
      },
      [EditAreaConfig.PLAIN_TEXT_WITHOUT_ATTACHMENT]: {
        showAttachment: false,
        hidePanel: true,
        plainText: true,
      },
    }

    const transportForConfig = transport || ConversationTransport.Email
    const config =
      editAreaConfigProp || editAreaConfigByTransport[transportForConfig]
    const editAreaProps = editAreaPropsByConfig[config]
    const maxLength = getCommunicationsMessageMaxLength(
      transportForConfig,
      includesDynamicText(messageField?.value),
    )

    useImperativeHandle(ref, () => ({
      resetMessageState: () => setRichEditKey(uuid()),
      formRecipients: () => toInputRef.current?.formRecipients() || [],
    }))

    return (
      <Grid
        container
        className={classes.messageFormattingArea}
        direction="column"
        ref={rootRef}
        rowSpacing={2}
      >
        <ToInput
          classes={toInputClasses}
          clientId={clientId}
          hide={hideTo}
          patientId={patientId}
          ref={toInputRef}
          to={to}
          transport={transport}
          {...toInputProps}
        />
        {isShowSubject && (
          <Grid container item>
            <Text strong variant="subheading3">
              {`${t('Common:SUBJECT')}*`}:
              {transport === ConversationTransport.Sms && (
                <Text component="span" variant="subheading3">
                  &nbsp;
                  {t(
                    'Communications:CONVERSATION_MESSAGE_FORMATTING_AREA.NOT_SHOW_IN_MESSAGE',
                  )}
                </Text>
              )}
            </Text>
            <PuiTextField
              aria-label={t('Common:SUBJECT')}
              field={subjectField}
              inputProps={{
                className: classes.subjectTextInput,
                maxLength: 255,
              }}
              maxRows={1}
              minRows={1}
              variant="outlined"
            />
          </Grid>
        )}

        {isShowPreview && transport === ConversationTransport.Email && (
          <Grid container item alignItems="center" mb={1}>
            <Grid item>
              <PuiCheckbox
                checked={includeHeaderAndFooter}
                label={t(
                  'Communications:CONVERSATION_MESSAGE_FORMATTING_AREA.INCLUDE_IN_PRACTICE',
                )}
                onChange={toggleIncludeHeaderAndFooter}
              />
            </Grid>
            <Grid item>
              <PreviewButton onClick={onPreviewClick} />
            </Grid>
          </Grid>
        )}

        <Grid container item>
          <CommunicationTemplateInput
            resetStateOnValueChange
            clientId={clientId}
            eventId={eventId}
            field={messageField}
            key={richEditKey}
            maxLength={maxLength}
            patientId={patientId}
            plainTextInput={editAreaProps.plainText}
            soapId={soapId}
            title={`${t('Common:MESSAGE')}:*`}
            onSubjectChangeRequested={subjectField?.setValue}
            {...editAreaProps}
            {...rest}
          />
        </Grid>
        <EmailPreviewPopper
          anchorEl={rootRef.current}
          content={messageField.value}
          includeHeaderAndFooter={includeHeaderAndFooter}
          open={previewOpen}
          onClose={onPreviewClose}
        />
      </Grid>
    )
  },
)

export default ConversationMessageFormattingArea

import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { Grid, Table, TableBody, TableCell, TableHead } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ErrorTooltip,
  Field,
  Text,
  WellnessPlan,
  WellnessPlanBenefit,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

import WellnessPlanAutoRenewalToggle from '../../WellnessPlanAutoRenewalToggle'
import WellnessPlanPriceTypeControl, {
  WellnessPlanPriceTypeControlHandle,
} from '../../WellnessPlanPriceTypeControl'
import {
  getBenefitCoversAppointmentTypes,
  getBenefitCoversCatalog,
  getBenefitFromPlan,
  getBenefitIndex,
} from '../../wellnessPlanUtils'
import { WellnessPlanBenefit as WellnessPlanBenefitComponent } from './WellnessPlanBenefit'
import { WellnessPlanName } from './WellnessPlanName'

const useStyles = makeStyles(
  (theme) => ({
    autoRenewalToggle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    benefitCell: {
      width: 185,
    },
    container: {
      overflowX: 'auto',
    },
    descriptionHeaderCell: {
      width: 180,
    },
    planPriceStructureText: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    table: {
      marginTop: theme.spacing(2),
      border: theme.constants.tableBorder,
      borderRadius: 2,
      height: '100%',
    },
    tableCell: {
      padding: theme.spacing(1),
      '&:not(:last-of-type)': {
        borderRight: theme.constants.tableBorder,
      },
      verticalAlign: 'top',
      textAlign: 'start',
    },
    tableHeader: {
      borderBottom: theme.constants.tableBorder,
    },
  }),
  { name: 'WellnessPlanLevelsDetails' },
)

export interface WellnessPlanLevelsDetailsProps {
  benefits: WellnessPlanBenefit[]
  disableEdit: boolean
  hasActivePatients: boolean
  hasCoverItemsValidationError: boolean
  hasEnoughBenefits: boolean
  isBaseLevel: boolean
  name: Field
  planCandidate: WellnessPlan
  planDefaultName: string
  price: Field
  priceTypeControlRef: React.RefObject<WellnessPlanPriceTypeControlHandle>
  setHasCoverItemsValidationError: React.Dispatch<React.SetStateAction<boolean>>
  setPlanCandidate: React.Dispatch<React.SetStateAction<WellnessPlan>>
  wellnessPlan: WellnessPlan
  wellnessPlanVersion: WellnessPlanVersion
}

export const WellnessPlanLevelsDetails = ({
  benefits,
  disableEdit,
  hasActivePatients,
  hasCoverItemsValidationError,
  hasEnoughBenefits,
  name,
  isBaseLevel,
  planCandidate,
  planDefaultName,
  price,
  priceTypeControlRef,
  setHasCoverItemsValidationError,
  setPlanCandidate,
  wellnessPlan,
  wellnessPlanVersion,
}: WellnessPlanLevelsDetailsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const [openWellnessPlanBenefitsDialog] = useDialog(
    DialogNames.WELLNESS_PLAN_BENEFITS,
  )

  const showAppointmentTypesColumn = benefits.some(
    getBenefitCoversAppointmentTypes,
  )
  const showCoveragesColumn = benefits.some(getBenefitCoversCatalog)

  const handleChangeBenefits = () => {
    openWellnessPlanBenefitsDialog({
      plan: planCandidate,
      onSelected: (selectedBenefits) => {
        // if the benefit is already present in plan, use it so we do not remove already added cover options
        const updatedBenefits = selectedBenefits.map(
          (benefit) =>
            getBenefitFromPlan(planCandidate, benefit.globalBenefitId) ||
            benefit,
        )
        const deletedBenefitIds = (planCandidate.benefits || [])
          .filter((benefit) =>
            selectedBenefits.every(
              ({ globalBenefitId }) =>
                globalBenefitId !== benefit.globalBenefitId,
            ),
          )
          .map((benefit) => benefit.id)
          .filter(Boolean) as string[]

        setPlanCandidate({
          ...planCandidate,
          benefits: updatedBenefits,
          deletedBenefitIds: R.uniq(
            (planCandidate.deletedBenefitIds || []).concat(deletedBenefitIds),
          ),
        })
      },
    })
  }

  const removeBenefit = (benefit: WellnessPlanBenefit) => {
    const updatedBenefits = R.without([benefit], planCandidate.benefits)
    const updatedPlan = {
      ...planCandidate,
      benefits: updatedBenefits,
      deletedBenefitIds: (planCandidate.deletedBenefitIds || []).concat(
        benefit.id!,
      ),
    }

    setPlanCandidate(updatedPlan)
  }

  const updateBenefit = (benefit: WellnessPlanBenefit) => {
    const benefitIndex = getBenefitIndex(planCandidate, benefit.globalBenefitId)
    const updatedBenefits = R.update(
      benefitIndex,
      benefit,
      planCandidate.benefits,
    )
    const updatedPlan = { ...planCandidate, benefits: updatedBenefits }

    setPlanCandidate(updatedPlan)

    return updatedPlan
  }

  return (
    <Grid container item className={classes.container} direction="column">
      {!disableEdit && (
        <WellnessPlanName
          isBaseLevel={isBaseLevel}
          name={name}
          planDefaultName={planDefaultName}
          price={price}
          wellnessPlan={wellnessPlan}
          wellnessPlanVersion={wellnessPlanVersion}
        />
      )}
      {!isBaseLevel && (
        <>
          <Text className={classes.planPriceStructureText} variant="body">
            {t('Common:PLAN_PRICE_STRUCTURE')}
          </Text>
          <WellnessPlanPriceTypeControl
            disableEdit={disableEdit}
            plan={planCandidate}
            ref={priceTypeControlRef}
            wellnessPlanVersion={wellnessPlanVersion}
          />
        </>
      )}
      <WellnessPlanAutoRenewalToggle
        className={classes.autoRenewalToggle}
        plan={planCandidate}
      />
      <Table className={classes.table}>
        <TableHead>
          <TableCell
            className={classNames(classes.tableCell, classes.benefitCell)}
          >
            <Text strong variant="lowAccent2">
              {t('Common:BENEFITS')}
            </Text>
            {!disableEdit && (
              <Dotdotdot clamp={1}>
                <Text link variant="body2" onClick={handleChangeBenefits}>
                  {t('Common:CHANGE_BENEFITS')}
                </Text>
              </Dotdotdot>
            )}
          </TableCell>
          <TableCell
            className={classNames(
              classes.tableCell,
              classes.descriptionHeaderCell,
            )}
          >
            <Text strong variant="lowAccent2">
              {t('Common:DESCRIPTION')}
            </Text>
            <Dotdotdot clamp={1}>
              <Text variant="body2">{t('Common:DISPLAYED_TO_CLIENTS')}</Text>
            </Dotdotdot>
          </TableCell>
          {showAppointmentTypesColumn && (
            <TableCell className={classes.tableCell}>
              <Text strong variant="lowAccent2">
                {t('Common:APPOINTMENT_TYPES')}
              </Text>
            </TableCell>
          )}
          {showCoveragesColumn && (
            <TableCell className={classes.tableCell}>
              <ErrorTooltip
                message={t(
                  'WellnessPlans:MAKE_SURE_TO_ADD_WHAT_ITEMS_ARE_COVERED_FOR_BENEFIT',
                )}
                open={hasCoverItemsValidationError}
                placement="top"
                validateTag={Math.random()}
              >
                <Text strong variant="lowAccent2">
                  {t('Common:COVERED')}
                </Text>
              </ErrorTooltip>
            </TableCell>
          )}
        </TableHead>
        <TableBody>
          {benefits.map((benefit, index) => (
            <WellnessPlanBenefitComponent
              benefit={benefit}
              disableEdit={disableEdit}
              hasActivePatients={hasActivePatients}
              hasCoverItemsValidationError={hasCoverItemsValidationError}
              hasEnoughBenefits={hasEnoughBenefits}
              isBaseLevel={isBaseLevel}
              // eslint-disable-next-line react/no-array-index-key
              key={`${benefit.globalBenefitId}_${index}`}
              setHasCoverItemsValidationError={setHasCoverItemsValidationError}
              showAppointmentTypesColumn={showAppointmentTypesColumn}
              showCoveragesColumn={showCoveragesColumn}
              onRemoveBenefit={removeBenefit}
              onUpdateBenefit={updateBenefit}
            />
          ))}
        </TableBody>
      </Table>
    </Grid>
  )
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { Invoice } from '~/types'

interface PaymentDetailsRefundButtonMenuProps {
  buttonClassNames: string
  clickRefundButton: (invoice: Invoice) => void
  invoices: Invoice[]
}

const PaymentDetailsRefundButtonMenu = ({
  invoices,
  buttonClassNames,
  clickRefundButton,
}: PaymentDetailsRefundButtonMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { t } = useTranslation(['Common'])

  return (
    <div>
      <Button
        className={buttonClassNames}
        color="primary"
        endIcon={<KeyboardArrowDown />}
        variant="contained"
        onClick={handleClick}
      >
        {t('Common:REFUND_ACTION')}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {invoices.map((invoice) => (
          <MenuItem
            key={invoice.id}
            onClick={() => {
              handleClose()
              clickRefundButton(invoice)
            }}
          >
            {`${t('Common:INVOICE')} ${invoice.invoiceNo}`}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default PaymentDetailsRefundButtonMenu

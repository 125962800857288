import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Fab } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import { ClassesType } from '@pbt/pbt-ui-components'

import { InventoryItemState, OrderType } from '~/constants/SOAPStates'
import { editOrder } from '~/store/actions/orders'
import { editChargeSheetOrder } from '~/store/duck/clientFinanceData'
import { useOrderStateId } from '~/store/hooks/orders'
import { InvoiceLineItem, Order } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    fab: {
      height: 33,
      width: '100%',
      fontSize: '1.2rem',
      fontWeight: 500,
      padding: theme.spacing(0, 1),
    },
    fabSelected: {
      '&&&&:hover': {
        backgroundColor: theme.colors.selectedOption,
      },
      backgroundColor: theme.colors.selectedOption,
      color: theme.colors.primaryText,
    },
  }),
  { name: 'InventoryItemDispenseButton' },
)

export interface InventoryItemDispenseButtonProps {
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  getInventoryStateId: (name: string) => any
  order: Order | InvoiceLineItem
}

const InventoryItemDispenseButton = ({
  classes: classesProp,
  disabled,
  order,
  getInventoryStateId,
}: InventoryItemDispenseButtonProps) => {
  const classes = useStyles({ classes: classesProp })

  const dispatch = useDispatch()

  const { t } = useTranslation('Common')

  const stateId = useOrderStateId(order)

  const isDispensed =
    stateId === getInventoryStateId(InventoryItemState.DISPENSED)

  const onDispense = () => {
    const currentStateId =
      getInventoryStateId(InventoryItemState.DISPENSED) === stateId
        ? getInventoryStateId(InventoryItemState.ORDERED)
        : getInventoryStateId(InventoryItemState.DISPENSED)

    const logId = R.prop('logId', order)
    const soapLogModificationDate = R.prop('soapLogModificationDate', order)
    if (logId && soapLogModificationDate) {
      dispatch(
        editChargeSheetOrder({
          id: logId,
          type: OrderType.INVENTORY,
          order: {
            stateId: currentStateId,
          },
          soapLogModificationDate,
        }),
      )
    } else {
      dispatch(
        editOrder({
          ...(order as Order),
          stateId: currentStateId,
        }),
      )
    }
  }

  return (
    <Fab
      className={classNames(classes.fab, {
        [classes.fabSelected]: isDispensed,
      })}
      color="primary"
      disabled={disabled}
      type="button"
      variant="extended"
      onClick={onDispense}
    >
      {isDispensed ? t('Common:DISPENSED') : t('Common:DISPENSE_ACTION')}
    </Fab>
  )
}

export default InventoryItemDispenseButton

/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import * as R from 'ramda'
import {
  DateUtils,
  Nil,
  PermissionArea,
  StateLabel,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  Edit as EditIcon,
  Estimates as EstimatesIcon,
  Prescription as PrescriptionIcon,
  Print as PrintIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import LandingWidget, {
  LandingWidgetProps,
} from '~/components/dashboard/landing/widgets/LandingWidget'
import NoRecords from '~/components/dashboard/landing/widgets/NoRecords'
import TableWidget from '~/components/dashboard/landing/widgets/TableWidget'
import {
  getIdFromTimelineItem,
  getPrescriptionCanBeRefilled,
  getRemainingRefillsCount,
  timelinePrescriptionToRegularPrescription,
} from '~/components/dashboard/soap/utils/prescriptionUtils'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { InventoryCategoryName } from '~/constants/inventoryCategory'
import InvoiceType from '~/constants/InvoiceType'
import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import { OrderType } from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import {
  fetchChargesByLog,
  getCharges,
  getChargesLoading,
} from '~/store/duck/charges'
import { getWidgetData, getWidgetDataIsLoading } from '~/store/duck/landing'
import { useGetChargeSheetId } from '~/store/hooks/chargeSheet'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  useLogItemStateGetter,
  useNavigateOrderToPrescription,
  useOpenPrescriptionDialogAfterFetchUnifiedOrder,
  useRefillDialog,
} from '~/store/hooks/orders'
import { useCreateOrUpdatePrescription } from '~/store/hooks/prescription'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getCurrentBusinessId,
} from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getInventoryCategory,
} from '~/store/reducers/constants'
import { getOrdersIsSending } from '~/store/reducers/orders'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import {
  CRUDOrderOptions,
  Order,
  Prescription,
  PrescriptionSnapshotItem,
  WidgetColumn,
} from '~/types'
import { addOriginalBusinessId } from '~/utils'
import { isChargesInvoiceType } from '~/utils/finance'
import { getInventoryItemCategoryId } from '~/utils/orderUtils'
import { getPrescriptionType } from '~/utils/prescription'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import PrescriptionNameCell from './PrescriptionNameCell'
import PrescriptionSnapShotLoadingOrIcon from './PrescriptionSnapShotLoadingOrIcon'

const RefillsLabel = ({ numberRefills }: { numberRefills: number | Nil }) => {
  if (!numberRefills) {
    return null
  }

  const numberRefillsLabel = i18n.t('Plurals:Z_ICU_WORKAROUND.NUMBER_REFILLS', {
    number: numberRefills,
  })

  return <StateLabel normal>{numberRefillsLabel}</StateLabel>
}

const columns: WidgetColumn<PrescriptionSnapshotItem>[] = [
  {
    id: 'name',
    component: PrescriptionNameCell,
    width: 4.25,
    label: i18n.t('Common:NAME'),
  },
  {
    id: 'doctorName',
    prop: ({ prescription }) =>
      prescription?.doctor
        ? Utils.getPersonString(prescription?.doctor)
        : undefined,
    width: 2,
    label: i18n.t('Common:DOCTOR'),
  },
  {
    id: 'date',
    prop: ({ date }) => DateUtils.formatDate(date),
    label: i18n.t('Common:PRESCRIBED'),
    width: 2.25,
  },
  {
    id: 'refills',
    prop: ({ prescription }) => (
      <RefillsLabel
        numberRefills={prescription && getRemainingRefillsCount(prescription)}
      />
    ),
    width: 2,
    label: i18n.t('Common:REFILL_OTHER'),
  },
]

interface PrescriptionsSnapshotProps
  extends Omit<
    LandingWidgetProps<PrescriptionSnapshotItem>,
    'component' | 'data'
  > {
  clientId: string
  name: string
  patientId: string
}

const PrescriptionsSnapshot = ({
  name,
  clientId,
  patientId,
  ...rest
}: PrescriptionsSnapshotProps) => {
  const { t } = useTranslation(['Abbreviations', 'Common', 'Clients'])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const currentBusinessId = useSelector(getCurrentBusinessId)
  const business = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const InventoryCategory = useSelector(getInventoryCategory)
  const charges = useSelector(getCharges)
  const chargesLoading = useSelector(getChargesLoading)
  const {
    create: invoiceCreatePermissions,
    read: invoiceReadPermissions,
    update: invoiceUpdatePermissions,
  } = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const { read: soapReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.SOAP),
  )
  const { update: prescriptionUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.PRESCRIPTION),
  )

  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )
  const chargeSheetId = useGetChargeSheetId(clientId)

  const DrugId = Utils.findConstantIdByName(
    InventoryCategoryName.DRUG,
    InventoryCategory,
  )
  const VetDietId = Utils.findConstantIdByName(
    InventoryCategoryName.VET_DIET,
    InventoryCategory,
  )
  const FoodId = Utils.findConstantIdByName(
    InventoryCategoryName.FOOD,
    InventoryCategory,
  )

  const prescriptionsAndOrders: PrescriptionSnapshotItem[] = useSelector(
    getWidgetData(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Prescriptions,
    ),
  )

  const [openRefillDialog, closeRefillDialog] = useRefillDialog()
  const closeRefillDialogAfterSave = useCloseAfterCreation(
    closeRefillDialog,
    getOrdersIsSending,
  )

  const isImportedPrescription = (item?: PrescriptionSnapshotItem) =>
    item?.type === 'HISTORY'

  const prescriptions = prescriptionsAndOrders.filter(
    (item) =>
      item.type === OrderType.PRESCRIPTION || isImportedPrescription(item),
  )

  const isLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Prescriptions,
    ),
  )

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openPrintInHouseOrderLabelDialog] = useDialog(
    DialogNames.PRINT_IN_HOUSE_ORDER_LABEL,
  )
  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)
  const navigateToPrescriptionDialog = useNavigateOrderToPrescription()
  const createOrUpdatePrescription = useCreateOrUpdatePrescription()
  const logItemStateGetter = useLogItemStateGetter()

  const openPrescriptionDialog = (
    order: Prescription,
    originalBusinessId: string | Nil,
  ) => {
    const categoryId = getInventoryItemCategoryId(order, isFoodCatalogEnabled)

    if (categoryId === DrugId) {
      navigateToPrescriptionDialog(
        InventoryCategoryName.DRUG,
        order,
        originalBusinessId,
      )
    } else if (categoryId === VetDietId) {
      navigateToPrescriptionDialog(
        InventoryCategoryName.VET_DIET,
        order,
        originalBusinessId,
      )
    } else if (categoryId === FoodId) {
      navigateToPrescriptionDialog(
        InventoryCategoryName.FOOD,
        order,
        originalBusinessId,
      )
    }
  }

  const handleGoToSoap = (
    soapId: string,
    originalBusinessId: string | Nil,
    prescription?: Prescription,
  ) => {
    if (!prescription) {
      navigate(
        addOriginalBusinessId(
          `/soap/${soapId}`,
          isPatientSharingEnabled ? originalBusinessId : null,
        ),
      )
    } else {
      openPrescriptionDialog(
        { ...prescription, type: OrderType.PRESCRIPTION, soapId },
        originalBusinessId,
      )
    }
  }

  const handleGoToInvoice = (item: PrescriptionSnapshotItem) => {
    openInvoice({
      clientId,
      patientId,
      prescriptionId: item.prescription?.id,
      soapId: item.soapId,
      logId: R.path(['prescription', 'id'], item),
      logType: OrderType.PRESCRIPTION,
      invoiceId: getIdFromTimelineItem(item),
    })
  }

  const { fetchUnifiedOrderAndOpenPrescription } =
    useOpenPrescriptionDialogAfterFetchUnifiedOrder({
      overrideParams: {
        outsideSoap: true,
        skipOrderFetching: false,
        soapId: undefined,
      },
    })

  const handleNavigateToItemEditableDetails = ({
    soapId,
    prescription,
    businessId,
  }: PrescriptionSnapshotItem) => {
    if (soapId) {
      handleGoToSoap(soapId, businessId, prescription)
    } else {
      fetchUnifiedOrderAndOpenPrescription(prescription.id)
    }
  }

  const handlePrint = (item: PrescriptionSnapshotItem) => {
    openPrintInHouseOrderLabelDialog({
      printInfo: {
        ...timelinePrescriptionToRegularPrescription(
          item.prescription,
          item,
          isFoodCatalogEnabled,
        ),
        client,
        patient,
        business,
      },
    })
  }

  const createChargeSheetRefill = (
    refill: Prescription,
    options: CRUDOrderOptions,
  ) => {
    createOrUpdatePrescription(
      logItemStateGetter({ ...refill, items: [] } as Order)!,
      {
        ...options,
        outsideSoap: true,
        invoiceId: chargeSheetId,
        soapId: refill.soapId || undefined,
      },
    )
    if (options.print) {
      closeRefillDialogAfterSave()
    }
  }

  const handleDispenseRefill = (item: PrescriptionSnapshotItem) => {
    const refillItem = timelinePrescriptionToRegularPrescription(
      item.prescription,
      item,
      isFoodCatalogEnabled,
    )
    openRefillDialog({
      outsideSoap: true,
      isInvoice: false,
      prescription: {
        ...R.omit(['id', 'soapId'], refillItem),
        parent: { ...R.omit(['soapId'], refillItem) },
      },
      onSave: (refill: Prescription, options: CRUDOrderOptions) =>
        createChargeSheetRefill(refill, options),
    })
  }

  const getActions = (
    item?: PrescriptionSnapshotItem,
    refetchEntity?: boolean,
  ) => {
    if (!item) {
      return []
    }

    if (refetchEntity) {
      dispatch(
        fetchChargesByLog({
          logId: R.path(['prescription', 'id'], item),
          logType: OrderType.PRESCRIPTION,
        }),
      )
    }

    const isCurrentContextItem =
      !isPatientSharingEnabled || item.businessId === currentBusinessId
    const isInvoice = isChargesInvoiceType(charges)
    const isEditAllowedInInvoice = !isInvoice || isEditPostedChargesEnabled

    return isImportedPrescription(item)
      ? []
      : ([
          isCurrentContextItem &&
            item.prescription &&
            isEditAllowedInInvoice && {
              id: 'edit',
              label: t('Common:EDIT_ACTION'),
              Icon: PrescriptionSnapShotLoadingOrIcon,
              IconProps: { Icon: EditIcon },
              onClick:
                (invoiceUpdatePermissions && !item.soapId) ||
                (item.soapId && prescriptionUpdatePermissions)
                  ? () => handleNavigateToItemEditableDetails(item)
                  : undefined,
              disabled: item.soapId
                ? !prescriptionUpdatePermissions
                : !invoiceUpdatePermissions,
            },
          isCurrentContextItem &&
            item.prescription &&
            getPrescriptionType(item.prescription.prescriptionType)
              ?.isInHouse && {
              id: 'print',
              label: t('Common:PRINT_ACTION'),
              Icon: PrintIcon,
              onClick: invoiceReadPermissions
                ? () => handlePrint(item)
                : undefined,
              disabled: !invoiceReadPermissions,
            },
          isCurrentContextItem &&
            Boolean(getIdFromTimelineItem(item)) && {
              id: 'goToInvoice',
              label: chargesLoading
                ? ''
                : isInvoice
                  ? t('Common:GO_TO_INVOICE')
                  : t('Common:GO_TO_CHARGE_SHEET'),
              Icon: PrescriptionSnapShotLoadingOrIcon,
              IconProps: { Icon: EstimatesIcon },
              onClick: invoiceReadPermissions
                ? () => handleGoToInvoice(item)
                : undefined,
              disabled: !invoiceReadPermissions,
            },
          item.soapId &&
            !item.isOTC && {
              id: 'goToSoap',
              label: t('Common:GO_TO_SOAP'),
              Icon: ArrowForwardIcon,
              onClick: soapReadPermissions
                ? () => handleGoToSoap(item.soapId, item.businessId)
                : undefined,
              disabled: !soapReadPermissions,
            },
          isCurrentContextItem &&
            item.prescription &&
            getPrescriptionCanBeRefilled(item.prescription) && {
              id: 'dispenseRefill',
              label: t('Common:DISPENSE_REFILL'),
              Icon: PrescriptionIcon,
              onClick: invoiceUpdatePermissions
                ? () => handleDispenseRefill(item)
                : undefined,
              disabled: !invoiceUpdatePermissions,
            },
        ].filter(Boolean) as PopperAction[])
  }

  const canNavigateToItemEditableDetails = (item: PrescriptionSnapshotItem) => {
    const hasRequiredRelations = Boolean(item?.soapId || item?.invoiceId)
    const isCurrentContextItem =
      !isPatientSharingEnabled || item?.businessId === currentBusinessId
    return isCurrentContextItem && hasRequiredRelations
  }

  const handleAddPrescription = () =>
    openInvoice({
      clientId,
      patientId,
      chargesEntityType: InvoiceType.CHARGE_SHEET,
      create: true,
      isOtcInvoice: true,
    })

  return (
    <LandingWidget
      showHeaderTitles
      canNavigateToDetails={false}
      canNavigateToItemDetails={canNavigateToItemEditableDetails}
      columns={columns}
      component={TableWidget}
      data={prescriptions}
      getActions={getActions}
      isLoading={isLoading}
      name={name}
      noRecords={
        <NoRecords
          action={t('Abbreviations:ACRONYMS.OVER_THE_COUNTER.PRESCRIBE_OTC')}
          text={t('Clients:SNAPSHOTS_AND_RECORDS.NO_PRESCRIPTIONS')}
          onClick={invoiceCreatePermissions ? handleAddPrescription : undefined}
        />
      }
      showPaddings={false}
      onNavigateToItemDetails={handleNavigateToItemEditableDetails}
      {...rest}
    />
  )
}

export default PrescriptionsSnapshot

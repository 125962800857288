import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  BasePuiDialogProps,
  ButtonWithLoader,
  ErrorTooltip,
  PuiDialog,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  createReminderProtocolGroup,
  getLastCreatedReminderProtocolGroupId,
  getReminderProtocolsIsLoading,
} from '~/store/duck/reminderProtocols'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Order, ReminderProtocol } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import ProtocolsLabel from './ProtocolsLabel'
import ReminderProtocolTable from './ReminderProtocolTable'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      flex: 1,
      minHeight: 300,
    },
    createButton: {
      width: 152,
    },
    table: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: 'NewReminderProtocolGroupDialog' },
)

interface NewReminderProtocolGroupDialogProps extends BasePuiDialogProps {}

const NewReminderProtocolGroupDialog = ({
  open,
  onClose,
}: NewReminderProtocolGroupDialogProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const businessId = useSelector(getCurrentBusinessId)
  const lastCreatedReminderProtocolGroupId = useSelector(
    getLastCreatedReminderProtocolGroupId,
  )
  const isCvcCareDueRemindersEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_CARE_DUE_REMINDERS),
  )
  const isLoading = useSelector(getReminderProtocolsIsLoading)
  const { t } = useTranslation(['Common', 'Dialogs', 'Tooltips'])

  const [protocols, setProtocols] = useState<ReminderProtocol[]>([])

  const {
    fields: { name },
    validate,
  } = useFields(
    [
      {
        name: 'name',
        label: t('Common:REMINDER_GROUP_NAME'),
        validators: ['required'],
        initialValue: '',
      },
    ],
    false,
  )

  const [touched, setTouched] = useState(false)

  const [openReminderProtocolDialog] = useDialog(DialogNames.REMINDER_PROTOCOL)
  const [openAddReminderProtocolItems] = useDialog(
    DialogNames.ADD_REMINDER_PROTOCOL_ITEMS,
  )

  const onReminderProtocolGroupCreated = () => {
    if (onClose) {
      onClose()
    }
    if (lastCreatedReminderProtocolGroupId) {
      navigate(
        `/admin/catalog/reminder-protocols/${lastCreatedReminderProtocolGroupId}`,
      )
    }
  }

  const setCallbackOnCreated = useCloseAfterCreation(
    onReminderProtocolGroupCreated,
    getReminderProtocolsIsLoading,
  )

  const updateProtocol = (newProtocol: ReminderProtocol, index: number) => {
    const newProtocols = R.update(index, newProtocol, protocols)
    setProtocols(newProtocols)
  }

  const addProtocol = (newProtocol: ReminderProtocol, index = 0) => {
    const newProtocols = R.insert(index, newProtocol, protocols)
    setProtocols(newProtocols)
  }

  const addOrUpdateProtocol = (
    protocol: ReminderProtocol,
    index: number,
    onSave: (newProtocol: ReminderProtocol, index: number) => void,
  ) => {
    openReminderProtocolDialog({
      groupName: name.value as string,
      protocol,
      onSave: (newProtocol: ReminderProtocol) => {
        onSave(newProtocol, index)
      },
    })
  }

  const handleUpdateProtocol = (protocol: ReminderProtocol, index: number) =>
    addOrUpdateProtocol(protocol, index, updateProtocol)
  const handleCloneProtocol = (protocol: ReminderProtocol, index: number) =>
    addOrUpdateProtocol(protocol, index, addProtocol)
  const handleDeleteProtocol = (
    protocolToDelete: ReminderProtocol,
    index: number,
  ) => {
    setProtocols(R.remove(index, 1, protocols))
  }

  const handleAddProtocol = () => {
    openAddReminderProtocolItems({
      title: t('Common:CHOOSE_WHAT_TRIGGERS_REMINDER'),
      onSave: (triggersArg: Partial<Order>[]) => {
        openReminderProtocolDialog({
          groupName: name.value,
          triggers: triggersArg as ReminderProtocol['triggersWith'],
          onSave: addProtocol,
        })
      },
    })
  }

  const handleCreateGroup = () => {
    if (validate() && protocols.length > 0) {
      setCallbackOnCreated()
      dispatch(
        createReminderProtocolGroup({
          name: name.value,
          active: true,
          businessId,
          protocols,
        }),
      )
    }

    setTouched(true)
  }

  const showErrorMessage = touched && !protocols?.length && name.valid

  const confirmCloseDialogProps = {
    title: t('Dialogs:NEW_REMINDER_PROTOCOL_GROUP_DIALOG.CLOSE_WARNING_TITLE'),
    okLabel: t('Common:CONTINUE_ACTION'),
    notOkLabel: t('Common:EXIT_ACTION'),
    onOk: R.F,
  }

  const hasChanges = () => name.value || protocols.length > 0

  return (
    <PuiDialog
      confirmSaveOnClose
      fullWidth
      ConfirmCloseDialogProps={confirmCloseDialogProps}
      actions={
        <ButtonWithLoader
          className={classes.createButton}
          disabled={isLoading}
          loading={isLoading}
          onClick={handleCreateGroup}
        >
          {t('Common:CREATE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="reminder-protocol-group-dialog"
      hasUnsavedChanges={hasChanges}
      maxWidth="md"
      open={open}
      scroll="paper"
      title={t('Dialogs:NEW_REMINDER_PROTOCOL_GROUP_DIALOG.TITLE')}
      onClose={onClose}
    >
      <Grid
        container
        className={classes.container}
        direction="column"
        pb={3}
        pt={2}
        px={3}
      >
        <Grid item xs={8}>
          <PuiTextField
            FormHelperTextProps={{
              sx: { color: (theme: Theme) => theme.colors.grayGray2 },
            }}
            field={name}
            helperText={
              isCvcCareDueRemindersEnabled
                ? t('Common:REMINDER_GROUP_NAME_HELPER')
                : undefined
            }
            label={`${name.label}*`}
          />
        </Grid>
        <Grid item mt={2}>
          <ProtocolsLabel />
        </Grid>

        {protocols.length > 0 && (
          <ReminderProtocolTable
            classes={{ root: classes.table }}
            protocols={protocols}
            onClone={handleCloneProtocol}
            onDelete={handleDeleteProtocol}
            onEdit={handleUpdateProtocol}
          />
        )}

        <ErrorTooltip
          message={t('Tooltips:AT_LEAST_ONE_REMINDER_SHOULD_BE_ADDED')}
          open={showErrorMessage}
        >
          <Grid item mt={1}>
            <AddButton
              inline
              addText={t('Common:ADD_PROTOCOL')}
              onAdd={handleAddProtocol}
            />
          </Grid>
        </ErrorTooltip>
      </Grid>
    </PuiDialog>
  )
}

export default NewReminderProtocolGroupDialog

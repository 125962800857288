import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as R from 'ramda'
import { useFields } from '@pbt/pbt-ui-components'

import Typography from '~/components/elements/Typography/Typography'

import { WellnessPlanSelect } from './WellnessPlanSelect'

interface VerifyAutoRenewingPlansTableProps {
  plansList: string[]
  plansMap: Record<string, any> // replace "any" in CVC-13486
  versionsList: string[]
  versionsMap: Record<string, any> // replace "any" in CVC-13486
}

const StyledTableHeadCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
`

const StyledTableBodyCell = styled(TableCell)`
  border-right: ${({ theme }) => theme.constants.tableBorder};
  padding-top: 5px;
  padding-bottom: 5px;
`

const createRowBackgroundColor = () => {
  let lastVersionId: string | null = null
  let isGrey = false

  return (currentVersionId: string): boolean => {
    if (currentVersionId !== lastVersionId) {
      isGrey = !isGrey
      lastVersionId = currentVersionId
    }
    return isGrey
  }
}

export const VerifyAutoRenewingPlansTable = ({
  plansMap,
  versionsList,
  versionsMap,
  plansList,
}: VerifyAutoRenewingPlansTableProps) => {
  const { t } = useTranslation('Dialogs')

  const getRowBackgroundColor = createRowBackgroundColor()

  const { fields } = useFields(
    plansList.map((planId) => ({
      name: planId,
      initialValue: plansMap[planId].autoRenewInto || planId,
      type: 'select',
    })),
  )

  const activeVersion = versionsList.find(
    (id) => versionsMap[id]?.active,
  ) as string

  const activePlans = versionsMap[activeVersion].plans

  return (
    <Table sx={{ border: (theme) => theme.constants.tableBorder }}>
      <TableHead>
        <TableRow>
          <StyledTableHeadCell>
            <Typography.H3 color="grayGray2">
              {t('Dialogs:VERIFY_AUTO_RENEWING_PLANS_DIALOG.VERSION')}
            </Typography.H3>
          </StyledTableHeadCell>
          <StyledTableHeadCell>
            <Typography.H3 color="grayGray2">
              {t('Dialogs:VERIFY_AUTO_RENEWING_PLANS_DIALOG.PLAN')}
            </Typography.H3>
          </StyledTableHeadCell>
          <StyledTableHeadCell>
            <Typography.H3 color="grayGray2">
              {t('Dialogs:VERIFY_AUTO_RENEWING_PLANS_DIALOG.ACTIVE_PATIENTS')}
            </Typography.H3>
          </StyledTableHeadCell>
          <StyledTableHeadCell>
            <Typography.H3 color="grayGray2">
              {t('Dialogs:VERIFY_AUTO_RENEWING_PLANS_DIALOG.AUTO_RENEW_INFO')}
            </Typography.H3>
          </StyledTableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {versionsList.map((versionId: string) => {
          const version = versionsMap[versionId]
          const versionPlansList = version.plans

          const isGrey = getRowBackgroundColor(versionId)

          return versionPlansList.map((planId: string) => {
            const plan = plansMap[planId]
            return (
              <TableRow
                key={planId}
                sx={{
                  backgroundColor: (theme) =>
                    isGrey ? theme.colors.grayGray4 : 'none',
                }}
              >
                <StyledTableBodyCell>
                  <Typography.H3>
                    {version.name}
                    {version.active && (
                      <>{` (${t('Dialogs:VERIFY_AUTO_RENEWING_PLANS_DIALOG.CONFIGURING')})`}</>
                    )}
                  </Typography.H3>
                </StyledTableBodyCell>
                <StyledTableBodyCell>
                  <Typography.Link>{plan.name}</Typography.Link>
                </StyledTableBodyCell>
                <StyledTableBodyCell sx={{ width: 150 }}>
                  <Typography.Paragraph>
                    {version.activePatients}
                  </Typography.Paragraph>
                </StyledTableBodyCell>
                <StyledTableBodyCell>
                  <WellnessPlanSelect
                    field={fields[planId]}
                    plansList={R.uniq([
                      plan.autoRenewInto,
                      planId,
                      ...activePlans,
                    ])}
                  />
                </StyledTableBodyCell>
              </TableRow>
            )
          })
        })}
      </TableBody>
    </Table>
  )
}

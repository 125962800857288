import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import deepEqual from 'fast-deep-equal'
import * as R from 'ramda'
import { NumberUtils, PermissionArea, Text } from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import TableActionButton from '~/components/common/buttons/TableActionButton'
import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { fetchMember } from '~/store/actions/members'
import {
  deleteShipment,
  deleteShipmentItem,
  editShipment,
  fetchShipment,
  getShipment,
  getShipmentsIsDeleting,
  getShipmentsIsFetching,
  getShipmentsIsUpdating,
} from '~/store/duck/shipments'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { Shipment as ShipmentType, ShipmentItem } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useGetBulkShipmentItemStatusIdByShipment from '~/utils/useGetBulkShipmentItemStatusIdByShipment'

import Shipment, { ShipmentHandle } from './Shipment'
import { getShipmentPriceInfo } from './shipment-item-utils'
import ShipmentItemsTable from './ShipmentItemsTable'
import ShipmentTaxes, { ShipmentTaxesHandle } from './ShipmentTaxes'

const useStyles = makeStyles(
  (theme) => ({
    shipmentTaxes: {
      marginBottom: theme.spacing(3),
      width: 200,
    },
  }),
  { name: 'ShipmentDetails' },
)

interface ShipmentDetailsProps {
  itemId: string
}

const ShipmentDetails = ({ itemId: shipmentId }: ShipmentDetailsProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const shipmentRef = useRef<ShipmentHandle>(null)
  const shipmentTaxesRef = useRef<ShipmentTaxesHandle>(null)

  const shipment = useSelector(getShipment(shipmentId))
  const isUpdating = useSelector(getShipmentsIsUpdating)
  const isFetching = useSelector(getShipmentsIsFetching)
  const isDeleting = useSelector(getShipmentsIsDeleting)
  const isOptimizeInventoryShipmentsLoadingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.OPTIMIZE_INVENTORY_SHIPMENTS_LOADING),
  )
  const member = useSelector(getUser(shipment?.receivedById))

  if (!member && shipment?.receivedById) {
    dispatch(fetchMember(shipment?.receivedById, true))
  }

  const getBulkShipmentItemStatusId = useGetBulkShipmentItemStatusIdByShipment()

  const [openShipmentDialog] = useDialog(DialogNames.SHIPMENT)
  const [openShipmentItemDialog] = useDialog(DialogNames.SHIPMENT_ITEM)

  const deleteShipmentItemAfterUpdate = useCloseAfterCreation(
    (shipmentItemId: string) => {
      dispatch(deleteShipmentItem(shipmentId, shipmentItemId))
    },
    getShipmentsIsUpdating,
  )

  const [draftShipmentItems, setDraftShipmentItems] = useState<ShipmentItem[]>(
    shipment?.items || [],
  )

  const dirtyShipmentTotalTax = Boolean(shipment?.customTotalTax)
  const dirtyShipmentItemsTaxes = draftShipmentItems.some((item) =>
    Number(item.tax),
  )

  useEffect(() => {
    const isShipmentIncomplete = R.isEmpty(shipment) || !shipment?.items
    const shouldLoadShipment = isOptimizeInventoryShipmentsLoadingEnabled
      ? shipmentId && isShipmentIncomplete
      : R.isEmpty(shipment) && shipmentId

    if (shouldLoadShipment) {
      dispatch(fetchShipment(shipmentId))
    }
  }, [shipmentId])

  useEffect(() => {
    if (shipment?.items) {
      setDraftShipmentItems(shipment?.items)
    }
  }, [shipment?.items])

  const permissions = useSelector(getCRUDByArea(PermissionArea.SHIPMENTS))

  const handleBackButtonClick = () =>
    navigate('/admin/catalog/inventories/shipments')

  const setCloseAfterDelete = useCloseAfterCreation(
    handleBackButtonClick,
    getShipmentsIsDeleting,
  )

  const getNewShipment = () => {
    const shipmentTaxes = shipmentTaxesRef.current?.get()
    const { total } = getShipmentPriceInfo({
      ...shipmentTaxes,
      items: draftShipmentItems,
    })

    return {
      ...(shipment || {}),
      ...shipmentRef.current?.get(),
      ...shipmentTaxesRef.current?.get(),
      items: draftShipmentItems,
      costTotal: total,
    } as ShipmentType
  }

  const validateShipmentChanges = () =>
    shipmentRef.current?.validate() && shipmentTaxesRef.current?.validate()

  const getHasUnsavedItems = () => {
    const newShipment = getNewShipment()
    return !deepEqual(shipment?.items, newShipment.items)
  }

  const handleSave = () => {
    const newShipment = getNewShipment()
    const hasUnsavedItems = getHasUnsavedItems()
    dispatch(
      editShipment(
        newShipment,
        isOptimizeInventoryShipmentsLoadingEnabled ? hasUnsavedItems : true,
      ),
    )
  }

  const onSaveRequested = () => {
    if (validateShipmentChanges()) {
      handleSave()
    }
  }

  const onCloneRequested = () => {
    const itemsClone = draftShipmentItems.map(R.omit(['id']))
    const shipmentClone = {
      ...R.omit(['id'], shipment),
      items: itemsClone,
    }
    openShipmentDialog({ shipment: shipmentClone as ShipmentType })
  }

  const onDeleteRequested = () => {
    setCloseAfterDelete()
    dispatch(deleteShipment(shipmentId))
  }

  const handleShipmentItemEdit = (shipmentItemId: string) => {
    const shipmentItem = draftShipmentItems.find(
      ({ id }) => id === shipmentItemId,
    )
    openShipmentItemDialog({
      shipmentId: shipment?.id,
      shipmentItem,
      title: shipmentItem?.name,
    })
  }

  const handleShipmentItemDuplicate = (shipmentItemId: string) => {
    const shipmentItem = draftShipmentItems.find(
      ({ id }) => id === shipmentItemId,
    )
    openShipmentItemDialog({
      shipmentId: shipment?.id,
      shipmentItem: shipmentItem
        ? (R.omit(['id'], shipmentItem) as ShipmentItem)
        : undefined,
      title: shipmentItem?.name,
    })
  }

  const handleShipmentStatusChange = (shipmentStatusId: string) => {
    setDraftShipmentItems(
      draftShipmentItems.map((item) => ({
        ...item,
        statusId: getBulkShipmentItemStatusId(shipmentStatusId),
      })),
    )
  }

  const getUnsavedData = () => {
    const newShipment = getNewShipment()
    // set costTotal = 0 because browser calculates the sum for some decimal numbers differently from the server
    // we receive items, notes, customTotalTax and shippingAndHandling with another endpoint
    const areShipmentsEqual = isOptimizeInventoryShipmentsLoadingEnabled
      ? deepEqual(
          {
            ...shipment,
            items: shipment?.items || [],
            notes: shipment?.notes || '',
            customTotalTax: shipment?.customTotalTax || 0,
            shippingAndHandling: shipment?.shippingAndHandling || 0,
            costTotal: 0,
          },
          {
            ...newShipment,
            notes: newShipment?.notes || '',
            customTotalTax: newShipment?.customTotalTax || 0,
            shippingAndHandling: newShipment?.shippingAndHandling || 0,
            items: newShipment?.items || [],
            costTotal: 0,
          },
        )
      : deepEqual(
          { ...shipment, costTotal: 0 },
          { ...newShipment, costTotal: 0 },
        )

    return shipment && !R.isEmpty(shipment) && !areShipmentsEqual
  }

  const handleShipmentItemDelete = (shipmentItemId: string) => {
    const hasUnsaved = getUnsavedData()
    if (shipment) {
      if (!isOptimizeInventoryShipmentsLoadingEnabled || hasUnsaved) {
        handleSave()
      }
      deleteShipmentItemAfterUpdate(shipmentItemId)
    }
  }

  const handleEditShipment = () => {
    const hasUnsaved = getUnsavedData()
    if (!isOptimizeInventoryShipmentsLoadingEnabled || hasUnsaved) {
      handleSave()
    } else {
      dispatch(fetchShipment(shipmentId))
    }

    openShipmentDialog({ shipment })
  }

  return (
    <Expander
      isConfirmToDelete
      expandedItemClass={t('Common:SHIPMENT').toLowerCase()}
      getUnsavedData={getUnsavedData}
      isDeleting={isDeleting}
      isFetching={isFetching}
      isSaving={isUpdating}
      onBack={handleBackButtonClick}
      onCloneRequested={permissions.create ? onCloneRequested : undefined}
      onDeleteRequested={permissions.delete ? onDeleteRequested : undefined}
      onSaveRequested={permissions.update ? onSaveRequested : undefined}
    >
      <Shipment
        ref={shipmentRef}
        shipment={shipment}
        onShipmentStatusChange={handleShipmentStatusChange}
      />
      <Text mb={1} mt={2} variant="body">
        <Text inline strong component="span" mr={1} variant="body">
          {t('Common:SHIPMENT_TOTAL')}:
        </Text>
        {NumberUtils.formatMoney(shipment?.costTotal)}
      </Text>
      <ShipmentTaxes
        className={classes.shipmentTaxes}
        ref={shipmentTaxesRef}
        shipment={shipment}
        taxDisabled={dirtyShipmentItemsTaxes && !dirtyShipmentTotalTax}
      />
      <Text strong mb={1} variant="body2">
        {t('Common:ITEMS_RECEIVED')}
      </Text>
      <ShipmentItemsTable
        showName
        createDisabled={!permissions.create}
        deleteDisabled={!permissions.delete}
        editDisabled={!permissions.update}
        shipmentItems={draftShipmentItems}
        showDate={false}
        onDelete={handleShipmentItemDelete}
        onDuplicate={handleShipmentItemDuplicate}
        onEdit={handleShipmentItemEdit}
      />
      <Grid container item alignItems="center" mt={1}>
        <TableActionButton
          Icon={EditIcon}
          label={t('Common:EDIT_ALL')}
          onClick={handleEditShipment}
        />
      </Grid>
    </Expander>
  )
}

export default ShipmentDetails

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Input, InputLabel } from '@mui/material'
import {
  Field,
  Nil,
  PuiSelect,
  PuiSelectProps,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import RoleName from '~/constants/roleNames'
import { fetchMembersForSelectList } from '~/store/actions/members'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import {
  getMembersIsFetchingSelectList,
  getMembersSelectList,
} from '~/store/reducers/members'

import useTeamMembers from './useTeamMembers'

const makeMembersSelectOptions = (members: User[]) =>
  members
    .filter(Boolean)
    .map((member) => ({ id: member.id, name: Utils.getPersonString(member) }))

export type TeamMemberSelectProps = PuiSelectProps & {
  field: Field
  key?: string
  label?: string | Nil
  members?: string[]
  placeholder?: string
  roles?: RoleName[]
}

const TeamMemberSelect = ({
  key = 'doctor-select',
  field,
  label,
  roles,
  members = [],
  placeholder,
  ...rest
}: TeamMemberSelectProps) => {
  const dispatch = useDispatch()

  const selectList = useSelector(getMembersSelectList)
  const isFetching = useSelector(getMembersIsFetchingSelectList)
  const RolesList = useMainStaffRoles()

  const useMembersProp = members?.length > 0
  const list = useMembersProp ? members : selectList
  const teamMembers = useTeamMembers(list)
  const selectOptions = makeMembersSelectOptions(teamMembers)

  useEffect(() => {
    if (!useMembersProp) {
      // @TODO: this list seems to be paginated
      //        don't mind to much, as it is temporary solution
      //        change to list of distinct doctors from backend
      const roleIds =
        roles &&
        roles.flatMap((roleName) =>
          Utils.findConstantIdsByName(roleName, RolesList),
        )
      dispatch(
        fetchMembersForSelectList({
          from: 0,
          to: 1000, // RHAP-5895: Replace this TeamMemberSelect.tsx component with UserSelect.tsx by 4/15/24
          includeInactive: false,
          shorten: true,
          roleIds,
          noRolesInResult: true,
        }),
      )
    }
  }, [useMembersProp])

  const shouldShrinkLabel = placeholder ? true : undefined

  return (
    <FormControl fullWidth>
      {label && (
        <InputLabel htmlFor={key} shrink={shouldShrinkLabel}>
          {label}
        </InputLabel>
      )}
      <PuiSelect
        disabled={isFetching}
        field={field}
        input={<Input id={key} />}
        isLoading={isFetching}
        items={selectOptions}
        placeholder={placeholder}
        {...rest}
      />
    </FormControl>
  )
}

export default TeamMemberSelect

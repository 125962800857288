import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, MenuItem } from '@mui/material'

import { Status } from '~/components/elements/Status/Status'
import Typography from '~/components/elements/Typography/Typography'

interface WellnessPlanSelectItemProps {
  active: boolean
  name: string
}

export const WellnessPlanSelectItem = ({
  name,
  active,
  ...props
}: WellnessPlanSelectItemProps) => {
  const { t } = useTranslation('Common')
  return (
    <MenuItem {...props}>
      <Grid container justifyContent="space-between">
        <Typography.Paragraph>{name}</Typography.Paragraph>
        <Status variant={active ? 'good' : 'inactive'}>
          {active ? t('Common:ACTIVE_ONE') : t('Common:INACTIVE_ONE')}
        </Status>
      </Grid>
    </MenuItem>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { CardPaymentMethod } from '~/api/graphql/generated/types'
import { Status } from '~/components/elements/Status/Status'
import Typography from '~/components/elements/Typography/Typography'
import { formatCardPaymentMethod } from '~/utils/cvcClient'

export const ChewyCardPaymentMethodFormatted = ({
  cardPaymentMethod,
}: {
  cardPaymentMethod: CardPaymentMethod
}) => {
  const { t } = useTranslation(['Common'])
  return (
    <>
      <div
        style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
      >
        <Typography.Paragraph style={{ margin: 0 }}>
          {formatCardPaymentMethod(cardPaymentMethod)}
        </Typography.Paragraph>
        {cardPaymentMethod.primary && (
          <Status style={{ marginLeft: '8px' }} variant="normal">
            {t('Common:PRIMARY')}
          </Status>
        )}
      </div>
    </>
  )
}

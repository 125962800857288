import React, { useCallback, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Constant,
  Field,
  LanguageUtils,
  PuiInput,
} from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    toggleIcon: {
      right: 0,
      color: theme.colors.toggleIcon,
      position: 'absolute',
      pointerEvents: 'none',
    },
    spinner: {
      position: 'relative',
      right: 25,
    },
    loader: {
      display: 'block',
      margin: `${theme.spacing(2)} auto`,
    },
    hidden: {
      display: 'none',
    },
  }),
  { name: 'InfiniteScrollSelect' },
)

export type InfiniteScrollSelectItem = Constant & {
  disabled?: boolean
}

export type InfiniteScrollSelectProps = SelectProps & {
  field: Field
  items: InfiniteScrollSelectItem[]
  loadItems?: () => void
  loadMore: () => void
  totalCount: number
}

const InfiniteScrollSelect = ({
  classes,
  field,
  disabled,
  items: itemsProp,
  totalCount,
  loadItems = R.F,
  loadMore,
  ...rest
}: InfiniteScrollSelectProps) => {
  const items = itemsProp || []
  const ownClasses = useStyles(rest)

  const [open, setOpen] = useState(false)
  const [firstOpen, setFirstOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    if (firstOpen) {
      setFirstOpen(false)
      loadItems()
    }
    setOpen(true)
  }

  const IconComponent = useCallback(
    () => (
      <InputAdornment position="end">
        {!disabled && <Toggle className={ownClasses.toggleIcon} />}
      </InputAdornment>
    ),
    [disabled],
  )

  const options = items.map((item) => (
    <MenuItem
      disabled={item.disabled}
      key={item.id}
      selected={item.id === field.value}
      value={item.id}
      onClick={() => {
        field.setValue(item.id)
        handleClose()
      }}
    >
      {LanguageUtils.getTranslatedFieldName(item)}
    </MenuItem>
  ))

  // this hack is required because material UI select needs direct children inside to get values from them
  // if we don't pass this the select will not know what are the available options and will not render anything
  const hiddenOptions = options.map((item) =>
    React.cloneElement(item, { className: ownClasses.hidden }),
  )

  return (
    <PuiInput field={field}>
      <Select
        IconComponent={IconComponent}
        MenuProps={{
          PaperProps: {
            id: 'infinite-scroll-select',
          },
        }}
        classes={classes}
        disabled={disabled}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        {...rest}
      >
        {hiddenOptions}
        <InfiniteScroll
          dataLength={items.length}
          hasMore={totalCount > items.length}
          loader={
            <CircularProgress
              className={ownClasses.loader}
              color="primary"
              size={32}
            />
          }
          next={loadMore}
          scrollableTarget="infinite-scroll-select"
        >
          {options}
        </InfiniteScroll>
      </Select>
    </PuiInput>
  )
}

export default InfiniteScrollSelect

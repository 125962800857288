import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { PermissionArea } from '@pbt/pbt-ui-components'

import { getChargeSheet } from '~/store/duck/clientFinanceData'
import { useGetChargesList } from '~/store/hooks/chargeSheet'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getClientId, getPatientId, getSoapId } from '~/store/reducers/soap'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import { LineItemComponentsActions } from '../../soap/rail/summary/orders/orderSummaryUtils'
import SoapWidget from '../SoapWidget'
import ChargesSoapTable from './table/ChargesSoapTable'
import ChargesItems from './tabs/ChargesItems'
import { useChargeOrderAlert } from './utils/useChargeOrderAlert'
import { useClearExpandedGroup } from './utils/useClearExpandedGroup'

const useStyles = makeStyles(
  () => ({
    content: {
      flexDirection: 'column-reverse',
    },
  }),
  { name: 'ChargesWidgetUnlocked' },
)

const ChargesWidgetUnlocked = () => {
  const classes = useStyles()
  const { t } = useTranslation('Payments')

  const soapId = useSelector(getSoapId)
  const patientId = useSelector(getPatientId)
  const clientId = useSelector(getClientId)
  const SOAPPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const chargeSheet = useSelector(getChargeSheet)

  // TODO need to refactor ChargesSoapTable and it's children to useInvoiceLineItem and GroupLineItems types
  // now it handles both bundles and items but it dones't reflect in types
  // @ts-ignore
  const chargesList = useGetChargesList(clientId, soapId)
  // Show alert when order can't be removed
  useChargeOrderAlert()
  // Expanded bundles from charges widget should be consistent with invoice
  useClearExpandedGroup()

  const [expandedTab, setExpandedTab] = useState<string | null>(null)

  const items = chargesList
  const editDisabled = !SOAPPermissions.update

  const handleExpandTab = useCallback((id: string | null) => {
    setExpandedTab(id)
  }, [])

  useWSTopic({
    wsTopic: WSTopics.CHARGES_WIDGET_GROUPED_ITEM,
    context: {
      retrySubscription: false,
      topicParams: {
        soapId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.REMINDERS,
    context: {
      retrySubscription: true,
      topicParams: {
        patientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGES_POSTED_ON_SOAP,
    context: {
      retrySubscription: false,
      topicParams: {
        soapId,
        clientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGES_SPECIAL_PROCEDURE,
    context: {
      retrySubscription: false,
      topicParams: {
        patientId,
      },
    },
    unsubscribe: true,
  })

  return (
    <SoapWidget
      classes={{ content: classes.content }}
      id="charges-widget"
      title={t('Payments:CHARGES')}
    >
      {!expandedTab && (
        <ChargesSoapTable
          ComponentsActions={LineItemComponentsActions}
          chargesId={R.propOr('', 'id', chargeSheet)}
          editDisabled={editDisabled}
          items={items}
        />
      )}
      <ChargesItems
        editDisabled={editDisabled}
        expandedTab={expandedTab}
        view="drop-down"
        onExpandTab={handleExpandTab}
      />
    </SoapWidget>
  )
}

export default ChargesWidgetUnlocked

import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { PuiTooltip, Text } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { getIsRetailOrderLineItem } from '~/components/dashboard/invoices/invoiceUtils'
import { FINANCE_CELL_WIDTH } from '~/constants/financeTable'
import { InvoiceLineItem } from '~/types'

const { QUANTITY } = FINANCE_CELL_WIDTH

const useStyles = makeStyles(
  () => ({
    quantityCell: {
      minWidth: QUANTITY,
      width: QUANTITY,
    },
  }),
  { name: 'FinanceItemQuantityCellBody' },
)

export interface FinanceItemQuantityCellBodyProps {
  isFullyRefunded?: boolean
  item: InvoiceLineItem | RetailOrderLineItem
  showUsedPrepaid?: boolean
  tableCellClassName: string
  tableCellWithBorder: string
}

const FinanceItemQuantityCellBody = ({
  item,
  isFullyRefunded = false,
  showUsedPrepaid,
  tableCellClassName,
  tableCellWithBorder,
}: FinanceItemQuantityCellBodyProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isRetailOrderLineItem = getIsRetailOrderLineItem(item)

  const { usedQuantity, quantity, prepaidRemaining, prepaid } =
    !isRetailOrderLineItem
      ? item
      : {
          // Default for RetailOrderLineItem. No concept of null values here.
          usedQuantity: null,
          prepaidRemaining: null,
          prepaid: null,
          quantity: item.quantity,
        }

  const paidText = `${quantity} ${t('Common:PAYMENTS.PAID').toLowerCase()}`
  const remainingText = Number.isSafeInteger(prepaidRemaining)
    ? ` | ${prepaidRemaining} ${t('Common:REMAINING').toLowerCase()}`
    : ''
  const usedText = Number.isSafeInteger(usedQuantity)
    ? ` | ${usedQuantity} ${t('Common:USED').toLowerCase()}`
    : ''
  const toolTipText = `${paidText}${usedText}${remainingText}`

  const showNewPrepaidDesign = prepaid && Boolean(showUsedPrepaid)

  const prepaidQuantityString = showNewPrepaidDesign
    ? `${quantity} | ${usedQuantity} | ${prepaidRemaining}`
    : usedQuantity

  return (
    <TableCell
      align="left"
      className={classNames(
        tableCellClassName,
        tableCellWithBorder,
        classes.quantityCell,
      )}
    >
      {!item.declined && (
        <PuiTooltip
          disabled={!prepaid}
          tooltipPlacement="top"
          tooltipText={toolTipText}
        >
          <Text
            textAlign={showNewPrepaidDesign ? 'center' : 'inherit'}
            variant={isFullyRefunded ? 'lowAccent2' : 'body2'}
          >
            {prepaid ? prepaidQuantityString : quantity}
          </Text>
        </PuiTooltip>
      )}
    </TableCell>
  )
}

export default FinanceItemQuantityCellBody

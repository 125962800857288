import { combineReducers } from 'redux'

import * as AuthActions from '../actions/types/auth'
import { UPDATE_CURRENT_USERS_BUSINESS_ID } from '../actions/types/users'
import { addendumsReducer as addendums } from '../duck/addendums'
import { analyticsReducer as analytics } from '../duck/analytics'
import automaticCommunications from '../duck/automaticCommunications'
import { benchmarkingReducer as benchmarking } from '../duck/benchmarking'
import { benchmarkingPlusReducer as benchmarkingPlus } from '../duck/benchmarkingPlus'
import { boopReducer as boop } from '../duck/boop'
import { bulkPricesSlice } from '../duck/bulkPrices'
import { businessSettingsReducer as businessSettings } from '../duck/businessSettings'
import charges from '../duck/charges'
import chewyPets from '../duck/chewyPets'
import { ckeditorReducer as ckeditor } from '../duck/ckeditor'
import clientBillingActivity from '../duck/clientBillingActivityData'
import clientFinanceData from '../duck/clientFinanceData'
import clientPreferences from '../duck/clientPreferences'
import { conferencingReducer as conferencing } from '../duck/conferencing'
import { connectedDevicesReducer as connectedDevices } from '../duck/connectedDevices'
import { contactsReducer as contacts } from '../duck/contacts'
import { customLoadingReducer as customLoading } from '../duck/customLoading'
import { departmentsReducer as departments } from '../duck/departments'
import { dialogsReducer as dialogs } from '../duck/dialogs'
import { discountGroupsReducer as discountGroups } from '../duck/discountGroups'
import { discountReasonsReducer as discountReasons } from '../duck/discountReasons'
import { documentsV2Reducer as documentsV2 } from '../duck/documentsV2'
import { emailAppointmentReducer as emailAppointment } from '../duck/emailAppointment'
import { errorsReducer as errors } from '../duck/errors'
import { filesReducer as files } from '../duck/files'
import { imagingDashboardReducer as imagingDashboard } from '../duck/imagingDashboard'
import { imagingOrdersReducer as imagingOrders } from '../duck/imagingOrders'
import { imagingVendorConfigReducer as imagingVendorConfig } from '../duck/imagingVendorConfig'
import { intercomReducer as intercom } from '../duck/intercom'
import { inventoryAdjustmentsReducer as inventoryAdjustments } from '../duck/inventoryAdjustments'
import { labelPrintingReducer as labelPrinting } from '../duck/labelPrinting'
import { labOrdersReducer as labOrders } from '../duck/labOrders'
import { labTestsDashboardReducer as labTestsDashboard } from '../duck/labTestsDashboard'
import { labVendorConfigReducer as labVendorConfig } from '../duck/labVendorConfig'
import { landingReducer as landing } from '../duck/landing'
import { lastAppointmentsReducer as lastAppointments } from '../duck/lastAppointments'
import { loyaltyPointsReducer as loyaltyPoints } from '../duck/loyaltyPoints'
import { marketplaceReducer as marketplace } from '../duck/marketplace'
import { medicalHistoryCardReducer as medicalHistoryCard } from '../duck/medicalHistoryCard'
import { membershipPaymentsReducer as membershipPayments } from '../duck/membershipPayments'
import { migrationSlice } from '../duck/migrationV2'
import monitor from '../duck/monitor'
import { onHandCatalogReducer as onHandCatalog } from '../duck/onHandCatalog'
import { patientMembershipsReducer as patientMemberships } from '../duck/patientMemberships'
import pausedPatientMemberships from '../duck/pausedPatientMemberships'
import { pdmpReportReducer as pdmpReport } from '../duck/pdmpReport'
import { practiceExportReducer as practiceExport } from '../duck/practiceExport'
import { practiceImportReducer as practiceImport } from '../duck/practiceImport'
import prescriptions from '../duck/prescriptions'
import { prescriptionTemplatesReducer as prescriptionTemplates } from '../duck/prescriptionTemplates'
import { printReducer as print } from '../duck/print'
import { progressReducer as progress } from '../duck/progress'
import refunds from '../duck/refunds'
import { reminderProtocolReducer as reminderProtocols } from '../duck/reminderProtocols'
import { reminderProtocolSettingsReducer as reminderProtocolSettings } from '../duck/reminderProtocolSettings'
import reminderProtocolsV2 from '../duck/reminderProtocolsV2'
import { remindersReducer as reminders } from '../duck/reminders'
import { remindersCatalogReducer as remindersCatalog } from '../duck/remindersCatalog'
import { reportCardReducer as reportCard } from '../duck/reportCard'
import { rhapsodyPayReducer as rhapsodyPay } from '../duck/rhapsodyPay'
import { schedulerSettingsReducer as schedulerSettings } from '../duck/schedulerSettings'
import { serviceWorkerReducer as serviceWorker } from '../duck/serviceWorker'
import { settingsReducer as settings } from '../duck/settings'
import { shipmentImportReducer as shipmentImport } from '../duck/shipmentImport'
import { shipmentItemsReducer as shipmentItems } from '../duck/shipmentItems'
import { shipmentsReducer as shipments } from '../duck/shipments'
import { soapDiagnosesReducer as soapDiagnoses } from '../duck/soapDiagnoses'
import { timeTrackerReducer as timeTracker } from '../duck/timeTracker'
import { uiAlertsReducer as uiAlerts } from '../duck/uiAlerts'
import { uiNotificationsReducer as uiNotifications } from '../duck/uiNotifications'
import { userSettingsReducer as userSettings } from '../duck/userSettings'
import { vaccinationReducer as vaccination } from '../duck/vaccination'
import { vetcoveConfigsReducer as vetcoveConfigs } from '../duck/vetcoveConfig'
import { vitalsReducer as vitals } from '../duck/vitals'
import { websocketReducer as websocket } from '../duck/websocket'
import { wellnessPlansCatalogReducer as wellnessPlansCatalog } from '../duck/wellnessPlansCatalog'
import appointmentTypes from './appointmentTypes'
import auth from './auth'
import availabilityRules from './availabilityRules'
import bundles from './bundles'
import businesses from './businesses'
import chat from './chat'
import clients from './clients'
import constants from './constants'
import conversationMessages from './conversationMessages'
import conversations from './conversations'
import coparents from './coparents'
import documents from './documents'
import easterEgg from './easterEgg'
import finance from './finance'
import globalInventoryCatalog from './globalInventoryCatalog'
import inventories from './inventories'
import invoiceV3 from './invoiceV3'
import journal from './journal'
import labTests from './labTests'
import loginSessionSettings from './loginSessionSettings'
import medicalHistory from './medicalHistory'
import members from './members'
import migration from './migration'
import { notificationsReducer as notification } from './notifications'
import orders from './orders'
import patientPreferences from './patientPreferences'
import patients from './patients'
import payments from './payments'
import { problemReducer as problems } from './problems'
import procedures from './procedures'
import questions from './questions'
import registration from './registration'
import roles from './roles'
import scheduler from './scheduler'
import search from './search'
import soap from './soap'
import soapV2 from './soapV2'
import spaces from './spaces'
import supportUsers from './supportUsers'
import tasks from './tasks'
import timeline from './timeline'
import timetable from './timetable'
import users from './users'
import variations from './variations'
import wellnessPlans from './wellnessPlans'
import whiteboard from './whiteboard'

export const appReducer = combineReducers({
  addendums,
  appointmentTypes,
  analytics,
  auth,
  automaticCommunications,
  availabilityRules,
  clientBillingActivity,
  benchmarking,
  benchmarkingPlus,
  boop,
  bulkPrices: bulkPricesSlice.reducer,
  bundles,
  businesses,
  businessSettings,
  charges,
  chat,
  chewyPets,
  ckeditor,
  clientFinanceData,
  clientPreferences,
  clients,
  conferencing,
  connectedDevices,
  constants,
  contacts,
  conversationMessages,
  conversations,
  coparents,
  customLoading,
  departments,
  dialogs,
  discountGroups,
  discountReasons,
  documents,
  documentsV2,
  easterEgg,
  emailAppointment,
  errors,
  files,
  finance,
  globalInventoryCatalog,
  imagingDashboard,
  imagingOrders,
  imagingVendorConfig,
  intercom,
  inventories,
  inventoryAdjustments,
  invoiceV3,
  journal,
  labelPrinting,
  labOrders,
  labTests,
  labTestsDashboard,
  labVendorConfig,
  landing,
  lastAppointments,
  loginSessionSettings,
  loyaltyPoints,
  marketplace,
  medicalHistory,
  medicalHistoryCard,
  members,
  membershipPayments,
  migration,
  migrationV2: migrationSlice.reducer,
  monitor,
  notification,
  onHandCatalog,
  orders,
  patientMemberships,
  patientPreferences,
  pausedPatientMemberships,
  patients,
  payments,
  pdmpReport,
  practiceExport,
  practiceImport,
  prescriptions,
  prescriptionTemplates,
  print,
  problems,
  procedures,
  progress,
  questions,
  refunds,
  registration,
  reminderProtocols,
  reminderProtocolSettings,
  reminderProtocolsV2,
  reminders,
  remindersCatalog,
  reportCard,
  rhapsodyPay,
  roles,
  scheduler,
  schedulerSettings,
  search,
  serviceWorker,
  settings,
  shipmentImport,
  shipmentItems,
  shipments,
  soap,
  soapDiagnoses,
  soapV2,
  spaces,
  supportUsers,
  tasks,
  timeline,
  timetable,
  timeTracker,
  uiAlerts,
  uiNotifications,
  users,
  userSettings,
  vaccination,
  variations,
  vetcoveConfigs,
  vitals,
  websocket,
  wellnessPlans,
  wellnessPlansCatalog,
  whiteboard,
})

export default (state, action) => {
  switch (action.type) {
    case AuthActions.LOGOUT:
      return appReducer(
        {
          journal: state.journal,
          auth: {
            authorizationError: state.auth.authorizationError,
          },
          settings: state.settings,
          userSettings: state.userSettings,
          timetable: state.timetable,
        },
        action,
      )
    case UPDATE_CURRENT_USERS_BUSINESS_ID:
      return appReducer(
        {
          auth: state.auth,
          users: state.users,
          roles: state.roles,
          journal: state.journal,
          constants: state.constants,
          businesses: state.businesses,
          settings: state.settings,
          userSettings: state.userSettings,
          loginSessionSettings: state.loginSessionSettings,
        },
        action,
      )
    default:
      return appReducer(state, action)
  }
}

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { head } from 'ramda'
import {
  ButtonWithLoader,
  DateUtils,
  LanguageUtils,
  Utils,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import DownloadPdfContentButton from '~/components/common/buttons/DownloadPdfContentButton'
import EmailForm, { EmailFormHandle } from '~/components/common/email/EmailForm'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { GeneratingPdfContentKind } from '~/constants/communications'
import InvoiceType, { InvoiceTypeDisplayName } from '~/constants/InvoiceType'
import { fetchClient } from '~/store/actions/clients'
import { emailInvoice } from '~/store/actions/communications'
import { useCreatedConversationsInfo } from '~/store/hooks/conversations'
import { useDownloadContentAsPdf } from '~/store/hooks/finance'
import { getCurrentBusiness } from '~/store/reducers/auth'
import {
  getFinanceInvoice,
  getFinanceIsLoading,
} from '~/store/reducers/finance'
import { getPatientName } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { EmailInvoiceData, EmailInvoiceParams, Invoice } from '~/types'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'
import { useSaveEmailAndSendMessage } from '~/utils/useSaveEmailAndSendMessage'

import EmailInvoiceSelectableList from './EmailInvoiceSelectableList'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(3),
    },
    button: {
      width: 167,
      height: 40,
    },
    row: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'EmailInvoice' },
)

export interface EmailInvoiceProps {
  invoiceIds: string[]
  isEstimate?: boolean
  onOk: () => void
  params: EmailInvoiceParams
}

const EmailInvoice = ({
  isEstimate,
  invoiceIds,
  params,
  onOk,
}: EmailInvoiceProps) => {
  const invoice = useSelector(
    getFinanceInvoice(head(invoiceIds) as string),
  ) as Invoice
  const { t } = useTranslation(['Common', 'Invoices'])

  const clientId = (invoice?.client || invoice?.clientId) as string

  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(getFinanceIsLoading)
  const currentBusiness = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId))
  const patientName = useSelector(getPatientName(invoice?.patient))

  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState(invoiceIds)

  const emailFormRef = useRef<EmailFormHandle>(null)

  const vet = invoice?.event?.assignedVet
  const sender = (vet && Utils.getPersonString(vet)) || currentBusiness?.name
  const eventStart = DateUtils.formatDate(
    invoice?.event?.scheduledStartDatetime,
  )
  const invoiceType = invoice?.type
    ? InvoiceTypeDisplayName[invoice.type] || invoice.type
    : InvoiceTypeDisplayName[
        isEstimate ? InvoiceType.ESTIMATE : InvoiceType.INVOICE
      ]
  const isBatchInvoice = invoiceIds.length > 1

  const messageBody = isEstimate
    ? t('Invoices:EMAIL_INVOICE.BODY_MESSAGE_ESTIMATE', { patientName })
    : t('Invoices:EMAIL_INVOICE.BODY_MESSAGE_INVOICE', { eventStart })

  const endOfMessage = isEstimate
    ? t('Invoices:EMAIL_INVOICE.UPCOMING_SERVICES')
    : t('Invoices:EMAIL_INVOICE.VISIT_ON_DATE', { eventStart })

  const defaultSubject = isBatchInvoice
    ? t('Invoices:EMAIL_INVOICE.SUBJECT_BATCH_INVOICE', {
        currentBusinessName: currentBusiness?.name,
      })
    : t('Invoices:EMAIL_INVOICE.SUBJECT_INVOICE', {
        invoiceType,
        patientName,
        endOfMessage,
      })

  const initialMessage = t('Invoices:EMAIL_INITIAL_MESSAGE', {
    clientFirstName: client?.firstName,
    messageBody,
    sender,
  })

  const {
    fields: { subject, message, to },
    validate,
  } = useNewConversationValidationForm(
    { transport: ConversationTransport.Email, client },
    { initialSubject: defaultSubject, initialMessage },
  )

  useEffect(() => {
    setSelectedInvoiceIds(invoiceIds)
  }, [invoiceIds])

  useEffect(() => {
    if (!client?.email) {
      dispatch(fetchClient({ clientId }))
    }
  }, [clientId])

  const formRecipients = () => emailFormRef.current?.formRecipients() || []

  const getEmailData = (): EmailInvoiceData => ({
    invoiceIds: selectedInvoiceIds,
    invoiceId: invoice?.id,
    transport: ConversationTransport.Email,
    recipients: formRecipients(),
    subject: subject.value,
    message: message.value,
  })

  const displayConversationCreationResult = useCreatedConversationsInfo({
    getIsConversationCreating: getFinanceIsLoading,
    onConversationCreationSuccess: onOk,
    createdInfoDialogProps: {
      titleMessageName: LanguageUtils.capitalize(
        t('Invoices:CREATED_INFO_DIALOG_TITLE', {
          invoiceType: invoiceType.toLowerCase(),
          patientName,
        }),
      ),
    },
  })

  const send = (emailData: EmailInvoiceData) => {
    if (validate()) {
      displayConversationCreationResult()
      dispatch(emailInvoice(emailData, params))
    }
  }

  const sendEmail = () => send(getEmailData())

  const [generatePdf, isGenerating] = useDownloadContentAsPdf(
    GeneratingPdfContentKind.INVOICE,
    {
      invoiceId: selectedInvoiceIds[0],
    },
  )

  const handleDownloadClick = () => {
    generatePdf({
      invoiceIds: selectedInvoiceIds,
      message: message.value,
      params,
    })
  }

  const handleAskForSaveEmailAndSend = useSaveEmailAndSendMessage(
    sendEmail,
    client,
    formRecipients,
    to.value,
  )
  const handleSendClick = () => validate() && handleAskForSaveEmailAndSend()

  return (
    <Grid container className={classes.root} direction="column">
      <EmailForm
        clientId={clientId}
        eventId={invoice?.event?.id}
        message={message}
        patientId={invoice?.patient}
        ref={emailFormRef}
        subject={subject}
        to={to}
      />
      {invoiceIds.length > 1 && (
        <EmailInvoiceSelectableList
          invoiceIds={invoiceIds}
          selectedItems={selectedInvoiceIds}
          toggleItem={(item) =>
            setSelectedInvoiceIds(
              Utils.toggleListItem(item, selectedInvoiceIds),
            )
          }
        />
      )}
      <Grid item className={classes.row}>
        <RequiredFieldsNotice />
      </Grid>
      <Grid
        container
        item
        className={classes.row}
        justifyContent="space-between"
      >
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          onClick={handleSendClick}
        >
          {t('Common:SEND_ACTION')}
        </ButtonWithLoader>
        <DownloadPdfContentButton
          loading={isGenerating}
          onClick={handleDownloadClick}
        />
      </Grid>
    </Grid>
  )
}

export default EmailInvoice

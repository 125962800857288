import { initReactI18next } from 'react-i18next'
import { createInstance, InitOptions } from 'i18next'
import LanguageDetector, {
  DetectorOptions,
} from 'i18next-browser-languagedetector'
import ICU from 'i18next-icu'
import {
  Config,
  FallbackLanguageCode,
  LanguageCodes,
  SupportedLngCodesList,
} from '@pbt/pbt-ui-components/src/localization'

import { TRANSLATIONS_EN_US } from './languages'

const NameSpaces = [
  'Abbreviations',
  'Admin',
  'Auth',
  'Businesses',
  'Clients',
  'Common',
  'Communications',
  'Constants',
  'Dialogs',
  'Errors',
  'Invoices',
  'LabTests',
  'Landing',
  'MedicalHistory',
  'Memberships',
  'Menu',
  'Mock',
  'Notifications',
  'PageTitles',
  'Payments',
  'Plurals',
  'Prices',
  'PrivacyNotice',
  'Profile',
  'Registration',
  'Reminders',
  'Search',
  'Soap',
  'Tasks',
  'TermsAndConditions',
  'Time',
  'TimeTable',
  'Tooltips',
  'Utils',
  'Validations',
  'Watcher',
  'WellnessPlans',
]

const langDetectorOptions: DetectorOptions = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'sessionStorage',
    'localStorage',
    'navigator',
  ],
  // keys or params to lookup language from
  lookupQuerystring: Config.LookupQueryString,
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  // cache user language on
  caches: ['localStorage', 'cookie'],
  // languages to not persist (cookie, localStorage)
  excludeCacheFor: ['cimode'],
}

const enabledI18nDebug = localStorage.getItem('enabledI18nDebug')
const i18nextPortalOptions: InitOptions = {
  debug: enabledI18nDebug ? JSON.parse(enabledI18nDebug) : false,
  defaultNS: 'Common',
  fallbackNS: 'Common',
  ns: NameSpaces,
  detection: langDetectorOptions,
  fallbackLng: FallbackLanguageCode,
  supportedLngs: SupportedLngCodesList,
  load: 'currentOnly',
  react: {
    useSuspense: true,
  },
  resources: {
    [LanguageCodes['en-US']]: TRANSLATIONS_EN_US,
  },
  returnEmptyString: false, // avoid empty string when there's no available translations yet
}

const i18nPortal = createInstance()
i18nPortal
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nextPortalOptions, (err) => {
    if (err) {
      throw new Error(
        `[i18next-portal]: something went wrong loading: ${err.toString()}`,
      )
    }
  })

export default i18nPortal

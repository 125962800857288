import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  AtLeast,
  BreedConstant,
  Constant,
  DateFormat,
  LanguageUtils,
  NamedEntity,
  Nil,
  Patient,
  SentenceFormatter,
  UnitsState,
  UnitUtils,
  User,
  Utils,
} from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'
import { formatDate } from '@pbt/pbt-ui-components/src/utils/dateUtils'

import {
  RefundChargesSection,
  ShippingAddress,
} from '~/api/graphql/generated/types'
import { getShortGenderString } from '~/components/common/inputs/gender/genderUtils'
import { ChargeSheetEntityTypes } from '~/components/dashboard/charge-sheet/constants'
import DialogNames from '~/constants/DialogNames'
import InvoiceType from '~/constants/InvoiceType'
import { OrderType } from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import { getBusinessScopeModalityList } from '~/store/duck/imagingOrders'
import { useGetIdexxImagingId } from '~/store/hooks/constants'
import {
  getChargesLogsByLogType,
  hasImagingOrders,
  hasUnfinishedLabs,
} from '~/store/hooks/finance'
import { useGetActiveImagingVendorsMap } from '~/store/hooks/imagingVendorConfig'
import { useGetActiveLabVendorsMap } from '~/store/hooks/labVendorConfig'
import {
  InvoiceFromLineItem,
  InvoiceLineItem,
  Order,
  TimetableEvent,
} from '~/types'
import { ChargeSheetItemSection } from '~/types/entities/chargesSheet'
import { getFullBreedName, getPatientAge } from '~/utils'
import { formatShippingAddress, getPrimary } from '~/utils/cvcClient'
import { convertInvoiceLineItemToCreateItemInput } from '~/utils/finance'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import {
  addChargeSheetItems,
  deleteChargeSheetItems,
  fetchChargeSheetOrderFilters,
  fetchClientFinanceCharges,
  getChargeSheet,
  getChargeSheetOrderFilters,
  getChargeSheetOrderFiltersLoading,
  getChargeSheetSubItemById,
  getChargeSheetSubItemsMap,
  getChargesList,
  getClientFinanceClientId,
  UPDATE_CLIENT_FINANCE_CHARGES,
} from '../duck/clientFinanceData'
import { getRefundInvoice, getRefundsId } from '../duck/refunds'
import { getUnitsState } from '../duck/settings'
import { getCurrentUserId } from '../reducers/auth'
import {
  getBreed,
  getFullAlertType,
  getGender,
  getProcedureCategory,
  getReadyForOrderLabStates,
} from '../reducers/constants'
import {
  getInvoiceV3,
  getInvoiceV3Id,
  getInvoiceV3SubItemsMap,
} from '../reducers/invoiceV3'
import { getPatientsList } from '../reducers/patients'
import { getIsCurrentContextSoap } from '../reducers/soap'
import { getTimetableEventsMap } from '../reducers/timetable'
import { getUser, getUsersMap } from '../reducers/users'
import { useIsChewyCheckoutEnabled } from './business'

export const useChargeSheetSectionMap = (
  clientId: string | Nil,
  soapId?: string | Nil,
) => {
  const dispatch = useDispatch()
  const subItems = useSelector(getChargeSheetSubItemsMap)

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientFinanceCharges({ id: clientId, soapId }))
    }
  }, [clientId, soapId])

  if (R.isNil(subItems)) {
    return {}
  }

  return subItems
}

export const useGetChargesList = (
  clientId: string | Nil,
  soapId: string | Nil,
) => {
  const dispatch = useDispatch()
  const chargesList = useSelector(getChargesList)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)

  useEffect(() => {
    if (clientId && soapId && isCurrentContextSoap) {
      dispatch(fetchClientFinanceCharges({ id: clientId, soapId }))
    }
  }, [clientId, soapId, isCurrentContextSoap])

  if (R.isNil(chargesList)) {
    return []
  }

  return chargesList
}

type GetChargeSheetItemHeader = {
  chargeSheetProducer: Record<string, User>
  currentItem: ChargeSheetItemSection | Nil
  eventsMap: Record<string, TimetableEvent>
  isEntityNames?: boolean
  patients: Patient[] | Nil
}

const ChargeSheetItemHeaderNames = {
  OTCItemName: (isEntityNames?: boolean) => (isEntityNames ? '' : 'OTC'),
  PatientName: (patients: Patient[] | Nil, currentPatientId?: string) =>
    currentPatientId
      ? R.prop(
          'name',
          patients?.find((patient) => patient.id === currentPatientId),
        )
      : '',
  DoctorName: (doctorName?: string, isEntityNames?: boolean) =>
    doctorName && !isEntityNames
      ? `${i18n.t('Common:WITH').toLowerCase()} ${doctorName}`
      : '',
  FormattedPatientName: (patientName?: string, isEntityNames?: boolean) =>
    patientName
      ? `${isEntityNames ? '' : i18n.t('Common:FOR')} ${patientName}`
      : '',
}

const getChargeSheetItemHeader = ({
  currentItem,
  patients,
  isEntityNames,
  eventsMap,
  chargeSheetProducer,
}: GetChargeSheetItemHeader) => {
  const wordsSeparator = isEntityNames ? ' | ' : ' '
  if (R.isNil(currentItem) || R.isNil(patients)) {
    return ''
  }

  const currentPatientId = R.prop('patientId', currentItem)
  const { soap, eventId } = currentItem || {}

  const { assignedVetId } = soap || {}

  const patientName = ChargeSheetItemHeaderNames.PatientName(
    patients,
    currentPatientId,
  )

  const OTCName = ChargeSheetItemHeaderNames.OTCItemName(isEntityNames)
  const itemName =
    soap && eventId
      ? R.path(['businessAppointmentType', 'name'], eventsMap[eventId])
      : OTCName

  const doctorName = assignedVetId
    ? Utils.getPersonString(chargeSheetProducer[assignedVetId])
    : ''

  const date =
    soap && eventId
      ? formatDate(R.prop('scheduledStartDatetime', eventsMap[eventId]))
      : ''

  return LanguageUtils.getSentence(
    [
      // @ts-ignore
      itemName.toString(),
      ChargeSheetItemHeaderNames.FormattedPatientName(
        patientName,
        isEntityNames,
      ),
      ChargeSheetItemHeaderNames.DoctorName(doctorName, isEntityNames),
      date,
    ],
    SentenceFormatter.REGULAR,
    wordsSeparator,
  )
}

export const useGetChargeSheetItemHeader = (currentItemId?: string) => {
  const chargeSheetClientId = useSelector(getClientFinanceClientId)
  const chargeSheetClient = useSelector(getUser(chargeSheetClientId))
  const patients = useSelector(getPatientsList(chargeSheetClient?.patients))
  const currentItem = useSelector(getChargeSheetSubItemById(currentItemId))
  const eventsMap = useSelector(getTimetableEventsMap)
  const chargeSheetProducer = useSelector(getUsersMap)

  return getChargeSheetItemHeader({
    currentItem,
    patients,
    eventsMap,
    chargeSheetProducer,
  })
}

// TODO use Gender when we return to genderID
export const getPatientData = (
  patient: Patient,
  unitsState: UnitsState,
  Gender: Constant[],
  Breed: BreedConstant,
) => {
  const {
    color,
    currentWeight,
    breeds,
    approximateDateOfBirth,
    gender,
    spayedNeutered,
    species,
  } = patient || {}
  const { value, date } = currentWeight || {}

  const patientGender = getShortGenderString(gender, spayedNeutered, Gender)
  const aproximateSign = approximateDateOfBirth ? '~' : ''
  const age = getPatientAge(patient, true)
  const weight = UnitUtils.convertUnitsForDisplay(
    UnitTypes.WEIGHT,
    value,
    unitsState,
  )

  const formattedBreeds = getFullBreedName(species, breeds, Breed, ' | ')

  const formattedDate = date
    ? `(${formatDate(date, false, DateFormat.SHORT_DATE_YEAR_ABBREV)})`
    : ''
  const formattedWeight = weight ? `${weight} ${unitsState.weight}` : ''
  const formattedAge = age ? `${aproximateSign} ${age} |` : ''
  const formattedColor = color ? `${color} |` : ''
  const formattedGender = patientGender ? `${patientGender} |` : ''

  return LanguageUtils.getSentence(
    [
      formattedAge,
      formattedGender,
      formattedBreeds,
      formattedColor,
      formattedWeight,
      formattedDate,
    ],
    SentenceFormatter.REGULAR,
  )
}

export type ClientPatientData = {
  alertColorId?: string | Nil
  alertText?: string
  data: string
  isBoopUser?: boolean
  isClient?: boolean
  name: string
  patientId?: string
  primaryShippingAddress?: ShippingAddress | Nil
}

export const getAlertText = (
  patient: Patient,
  FullAlertTypes: NamedEntity[],
) => {
  if (!patient) {
    return ''
  }

  const alerts: NamedEntity[] = FullAlertTypes.filter((type) =>
    patient.alertTypeIds?.includes(type.id),
  )
  const alertsToRender: string = [
    ...alerts.map((alert: NamedEntity) =>
      LanguageUtils.getConstantTranslatedName(alert?.id, alerts),
    ),
    patient.customAlertType || '',
  ]
    .filter(Boolean)
    .join(',')

  return alertsToRender
}

export const useGetClientFinancePatientData = (
  clientId: string | Nil,
  isInvoice?: boolean,
  isRefundInvoice?: boolean,
): ClientPatientData[] => {
  const clientStore = useSelector(getUser(clientId))
  const unitsState = useSelector(getUnitsState)
  const Gender = useSelector(getGender)
  const Breed = useSelector(getBreed)
  const FullAlertTypes = useSelector(getFullAlertType)
  const chargeSheetItems = useSelector(getChargeSheetSubItemsMap)
  const invoiceItems = useSelector(getInvoiceV3SubItemsMap)
  const refundInvoiceId = useSelector(getRefundsId)
  const refundInvoice = useSelector(getRefundInvoice(refundInvoiceId))
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const invoiceId = useSelector(getInvoiceV3Id)
  const invoice = useSelector(getInvoiceV3(invoiceId))
  const client = clientStore || refundInvoice?.client

  const patientList =
    clientStore?.patients ||
    invoice?.patients ||
    R.pluck('id', refundInvoice?.client.patients || [])

  const patients = useSelector(getPatientsList(patientList))
  const refundItems = R.reduce(
    (acc, section) => {
      if (section.id) {
        acc[section.id] = section
      }
      return acc
    },
    {} as { [key: string]: RefundChargesSection },
    refundInvoice?.sections || [],
  )

  const charges =
    isRefundInvoice && refundInvoice
      ? refundItems
      : isInvoice
        ? invoiceItems
        : chargeSheetItems

  const { firstName, lastName, homePhone, mobilePhone, email } = client || {}
  const phone = homePhone || mobilePhone || ''
  const shippingAddresses = (client as any)?.shippingAddresses as
    | ShippingAddress[]
    | undefined

  const patientIdsWithCharges = R.pipe<
    (typeof charges)[],
    (ChargeSheetItemSection | RefundChargesSection)[],
    string[],
    string[]
  >(
    R.values,
    R.map((section: ChargeSheetItemSection | RefundChargesSection) => {
      if (R.has('patient', section)) {
        return (section as RefundChargesSection).patient?.id ?? ''
      }
      return (section as ChargeSheetItemSection).patientId
    }),
    R.filter(Boolean),
  )(charges)

  const patientWithCharges = R.filter(
    ({ id }) => patientIdsWithCharges.includes(id),
    patients,
  )

  let clientData = phone ? `${phone} | ${email}` : email || ''
  const primaryShippingAddress = getPrimary(
    shippingAddresses,
    invoice?.retailOrder?.shippingAddress?.id,
  )
  if (isChewyCheckoutEnabled && primaryShippingAddress && !isRefundInvoice) {
    const writtenAddress = `${i18n.t(
      'Soap:CHEWY_SHIPPING_ADDRESS',
    )} ${formatShippingAddress(primaryShippingAddress)}`
    clientData += clientData ? ` | ${writtenAddress}` : writtenAddress
  }

  return !R.isNil(client) && !R.isNil(patients)
    ? [
        {
          name: `${firstName} ${lastName}`,
          data: clientData,
          isBoopUser: Boolean(R.prop('isBoopUser', client)),
          isClient: true,
          primaryShippingAddress,
        },
        ...R.map(
          (patient: Patient) => ({
            name: patient.name,
            data: getPatientData(patient, unitsState, Gender, Breed),
            alertColorId: R.prop('alertColorId', client),
            alertText: getAlertText(patient, FullAlertTypes),
            patientId: patient.id,
          }),
          Object.values(patientWithCharges),
        ),
      ]
    : []
}

export type ChargeSheetSecondLevelMenuItems = {
  id: string
  isSoap: boolean
  item: string
  itemReadOnly: boolean
  patientId?: string
}

export type ChargeSheetSectionShort = {
  id: string
  patientId?: string
}

const isNillOrEmpty = R.anyPass([R.isEmpty, R.isNil])

const getFootnoteMenuItemName = (
  item: Patient | ChargeSheetItemSection,
  patients: Nil | Patient[],
  eventsMap: Record<string, TimetableEvent>,
  chargeSheetProducer: Record<string, User>,
) =>
  !R.has('groupedItems', item)
    ? item.name
    : getChargeSheetItemHeader({
        currentItem: item,
        patients,
        isEntityNames: true,
        eventsMap,
        chargeSheetProducer,
      })

export const useGetFootnoteMenuItems = (): Record<
  string,
  ChargeSheetSecondLevelMenuItems[]
> => {
  const subItems = useSelector(getChargeSheetSubItemsMap)
  const chargeSheetClientId = useSelector(getClientFinanceClientId)
  const chargeSheetClient = useSelector(getUser(chargeSheetClientId))
  const patients = useSelector(getPatientsList(chargeSheetClient?.patients))
  const eventsMap = useSelector(getTimetableEventsMap)
  const chargeSheetProducer = useSelector(getUsersMap)

  const soapItems = R.isNil(subItems)
    ? []
    : R.pipe<
        (typeof subItems)[],
        ChargeSheetItemSection[],
        ChargeSheetItemSection[]
      >(
        R.values,
        R.filter(({ soap }: ChargeSheetItemSection) => Boolean(soap)),
      )(subItems)

  const itemsAndPatients =
    isNillOrEmpty(soapItems) && R.isNil(patients)
      ? []
      : [...soapItems, ...patients]

  return R.pipe(
    R.map((item: ChargeSheetItemSection | Patient) => ({
      isSoap: R.has('soap', item) && Boolean(item.soap),
      id: item.id,
      item: getFootnoteMenuItemName(
        item,
        patients,
        eventsMap,
        chargeSheetProducer,
      ),
      itemReadOnly:
        R.has('soap', item) &&
        !R.isNil(item.soap) &&
        Boolean(item.soap.finalized),
      patientId: R.has('groupedItems', item) ? undefined : item.id,
    })),
    R.groupBy(({ isSoap }) =>
      isSoap ? ChargeSheetEntityTypes.SOAP : ChargeSheetEntityTypes.OTC,
    ),
  )(itemsAndPatients)
}

// TODO change type for LineItem when openApi model will be ready
export const useGetDeleteChargeSheetItemAction = (
  order: AtLeast<Order, 'id' & 'modificationDate'>,
) => {
  const clientId = useSelector(getClientFinanceClientId)
  const dispatch = useDispatch()
  const modifierId = useSelector(getCurrentUserId)

  const deleteData =
    modifierId && clientId
      ? {
          items: [order].map((itm) => ({
            id: itm.id,
            expectedModificationDate: itm.modificationDate,
          })),
          modifierId,
          clientId,
        }
      : ({} as { clientId: string; items: any; modifierId: string })

  return R.isEmpty(deleteData)
    ? undefined
    : () => dispatch(deleteChargeSheetItems(deleteData))
}

type OpenAddInvoiceDialogAfterOrderFiltersFetched = {
  clientId: string
  evemtId?: string
  eventId?: string
  groupedItems?: InvoiceLineItem[]
  id: string
  patientId: string
  producerId?: string
  soapId?: string
}

export const useGetOpenAddInvoiceDialogAfterOrderFiltersFetched = () => {
  const dispatch = useDispatch()
  const orderFilters = useSelector(getChargeSheetOrderFilters)
  const [openAddInvoiceItemDialog] = useDialog(DialogNames.ADD_INVOICE_ITEM)
  const openAfterFetch = useCloseAfterCreation(
    ({
      clientId,
      patientId,
      id,
      groupedItems,
      soapId,
      eventId,
      producerId,
    }: OpenAddInvoiceDialogAfterOrderFiltersFetched) => {
      if (orderFilters.length) {
        const flattenGroupedItems: InvoiceLineItem[] = R.chain(
          (group: InvoiceLineItem) => R.prop('items', group) || [group],
          groupedItems || [],
        )
        const invoice: InvoiceFromLineItem = {
          id,
          groups: [{ groupedItems: flattenGroupedItems }],
          client: clientId,
          patient: patientId,
          type: InvoiceType.CHARGE_SHEET,
          orderFilters,
        }
        openAddInvoiceItemDialog({
          invoice,
          isEstimate: false,
          isOtcInvoice: !soapId,
          soapId,
          isChargeSheet: true,
          onSave: (items) => {
            dispatch(
              addChargeSheetItems({
                clientId,
                createItemInputs: convertInvoiceLineItemToCreateItemInput(
                  items.map((item) => ({
                    ...item,
                    producerId,
                  })),
                  patientId,
                  eventId,
                ),
              }),
            )
          },
          onSavePrescription: () =>
            dispatch({
              type: UPDATE_CLIENT_FINANCE_CHARGES,
              payload: { clientId },
            }),
        })
      }
    },
    getChargeSheetOrderFiltersLoading,
  )

  return ({
    clientId,
    patientId,
    id,
    groupedItems,
    soapId,
    eventId,
    producerId,
  }: OpenAddInvoiceDialogAfterOrderFiltersFetched) => {
    openAfterFetch({
      clientId,
      patientId,
      id,
      groupedItems,
      soapId,
      eventId,
      producerId,
    })
    dispatch(fetchChargeSheetOrderFilters({ clientId, patientId }))
  }
}

export const useCheckFinalizedOrders = (
  clientId: string | Nil,
  selectedSections: any[],
) => {
  const activeLabVendors = useGetActiveLabVendorsMap()
  const ReadyForOrderLabStates = useSelector(getReadyForOrderLabStates) || []
  const ProcedureCategory = useSelector(getProcedureCategory)
  const activeImagingVendors = useGetActiveImagingVendorsMap()
  const idexxImagingVendorId = useGetIdexxImagingId()
  const businessScopeModalities = useSelector(
    getBusinessScopeModalityList(idexxImagingVendorId),
  )
  const sections = useChargeSheetSectionMap(clientId)

  const chargeSheetSectionToPost = R.pipe<
    (typeof selectedSections)[],
    string[],
    ChargeSheetItemSection[]
  >(
    R.filter((item: string) => item !== 'clientBalanceSection'),
    R.map((id) => sections[id]),
  )(selectedSections)

  const labLogs = getChargesLogsByLogType(
    OrderType.LAB_TEST,
    chargeSheetSectionToPost,
  )
  const procedureLogs = getChargesLogsByLogType(
    OrderType.PROCEDURE,
    chargeSheetSectionToPost,
  )

  const unfinishedLabs = hasUnfinishedLabs(
    labLogs,
    activeLabVendors,
    ReadyForOrderLabStates,
  )

  const unfinishedImagingOrders = hasImagingOrders(
    procedureLogs,
    ProcedureCategory,
    activeImagingVendors,
    idexxImagingVendorId,
    businessScopeModalities,
  )

  return !(unfinishedLabs || unfinishedImagingOrders)
}

export const useGetChargeSheetId = (clientId?: string | Nil) => {
  const dispatch = useDispatch()
  const chargeSheet = useSelector(getChargeSheet)
  const chargeSheetClientId = useSelector(getClientFinanceClientId)
  const id = R.prop('id', chargeSheet)

  useEffect(() => {
    if (
      clientId &&
      (R.isNil(chargeSheet) || !R.equals(chargeSheetClientId, clientId))
    ) {
      dispatch(fetchClientFinanceCharges({ id: clientId }))
    }
  }, [chargeSheet, clientId])

  return id
}

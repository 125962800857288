import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Moment } from 'moment'
import {
  ButtonWithLoader,
  ClassesType,
  DateFormat,
  moment,
  PuiDialog,
  PuiDialogProps,
  PuiSelect,
  PuiTextArea,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import ValidationErrorPopper from '~/components/common/validation/ValidationErrorPopper'
import {
  clearError,
  clockIn,
  clockOut,
  getCurrentTimeEntity,
  getTimeTrackerError,
  getTimeTrackerIsLoading,
} from '~/store/duck/timeTracker'
import { getClockOutReasons } from '~/store/reducers/constants'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import TimezoneWarningLabel from '../alerts/TimezoneWarningLabel'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
    },
    button: {
      height: 40,
      width: 150,
    },
    clockInDateForClockOut: {
      opacity: 0.5,
    },
    reason: {
      width: 212,
    },
  }),
  { name: 'TimeTrackerDialog' },
)

export interface TimeTrackerDialogProps extends PuiDialogProps {
  classes: ClassesType<typeof useStyles>
  id: string
}

const TimeTrackerDialog = ({
  id,
  open,
  onClose,
  classes: classesProp,
  ...props
}: TimeTrackerDialogProps) => {
  const classes = useStyles(classesProp)
  const { t } = useTranslation(['Common', 'Time'])

  const dispatch = useDispatch()
  const entity = useSelector(getCurrentTimeEntity)
  const isLoading = useSelector(getTimeTrackerIsLoading)
  const ClockOutReasons = useSelector(getClockOutReasons)
  const error = useSelector(getTimeTrackerError)

  const rootRef = useRef<HTMLDivElement>(null)

  const {
    fields: { clockOutReason },
  } = useFields([
    {
      name: 'clockOutReason',
      type: 'select',
      initialValue: Utils.findConstantIdByName('End of shift', ClockOutReasons),
    },
  ])

  const checkErrorsAndClose = () => {
    if (!error && onClose) {
      onClose()
    }
  }

  const setCloseAfterFinishOn = useCloseAfterCreation(
    checkErrorsAndClose,
    getTimeTrackerIsLoading,
  )

  const [currentDate, setCurrentDate] = useState<Moment>()
  const [notes, setNotes] = useState<string>('')

  const isMultiday =
    entity && moment(entity.clockinDatetime).isSame(currentDate, 'day')
  const dateFormat = isMultiday
    ? DateFormat.DAY_OF_WEEK_MONTH_DAY_COMMA_YEAR_FULL_TIME_WITH_MERIDIAN_AND_SPACE
    : DateFormat.FULL_TIME_WITH_MERIDIAN_AND_SPACE
  const clockText = entity
    ? t('Time:LABEL.CLOCK_OUT')
    : t('Time:LABEL.CLOCK_IN')
  const clockActionText = entity
    ? t('Time:LABEL.CLOCK_OUT_ACTION')
    : t('Time:LABEL.CLOCK_IN_ACTION')

  useEffect(() => {
    if (open) {
      setCurrentDate(moment())
    }
  }, [open])

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            if (entity) {
              dispatch(clockOut(clockOutReason.value, notes))
            } else {
              dispatch(clockIn(notes))
            }
            setCloseAfterFinishOn()
          }}
        >
          {clockActionText}
        </ButtonWithLoader>
      }
      aria-labelledby="time-tracker-dialog"
      classes={{
        paper: classes.paper,
      }}
      header={
        <TimezoneWarningLabel variant="h3">{clockText}</TimezoneWarningLabel>
      }
      open={open}
      onClose={onClose}
      {...props}
    >
      <Grid container direction="column" p={2} ref={rootRef} rowSpacing={1}>
        {!entity && currentDate && (
          <Grid item>
            <Text variant="body">
              {currentDate.format(DateFormat.DAY_OF_WEEK_MONTH_DAY_COMMA_YEAR)}
            </Text>
          </Grid>
        )}
        {!entity && currentDate && (
          <Grid item>
            <Text mb={1} variant="body">
              {t('Time:LABEL.CLOCK_IN')}:{' '}
              {currentDate.format(DateFormat.FULL_TIME_WITH_MERIDIAN_AND_SPACE)}
            </Text>
          </Grid>
        )}
        {entity && (
          <>
            {!isMultiday && currentDate && (
              <Grid item>
                <Text mb={1} variant="body">
                  {currentDate.format(
                    DateFormat.DAY_OF_WEEK_MONTH_DAY_COMMA_YEAR,
                  )}
                </Text>
              </Grid>
            )}
            {entity.clockinDatetime && (
              <Grid item>
                <Text className={classes.clockInDateForClockOut} variant="body">
                  {t('Time:LABEL.CLOCK_IN')}:{' '}
                  {moment(entity.clockinDatetime).format(dateFormat)}
                </Text>
              </Grid>
            )}
            {currentDate && (
              <Grid item>
                <Text variant="body">
                  {t('Time:LABEL.CLOCK_OUT')}: {currentDate.format(dateFormat)}
                </Text>
              </Grid>
            )}
            <Grid item>
              <PuiSelect
                className={classes.reason}
                field={clockOutReason}
                items={ClockOutReasons}
                renderEmpty={false}
              />
            </Grid>
          </>
        )}
        <Grid item>
          <PuiTextArea
            fullWidth
            placeholder={t('Common:NOTES')}
            value={notes}
            onChange={Utils.handleFormTextInput(setNotes)}
          />
        </Grid>
      </Grid>
      <ValidationErrorPopper
        anchorEl={rootRef.current}
        error={error}
        open={!entity && Boolean(error)}
        proceedButtonActionName={t('Time:LABEL.CLOCK_IN')}
        onBack={() => {
          dispatch(clearError())
        }}
        onProceed={() => {
          dispatch(clockIn(notes, true))
          setCloseAfterFinishOn()
        }}
      />
    </PuiDialog>
  )
}

export default TimeTrackerDialog

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  NamedEntity,
  Text,
  WellnessPlanCoverItem,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import NumericInput from '~/components/common/inputs/NumericInput'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minHeight: 40,
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    iconButton: {
      padding: theme.spacing(1),
      margin: 'auto 0 auto auto',
    },
  }),
  { name: 'WellnessPlanBenefitCoverageRow' },
)

export interface WellnessPlanBenefitCoverageRowProps {
  coverPercentField?: number
  coverage: WellnessPlanCoverItem | NamedEntity
  onCoverPercentChange?: (newValue: string | number) => void
  onDelete: () => void
  showDiscountFields?: boolean
  wellnessPlanVersion: WellnessPlanVersion
}

const WellnessPlanBenefitCoverageRow = ({
  wellnessPlanVersion,
  coverage,
  onDelete,
  coverPercentField,
  onCoverPercentChange,
  showDiscountFields = false,
}: WellnessPlanBenefitCoverageRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation('WellnessPlans')

  const { activePatients = 0 } = wellnessPlanVersion
  const hasActivePatients = activePatients > 0

  const coverageDisplayName = LanguageUtils.getTranslatedFieldName(
    coverage,
    'displayName',
    LanguageUtils.getTranslatedFieldName(coverage),
  )

  return (
    <Grid
      container
      item
      alignItems="flex-end"
      className={classes.root}
      justifyContent="left"
      px={1}
      wrap="nowrap"
    >
      <Grid item flex="0 1 400px" my="auto">
        <Text variant="body">{coverageDisplayName}</Text>
      </Grid>
      {showDiscountFields && (
        <Grid item flex="0 1 100px">
          <NumericInput
            InputProps={{ endAdornment: '%' }}
            label={t('WellnessPlans:DISCOUNT')}
            max={100}
            size="small"
            value={coverPercentField}
            onChange={onCoverPercentChange}
          />
        </Grid>
      )}
      {(!hasActivePatients || !coverage.id) && (
        <IconButton
          aria-label="delete"
          className={classes.iconButton}
          size="large"
          onClick={onDelete}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Grid>
  )
}

export default WellnessPlanBenefitCoverageRow

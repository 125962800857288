import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Fab, Grid, Hidden } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  Defaults,
  PermissionArea,
  Text,
  useInterval,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import InvoiceType from '~/constants/InvoiceType'
import { fetchClient } from '~/store/actions/clients'
import { fetchBalance } from '~/store/actions/users'
import { fetchBalanceChargeSheet } from '~/store/duck/clientFinanceData'
import { getHasOpenDialogs } from '~/store/duck/dialogs'
import {
  fetchRhapsodyPayConfig,
  getRhapsodyPayConfig,
} from '~/store/duck/rhapsodyPay'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { BalanceListEntryItem, ExtendPayment, InvoiceOrEstimate } from '~/types'
import { isRhapsodyGoAvailableForPractice } from '~/utils/paymentUtils'
import useDialog from '~/utils/useDialog'
import { useNavigatePrevState } from '~/utils/useNavigatePrevState'

import { INVOICE_CHARGE_SHEET_TYPE } from '../charge-sheet/constants'
import ClientAndPatientSection from '../clients/details/new-client-and-patient/ClientAndPatientSection'
import ClientInfoLinkType from '../clients/details/new-client-and-patient/ClientInfoLinkType'
import DetailsBackButton from '../clients/DetailsBackButton'
import PaymentsTableComponent from './PaymentsTableComponent'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    button: {
      minWidth: 160,
      height: 40,
    },
    inheritButton: {
      boxShadow:
        '0 2px 2px 0 rgba(46,153,168,0.14), 0 1px 5px 0 rgba(53,177,194,0.12)',
    },
    invoiceButton: {
      minWidth: 165,
      whiteSpace: 'nowrap',
    },
    roundedButton: {
      borderRadius: 24,
      textTransform: 'none',
      fontWeight: 500,
    },
    header: {
      boxShadow: '0px 3px 5px -2px rgba(0,0,0,0.10)',
    },
    backButton: {
      paddingLeft: theme.spacing(2),
    },
  }),
  { name: 'BalancePage' },
)

type BalancePageParams = {
  clientId: string
  eventId?: string
  invoiceId?: string
  patientId: string
  paymentId: string
}

type BalancePageLocationState = {
  patientId?: string
}

const BalancePage = () => {
  const navigate = useNavigate()
  const navigatePrevState = useNavigatePrevState()
  const location = useLocation()
  const { clientId, patientId, eventId, invoiceId, paymentId } =
    useParams() as BalancePageParams
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const client = useSelector(getUser(clientId))
  const business = useSelector(getCurrentBusiness)
  const rhapsodyPayConfig =
    useSelector(getRhapsodyPayConfig(business?.id)) || {}
  const hasOpenDialogs = useSelector(getHasOpenDialogs)
  const paymentPermissions = useSelector(getCRUDByArea(PermissionArea.PAYMENT))
  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const creditAdjustmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.CREDIT_ADJUSTMENT),
  )
  const isIpoM0CreditAdjustmentEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_CREDIT_ADJUSTMENT),
  )

  const locationState = location.state as BalancePageLocationState

  const isGoAvailableForPractice = isRhapsodyGoAvailableForPractice(
    business,
    rhapsodyPayConfig,
  )

  const goBack = () => navigate(-1)

  const onDirectPaymentDialogClose = () => goBack()

  useInterval(() => {
    if (
      !hasOpenDialogs &&
      clientId &&
      business?.id &&
      paymentPermissions.read
    ) {
      dispatch(fetchBalance(clientId))
    }
  }, Defaults.BALANCE_PAGE_UPDATE_INTERVAL)

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openPaymentDetailsDialog] = useDialog(DialogNames.PAYMENT_DETAILS)
  const [openPaymentRequest] = useDialog(DialogNames.PAYMENT_REQUEST)
  const [openDirectPaymentDetailsDialog] = useDialog(
    DialogNames.PAYMENT_DETAILS,
    onDirectPaymentDialogClose,
  )
  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const [openCreditAdjustmentDialog] = useDialog(
    DialogNames.CREDIT_ADJUSTMENT_DIALOG,
  )

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const openInvoiceCallback = useCallback(() => {
    openInvoice({
      clientId,
      create: Boolean(!invoiceId || (patientId && eventId)),
      eventId,
      invoiceId,
      patientId,
    })
  }, [clientId, patientId, eventId, invoiceId])

  useEffect(() => {
    if (patientId || invoiceId) {
      openInvoiceCallback()
    }
  }, [patientId, invoiceId])

  useEffect(() => {
    if (paymentId) {
      openDirectPaymentDetailsDialog({
        clientId,
        paymentId,
      })
    }
  }, [paymentId])

  useEffect(() => {
    if (!client && clientId) {
      dispatch(fetchClient({ clientId }))
    }
  }, [clientId])

  useEffect(() => {
    if (business?.id) {
      dispatch(fetchRhapsodyPayConfig(business.id))
    }
  }, [business?.id])

  useEffect(() => {
    dispatch(fetchBalanceChargeSheet({ id: clientId }))
  }, [clientId])

  const onBackButtonClick = () => {
    const url = locationState?.patientId
      ? `/client/${clientId}/patient/${locationState?.patientId}`
      : `/client/${clientId}`

    navigate(url)
  }

  const openDialogFunctionsMap: Record<string, (props: any) => void> = {
    Invoice: openInvoice,
    Payment: openPaymentDetailsDialog,
    GoPayment: openPaymentDetailsDialog,
    GoStripePayment: openPaymentDetailsDialog,
  }

  const dialogPropsMap: Record<
    string,
    (item: InvoiceOrEstimate | ExtendPayment) => any
  > = {
    Invoice: (item: InvoiceOrEstimate | ExtendPayment) => ({
      clientId,
      patientId,
      invoiceId: item.id,
    }),
    Default: (item: InvoiceOrEstimate | ExtendPayment) => ({
      clientId,
      payment: item,
    }),
  }

  const openItemDetailsDialog = (item: BalanceListEntryItem) => {
    if (item.type === INVOICE_CHARGE_SHEET_TYPE) {
      navigatePrevState(`/chargesheet/${clientId}`)
      return
    }

    if (item.type === InvoiceType.INVOICE && R.has('id', item)) {
      navigatePrevState(`/invoice/${item.id}`)
      return
    }

    const openDialogFunc = openDialogFunctionsMap[item.type]
    const getDialogProps = dialogPropsMap[item.type] || dialogPropsMap.Default
    const dialogProps = getDialogProps(
      item as InvoiceOrEstimate | ExtendPayment,
    )
    openDialogFunc(dialogProps)
  }

  return (
    <Grid container className={classes.root} wrap="nowrap">
      <Hidden mdDown>
        <Grid item>
          <ClientAndPatientSection
            clientId={clientId}
            highlight={ClientInfoLinkType.BALANCE}
            patientId={patientId}
          />
        </Grid>
      </Hidden>
      <Grid container item xs direction="column" wrap="nowrap">
        <DetailsBackButton
          fullWidth
          thin
          className={classes.backButton}
          onClick={onBackButtonClick}
        >
          {t('Common:CLIENT_AND_PATIENT_DETAIL')}
        </DetailsBackButton>
        <Grid
          container
          alignItems="flex-start"
          className={classes.header}
          direction={{ sm: 'column', md: 'row' }}
          px={3}
          py={2}
          wrap="nowrap"
        >
          <Text variant="h1">{t('Common:CLIENT_BALANCE')}</Text>
          <Grid
            container
            item
            xs
            columnSpacing={2}
            justifyContent="flex-end"
            mt={{ xs: 1, md: 0 }}
            rowSpacing={{ xs: 2, md: 0.01 }}
          >
            {isIpoM0CreditAdjustmentEnabled
              ? creditAdjustmentPermissions.create && (
                  <Grid item>
                    <Fab
                      className={classNames(
                        classes.inheritButton,
                        classes.button,
                      )}
                      color="inherit"
                      type="submit"
                      variant="extended"
                      onClick={() => openCreditAdjustmentDialog({ clientId })}
                    >
                      {t('Common:CREDIT_ADJUSTMENT')}
                    </Fab>
                  </Grid>
                )
              : paymentPermissions.create && (
                  <Grid item>
                    <Button
                      className={classNames(
                        classes.button,
                        classes.roundedButton,
                      )}
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        openAddPaymentDialog({
                          clientId,
                          ComponentProps: { label: 'Adjustment' },
                          isAdjustment: true,
                        })
                      }
                    >
                      {t('Common:ADJUSTMENT')}
                    </Button>
                  </Grid>
                )}
            {paymentPermissions.create && isGoAvailableForPractice && (
              <Grid item>
                <Fab
                  className={classNames(classes.inheritButton, classes.button)}
                  color="inherit"
                  type="submit"
                  variant="extended"
                  onClick={() =>
                    openPaymentRequest({ balancePayment: true, clientId })
                  }
                >
                  {t('Common:REQUEST_PAYMENT')}
                </Fab>
              </Grid>
            )}
            {paymentPermissions.create && (
              <Grid item>
                <Fab
                  className={classNames(classes.inheritButton, classes.button)}
                  color="inherit"
                  type="submit"
                  variant="extended"
                  onClick={() => openAddPaymentDialog({ clientId })}
                >
                  {t('Common:ADD_PAYMENT')}
                </Fab>
              </Grid>
            )}
            {invoicePermissions.create && (
              <Grid item>
                <Fab
                  className={classNames(
                    classes.inheritButton,
                    classes.button,
                    classes.invoiceButton,
                  )}
                  color="inherit"
                  type="submit"
                  variant="extended"
                  onClick={() => openInvoiceCallback()}
                >
                  {t(
                    'Abbreviations:ACRONYMS.OVER_THE_COUNTER.CREATE_OTC_INVOICE',
                  )}
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs mt={{ xs: 1, sm: 1 }} mx={{ xs: 1, sm: 1 }}>
          {paymentPermissions.read && (
            <PaymentsTableComponent
              clientId={clientId}
              onItemClick={openItemDetailsDialog}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BalancePage

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  CurrencyTextField,
  CustomFieldValidatorState,
  Nil,
  PuiDialog,
  PuiTextArea,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { paymentNotesMaxLength } from '~/components/dashboard/invoices/payment/payment-details-dialog/PaymentDetailsChunk'
import DialogNames from '~/constants/DialogNames'
import PaymentTypes from '~/constants/paymentTypes'
import { createPayment } from '~/store/actions/payments'
import { getPaymentType } from '~/store/reducers/constants'
import {
  getLastCreatedPayment,
  getPaymentsIsLoading,
} from '~/store/reducers/payments'
import { ExtendPayment } from '~/types'
import { handleNumberInput } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import PaymentClientNotesSection from './PaymentClientNotesSection'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    actions: {
      padding: theme.spacing(1, 3),
    },
    button: {
      width: 155,
    },
    largeButton: {
      width: 220,
    },
    currencyFieldRefund: {
      maxWidth: 100,
      paddingLeft: theme.spacing(1),
    },
    currencyTextField: {
      fontWeight: 500,
    },
  }),
  { name: 'RefundPaymentDialog' },
)

interface RefundPaymentDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  depositExceedsInvoiceAmount?: boolean
  payment: ExtendPayment
}

const RefundPaymentDialog = ({
  open,
  onClose,
  payment,
  clientId,
  depositExceedsInvoiceAmount,
}: RefundPaymentDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const PaymentType = useSelector(getPaymentType)
  const isLoading = useSelector(getPaymentsIsLoading)
  const createdPayment = useSelector(getLastCreatedPayment)
  const { t } = useTranslation(['Common', 'Validations'])
  const refundableValue = depositExceedsInvoiceAmount
    ? payment.unappliedAmount
    : payment.refundableAmount

  const [openPaymentDetails] = useDialog(DialogNames.PAYMENT_DETAILS)

  const ReverseChargePaymentType = Utils.findConstantIdByName(
    PaymentTypes.REVERSE_CHARGE,
    PaymentType,
  )

  const onRefundPaymentCreated = () => {
    if (onClose) {
      onClose()
    }
    if (createdPayment) {
      openPaymentDetails({
        clientId,
        payment: createdPayment,
      })
    }
  }

  const closeDialogAfterCreation = useCloseAfterCreation(
    onRefundPaymentCreated,
    getPaymentsIsLoading,
  )

  const validateRefundValue = ({
    state: { amount },
  }: CustomFieldValidatorState) => amount <= (refundableValue || 0)

  const {
    fields: { amount, notes },
    validate,
  } = useFields([
    {
      name: 'amount',
      initialValue: refundableValue || '',
      validators: [
        'greaterThanZero',
        {
          validator: validateRefundValue,
          validatorName: 'incorrectRefund',
        },
      ],
      messages: {
        incorrectRefund: t('Validations:INCORRECT_REFUND'),
      },
    },
    { name: 'notes', initialValue: '' },
  ])

  const refund = () => {
    if (validate()) {
      const refundPayment = {
        amount: amount.value,
        paymentTypeId: ReverseChargePaymentType,
        notes: notes.value,
        originPaymentId: payment.id,
      }

      closeDialogAfterCreation()
      dispatch(createPayment(clientId, refundPayment as ExtendPayment))
    }
  }

  return (
    <PuiDialog
      actions={
        <>
          <ButtonWithLoader
            className={classes.button}
            loading={isLoading}
            onClick={refund}
          >
            {t('Common:REFUND_NOUN')}
          </ButtonWithLoader>
          {depositExceedsInvoiceAmount && (
            <ButtonWithLoader
              className={classes.largeButton}
              loading={isLoading}
              onClick={onClose}
            >
              {t('Common:PAYMENTS.REFUND_TO_CLIENT_BALANCE')}
            </ButtonWithLoader>
          )}
        </>
      }
      aria-labelledby="refund-payment-dialog"
      classes={{
        actions: classes.actions,
        paper: classes.paper,
      }}
      open={open}
      title={
        depositExceedsInvoiceAmount
          ? t('Common:PAYMENTS.REFUND_DEPOSIT_GREATER_TITLE')
          : t('Common:REFUND_NOUN')
      }
      onClose={onClose}
    >
      <Grid container item>
        <Grid container item p={3} xs={7}>
          <Grid container item alignItems="center" mb={2} wrap="nowrap">
            <Text strong>{t('Common:PAYMENTS.REFUND_AMOUNT')}:</Text>
            <CurrencyTextField
              className={classes.currencyFieldRefund}
              disabled={isLoading}
              field={{
                ...amount,
                set: handleNumberInput(
                  (value: string) => {
                    const formattedValue = parseFloat(value) || 0
                    amount.setValue(formattedValue)
                  },
                  7,
                  2,
                  true,
                ),
              }}
            />
          </Grid>
          <PuiTextArea
            InputProps={{
              inputProps: { maxLength: paymentNotesMaxLength },
            }}
            field={notes}
            label={t('Common:PAYMENTS.REFUND_NOTES')}
            margin="none"
          />
        </Grid>
        <Grid container item xs>
          <PaymentClientNotesSection
            isRefund
            clientId={clientId}
            depositExceedsInvoiceAmountRefund={depositExceedsInvoiceAmount}
            payment={payment}
          />
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default RefundPaymentDialog

import * as React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const SvgDropdownToggleIcon = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <g
      clipPath="url(#dropdownToggleIcon_svg__a)"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    >
      <path d="m12.5 4 4.5 7H8zM12.5 20l4.5-7H8z" />
    </g>,
    <defs>
      <clipPath id="dropdownToggleIcon_svg__a">
        <path d="M8 4h9v16H8z" fill="#fff" />
      </clipPath>
    </defs>,
  )
export default SvgDropdownToggleIcon

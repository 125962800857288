import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { identity, prop } from 'ramda'
import { Constant, LanguageUtils } from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'
import { getDeviceType } from '@pbt/pbt-ui-components/src/utils/browserUtils'

import { DymoLabelHtmlTypes, DymoLabelType } from '~/constants/dymo'
import {
  getLabelTemplates,
  getLabelTemplatesFormatted,
  getSupportedLabelTemplatesHtml,
} from '~/store/reducers/constants'
import { arrayToMap } from '~/utils'

const useStyles = makeStyles(
  (theme) => ({
    toggleIcon: {
      right: 0,
      color: theme.colors.toggleIcon,
      position: 'absolute',
      pointerEvents: 'none',
    },
    subHeader: {
      padding: theme.spacing(0, 1),
      lineHeight: theme.spacing(3),
    },
    placeholder: {
      opacity: '0.5',
    },
  }),
  { name: 'LabelSelect' },
)

type LabelSelectProps = SelectProps & {
  labelType: DymoLabelType | DymoLabelHtmlTypes
  placeholder: string
  renderEmpty: boolean
  showFormattedLabels: boolean
  showHtmlLabels: boolean
  showSimpleTextLabels: boolean
}

const LabelSelect = ({
  showSimpleTextLabels,
  showFormattedLabels,
  showHtmlLabels,
  renderEmpty,
  placeholder = '',
  labelType,
  ...rest
}: LabelSelectProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Dialogs')

  const formattedLabels: Constant[] = useSelector(getLabelTemplatesFormatted)
  const deviceType = getDeviceType()
  const htmlLabels: Constant[] = useSelector(
    getSupportedLabelTemplatesHtml(labelType, deviceType),
  )

  const formattedLabelsMap = arrayToMap(formattedLabels, prop('id'), identity)
  const labels: Constant[] = useSelector(getLabelTemplates)

  const simpleTextLabels = showFormattedLabels
    ? labels.filter(({ id }) => !formattedLabelsMap[id])
    : labels

  const renderLabelGroup = (items: Constant[], label: string) => {
    const menuItems = items.map((item: Constant) => (
      <MenuItem key={item.id} value={item.id}>
        {LanguageUtils.getTranslatedFieldName(item)}
      </MenuItem>
    ))

    if (label && items.length > 0) {
      menuItems.unshift(
        <ListSubheader
          disableGutters
          disableSticky
          className={classes.subHeader}
          key={label}
        >
          {label}
        </ListSubheader>,
      )
    }

    return menuItems
  }

  const IconComponent = useCallback(
    () => (
      <InputAdornment position="end">
        <Toggle className={classes.toggleIcon} />
      </InputAdornment>
    ),
    [],
  )

  return (
    <Select
      IconComponent={IconComponent}
      displayEmpty={renderEmpty}
      id="label-select"
      {...rest}
    >
      {renderEmpty && (
        <MenuItem classes={{ root: classes.placeholder }} value="">
          {placeholder}
        </MenuItem>
      )}
      {showFormattedLabels &&
        renderLabelGroup(
          formattedLabels,
          t('Dialogs:PRINT_LABEL_DIALOG.DYMO_INTEGRATED_LABELS_DEFAULT'),
        )}
      {showHtmlLabels &&
        renderLabelGroup(
          htmlLabels,
          t('Dialogs:PRINT_LABEL_DIALOG.OTHER_PRINTERS'),
        )}
      {showSimpleTextLabels &&
        renderLabelGroup(
          simpleTextLabels,
          t('Dialogs:PRINT_LABEL_DIALOG.DYMO_INTEGRATED_LABELS_SIMPLE_TEXT'),
        )}
    </Select>
  )
}

export default LabelSelect

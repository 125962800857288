import * as R from 'ramda'

import i18n from '~/locales/i18n'

export enum PracticeDetailsPanels {
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  ALERT_CONFIGURATION = 'ALERT_CONFIGURATION',
  APPOINTMENT_COMMUNICATIONS = 'APPOINTMENT_COMMUNICATIONS',
  APPOINTMENT_CONFIGURATION = 'APPOINTMENT_CONFIGURATION',
  AUTO_REPLY_COMMUNICATIONS = 'AUTO_REPLY_COMMUNICATIONS',
  BOOP_COMMUNICATIONS = 'BOOP_COMMUNICATIONS',
  BRANDING_CONFIGURATION = 'BRANDING_CONFIGURATION',
  CHEWY = 'CHEWY',
  COUNTRY_SECTION = 'COUNTRY_SECTION',
  DEPARTMENT = 'DEPARTMENT',
  DISCOUNT_CONFIGURATION = 'DISCOUNT_CONFIGURATION',
  DISCOUNT_REASON_CONFIGURATION = 'DISCOUNT_REASON_CONFIGURATION',
  GROUP_ACCESS_ROLES = 'GROUP_ACCESS_ROLES',
  GROUP_DETAILS = 'GROUP_DETAILS',
  IMAGING_INTEGRATIONS = 'IMAGING_INTEGRATIONS',
  INTERNAL_CHAT_AND_LOGS = 'INTERNAL_CHAT_AND_LOGS',
  INVOICE_ESTIMATE_CONFIGURATION = 'INVOICE_ESTIMATE_CONFIGURATION',
  KIOSK = 'KIOSK',
  LAB_INTEGRATIONS = 'LAB_INTEGRATIONS',
  LABEL_CONFIGURATION = 'LABEL_CONFIGURATION',
  LICENSES = 'LICENSES',
  LOCALIZATIONS = 'LOCALIZATIONS',
  MAIN = 'MAIN',
  MARKETPLACE_MANAGEMENT = 'MARKETPLACE_MANAGEMENT',
  MIGRATION = 'MIGRATION',
  MIGRATION_V2 = 'MIGRATION_V2',
  MOBILE = 'MOBILE',
  NPS_SYSTEM = 'NPS_SYSTEM',
  RECORD_SHARING = 'RECORD_SHARING',
  REMINDER_SET_UP = 'REMINDER_SET_UP',
  RHAPSODY_PAY = 'RHAPSODY_PAY',
  SAVED_SIGNATURE_CONFIGURATION = 'SAVED_SIGNATURE_CONFIGURATION',
  SHARING = 'SHARING',
  SOAP_CONFIGURATION = 'SOAP_CONFIGURATION',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  SSO_CONFIGURATION = 'SSO_CONFIGURATION',
  TAX_CONFIGURATION = 'TAX_CONFIGURATION',
  TEAM_CONFIGURATION = 'TEAM_CONFIGURATION',
  TELEHEALTH_INTEGRATION = 'TELEHEALTH_INTEGRATION',
  UNITS_OF_MEASUREMENT = 'UNITS_OF_MEASUREMENT',
  VACCINE_CERTIFICATE_FOOTERS = 'VACCINE_CERTIFICATE_FOOTERS',
  VETCOVE_INTEGRATION = 'VETCOVE_INTEGRATION',
  WELLNESS_PLANS = 'WELLNESS_PLANS',
}

export const PracticeDetailsPanelsList = R.values(
  R.omit(['MAIN'], PracticeDetailsPanels),
)

export const PracticeDetailsPanelsLabels = {
  [PracticeDetailsPanels.GENERAL_INFORMATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.GENERAL_INFORMATION',
  ),
  [PracticeDetailsPanels.ALERT_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.ALERT_CONFIGURATION',
  ),
  [PracticeDetailsPanels.APPOINTMENT_COMMUNICATIONS]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.APPOINTMENT_COMMUNICATIONS',
  ),
  [PracticeDetailsPanels.APPOINTMENT_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.APPOINTMENT_CONFIGURATION',
  ),
  [PracticeDetailsPanels.AUTO_REPLY_COMMUNICATIONS]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.AUTO_REPLY_COMMUNICATIONS',
  ),
  [PracticeDetailsPanels.BOOP_COMMUNICATIONS]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.BOOP_COMMUNICATIONS',
  ),
  [PracticeDetailsPanels.BRANDING_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.BRANDING_CONFIGURATION',
  ),
  [PracticeDetailsPanels.CHEWY]: i18n.t('Admin:PRACTICE.DETAILS_LABEL.CHEWY'),
  [PracticeDetailsPanels.COUNTRY_SECTION]: i18n.t(
    'Admin:PRACTICE.COUNTRY_CODE',
  ),
  [PracticeDetailsPanels.DEPARTMENT]: i18n.t('Admin:PRACTICE.DEPARTMENT'),
  [PracticeDetailsPanels.DISCOUNT_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.DISCOUNT_CONFIGURATION',
  ),
  [PracticeDetailsPanels.DISCOUNT_REASON_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.DISCOUNT_REASON_CONFIGURATION',
  ),
  [PracticeDetailsPanels.GROUP_ACCESS_ROLES]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.GROUP_ACCESS_ROLES',
  ),
  [PracticeDetailsPanels.GROUP_DETAILS]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.GROUP_DETAILS',
  ),
  [PracticeDetailsPanels.IMAGING_INTEGRATIONS]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.IMAGING_INTEGRATIONS',
  ),
  [PracticeDetailsPanels.INTERNAL_CHAT_AND_LOGS]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.INTERNAL_CHAT_AND_LOGS',
  ),
  [PracticeDetailsPanels.INVOICE_ESTIMATE_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.INVOICE_ESTIMATE_CONFIGURATION',
  ),
  [PracticeDetailsPanels.KIOSK]: i18n.t('Admin:PRACTICE.DETAILS_LABEL.KIOSK'),
  [PracticeDetailsPanels.LAB_INTEGRATIONS]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.LAB_INTEGRATIONS',
  ),
  [PracticeDetailsPanels.LABEL_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.LABEL_CONFIGURATION',
  ),
  [PracticeDetailsPanels.LICENSES]: i18n.t('Admin:MEMBER.LICENSE.LICENSES'),
  [PracticeDetailsPanels.LOCALIZATIONS]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.LOCALIZATION',
  ),
  [PracticeDetailsPanels.MARKETPLACE_MANAGEMENT]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.MARKETPLACE_MANAGEMENT',
  ),
  [PracticeDetailsPanels.MIGRATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.MIGRATION',
  ),
  [PracticeDetailsPanels.MIGRATION_V2]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.MIGRATION',
  ),
  [PracticeDetailsPanels.MOBILE]: i18n.t('Admin:PRACTICE.DETAILS_LABEL.MOBILE'),
  [PracticeDetailsPanels.NPS_SYSTEM]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.NPS_SYSTEM',
  ),
  [PracticeDetailsPanels.RECORD_SHARING]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.RECORD_SHARING',
  ),
  [PracticeDetailsPanels.REMINDER_SET_UP]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.REMINDER_SET_UP',
  ),
  [PracticeDetailsPanels.RHAPSODY_PAY]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.RHAPSODY_PAY',
  ),
  [PracticeDetailsPanels.SAVED_SIGNATURE_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.SAVED_SIGNATURE_CONFIGURATION',
  ),
  [PracticeDetailsPanels.SHARING]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.SHARING',
  ),
  [PracticeDetailsPanels.SOAP_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.SOAP_CONFIGURATION',
  ),
  [PracticeDetailsPanels.SOCIAL_MEDIA]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.SOCIAL_MEDIA',
  ),
  [PracticeDetailsPanels.SSO_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.SSO_CONFIGURATION',
  ),
  [PracticeDetailsPanels.TAX_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.TAX_CONFIGURATION',
  ),
  [PracticeDetailsPanels.TEAM_CONFIGURATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.TEAM_CONFIGURATION',
  ),
  [PracticeDetailsPanels.TELEHEALTH_INTEGRATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.TELEHEALTH_INTEGRATION',
  ),
  [PracticeDetailsPanels.UNITS_OF_MEASUREMENT]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.UNITS_OF_MEASUREMENT',
  ),
  [PracticeDetailsPanels.VACCINE_CERTIFICATE_FOOTERS]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.VACCINE_CERTIFICATE_FOOTERS',
  ),
  [PracticeDetailsPanels.VETCOVE_INTEGRATION]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.VETCOVE_INTEGRATION',
  ),
  [PracticeDetailsPanels.WELLNESS_PLANS]: i18n.t(
    'Admin:PRACTICE.DETAILS_LABEL.WELLNESS_PLANS',
  ),
}

export const PracticeDetailsPanelsNoPadding = [
  PracticeDetailsPanels.WELLNESS_PLANS,
]

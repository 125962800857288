import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, CircularProgress } from '@mui/material'
import * as R from 'ramda'
import {
  Field,
  LanguageUtils,
  Nil,
  PuiTextField,
  PuiTextFieldProps,
} from '@pbt/pbt-ui-components'

import {
  clearSpacesListForAppointment,
  fetchSpacesListForAppointment,
} from '~/store/actions/spaces'
import {
  getMultipleSpaces,
  getSpacesIsLoading,
  getSpacesListForAppointment,
} from '~/store/reducers/spaces'
import { Space } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

export interface SpaceSelectProps {
  appointmentTypeId?: string
  disabled?: boolean
  field: Field
  margin: PuiTextFieldProps['margin']
  patientId: string | Nil
}

const SpaceSelect = ({
  patientId,
  appointmentTypeId,
  field,
  margin,
  ...rest
}: SpaceSelectProps) => {
  const dispatch = useDispatch()
  const isLoading: boolean = useSelector(getSpacesIsLoading)
  const spacesList: string[] = useSelector(getSpacesListForAppointment)
  const { t } = useTranslation('Common')

  const [open, setOpen] = useState(false)

  const list: string[] = field.value
    ? R.uniq(spacesList.concat(field.value))
    : spacesList

  const spaces: Space[] = useSelector(getMultipleSpaces(list))
  const options = spaces.filter(Boolean)

  useEffect(() => {
    if (!field.value && !open) {
      dispatch(clearSpacesListForAppointment())
    }
  }, [field.value])

  const setCloseAfterFetchingSpaces = useCloseAfterCreation(
    () => setOpen(true),
    getSpacesIsLoading,
  )

  const onOpen = () => {
    if (appointmentTypeId) {
      dispatch(
        fetchSpacesListForAppointment(appointmentTypeId, patientId || null),
      )
    }
    setCloseAfterFetchingSpaces()
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Autocomplete
      getOptionLabel={LanguageUtils.getTranslatedFieldName}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      loading={isLoading}
      open={open}
      options={options}
      renderInput={(params) => (
        <PuiTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={t('Common:SPACE_ONE')}
          margin={margin}
        />
      )}
      value={options.find((space) => space.id === field.value)}
      onChange={(event, space) => field.setValue(space?.id || '')}
      onClose={onClose}
      onOpen={onOpen}
      {...rest}
    />
  )
}

export default SpaceSelect

import React, { useEffect, useState } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { TableCell, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Nil, NumberUtils, Text } from '@pbt/pbt-ui-components'

import {
  GroupedLineItemArrangementInput,
  LineItemType,
  RetailOrderLineItem,
} from '~/api/graphql/generated/types'
import DragAndDropTable from '~/components/common/lists/DragAndDropTable'
import {
  getInvoiceGroupSubTotal,
  getIsRetailOrderLineItem,
  isGroupedInvoiceItem,
} from '~/components/dashboard/invoices/invoiceUtils'
import InvoiceIsPostedWarning from '~/components/dashboard/soap/rail/summary/InvoiceIsPostedWarning'
import DialogNames from '~/constants/DialogNames'
import {
  FINANCE_TABLE_CELL_HEIGHT,
  FINANCE_TABLE_PADDING_X_SPACING_VALUE,
} from '~/constants/financeTable'
import { OrderType } from '~/constants/SOAPStates'
import {
  rearrangeGroupedLineItems,
  rearrangeInvoiceLineItems,
  toggleInvoiceGroupExpanded,
} from '~/store/actions/finance'
import {
  fetchChargeSheetLineItem,
  fetchChargeSheetRetailOrderLineItem,
  fetchClientFinanceCharges,
  getClientFinanceLoading,
  getCurrentChargeSheetLineItem,
  getItemErrors,
  resetEditingLineItemState,
} from '~/store/duck/clientFinanceData'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { useGetInvoicePosted } from '~/store/hooks/soap'
import {
  getFinanceErrorRearranging,
  getFinanceInvoice,
  getFinanceIsFetching,
  getFinanceIsRearranging,
  getFinanceIsSaving,
  getInvoiceGroupIdIsExpanded,
} from '~/store/reducers/finance'
import { getOrdersIsSending } from '~/store/reducers/orders'
import { getClientId, getInvoiceId, getSoapId } from '~/store/reducers/soap'
import {
  Invoice,
  InvoiceLineItem,
  InvoiceLineItemsArrangement,
  Order,
} from '~/types'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import ChargeInformationPanel from '../information-panel/ChargeInformationPanel'
import FinanceTableItemCells from './cells/FinanceTableItemCells'
import { INVOICE_COLUMNS, SOAP_CHARGES_COLUMNS } from './Columns'

const useStyles = makeStyles(
  (theme) => ({
    footerCell: {
      padding: theme.spacing(1),
    },
    selectedRow: {
      outline: `1px solid ${theme.colors.tabSelected}`,
      backgroundColor: `${theme.colors.tableLeftColumnBackground} !important`,
    },
    table: {
      border: 'none',
      backgroundColor: 'inherit',
    },
    tableTitle: {
      height: theme.spacing(4),
      padding: theme.spacing(0, 1),
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE + 1.5),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
    tableRow: {
      '&:first-of-type': {
        borderTop: theme.constants.tabBorder,
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'inherit',
      },
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
      '&:last-of-type': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    tableCell: {
      height: FINANCE_TABLE_CELL_HEIGHT,
      '&:first-of-type': {
        paddingLeft: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
      '&:last-of-type': {
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
  }),
  { name: 'ChargesTable' },
)

export interface FinanceTableProps {
  ComponentsActions?: Partial<
    Record<OrderType, React.JSXElementConstructor<any>>
  >
  Footer?: React.JSXElementConstructor<any>
  SubHeaderComponent?: React.JSXElementConstructor<any>
  chargesId: string | Nil
  currentItemId?: string
  dragHandleProps?: DraggableProvidedDragHandleProps | Nil
  editDisabled: boolean
  hasHeader?: boolean
  isInvoice?: boolean
  items: (InvoiceLineItem | RetailOrderLineItem)[]
  useIndexAsId?: boolean
}

const ChargesSoapTable = ({
  editDisabled,
  items,
  hasHeader = true,
  useIndexAsId = false,
  Footer,
  SubHeaderComponent,
  currentItemId,
  dragHandleProps,
  isInvoice = false,
  chargesId,
  ComponentsActions,
}: FinanceTableProps) => {
  const columns = isInvoice ? INVOICE_COLUMNS : SOAP_CHARGES_COLUMNS
  const dispatch = useDispatch()
  const classes = useStyles()
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation('Common')

  const isFetching = useSelector(getFinanceIsFetching)
  const isFetchingChargeSheet = useSelector(getClientFinanceLoading)
  const isSaving = useSelector(getFinanceIsSaving)
  const isSendingOrders = useSelector(getOrdersIsSending)
  const isRearranging = useSelector(getFinanceIsRearranging)
  const hasErrorRearranging = useSelector(getFinanceErrorRearranging)
  const soapId = useSelector(getSoapId)
  const invoiceId = useSelector(getInvoiceId)
  const clientId = useSelector(getClientId)
  const invoice = useSelector(getFinanceInvoice(invoiceId)) as Invoice | Nil
  const currentChargeSheetLineItem = useSelector(getCurrentChargeSheetLineItem)
  const itemErrors = useSelector(getItemErrors)
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()
  const isInvoicePosted = useGetInvoicePosted()

  const [openDeleteLineItemDialog] = useDialog(DialogNames.DELETE_LINE_ITEMS)

  const [isChargeInformationPanelOpened, setIsChargeInformationPanelOpened] =
    useState(false)
  const [selectedItem, setSelectedItem] = useState<
    InvoiceLineItem | RetailOrderLineItem
  >()
  const [localOrderingItems, setLocalOrderingItems] =
    useState<(InvoiceLineItem | RetailOrderLineItem)[]>(items)

  const isLoading =
    isFetching || isSaving || isSendingOrders || isFetchingChargeSheet

  const isExpandedSelector = (item: InvoiceLineItem | RetailOrderLineItem) => {
    const groupId = getIsRetailOrderLineItem(item) ? null : item.group
    return getInvoiceGroupIdIsExpanded(groupId || item.id, soapId)
  }

  const toggleExpand = (item: InvoiceLineItem | RetailOrderLineItem) => {
    const groupId = getIsRetailOrderLineItem(item) ? null : item.group
    dispatch(toggleInvoiceGroupExpanded(groupId || item.id, soapId))
  }

  const setLineItemParam = (value: string) => {
    searchParams.set('lineItem', value)
    setSearchParams(searchParams)
  }

  const clearLineItemParam = () => {
    searchParams.delete('lineItem')
    setSearchParams(searchParams)
  }

  const closeChargeInformationPanel = () => {
    setIsChargeInformationPanelOpened(false)
    setSelectedItem(undefined)
    clearLineItemParam()
    dispatch(resetEditingLineItemState())
  }

  const getBundleItemId = (item: InvoiceLineItem) => {
    const chargesTableId = `charges-table-${item.soapId}_${item.group}`
    return item.id || chargesTableId
  }

  const getItemId = (item: InvoiceLineItem | RetailOrderLineItem) =>
    `charges-table-${item.id}_${item.logId}`

  const getId = (item: InvoiceLineItem | RetailOrderLineItem) =>
    isGroupedInvoiceItem(item)
      ? getBundleItemId(item as InvoiceLineItem)
      : getItemId(item)

  const onSelectItem = (
    item: InvoiceLineItem | RetailOrderLineItem | Order,
  ) => {
    setIsChargeInformationPanelOpened(true)

    if (!isGroupedInvoiceItem(item)) {
      setSelectedItem(
        getIsRetailOrderLineItem(item)
          ? (item as RetailOrderLineItem)
          : (item as InvoiceLineItem),
      )
      setLineItemParam(item.id)
    } else {
      // RetailOrderLineItem shouldn't ever be a grouped invoice
      const newItem = { ...item, id: getBundleItemId(item as InvoiceLineItem) }
      setSelectedItem(newItem as InvoiceLineItem)
      clearLineItemParam()
    }
  }

  useEffectExceptOnMount(() => {
    setLocalOrderingItems(items)
  }, [items])

  useEffect(() => {
    if (!isSendingOrders && !isFetchingChargeSheet && clientId) {
      dispatch(fetchClientFinanceCharges({ id: clientId, soapId }))
    }
  }, [isSendingOrders])

  useEffect(
    () => () => {
      if (itemErrors && itemErrors.length > 0 && clientId) {
        const lineItemIds = itemErrors.map((itemError) => itemError.lineItemId)
        const corruptedItems = items.filter((invoiceLineItem) =>
          lineItemIds.includes(invoiceLineItem.id),
        )
        openDeleteLineItemDialog({ lineItems: corruptedItems, clientId })
      }
    },
    [itemErrors],
  )

  useEffect(() => {
    if (
      selectedItem &&
      !isGroupedInvoiceItem(selectedItem) &&
      ((currentChargeSheetLineItem &&
        selectedItem.id !== currentChargeSheetLineItem.id) ||
        !currentChargeSheetLineItem)
    ) {
      if (getIsRetailOrderLineItem(selectedItem)) {
        dispatch(fetchChargeSheetRetailOrderLineItem({ id: selectedItem.id }))
      } else {
        dispatch(fetchChargeSheetLineItem({ id: selectedItem.id }))
      }
    }
  }, [selectedItem, currentChargeSheetLineItem])

  useDeepCompareEffect(() => {
    if (selectedItem && items.length > 0) {
      setSelectedItem(R.find(R.propEq('id', selectedItem.id), items))
    }
  }, [items])

  useEffect(() => {
    if (hasErrorRearranging) {
      setLocalOrderingItems(items)
    }
  }, [hasErrorRearranging])

  useDeepCompareEffect(() => {
    const selectedItemId = searchParams.get('lineItem')

    if (
      !isChargeInformationPanelOpened ||
      !selectedItemId ||
      R.isEmpty(items)
    ) {
      return
    }

    let currentItem
    const findInvoiceLineItem = (
      itemList: (InvoiceLineItem | RetailOrderLineItem)[] | Nil,
    ) =>
      itemList?.forEach((item) => {
        if (item.id === selectedItemId) {
          currentItem = item
        }

        if (!getIsRetailOrderLineItem(item) && isGroupedInvoiceItem(item)) {
          findInvoiceLineItem(item.items)
        }
      })
    findInvoiceLineItem(items)

    if (!currentItem) {
      closeChargeInformationPanel()
    }

    if (selectedItemId && currentItem) {
      onSelectItem(currentItem)
    }
  }, [items])

  useDeepCompareEffect(() => {
    if (
      R.isEmpty(items) &&
      !isFetching &&
      Boolean(searchParams.get('lineItem'))
    ) {
      clearLineItemParam()
    }
  }, [items])

  const rearrangeItems = (from: number, to: number) => {
    const newItemsOrder = R.move(from, to, localOrderingItems)
    const flatLocalOrderingItems = R.chain(
      (i) => (!getIsRetailOrderLineItem(i) && i.items ? [...i.items] : [i]),
      localOrderingItems,
    )
    const newItemsOrderSlicedFlat = R.chain(
      (i) => (!getIsRetailOrderLineItem(i) && i.items ? [...i.items] : [i]),
      R.slice(0, R.max(from, to) + 1, newItemsOrder),
    )

    if (chargesId) {
      if (isChewyCheckoutEnabled) {
        const rearrangements = newItemsOrderSlicedFlat.reduce(
          (arr: GroupedLineItemArrangementInput[], item, idx) => {
            const lineItemType = getIsRetailOrderLineItem(item)
              ? LineItemType.RetailOrderLineItem
              : LineItemType.InvoiceLineItem
            if (
              item.id &&
              (item.id !== flatLocalOrderingItems[idx].id ||
                item.viewOrderNumber == null)
            ) {
              arr.push({
                lineItemId: item.id,
                lineItemType,
                viewOrderNumber: idx,
              })
            }
            return arr
          },
          [],
        )
        dispatch(rearrangeGroupedLineItems(chargesId, rearrangements))
      } else {
        const rearrangements = newItemsOrderSlicedFlat.reduce(
          (arr: InvoiceLineItemsArrangement[], item, idx) => {
            if (
              item.id &&
              (item.id !== flatLocalOrderingItems[idx].id ||
                item.viewOrderNumber == null)
            ) {
              arr.push({ invoiceLineItemId: item.id, viewOrderNumber: idx })
            }
            return arr
          },
          [],
        )
        dispatch(rearrangeInvoiceLineItems(chargesId, rearrangements))
      }
    }
    setLocalOrderingItems(newItemsOrder)
  }

  if (R.isEmpty(items) && !isLoading) {
    return null
  }

  return (
    <>
      {isInvoicePosted && <InvoiceIsPostedWarning />}
      {selectedItem && (
        <ChargeInformationPanel
          ComponentsActions={ComponentsActions}
          editDisabled={editDisabled}
          invoice={invoice}
          isLoading={isLoading}
          isOpened={isChargeInformationPanelOpened}
          item={selectedItem}
          onClose={closeChargeInformationPanel}
          onSelectItem={onSelectItem}
        />
      )}
      <DragAndDropTable
        SubHeaderComponent={SubHeaderComponent}
        classes={{
          table: classes.table,
          tableCell: classes.tableCell,
          tableRow: classes.tableRow,
          tableTitle: classes.tableTitle,
          isSelectedRow: classes.selectedRow,
        }}
        currentItemId={currentItemId}
        data={localOrderingItems}
        dragHandleProps={dragHandleProps}
        footer={
          Footer && currentItemId ? (
            <Footer currentItemId={currentItemId} />
          ) : (
            <TableRow>
              <TableCell
                className={classes.footerCell}
                colSpan={columns.length}
              >
                <Text align="right" fontWeight={500}>
                  {t('Common:TOTAL')}{' '}
                  {NumberUtils.formatMoney(getInvoiceGroupSubTotal(items))}
                </Text>
              </TableCell>
            </TableRow>
          )
        }
        getId={getId}
        headerRow={hasHeader ? columns : []}
        isDragDisabled={isInvoice}
        isDropDisabled={isRearranging || isInvoice}
        isLoading={isLoading}
        loadingItemsLength={localOrderingItems.length ?? 6}
        selectedItem={selectedItem}
        useIndexAsId={useIndexAsId}
        onOrderChange={rearrangeItems}
        onRowClick={(item) => {
          if (!isGroupedInvoiceItem(item)) {
            onSelectItem(item)
          }
        }}
      >
        {({ item, tableCellClassName }) => (
          <FinanceTableItemCells
            columnsLength={columns.length}
            currentItemId={currentItemId}
            handleSelectItem={onSelectItem}
            isExpandedSelector={isExpandedSelector}
            isInvoice={isInvoice}
            item={item}
            selectedItem={selectedItem}
            soapId={soapId}
            tableCellClassName={tableCellClassName}
            toggleExpand={toggleExpand}
          />
        )}
      </DragAndDropTable>
    </>
  )
}

export default ChargesSoapTable

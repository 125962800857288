import { ApiError, Nil, User } from '@pbt/pbt-ui-components'

import {
  DiagnosesState,
  FindingLocation,
  FindingState,
  OrderFilter,
  Soap,
  SoapData,
  SoapFile,
  StaticFinding,
} from '~/types'

import * as SOAPTypes from './types/soap'

export const createSOAP = (
  appointmentId: string,
  originalBusinessId: string | Nil,
) => ({
  type: SOAPTypes.CREATE_SOAP,
  appointmentId,
  originalBusinessId,
})
export const createSOAPSuccess = (data: SoapData) => ({
  type: SOAPTypes.CREATE_SOAP_SUCCESS,
  data,
})
export const createSOAPFailure = (error: ApiError) => ({
  type: SOAPTypes.CREATE_SOAP_FAILURE,
  error,
})

export const addSOAP = (
  appointmentId: string | Nil,
  originalBusinessId: string | Nil,
) => ({
  type: SOAPTypes.ADD_SOAP,
  appointmentId,
  originalBusinessId,
})
export const addSOAPSuccess = (data: SoapData) => ({
  type: SOAPTypes.ADD_SOAP_SUCCESS,
  data,
})
export const addSOAPFailure = (error: ApiError) => ({
  type: SOAPTypes.ADD_SOAP_FAILURE,
  error,
})

export const fetchSoap = (id: string, originalBusinessId?: string | Nil) => ({
  type: SOAPTypes.FETCH_SOAP,
  id,
  originalBusinessId,
})
export const fetchSoapSuccess = (data: SoapData) => ({
  type: SOAPTypes.FETCH_SOAP_SUCCESS,
  data,
})
export const fetchSoapFailure = (error: ApiError) => ({
  type: SOAPTypes.FETCH_SOAP_FAILURE,
  error,
})

export const fetchSoapFindingsContainer = (
  soapId: string,
  originalBusinessId: string | Nil,
) => ({
  type: SOAPTypes.FETCH_SOAP_FINDINGS_CONTAINER,
  soapId,
  originalBusinessId,
})
export const fetchSoapFindingsContainerSuccess = (
  crc: number,
  findingLogs: Record<string, FindingState>,
) => ({
  type: SOAPTypes.FETCH_SOAP_FINDINGS_CONTAINER_SUCCESS,
  crc,
  findingLogs,
})
export const fetchSoapFindingsContainerFailure = (error: ApiError) => ({
  type: SOAPTypes.FETCH_SOAP_FINDINGS_CONTAINER_FAILURE,
  error,
})

export const selectDoctorLocally = (id?: string) => ({
  type: SOAPTypes.SELECT_DOCTOR_LOCALLY,
  id,
})
export const selectTechLocally = (id?: string) => ({
  type: SOAPTypes.SELECT_TECH_LOCALLY,
  id,
})
export const selectDoctor = (id: string, force?: boolean) => ({
  type: SOAPTypes.SELECT_DOCTOR,
  id,
  force,
})
export const selectDoctorForCustomSoap = (
  soapId: string,
  soapBusinessId: string | Nil,
  doctorId: string,
  force?: boolean,
) => ({
  type: SOAPTypes.SELECT_DOCTOR_FOR_CUSTOM_SOAP,
  soapId,
  soapBusinessId,
  doctorId,
  force,
})
export const selectTech = (id: string) => ({ type: SOAPTypes.SELECT_TECH, id })

export const addSoapVitalIds = (vitalIds: string[]) => ({
  type: SOAPTypes.ADD_SOAP_VITAL_IDS,
  vitalIds,
})
export const addV2SoapVitalId = (vitalId: string[]) => ({
  type: SOAPTypes.ADD_V2_SOAP_VITAL_ID,
  vitalId,
})
export const updateSoapVitalIds = (vitalIds: string[]) => ({
  type: SOAPTypes.UPDATE_SOAP_VITAL_IDS,
  vitalIds,
})
export const updateSoapFiles = (files: SoapFile[]) => ({
  type: SOAPTypes.UPDATE_SOAP_FILES,
  files,
})

export const editFindingsState = (data: {
  bodySystem: StaticFinding | FindingState
  finding?: Partial<StaticFinding> & { id: string }
  locations?: Record<string, FindingLocation>
  notes?: string
  state: string | Nil
  uncheckFinding?: boolean
}) => ({
  type: SOAPTypes.EDIT_FINDINGS_STATE,
  ...data,
})
export const editFindingsStateSuccess = () => ({
  type: SOAPTypes.EDIT_FINDINGS_STATE_SUCCESS,
})
export const editFindingsStateFailure = (error: ApiError) => ({
  type: SOAPTypes.EDIT_FINDINGS_STATE_FAILURE,
  error,
})

export const clearFindingLogsCrcValidationError = () => ({
  type: SOAPTypes.CLEAR_FINDING_LOGS_CRC_VALIDATION_ERROR,
})
export const updateFindingLogsCrcSuccess = (crc: number) => ({
  type: SOAPTypes.UPDATE_FINDING_LOGS_CRC_SUCCESS,
  crc,
})
export const updateFindingLogsCrcFailure = (error: ApiError) => ({
  type: SOAPTypes.UPDATE_FINDING_LOGS_CRC_FAILURE,
  error,
})

export const updateMedicalNotes = (notes: string, forceUpdate?: boolean) => ({
  type: SOAPTypes.UPDATE_MEDICAL_NOTES,
  notes,
  forceUpdate,
})
export const updateMedicalNotesComplete = () => ({
  type: SOAPTypes.UPDATE_MEDICAL_NOTES_COMPLETE,
})

export const updateDischargeNotes = (notes: string, forceUpdate?: boolean) => ({
  type: SOAPTypes.UPDATE_DISCHARGE_NOTES,
  notes,
  forceUpdate,
})
export const updateDischargeNotesComplete = () => ({
  type: SOAPTypes.UPDATE_DISCHARGE_NOTES_COMPLETE,
})

export const closeSoap = () => ({ type: SOAPTypes.CLOSE_SOAP })

export const saveSOAP = () => ({ type: SOAPTypes.SAVE_SOAP })
export const saveSOAPFailure = (error: ApiError) => ({
  type: SOAPTypes.SAVE_SOAP_FAILURE,
  error,
})
export const saveSOAPSuccess = (data?: SoapData) => ({
  type: SOAPTypes.SAVE_SOAP_SUCCESS,
  data,
})
export const saveSOAPAbolish = () => ({ type: SOAPTypes.SAVE_SOAP_ABOLISH })

export const soapPartialUpdate = (data: Partial<Soap>) => ({
  type: SOAPTypes.SOAP_PARTIAL_UPDATE,
  data,
})

export const uploadFile = (file: SoapFile) => ({
  type: SOAPTypes.UPLOAD_FILE,
  file,
})
export const uploadFileSuccess = () => ({ type: SOAPTypes.UPLOAD_FILE_SUCCESS })
export const uploadFileFailure = (error: ApiError) => ({
  type: SOAPTypes.UPLOAD_FILE_FAILURE,
  error,
})

export const deleteFile = (id: string, soapId?: string) => ({
  type: SOAPTypes.DELETE_FILE,
  id,
  soapId,
})
export const deleteFileSuccess = (id: string) => ({
  type: SOAPTypes.DELETE_FILE_SUCCESS,
  id,
})
export const deleteFileFailure = (error: ApiError) => ({
  type: SOAPTypes.DELETE_FILE_FAILURE,
  error,
})

export const updateInvoiceId = (invoiceId: string) => ({
  type: SOAPTypes.UPDATE_INVOICE_ID,
  invoiceId,
})
export const updateSOAP = (data: SoapData) => ({
  type: SOAPTypes.UPDATE_SOAP,
  data,
})
export const updateFindingToExpand = (
  findingToExpand: { categoryId: string; findingId: string } | null,
) => ({
  type: SOAPTypes.UPDATE_FINDING_TO_EXPAND,
  findingToExpand,
})

export const attachDocumentsToSoap = (
  documentIds: string[],
  soapId: string,
  soapBusinessId: string | Nil,
  authorId: string,
) => ({
  type: SOAPTypes.ATTACH_DOCUMENTS_TO_SOAP,
  documentIds,
  soapId,
  soapBusinessId,
  authorId,
})
export const attachDocumentsToSoapSuccess = () => ({
  type: SOAPTypes.ATTACH_DOCUMENTS_TO_SOAP_SUCCESS,
})
export const attachDocumentsToSoapFailure = (error: ApiError) => ({
  type: SOAPTypes.ATTACH_DOCUMENTS_TO_SOAP_FAILURE,
  error,
})

export const updateDocumentOnSoap = (
  soapId: string,
  soapBusinessId: string | Nil,
  file: SoapFile,
) => ({
  type: SOAPTypes.UPDATE_DOCUMENT_ON_SOAP,
  soapId,
  soapBusinessId,
  file,
})
export const updateDocumentOnSoapSuccess = () => ({
  type: SOAPTypes.UPDATE_DOCUMENT_ON_SOAP_SUCCESS,
})
export const updateDocumentOnSoapFailure = (error: ApiError) => ({
  type: SOAPTypes.UPDATE_DOCUMENT_ON_SOAP_FAILURE,
  error,
})
export const fetchSOAPOrders = (
  soapId: string,
  soapBusinessId: string | Nil,
) => ({
  type: SOAPTypes.FETCH_SOAP_ORDERS,
  soapId,
  soapBusinessId,
})
export const fetchSOAPOrdersSuccess = () => ({
  type: SOAPTypes.FETCH_SOAP_ORDERS_SUCCESS,
})
export const fetchSOAPOrdersFailure = (error: ApiError) => ({
  type: SOAPTypes.FETCH_SOAP_ORDERS_FAILURE,
  error,
})

export const fetchSOAPOrderFilters = (
  appointmentId: string,
  originalBusinessId: string | Nil,
) => ({
  type: SOAPTypes.FETCH_SOAP_ORDER_FILTERS,
  appointmentId,
  originalBusinessId,
})
export const fetchSOAPOrderFiltersSuccess = (orderFilters: OrderFilter[]) => ({
  type: SOAPTypes.FETCH_SOAP_ORDER_FILTERS_SUCCESS,
  orderFilters,
})
export const fetchSOAPOrderFiltersFailure = (error: ApiError) => ({
  type: SOAPTypes.FETCH_SOAP_ORDER_FILTERS_FAILURE,
  error,
})

export const notesValidationError = (error: ApiError) => ({
  type: SOAPTypes.NOTES_VALIDATION_ERROR,
  error,
})
export const assignedDoctorValidationError = (error: ApiError) => ({
  type: SOAPTypes.ASSIGNED_DOCTOR_VALIDATION_ERROR,
  error,
})

export const clearNotesValidationError = () => ({
  type: SOAPTypes.CLEAR_NOTES_VALIDATION_ERROR,
})
export const clearAssignedDoctorValidationError = () => ({
  type: SOAPTypes.CLEAR_ASSIGNED_DOCTOR_VALIDATION_ERROR,
})

export const fetchSoapFiles = (
  soapId: string,
  originalBusinessId: string | Nil,
) => ({
  type: SOAPTypes.FETCH_SOAP_FILES,
  soapId,
  originalBusinessId,
})
export const fetchSoapFilesSuccess = (files: SoapFile[]) => ({
  type: SOAPTypes.FETCH_SOAP_FILES_SUCCESS,
  files,
})
export const fetchSoapFilesFailure = (error: ApiError) => ({
  type: SOAPTypes.FETCH_SOAP_FILES_FAILURE,
  error,
})

export const navigateToSoapConversation = (conversationId: string) => ({
  type: SOAPTypes.NAVIGATE_TO_SOAP_CONVERSATION,
  conversationId,
})
export const navigateToConversationsList = () => ({
  type: SOAPTypes.NAVIGATE_TO_SOAP_CONVERSATIONS_LIST,
})

export const finalizeSoap = (
  soapId?: string,
  originalBusinessId?: string | Nil,
) => ({
  type: SOAPTypes.EDIT_SOAP_FINALIZED_STATUS,
  finalized: true,
  soapId,
  originalBusinessId,
})
export const reOpenSoap = (
  soapId?: string,
  originalBusinessId?: string | Nil,
) => ({
  type: SOAPTypes.EDIT_SOAP_FINALIZED_STATUS,
  finalized: false,
  soapId,
  originalBusinessId,
})

export const updateSoapFinalizedStatus = (finalizedStatus: string) => ({
  type: SOAPTypes.UPDATE_SOAP_FINALIZED_STATUS,
  finalizedStatus,
})

export const editDifferentialDiagnosesState = (
  id: string,
  data?: DiagnosesState,
) => ({
  type: SOAPTypes.EDIT_DIFFERENTIAL_DIAGNOSES_STATE,
  id,
  data,
})
export const editDifferentialDiagnosesStateSuccess = (
  data: DiagnosesState,
) => ({
  type: SOAPTypes.EDIT_DIFFERENTIAL_DIAGNOSES_STATE_SUCCESS,
  data,
})
export const editDifferentialDiagnosesStateFailure = (error: ApiError) => ({
  type: SOAPTypes.EDIT_DIFFERENTIAL_DIAGNOSES_STATE_FAILURE,
  error,
})

export const clearDocumentValidationError = () => ({
  type: SOAPTypes.CLEAR_DOCUMENT_VALIDATION_ERROR,
})

export const fetchUsersOnSoapCollaborationSession = (
  soapId: string,
  soapBusinessId: string | Nil,
) => ({
  type: SOAPTypes.FETCH_USERS_ON_SOAP_COLLABORATION_SESSION,
  soapId,
  soapBusinessId,
})
export const fetchUsersOnSoapCollaborationSessionSuccess = (
  users: Partial<User>[],
) => ({
  type: SOAPTypes.FETCH_USERS_ON_SOAP_COLLABORATION_SESSION_SUCCESS,
  users,
})
export const fetchUsersOnSoapCollaborationSessionFailure = (
  error: ApiError,
) => ({
  type: SOAPTypes.FETCH_USERS_ON_SOAP_COLLABORATION_SESSION_FAILURE,
  error,
})

export const setMedicalNotes = (notes: string) => ({
  type: SOAPTypes.SET_MEDICAL_NOTES,
  notes,
})
export const setDischargeNotes = (notes: string) => ({
  type: SOAPTypes.SET_DISCHARGE_NOTES,
  notes,
})

export const clearSoapError = () => ({ type: SOAPTypes.CLEAR_SOAP_ERROR })

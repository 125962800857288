import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  Constant,
  Field,
  FieldObject,
  FieldProp,
  NamedEntity,
  Nil,
  Responsibility,
  Role,
  Utils,
} from '@pbt/pbt-ui-components'
import { findById } from '@pbt/pbt-ui-components/src/utils'

import {
  fetchSchedulerSettings,
  getSchedulerSettingsByBusinessId,
} from '~/store/duck/schedulerSettings'
import { getEventType } from '~/store/reducers/constants'
import {
  SchedulerSettings,
  TimetableEvent,
  TimetableEventPersonResponsibility,
  TimetableEventPersonRole,
  WritableTimetableEvent,
} from '~/types'

export const useGetIsBusyType = () => {
  const EventType = useSelector(getEventType)

  const BusyEventType = Utils.findConstantByName('Busy', EventType)
  const BusySubtypes = BusyEventType?.subTypes || []
  const BusyEventId = Utils.findConstantIdByName('Person', BusySubtypes)
  const ReservedEventId = Utils.findConstantIdByName('Reserved', BusySubtypes)

  const BusyTypeIds = [BusyEventId, ReservedEventId]

  return (appointmentTypeId: string | Nil) =>
    appointmentTypeId && R.includes(appointmentTypeId, BusyTypeIds)
}

export const useGetIsReservedType = () => {
  const EventType = useSelector(getEventType)

  const BusyEventType = Utils.findConstantByName('Busy', EventType)
  const BusySubtypes = BusyEventType?.subTypes || []
  const ReservedEventId = Utils.findConstantIdByName('Reserved', BusySubtypes)

  return (appointmentTypeId: string | Nil) =>
    appointmentTypeId && appointmentTypeId === ReservedEventId
}

export const getIsRepeatedAppointment = (appointment: TimetableEvent | Nil) =>
  Boolean(appointment?.recurrenceParams)

export const getAppointmentHasFinalizedSoap = (
  appointment: TimetableEvent | Nil,
) => {
  const soaps = appointment?.soaps || []
  const hasSingleSoap = soaps.length === 1

  return hasSingleSoap && Boolean(R.head(soaps)?.finalized)
}

export const getAppointmentPatch = (
  newAppointment: WritableTimetableEvent,
  oldAppointment: TimetableEvent | Nil,
) => {
  const {
    state,
    type,
    assignedSpace,
    includeZoomLink,
    personRoles,
    personResponsibilities,
    ...otherFields
  } = oldAppointment || {}
  const originalFields = {
    ...otherFields,
    state: state?.id,
    type: type?.id,
    spaceId: assignedSpace || '',
    includeZoomLink: Boolean(includeZoomLink),
    personRoles: personRoles?.map(({ personId, roleId }) => ({
      person: personId,
      role: roleId,
    })),
    personResponsibilities: personResponsibilities?.map(
      ({ personId, responsibilityId }) => ({
        person: personId,
        responsibility: responsibilityId,
      }),
    ),
  }
  const changedFields = Object.keys(newAppointment).reduce((acc, k) => {
    const key = k as keyof WritableTimetableEvent
    return {
      ...acc,
      ...(R.equals(newAppointment[key], originalFields[key])
        ? {}
        : { [key]: newAppointment[key] }),
    }
  }, {})
  return { id: oldAppointment?.id, ...changedFields }
}
export const getRoleFieldName = (roleId: string) => `${roleId}Role`
export const getOccupyTimeSlotFieldName = (roleId: string) =>
  `${roleId}_occupyTimeSlot`
export const getOccupyTimeSlotFieldValue = (
  roles: FieldObject,
  roleId: string,
) => roles[getOccupyTimeSlotFieldName(roleId)]?.value

export const useGetSchedulerSettingsByBusiness = (businessId: string | Nil) => {
  const dispatch = useDispatch()

  const schedulerSettings = useSelector(
    getSchedulerSettingsByBusinessId(businessId),
  )

  useEffect(() => {
    if (businessId) {
      dispatch(fetchSchedulerSettings(businessId))
    }
  }, [businessId])

  return schedulerSettings
}

export const getRolesForResponsibility = (
  roleOrResponsibility: Responsibility | Role,
) => {
  if (!roleOrResponsibility) return []
  if (!(roleOrResponsibility as Responsibility).roles)
    return [roleOrResponsibility]
  return (roleOrResponsibility as Responsibility).roles
}

interface UseGetOccupyTimeSlotFields {
  isEdit: boolean
  personResponsibilities: TimetableEventPersonResponsibility[] | Nil
  personRoles: TimetableEventPersonRole[] | Nil
  roles: Role[] | Responsibility[] | undefined
}

export const useGetOccupyTimeSlotFields = ({
  personRoles,
  personResponsibilities,
  roles,
  isEdit,
}: UseGetOccupyTimeSlotFields) =>
  roles
    ? (roles.map((role) => {
        const { id } = role
        const occupyTimeslot =
          personRoles?.find((personRole) => personRole.roleId === id)
            ?.occupyTimeslot ||
          personResponsibilities?.find(
            (personResponsibility) =>
              personResponsibility.responsibilityId === id,
          )?.occupyTimeslot

        return {
          name: getOccupyTimeSlotFieldName(id),
          type: 'toggle',
          initialValue: isEdit ? Boolean(occupyTimeslot) : false,
        }
      }) as FieldProp[])
    : []

interface UseSetOccupyTimeSlotField {
  hasAppointmentTypeChanged: boolean
  isEdit: boolean
  isFirstRole: boolean
  occupyTimeSlotField: Field
  roleField: Field
  schedulerSettings: SchedulerSettings
}

export const useSetOccupyTimeSlotField = ({
  hasAppointmentTypeChanged,
  isEdit,
  isFirstRole,
  occupyTimeSlotField,
  roleField,
  schedulerSettings,
}: UseSetOccupyTimeSlotField) => {
  const isMounted = useRef(false)

  const handleOccupyTimeSlot = () => {
    if (roleField.value && !isFirstRole) {
      occupyTimeSlotField.setValue(!occupyTimeSlotField.value)
    }
  }

  useEffect(() => {
    // We must not run any side effect during mounted for appointment editions
    if (isEdit && !isMounted.current) {
      isMounted.current = true
      return
    }

    // unassigned fields should always populate as false
    if (!roleField.value) {
      occupyTimeSlotField.setValue(false)
      return
    }

    // Accordingly with scheduler settings we need to pre-select the field state
    if (schedulerSettings?.occupyAllStaffTimeslots) {
      occupyTimeSlotField.setValue(true)
    } else {
      occupyTimeSlotField.setValue(isFirstRole)
    }
  }, [
    schedulerSettings.occupyAllStaffTimeslots,
    roleField.value,
    isFirstRole,
    hasAppointmentTypeChanged,
  ])

  return handleOccupyTimeSlot
}

export const getMissingPaymentMethodReason = (
  reasonId: string | Nil,
  reasonsList: NamedEntity[],
) => findById(reasonId, reasonsList) as Constant

import React from 'react'
import { useTranslation } from 'react-i18next'
import { PuiAlert, PuiAlertProps } from '@pbt/pbt-ui-components'

export interface SoapAutochargedAlertProps extends PuiAlertProps {}

const SoapAutochargedAlert = ({
  open,
  onClose,
  ...rest
}: SoapAutochargedAlertProps) => {
  const { t } = useTranslation(['Common', 'Soap'])

  const message = t(
    'Soap:BASED_ON_APPOINTMENT_CRITERIA_BUNDLE_ITEMS_WERE_ADDED_TO_INVOICE',
  )

  return (
    <PuiAlert
      message={message}
      okButtonText={`${t('Common:GOT_IT')}!`}
      open={open}
      onClose={onClose}
      onOk={onClose}
      {...rest}
    />
  )
}

export default SoapAutochargedAlert

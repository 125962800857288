import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ArrowForward as ArrowForwardIcon,
  CheckCircle,
  CheckCircleOutline,
} from '@mui/icons-material'
import { DateUtils, Text, User, Utils } from '@pbt/pbt-ui-components'
import {
  AddNote,
  TasksDashboard as TasksDashboardIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import TaskStateLabel from '~/components/dashboard/tasks-dashboard/TaskStateLabel'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  LandingType,
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import i18n from '~/locales/i18n'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import { editTaskState, partialEditTask } from '~/store/actions/tasks'
import {
  fetchWidgetsData,
  getWidgetData,
  getWidgetDataIsLoading,
  getWidgetDataTotalCount,
} from '~/store/duck/landing'
import { useTaskEvent } from '~/store/hooks/tasks'
import {
  getFeatureToggle,
  getTasksWidgetAssignedMode,
} from '~/store/reducers/constants'
import { getMultipleTasks, getTasksIsLoading } from '~/store/reducers/tasks'
import { EasterEggEvents, Task, WidgetColumn } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import ClientAndPatientCell from '../ClientAndPatientCell'
import DateGroupingCell from '../DateGroupingCell'
import LandingWidget from '../LandingWidget'
import NoRecords from '../NoRecords'
import TableWidget from '../TableWidget'
// @ts-ignore
import useEnrichGroupedItemsByDate from '../useEnrichGroupedItemsByDate'
import UserSelectActionPopper from '../UserSelectActionPopper'
import { getVisibleTasks, getVisibleWidgetColumns } from '../widgetUtils'
import TaskDetailsCell from './TaskDetailsCell'
import TaskTypeCell from './TaskTypeCell'

const columns: WidgetColumn<Task>[] = [
  {
    id: 'dateGrouping',
    prop: (item) => <DateGroupingCell title={item?.dateTitle} />,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 1.5,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 1.5,
      [WidgetWidthType.HALF_WIDTH]: 1.5,
      [WidgetWidthType.THIRD_WIDTH]: 2,
    },
    label: i18n.t('Common:DATE_TIME'),
  },
  {
    id: 'dueTime',
    prop: (item) => (
      <Text strong variant="body2">
        {item.dueDatetime ? DateUtils.formatTime(item.dueDatetime) : ''}
      </Text>
    ),
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 1,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 1,
      [WidgetWidthType.HALF_WIDTH]: 1.5,
      [WidgetWidthType.THIRD_WIDTH]: 1.5,
    },
    label: i18n.t('Common:DUE_DATE'),
  },
  {
    id: 'taskDetails',
    width: 2,
    component: TaskDetailsCell,
    widthTypes: [
      WidgetWidthType.FULL_WIDTH,
      WidgetWidthType.TWO_THIRDS_WIDTH,
      WidgetWidthType.HALF_WIDTH,
      WidgetWidthType.THIRD_WIDTH,
    ],
    label: i18n.t('Common:DETAILS'),
  },
  {
    id: 'clientAndPatient',
    prop: (item) => <ClientAndPatientCell item={item} />,
    width: 1.5,
    widthTypes: [
      WidgetWidthType.FULL_WIDTH,
      WidgetWidthType.TWO_THIRDS_WIDTH,
      WidgetWidthType.HALF_WIDTH,
    ],
    label: i18n.t('Common:CLIENT_AND_PATIENT'),
  },
  {
    id: 'spaceName',
    prop: (item) => item.space?.name,
    width: 2,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:SPACE_ONE'),
  },
  {
    id: 'taskType',
    component: TaskTypeCell,
    width: 2,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:TASK_TYPE'),
  },
  {
    id: 'taskState',
    prop: (item) => <TaskStateLabel stateId={item.stateId} variant="small" />,
    width: 1.5,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:STATE'),
  },
]

interface TasksWidgetProps {
  onExpand: () => void
  rowsCount?: number
  widthType?: WidgetWidthType
}

const TasksWidget = ({
  widthType = WidgetWidthType.FULL_WIDTH,
  rowsCount = 10,
  onExpand,
  ...rest
}: TasksWidgetProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Landing', 'Tasks'])

  const TasksWidgetAssignedMode = useSelector(getTasksWidgetAssignedMode)
  const taskIds = useSelector(
    getWidgetData(LandingType.LANDING_DASHBOARD, LandingWidgetName.MY_TASKS),
  )
  const totalCount = useSelector(
    getWidgetDataTotalCount(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.MY_TASKS,
    ),
  )
  const isLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.MY_TASKS,
    ),
  )
  const tasksIsLoading = useSelector(getTasksIsLoading)
  const tasks = useSelector(getMultipleTasks(taskIds))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isEasterEggEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EASTER_EGG),
  )
  const isTaskListOracleIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.TASK_LIST_ORACLE_INTEGRATION),
  )

  const visibleTasks = getVisibleTasks(tasks, rowsCount)

  const tasksList = useEnrichGroupedItemsByDate({
    dateProp: 'dueDatetime',
    titleProp: 'dateTitle',
    items: visibleTasks,
  })

  const [openNotesDialog] = useDialog(DialogNames.NOTES)
  const [openTaskDialog] = useDialog(DialogNames.TASK)

  const TaskType = useTaskEvent()
  const TaskStates = TaskType.states
  const DoneState = Utils.findConstantIdByName('Done', TaskStates)
  const OpenState = Utils.findConstantIdByName('Open', TaskStates)

  const refreshTaskWidget = () => {
    dispatch(
      fetchWidgetsData([LandingWidgetName.MY_TASKS], {
        quiet: false,
        landingType: LandingType.LANDING_DASHBOARD,
      }),
    )
  }

  const refreshWidgetAfterUpdate = useCloseAfterCreation(
    refreshTaskWidget,
    getTasksIsLoading,
  )

  const updateTask = (task: Partial<Task> & { id: string }) => {
    dispatch(partialEditTask(task))
  }

  const handleNavigateToTaskDashboard = () => navigate('/tasks-dashboard')
  const navigateToTask = (id?: string) =>
    navigate(id ? `/tasks-dashboard/${id}` : '/tasks-dashboard')

  const handleNavigateToItemDetails = (item: Task) => navigateToTask(item.id)

  const handleViewInTaskDashboard = (item?: Task) => navigateToTask(item?.id)

  const handleGoToSoap = (item: Task) => {
    navigate(
      addOriginalBusinessId(
        `/soap/${item.soapId}`,
        isPatientSharingEnabled ? item.businessId : null,
      ),
    )
  }

  const handleMarkAsDone = (item?: Task) => {
    if (item) {
      if (isEasterEggEnabled) {
        dispatch(queueEasterEggEvent({ actionType: EasterEggEvents.TASK_DONE }))
      }
      dispatch(editTaskState(item.id, DoneState))
      refreshWidgetAfterUpdate()
    }
  }

  const handleMarkAsTodo = (item?: Task) => {
    if (item) {
      dispatch(editTaskState(item.id, OpenState))
      refreshWidgetAfterUpdate()
    }
  }

  const handleAddNote = (item?: Task) => {
    if (item) {
      openNotesDialog({
        name: item.name,
        notes: item.notes,
        onUpdateNotes: (notes: string) => updateTask({ id: item.id, notes }),
        isLoadingSelector: getTasksIsLoading,
      })
    }
  }

  const handleReAssign = (assignee: User, item?: Task) => {
    if (item) {
      updateTask({ id: item.id, assigneeId: assignee.id })
      refreshWidgetAfterUpdate()
    }
  }

  const isTaskDone = (task?: Task) => task?.stateId === DoneState

  const handleCreateTask = () =>
    openTaskDialog({ onTaskCreated: refreshTaskWidget })

  const getActions = (item?: Task) => {
    const todoStatusActions = isTaskDone(item)
      ? [
          {
            id: 'markAsDone',
            label: t('Tasks:LABEL.MARK_AS_TO-DO'),
            Icon: CheckCircleOutline,
            onClick: () => handleMarkAsTodo(item),
          },
        ]
      : isTaskListOracleIntegrationEnabled && item?.forChewyVcr
        ? []
        : [
            {
              id: 'markAsDone',
              label: t('Tasks:LABEL.MARK_AS_DONE'),
              Icon: CheckCircle,
              onClick: () => handleMarkAsDone(item),
            },
          ]

    return [
      {
        id: 'taskDashboard',
        label: t('Common:VIEW_IN_TASK_DASHBOARD'),
        Icon: TasksDashboardIcon,
        onClick: () => handleViewInTaskDashboard(item),
      },
      item?.soapId && {
        id: 'goToSoap',
        label: t('Common:GO_TO_SOAP'),
        Icon: ArrowForwardIcon,
        onClick: () => handleGoToSoap(item),
      },
      ...todoStatusActions,
      {
        id: 'addNote',
        label: t('Common:ADD_A_NOTE'),
        Icon: AddNote,
        onClick: () => handleAddNote(item),
      },
      {
        id: 'reAssign',
        label: t('Common:RE-ASSIGN'),
        Icon: null,
        subItem: UserSelectActionPopper,
        onClick: (value) => handleReAssign(value, item),
      },
    ] as PopperAction[]
  }

  const visibleColumns = getVisibleWidgetColumns(columns, widthType)

  return (
    <LandingWidget
      showHeaderTitles
      assignedModes={TasksWidgetAssignedMode}
      columns={visibleColumns}
      component={TableWidget}
      data={tasksList}
      getActions={getActions}
      isLoading={isLoading || tasksIsLoading}
      navigateToDetailsTooltip={t('Landing:TASKS_WIDGET.GO_TO_TASKS')}
      navigateToItemDetailsTooltip={t('Common:VIEW_IN_TASK_DASHBOARD')}
      noRecords={
        <NoRecords
          action={t('Common:ADD_TASK')}
          text={t('Landing:TASKS_WIDGET.YOU_HAVE_NO_TASKS')}
          onClick={handleCreateTask}
        />
      }
      rowsCount={rowsCount}
      totalCount={totalCount}
      onExpand={onExpand}
      onNavigateToDetails={() => handleNavigateToTaskDashboard()}
      onNavigateToItemDetails={handleNavigateToItemDetails}
      {...rest}
    />
  )
}

export default TasksWidget
